<div class="row">
    <div class="col-md-12">
        <nb-card class="own-scroll">
            <nb-card-header>
                <div class="row">
                    <div class="col-md-10">
                        Set session types
                    </div>
                </div>
            </nb-card-header>
        </nb-card>
        <nb-card class="own-scroll">

            <nb-card-body [nbSpinner]="loadingSessionTypes" nbSpinnerStatus="primary" nbSpinnerSize="large">
                <div id="data-grid-demo">
                    <dx-data-grid #grid id="gridContainer" [dataSource]="sessionTypes" keyExpr="id"
                        [focusedRowEnabled]="true" [showBorders]="true"
                        (onFocusedRowChanging)="onFocusedRowChanging($event)" (onEditingStart)="onEditingStart($event)"
                        (onSaving)="onSaving($event)" (onFocusedRowChanged)="onFocusedRowChanged($event)"
                        (onRowUpdated)="updateSessionType($event)" (onRowInserting)="addSessionType($event)"
                        (onRowRemoving)="deleteSessionType($event)">

                        <dxo-paging [enabled]="false"></dxo-paging>
                        <dxo-editing mode="form" [refreshMode]="refreshMode" [allowUpdating]="true"
                            [allowDeleting]="true" [allowAdding]="true">
                            <!-- control uploading image -->
                            <dxi-item itemType="group" caption="Image" [colCount]="2" [colSpan]="2">
                                <dxi-item dataField="imageUrl" [colSpan]="2"></dxi-item>
                            </dxi-item>
                            <dxi-item itemType="group" caption="Video" [colCount]="2" [colSpan]="2">
                                <dxi-item dataField="videoURL" [colSpan]="2"></dxi-item>
                            </dxi-item>
                            <dxi-item itemType="group" caption="Media" [colCount]="2" [colSpan]="2">
                                <dxi-item dataField="mediaURL" [colSpan]="2"></dxi-item>
                            </dxi-item>
                        </dxo-editing>
                        <dxo-filter-row [visible]="showFilterRow" [applyFilter]="currentFilter"></dxo-filter-row>
                        <dxo-header-filter [visible]="showHeaderFilter"></dxo-header-filter>
                        <dxo-search-panel [visible]="true" [width]="240" placeholder="Search...">

                        </dxo-search-panel>
                        <dxi-column dataField="name">

                        </dxi-column>
                        <dxi-column dataField="nameAr">
                            <dxi-validation-rule type="required"></dxi-validation-rule>

                        </dxi-column>
                        <dxi-column dataField="nameEn">
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dxi-column>
                        <dxi-column dataField="description">

                        </dxi-column>
                        <dxi-column dataField="descriptionAr">

                        </dxi-column>
                        <dxi-column dataField="descriptionEn">

                        </dxi-column>
                        <dxi-column dataField="duration" dataType='number'>

                        </dxi-column>
                        <dxi-column dataField="numberOfUsers" dataType='number'>
                        </dxi-column>
                        <dxi-column dataField="price" dataType='number'>
                        </dxi-column>
                        <dxi-column dataField="isDevice" dataType='boolean'>
                        </dxi-column>
                        <dxi-column dataField="userTypeId" caption="User type" [width]="125">
                            <dxo-lookup [dataSource]="userTypes" displayExpr="title" valueExpr="id">
                            </dxo-lookup>
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dxi-column>
                        <dxi-column dataField="imageUrl" [width]="70" cellTemplate="imageCellTemplate"
                            editCellTemplate="imageEditCellTemplate">
                        </dxi-column>
                        <dxi-column dataField="videoURL" [width]="70" cellTemplate="videoCellTemplate"
                            editCellTemplate="videoEditCellTemplate">
                        </dxi-column>
                        <dxi-column dataField="mediaURL" [width]="70" cellTemplate="mediaCellTemplate"
                            editCellTemplate="mediaEditCellTemplate">
                        </dxi-column>
                        <div *dxTemplate="let data of 'imageCellTemplate'">
                            <img [src]="data.value" width="70" height="70" />
                        </div>
                        <div *dxTemplate="let data of 'imageEditCellTemplate'">
                            <img #uploadedImage class="uploadedImage" width="70" height="70" [src]="data.value" />
                            <dx-file-uploader #fileUploader [multiple]="false" uploadMode="instantly"
                                (onValueChanged)="onImageValueChanged($event)" (onUploaded)="onUploaded($event, data)"
                                (onUploadError)="onUploadError($event)">
                            </dx-file-uploader>
                        </div>
                        <div *dxTemplate="let data of 'videoCellTemplate'">
                            <img [src]="data.value" width="70" height="70" />
                        </div>
                        <div *dxTemplate="let data of 'videoEditCellTemplate'">
                            <img #uploadedImage class="uploadedImage" width="70" height="70" [src]="data.value" />
                            <dx-file-uploader #fileUploader [multiple]="false" uploadMode="instantly"
                                (onValueChanged)="onVideoValueChanged($event)" (onUploaded)="onUploaded($event, data)"
                                (onUploadError)="onUploadError($event)"></dx-file-uploader>
                        </div>
                        <div *dxTemplate="let data of 'mediaCellTemplate'">
                            <img [src]="data.value" width="70" height="70" />
                        </div>
                        <div *dxTemplate="let data of 'mediaEditCellTemplate'">
                            <img #uploadedImage class="uploadedImage" width="70" height="70" [src]="data.value" />
                            <dx-file-uploader #fileUploader [multiple]="false" uploadMode="instantly"
                                (onValueChanged)="onMediaValueChanged($event)" (onUploaded)="onUploaded($event, data)"
                                (onUploadError)="onUploadError($event)"></dx-file-uploader>
                        </div>
                    </dx-data-grid>
                </div>
            </nb-card-body>
        </nb-card>



    </div>
</div>