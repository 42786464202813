<div class="row">
    <div class="col-md-12">
        <nb-card class="own-scroll">
            <nb-card-header>
                <div class="row">
                    <div class="col-md-10">
                        Show free slots
                    </div>
                </div>
            </nb-card-header>
        </nb-card>
        <nb-card class="own-scroll">

            <nb-card-body [nbSpinner]="loadingAppUsers" [nbSpinner]="loadingAppUsers" nbSpinnerMessage="loading"
                nbSpinnerStatus="primary">

                <div id="data-grid-demo">
                    <div>
                        <nb-card *ngIf=" appUser !=null">
                            <nb-card-header>
                                Full name
                            </nb-card-header>
                            <nb-card-body>
                                <div class="h2" style="text-align: center;">{{appUser?.fullName}} </div>
                            </nb-card-body>
                        </nb-card>
                    </div>
                    <div class="row">


                        <div class="col-md-11">

                            <div class="col-md-3">
                                <div class="h5">1</div>
                                <label width class="label">Choose a doctor<span style="color: green;">(Optional)</span>
                                    Default: Most Available</label>
                                <ngx-search-dropdown [items]="doctors" [size]="'small'" [img]="'product'"
                                    [label]="'fullName'" (afterChange)="selectDoctor()" [uid]="'id'"
                                    [(ngModel)]="doctorFullName">
                                </ngx-search-dropdown>
                            </div>
                            <div class="col-md-3">
                                <div class="h5">2</div>
                                <label class="label">Choose a day<span style="color: green;">(optional)</span>
                                    Default: Today</label>
                                <input fullWidth type="date" [min]="minDate" nbInput [(ngModel)]="DayOfSlots">
                            </div>
                            <div class="col-md-3">
                                <div class="h5">3 <i class="fa fa-question-circle" aria-hidden="true"></i>
                                </div>
                                <label class="label">Choose a session type <span
                                        style="color: red;">(required)</span></label>
                                <ngx-search-dropdown [items]="sessionTypes" [size]="'small'" [img]="'product'"
                                    [label]="'nameEn'" (afterChange)="selectSessionType()" [uid]="'id'"
                                    [(ngModel)]="sessionTypeName">
                                </ngx-search-dropdown>
                            </div>
                            <div class="col-md-3">
                                <div class="h5">4 <i class="fa fa-question-circle" aria-hidden="true"></i>
                                </div>
                                <label class="label">Choose a branch <span style="color: red;">(required)</span></label>
                                <ngx-search-dropdown [items]="branches" [size]="'small'" [label]="'nameEn'"
                                    (afterChange)="selectBranch()" [uid]="'id'" [(ngModel)]="branchName">
                                </ngx-search-dropdown>
                            </div>
                        </div>
                        <div style="width: 100%; text-align: center;">
                            <button class="button" (click)="getFreeSlotsAction()">Get free
                                Slots</button>

                        </div>

                    </div>
                    <hr>

                    <div [nbSpinner]="loadingGetSlots" nbSpinnerMessage="loading Slots" nbSpinnerStatus="primary"
                        nbSpinnerSize="large">

                        <dx-data-grid #dataGridRef id="gridContainer" [dataSource]="timeSlots" keyExpr="id"
                            [hoverStateEnabled]="true" [showBorders]="true" [selectedRowKeys]="[]"
                            [focusedRowEnabled]="true">
                            <dxo-paging [pageSize]="10"> </dxo-paging>
                            <dxo-pager [visible]="true" [allowedPageSizes]="allowedPageSizes"
                                [displayMode]="displayMode" [showPageSizeSelector]="showPageSizeSelector"
                                [showInfo]="showInfo" [showNavigationButtons]="showNavButtons">
                            </dxo-pager>
                            <dxo-filter-row [visible]="showFilterRow" [applyFilter]="currentFilter"></dxo-filter-row>
                            <dxo-header-filter [visible]="showHeaderFilter"></dxo-header-filter>

                            <dxo-search-panel [visible]="true" [width]="240" placeholder="Search...">
                            </dxo-search-panel>
                            <dxi-column dataField="id" dataType="number">
                            </dxi-column>
                            <dxi-column dataField="from" dataType="datetime">
                            </dxi-column>

                            <dxi-column dataField="to" dataType="datetime">
                            </dxi-column>
                            <dxi-column dataField="userId" caption="Doctor ID">
                            </dxi-column>
                            <dxi-column dataField="userFullName" caption="Doctor Full Name">
                            </dxi-column>

                            <dxi-column caption="Choose slot" type="buttons">
                                <dxi-button hint="select Slot" text="de/select slot" [onClick]="getSelectedData">
                                </dxi-button>
                            </dxi-column>
                        </dx-data-grid>
                    </div>


                </div>
            </nb-card-body>
        </nb-card>
    </div>
    <div class="col-md-12">
        <nb-card class="own-scroll">
            <nb-card-header>
                <div class="row">
                    <div class="col-md-10">
                        control User Sessions
                    </div>
                </div>
            </nb-card-header>
        </nb-card>
        <nb-card class="own-scroll">

            <nb-card-body [nbSpinner]="loadingSessions" nbSpinnerStatus="primary" nbSpinnerSize="large">
                <div id="data-grid-demo">
                    <div class="container border border-3 border-blue rounded p-3 d-flex justify-content-center m-3"
                        *ngIf="timeSlot!=null">
                        <div class="row row-cols-12">
                            <div class="h5"> Selected slot</div>
                            <div class="col">From: {{
                                timeSlot.from
                                }}</div>
                            <div class="col">To: {{
                                timeSlot.to
                                }}</div>
                            <div class="col">Doctor: {{
                                timeSlot.userFullName
                                }}</div>
                            <div class="col">sessionType: {{
                                timeSlot.sessionTypeName
                                }}</div>
                            <div class="col">Branch: {{
                                timeSlot.branchName
                                }}</div>

                        </div>
                    </div>
                    <dx-data-grid #grid id="gridContainer" [dataSource]="sessions" keyExpr="id"
                        [focusedRowEnabled]="true" [showBorders]="true"
                        (onFocusedRowChanging)="onFocusedRowChanging($event)" (onEditCanceled)="cancelEditing()"
                        (onInitNewRow)="initializeTimeSlot($event)" (onRowUpdating)="updateTimeSlot($event)"
                        (onEditingStart)="updateTimeSlot($event)" (onSaving)="onSaving($event)"
                        (onRowUpdated)="updateSession($event)" (onFocusedRowChanged)="onFocusedRowChanged($event)"
                        (onRowInserting)="addSessionForUser($event)" (onRowRemoving)="deleteSession($event)">

                        <dxo-paging [enabled]="false"></dxo-paging>
                        <dxo-editing mode="form" [refreshMode]="refreshMode" [allowAdding]="true" [allowUpdating]="true"
                            [allowDeleting]="true">
                        </dxo-editing>
                        <dxo-filter-row [visible]="showFilterRow" [applyFilter]="currentFilter"></dxo-filter-row>
                        <dxo-header-filter [visible]="showHeaderFilter"></dxo-header-filter>
                        <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>
                        <dxi-column dataField="from" [allowEditing]="false" dataType="datetime">
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dxi-column>
                        <dxi-column dataField="to" [allowEditing]="false" dataType="datetime">
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dxi-column>
                        <dxi-column dataField="sessionTypeId" [allowEditing]="false" caption="Session Type">
                            <dxo-lookup [dataSource]="sessionTypes" displayExpr="nameEn" valueExpr="id">
                            </dxo-lookup>
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dxi-column>
                        <dxi-column dataField="doctorId" [allowEditing]="false" caption="Doctor">
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                            <dxo-lookup [dataSource]="doctors" displayExpr="fullName" valueExpr="id">
                            </dxo-lookup>
                        </dxi-column>
                        <dxi-column dataField="branchId" [allowEditing]="false" caption="Branch">
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                            <dxo-lookup [dataSource]="branches" displayExpr="nameEn" valueExpr="id">
                            </dxo-lookup>
                        </dxi-column>
                        <dxi-column dataField="sessionStatus" caption="session status">
                            <dxo-lookup [dataSource]="sessionStatuses" displayExpr="name" valueExpr="id">
                            </dxo-lookup>
                        </dxi-column>
                        <dxi-column dataField="description">
                        </dxi-column>

                        <dxi-column dataField="comments" caption="comment">
                        </dxi-column>
                    </dx-data-grid>
                </div>
            </nb-card-body>
        </nb-card>



    </div>
</div>