import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { NbDialogService } from "@nebular/theme";
import { ArticleService } from "../../../../@core/app-services/article.service";
import { InterestService } from "../../../../@core/app-services/interest.service";
import { ToasterHelper } from "../../../../@core/app-services/toasterHelper";
import { Article } from "../../../../@core/models/article";
import { ArticleInterest } from "../../../../@core/models/articleInterest";
import { Interest } from "../../../../@core/models/interest";
import { AwsRepoConfig, AwsS3Manage } from "../../../../@core/lib/AwsS3Manage";
@Component({
  selector: "ngx-article",
  templateUrl: "./article.component.html",
  styleUrls: ["./article.component.scss"],
})
export class ArticleComponent implements OnInit {
  gettingPatientData: boolean = true;
  saving: boolean = false;
  awsS3Manage: AwsS3Manage = new AwsS3Manage();
  appUserId: number;
  imageFile: File;
  article: Article = new Article();
  articles: Article[] = [];
  interest: Interest = new Interest();
  interests: Interest[] = [];
  articleInterest: ArticleInterest = new ArticleInterest();
  articleInterests: ArticleInterest[] = [];
  isNewArticle: boolean = false;
  isUpdatedArticle: boolean = false;
  articleName: string = "";
  editorWidth: string = "370px";
  deleting: boolean = false;
  dialogWidth: number = 370;
  refreshMode: string;
  taskSubject: string;
  taskDetailsHtml: SafeHtml;
  taskStatus: string;
  taskProgress: string;
  currentFilter: any;
  showFilterRow: boolean = true;
  showHeaderFilter: boolean = true;
  autoNavigateToFocusedRow = true;
  isDisabled: boolean = true;
  loadingArticleInterests: boolean = false;
  interestName: string = "";
  selectedImage: string = "";

  constructor(
    private articleService: ArticleService,
    private toasterHelper: ToasterHelper,
    private sanitizer: DomSanitizer,
    private router: Router,
    private dialogService: NbDialogService,
    private interestService: InterestService
  ) {}

  openDialog(dialogArticle) {
    this.dialogService.open(dialogArticle);
  }

  getArticles() {
    this.articleService.getArticles().subscribe(
      (data) => {
        if (data.status) {
          this.articles = data.responseObject;
        } else {
          this.articles = [];
        }
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
      }
    );
  }

  getArticleInterests() {
    this.articleService
      .getArticleInterestByInterestId(this.interest.id)
      .subscribe(
        (data) => {
          if (data.status) {
            this.articleInterests = data.responseObject;
          } else {
            this.articleInterests = [];
          }
        },
        (error) => {
          this.toasterHelper.showError("Something went wrong");
        }
      );
  }
  deleteArticle() {
    this.deleting = true;
    this.articleService.deleteArticle(this.article.id).subscribe(
      (data) => {
        if (data.status) {
          this.toasterHelper.showToast(
            "deleted successfully",
            data.message,
            "success"
          );
          this.article = new Article();
          this.getArticles();
        } else {
          this.toasterHelper.showToast("not deleted", data.message, "danger");
        }
        this.deleting = false;
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
        this.deleting = false;
      }
    );
    if (
      this.article.imageUrl != null ||
      this.article.imageUrl.length != 0 ||
      this.article.imageUrl !=
        "https://diethub-resources.s3.eu-central-1.amazonaws.com/defaultimage.jpg"
    ) {
      this.awsS3Manage
        .deleteFile({
          fileName:
            this.article.name + "." + this.article.imageUrl.split(".").pop(),
          location: AwsRepoConfig.articleURL,
        })
        .then((result) => {})
        .catch((error) => {
          this.toasterHelper.showError("Couldn't set a animation photo for ");
          console.log(error.message);
        });
    }
  }
  selectArticle() {
    if (this.articleName != undefined) {
      this.article = this.articles.find(
        (p) => p.name.trim() == this.articleName.trim()
      );
    }
  }
  ngOnInit(): void {
    this.getArticles();
    this.getInterests();
  }
  async saveUpdate() {
    this.saving = true;
    if (this.imageFile) {
      await this.awsS3Manage
        .uploadFile({
          fileStream: this.imageFile,
          name: this.article.name + "." + this.imageFile.type.split("/").pop(),
          location: AwsRepoConfig.articleURL,
        })
        .then((result) => {
          this.article.imageUrl = result.Location;
        })
        .catch((error) => {
          this.toasterHelper.showError(
            "Couldn't set a image for " + this.article.name
          );
          this.article.imageUrl =
            "https://diethub-resources.s3.eu-central-1.amazonaws.com/defaultimage.jpg";
          console.log(error.message);
        });
    } else {
      this.article.imageUrl =
        "https://diethub-resources.s3.eu-central-1.amazonaws.com/defaultimage.jpg";
    }
    await this.articleService
      .updateArticle(this.article.id, this.article)
      .subscribe(
        (data) => {
          this.saving = false;
          this.toasterHelper.showToast(
            "Updated successfully",
            data.message,
            "info"
          );
        },
        (error) => {
          this.toasterHelper.showError("Something went wrong!");
          this.saving = false;
          console.log(error.message);
        }
      );
  }

  async saveNewArticle() {
    this.saving = true;
    if (this.imageFile) {
      await this.awsS3Manage
        .uploadFile({
          fileStream: this.imageFile,
          name: this.article.name + "." + this.imageFile.type.split("/").pop(),
          location: AwsRepoConfig.articleURL,
        })
        .then((result) => {
          this.article.imageUrl = result.Location;
        })
        .catch((error) => {
          this.toasterHelper.showError(
            "Couldn't set a image for " + this.article.name
          );
          this.article.imageUrl =
            "https://diethub-resources.s3.eu-central-1.amazonaws.com/defaultimage.jpg";
          console.log(error.message);
        });
    } else {
      this.article.imageUrl =
        "https://diethub-resources.s3.eu-central-1.amazonaws.com/defaultimage.jpg";
    }
    await this.articleService.addArticle(this.article).subscribe(
      async (data) => {
        this.saving = false;
        this.toasterHelper.showToast(
          "Added successfully",
          data.message,
          "success"
        );
        this.article = new Article();
        await this.getArticles();
      },
      (error) => {
        this.toasterHelper.showError("Please enter the required data!!");
        this.saving = false;
        console.log(error.message);
      }
    );
  }

  onFileSelected(e): void {
    this.imageFile = e.target.files[0] ?? null;
  }

  ShowNewAddArticle() {
    this.isNewArticle = true;
    this.isUpdatedArticle = false;
    this.article = new Article();
  }
  ShowUpdateArticle() {
    this.isNewArticle = false;
    this.isUpdatedArticle = true;
  }

  getInterests() {
    this.loadingArticleInterests = true;
    this.interestService.getInterests().subscribe(
      (data) => {
        if (data.status) {
          this.interests = data.responseObject;
        } else {
          this.interests = [];
        }
        this.loadingArticleInterests = false;
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
        this.loadingArticleInterests = false;
      }
    );
  }

  selectInterest() {
    this.interest = this.interests.find(
      (p) => p.nameEn.trim() == this.interestName.trim()
    );
    this.getArticleInterests();
    this.isDisabled = false;
  }

  // Article interests crud operations

  addArticleInterest(e) {
    this.loadingArticleInterests = true;
    let newArticleInterest = new ArticleInterest();
    this.articleInterest = e.data;
    newArticleInterest.interestId = this.interest.id;
    newArticleInterest.articleId = this.articleInterest.articleId;
    this.articleService.addArticleInterest(newArticleInterest).subscribe(
      (data) => {
        if (data.status) {
          this.toasterHelper.showToast(
            "Added successfully",
            data.message,
            "success"
          );
        } else {
          this.toasterHelper.showError(data.message);
        }
        this.loadingArticleInterests = false;

        this.getArticleInterests();
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
        this.loadingArticleInterests = false;
      }
    );
  }

  deleteArticleInterest(e) {
    this.loadingArticleInterests = true;
    this.articleInterest = e.data;
    this.articleService
      .deleteArticleInterest(this.articleInterest.id)
      .subscribe(
        (data) => {
          if (data.status) {
            this.toasterHelper.showToast(
              "Deleted successfully",
              data.message,
              "success"
            );
          } else {
            this.toasterHelper.showError(data.message);
          }
          this.loadingArticleInterests = false;
        },
        (error) => {
          this.toasterHelper.showError("Something went wrong");
          this.loadingArticleInterests = false;
        }
      );
  }

  onFocusedRowChanging(e) {
    const rowsCount = e.component.getVisibleRows().length;
    const pageCount = e.component.pageCount();
    const pageIndex = e.component.pageIndex();
    const key = e.event && e.event.key;
    const rowData = e.row && e.row.data;
    if (key && e.prevRowIndex === e.newRowIndex) {
      if (e.newRowIndex === rowsCount - 1 && pageIndex < pageCount - 1) {
        e.component.pageIndex(pageIndex + 1).done(() => {
          e.component.option("focusedRowIndex", 0);
        });
      } else if (e.newRowIndex === 0 && pageIndex > 0) {
        e.component.pageIndex(pageIndex - 1).done(() => {
          e.component.option("focusedRowIndex", rowsCount - 1);
        });
      }
    }
  }

  onFocusedRowChanged(e) {
    const rowData = e.row && e.row.data;
    // this.rowPackage= rowData;
    if (rowData) {
      this.taskSubject = rowData.Task_Subject;
      this.taskDetailsHtml = this.sanitizer.bypassSecurityTrustHtml(
        rowData.Task_Description
      );
      this.taskStatus = rowData.Task_Status;
      this.taskProgress = rowData.Task_Completion
        ? `${rowData.Task_Completion}` + "%"
        : "";
    }
  }
}
