export class Challenge {
  id: number;
  nameEn: string;
  nameAr: string;
  descriptionEn: string;
  descriptionAr: string;
  backgroundPictureURL: string;
  minWeight: number;
  maxWeight: number;
  minHeight: number;
  maxHeight: number;
  startDate: Date;
  endDate: Date;
  minPartcipants: number;
  promotionDate: Date;
}
