import { Component, Input, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label } from 'ng2-charts';
import { OperationPlanService } from '../../../@core/app-services/operationPlan.service';
import { MilestoneProgress } from '../../../@core/models/milestoneProgress';

@Component({
  selector: 'ngx-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss']
})
export class ChartComponent implements OnInit {
  private _milestoneId: number;
  public gettingData:boolean =true;
  @Input() set milestoneId(value: number) {
    this._milestoneId = value;
    if (value != null) {
      this.operationPlanService.getMilestoneProgress(this.milestoneId).subscribe(data=>{
        this.milestoneProgress=data;
        this.lineChartData = [{ data: this.milestoneProgress.map(({ matching }) => matching), label: 'Matching' },];
        this.lineChartLabels = this.milestoneProgress.map(({ date }) => date);
        this.gettingData = false;
      });
    }
  }
  get milestoneId(): number {
    return this._milestoneId;
  }
  public lineChartData: ChartDataSets[];
  public lineChartLabels: Label[] = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

  public lineChartColors: Color[] = [
    {
      borderColor: 'black',
      backgroundColor: 'rgba(255,0,0,0.3)',
    },
  ];
  public milestoneProgress:MilestoneProgress[]=new Array();
  public lineChartLegend = true;
  public lineChartType = 'line';
  public lineChartPlugins = [];

  constructor(private operationPlanService:OperationPlanService) { }

  ngOnInit(): void {
  }

}
