<div class="row">
    <div class="col-md-4">
        <nb-card [size]="'large'">
            <nb-card-header class="row">
                <div class="col-sm-7">
                    Payments By Date
                </div>
                <div class="col-sm-5">
                    <button nbButton (click)="openDialog(dialogPayment)">Add Payment</button>
                </div>
            </nb-card-header>
            <nb-card-body>
                <ngx-empty-state *ngIf="paymentDates.length==0" image="EmptyState.png" text="This patient has no payment">
                </ngx-empty-state>
                <table class="table" *ngIf="paymentDates.length>0">
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Debit</th>
                            <th>Credit</th>
                            <th>Balance</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr (click)="selectPaymentDay(paymentDay)" *ngFor="let paymentDay of paymentDates"
                            style="cursor: pointer;">
                            <td>
                                {{paymentDay.date | date: "dd-MM-yyyy"}}
                            </td>
                            <td [style.color]="paymentDay.debit < 0 ? 'red' : null">
                                {{ paymentDay.debit < 0 ? '(' +(paymentDay.debit * -1)+')' : paymentDay.debit}} </td>
                            <td [style.color]="paymentDay.credit < 0 ? 'red' : null">
                                {{ paymentDay.credit < 0 ? '(' +(paymentDay.credit * -1)+')' : paymentDay.credit}} </td>
                            <td [style.color]="paymentDay.balance < 0 ? 'red' : null">
                                {{ paymentDay.balance < 0 ? '(' +(paymentDay.balance * -1)+')' : paymentDay.balance}}
                                    </td>
                        </tr>
                    </tbody>
                </table>
            </nb-card-body>
            <nb-card-footer>
                Patient's Balance : <span [style.color]="balance < 0 ? 'red' : null"> {{ balance < 0 ? '(' +(balance *
                        -1)+')' : balance}} </span>
            </nb-card-footer>
        </nb-card>
    </div>
    <div class="col-md-8">
        <nb-card [size]="'large'">
            <nb-card-header class="row">
                <div class="col-md-10">
                    Selected Date: {{ selectedPaymentDay.date | date: "dd-MM-yyyy"}}
                </div>
                <div class="col-md-2">
                    <nb-action title="Add Payment" icon="plus-outline" (click)="openDialog(dialogPayment)">
                    </nb-action>
                </div>
            </nb-card-header>
            <nb-card-body>
                <div *ngIf="selectedPaymentDay.payments.length == 0">
                    <ngx-empty-state image="EmptyState.png" text="This patient has no payment">
                    </ngx-empty-state>
                    <div style="text-align: center;margin-top: 10px;">
                        <button (click)="openDialog(dialogPayment)" nbButton status="success">Add Payment</button>
                    </div>
                </div>
                <table class="table" *ngIf="selectedPaymentDay.payments.length>0" style="cursor: pointer;">
                    <thead>
                        <tr>
                            <th>Discription</th>
                            <th>Price</th>
                            <th>Comments</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let transaction of selectedPaymentDay.payments">
                            <td>
                                <span style="color: red;">{{ transaction.price < 0 ? 'Completed activity- '
                                        : 'Cash in- ' }}</span>
                                        {{transaction.activityTitle}}
                            </td>
                            <td [style.color]="transaction.price < 0 ? 'red' : null">
                                {{ transaction.price < 0 ? '(' +(transaction.price * -1)+')' : transaction.price}} </td>
                            <td>
                                {{transaction.comments }}
                            </td>
                            <td>
                                <nb-action title="Delete" icon="trash-2-outline" (click)="deletePayment(transaction.id)">
                                </nb-action>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </nb-card-body>
        </nb-card>
    </div>
</div>

<ng-template #dialogPayment let-data let-ref="dialogRef">
    <nb-card [size]="'small'">
        <nb-card-header>
            Add Payment
        </nb-card-header>
        <nb-card-body>
            <div class="form-group">
                <input fullWidth nbInput placeholder="Select Date" [(ngModel)]="this.newPayment.paymentDate"
                    [nbDatepicker]="datePicker">
                <nb-datepicker #datePicker></nb-datepicker>
            </div>
            <div class="form-group">
                <input fullWidth [(ngModel)]="this.newPayment.price" placeholder="Amount" type="text" nbInput>
            </div>
            <div class="form-group">
                <input fullWidth [(ngModel)]="this.newPayment.comments" placeholder="Comments" type="text" nbInput>
            </div>
        </nb-card-body>
        <nb-card-footer>
            <button nbButton (click)="addPayment(ref)">Pay</button>
        </nb-card-footer>
    </nb-card>
</ng-template>