import {
  NgModule,
  Component,
  enableProdMode,
  OnInit,
  ViewChild,
  TemplateRef,
} from "@angular/core";
import {
  BrowserModule,
  DomSanitizer,
  SafeHtml,
} from "@angular/platform-browser";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { Router } from "@angular/router";
import { NbDialogService } from "@nebular/theme";
import {
  DxDataGridComponent,
  DxDataGridModule,
  DxTreeViewComponent,
} from "devextreme-angular";
import { BranchService } from "../../../../@core/app-services/branch.service";
import { IdentityService } from "../../../../@core/app-services/identity.service";
import { ToasterHelper } from "../../../../@core/app-services/toasterHelper";
import { UserAvailabilityService } from "../../../../@core/app-services/user-availability.service";
import { AddUserAvailability } from "../../../../@core/models/addUserAvailability";
import { AvailabilitySession } from "../../../../@core/models/availabilitySession";
import { Branch } from "../../../../@core/models/Branch";
import { Day } from "../../../../@core/models/day";
import { IncreaseAvailability } from "../../../../@core/models/increaseAvailability";
import { MoveAvailability } from "../../../../@core/models/moveAvailability";
import { UserAvailability } from "../../../../@core/models/UserAvailability";
import { UserModel } from "../../../../@core/models/userModel";

@Component({
  selector: "ngx-user-availability",
  templateUrl: "./user-availability.component.html",
  styleUrls: ["./user-availability.component.scss"],
})
export class UserAvailabilityComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;
  UserAvailability: UserAvailability[] = [];
  upComingSessions: AvailabilitySession[] = [];
  AddUserAvailability: UserAvailability[] = [];
  Users: UserModel[];
  Branches: Branch[];
  User: UserModel;
  itemOfAvailability: UserAvailability;
  addAvailability: AddUserAvailability;
  moveAvailabilityItem: MoveAvailability;
  increaseAvailabilityItem: IncreaseAvailability;

  refreshMode: string;
  taskSubject: string;
  taskDetailsHtml: SafeHtml;
  taskStatus: string;
  taskProgress: string;
  isDisabled: boolean = true;
  currentFilter: any;
  showFilterRow: boolean = true;
  showHeaderFilter: boolean = true;
  autoNavigateToFocusedRow = true;
  isDeleteDialogShowed: boolean = false;
  loadingUsersAvailability: boolean = false;
  loadingUsers: boolean = false;

  days: Day[] = [
    {
      id: 0,
      name: "Sunday",
    },
    {
      id: 1,
      name: "Monday",
    },
    {
      id: 2,
      name: "Tuesday",
    },
    {
      id: 3,
      name: "Wednesday",
    },
    {
      id: 4,
      name: "Thursday",
    },
    {
      id: 5,
      name: "Friday",
    },
    {
      id: 6,
      name: "Saturday",
    },
  ];
  constructor(
    private userAvailabilityService: UserAvailabilityService,
    private branchService: BranchService,
    private identityService: IdentityService,
    private sanitizer: DomSanitizer,
    private toasterHelper: ToasterHelper,
    private router: Router,
    private dialogService: NbDialogService
  ) {
    this.moveAvailability = this.moveAvailability.bind(this);
    this.increaseAvailability = this.increaseAvailability.bind(this);
    this.WarningDeleteAvailabilityRow =
      this.WarningDeleteAvailabilityRow.bind(this);
    this.AddAvailabilityRow = this.AddAvailabilityRow.bind(this);
  }

  getUserAvailability(userId: string) {
    this.loadingUsersAvailability = true;
    this.userAvailabilityService.GetUserAvailability(userId).subscribe(
      (data) => {
        if (data.status) {
          this.UserAvailability = data.responseObject;
        } else {
          this.UserAvailability = [];
        }
        this.loadingUsersAvailability = false;
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
        this.loadingUsersAvailability = false;
      }
    );
  }
  getBranches() {
    this.branchService.getBranches().subscribe(
      (data) => {
        if (data.status) {
          this.Branches = data.responseObject;
        } else {
          this.Branches = [];
        }
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
      }
    );
  }
  getUsers() {
    this.loadingUsers = true;
    this.identityService.GetUsers().subscribe(
      (data) => {
        if (data.status) {
          this.Users = data.responseObject;
        } else {
          this.Users = [];
        }
        this.loadingUsers = false;
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
        this.loadingUsers = false;
      }
    );
  }
  selectUser(fullName: string) {
    this.User = this.Users.find((u) => u.fullName.trim() == fullName.trim());
    this.getUserAvailability(this.User.id);
    this.isDisabled = false;
  }
  increaseAvailability(e) {
    this.loadingUsersAvailability = true;
    let itemOfUserAvailability = new IncreaseAvailability();
    this.increaseAvailabilityItem = e.row.data;
    itemOfUserAvailability.id = this.increaseAvailabilityItem.id;
    itemOfUserAvailability.to = this.increaseAvailabilityItem.to;
    itemOfUserAvailability.from = this.increaseAvailabilityItem.from;
    this.userAvailabilityService
      .IncreaseAvailability(itemOfUserAvailability)
      .subscribe(
        (data) => {
          if (data.status) {
            this.toasterHelper.showToast(
              "updated successfully",
              data.message,
              "success"
            );
          } else {
            this.toasterHelper.showToast(
              "can't be updated",
              data.message,
              "danger"
            );
          }
          this.loadingUsersAvailability = false;
          this.getUserAvailability(this.User.id);
        },
        (error) => {
          this.toasterHelper.showError("Something went wrong");
          this.loadingUsersAvailability = false;
        }
      );
  }

  moveAvailability(e) {
    this.loadingUsersAvailability = true;
    let itemOfUserAvailability = new MoveAvailability();
    this.moveAvailabilityItem = e.row.data;
    itemOfUserAvailability.id = this.moveAvailabilityItem.id;
    itemOfUserAvailability.day = this.moveAvailabilityItem.day;
    itemOfUserAvailability.from = this.moveAvailabilityItem.from;
    itemOfUserAvailability.branchId = this.moveAvailabilityItem.branchId;
    this.userAvailabilityService
      .moveDoctorAvailability(itemOfUserAvailability)
      .subscribe(
        (data) => {
          if (data.status) {
            this.toasterHelper.showToast(
              "updated successfully",
              data.message,
              "success"
            );
          } else {
            this.toasterHelper.showToast(
              "can't be updated",
              data.message,
              "danger"
            );
          }
          this.loadingUsersAvailability = false;

          this.getUserAvailability(this.User.id);
        },
        (error) => {
          this.toasterHelper.showError("Something went wrong");
          this.loadingUsersAvailability = false;
        }
      );
  }
  AddAvailabilityRow(e) {
    this.loadingUsersAvailability = true;
    this.addAvailability = e.data;
    let itemOfUserAvailability = new AddUserAvailability();
    itemOfUserAvailability.doctorId = this.User.id;
    itemOfUserAvailability.day = this.addAvailability.day;
    itemOfUserAvailability.from = this.addAvailability.from;
    itemOfUserAvailability.to = this.addAvailability.to;
    itemOfUserAvailability.branchId = this.moveAvailabilityItem.branchId;
    itemOfUserAvailability.availabilityType = 0;
    this.userAvailabilityService
      .AddUserAvailability(itemOfUserAvailability)
      .subscribe(
        (data) => {
          if (data.status) {
            this.toasterHelper.showToast(
              "added successfully",
              data.message,
              "success"
            );
          } else {
            this.toasterHelper.showToast(
              "can't be added",
              data.message,
              "warning"
            );
          }
          this.loadingUsersAvailability = false;

          this.getUserAvailability(this.User.id);
        },
        (error) => {
          this.toasterHelper.showError("Something went wrong");
          this.loadingUsersAvailability = false;
        }
      );
  }
  WarningDeleteAvailabilityRow(e) {
    this.itemOfAvailability = e.row.data;
    this.userAvailabilityService
      .getAvailabilityUpcomingSessions(this.itemOfAvailability.id)
      .subscribe(
        (data) => {
          if (data.status) {
            this.upComingSessions = data.responseObject;
            this.isDeleteDialogShowed = true;
          } else {
            this.upComingSessions = [];
          }
        },
        (error) => {
          this.toasterHelper.showError("Something went wrong");
        }
      );
  }
  closeDialog() {
    this.isDeleteDialogShowed = false;
  }
  DeleteAvailabilityRow() {
    this.userAvailabilityService
      .DeleteAvailability(this.itemOfAvailability.id)
      .subscribe(
        (data) => {
          if (data.status) {
            this.toasterHelper.showToast("deleted", data.message, "success");
          } else {
            this.toasterHelper.showToast(
              "can't be deleted",
              data.message,
              "warning"
            );
          }
          this.isDeleteDialogShowed = false;
          this.getUserAvailability(this.User.id);
        },
        (error) => {
          this.toasterHelper.showError("Something went wrong");
        }
      );
  }
  onFocusedRowChanging(e) {
    const rowsCount = e.component.getVisibleRows().length;
    const pageCount = e.component.pageCount();
    const pageIndex = e.component.pageIndex();
    const key = e.event && e.event.key;

    if (key && e.prevRowIndex === e.newRowIndex) {
      if (e.newRowIndex === rowsCount - 1 && pageIndex < pageCount - 1) {
        e.component.pageIndex(pageIndex + 1).done(() => {
          e.component.option("focusedRowIndex", 0);
        });
      } else if (e.newRowIndex === 0 && pageIndex > 0) {
        e.component.pageIndex(pageIndex - 1).done(() => {
          e.component.option("focusedRowIndex", rowsCount - 1);
        });
      }
    }
  }
  onFocusedRowChanged(e) {
    const rowData = e.row && e.row.data;
    this.itemOfAvailability = rowData;
    if (rowData) {
      this.taskSubject = rowData.Task_Subject;
      this.taskDetailsHtml = this.sanitizer.bypassSecurityTrustHtml(
        rowData.Task_Description
      );
      this.taskStatus = rowData.Task_Status;
      this.taskProgress = rowData.Task_Completion
        ? `${rowData.Task_Completion}` + "%"
        : "";
    }
  }
  ngOnInit(): void {
    this.getUsers();
    this.getBranches();
  }
}
