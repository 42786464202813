<div id="visitscard">
    <nb-card>
        <nb-card-header>
            <div class="row">
                <h2 style="margin-left:auto;margin-right:auto;">Sessions History</h2>
            </div>
        </nb-card-header>
        <nb-card-body>
            <nb-tabset fullWidth>
                <nb-tab tabTitle="Visit Details" tabIcon="person-outline" badgeText="" badgeStatus="danger" fullWidth>
                    <nb-card-header class="row">
                        <div class="col-md-4">
                            Upcoming Sessions : {{ upcomingCount}}
                        </div>
                        <div class="col-md-4">
                            Missed Sessions : {{ missedCount}}
                        </div>
                        <div class="col-md-4">
                            Confirmed Sessions : {{ confirmedCount}}
                        </div>
                    </nb-card-header>
                    <nb-card-body>
                        <div class="row">

                            <dx-data-grid id="visitgrid" [dataSource]="sessions" keyExpr="id" [showBorders]="true"
                                [rowAlternationEnabled]="true" [allowColumnResizing]="true"
                                [allowColumnReordering]="true" #dataGrid>

                                <dxo-search-panel [visible]="true"></dxo-search-panel>
                                <dxo-group-panel [visible]="true"></dxo-group-panel>
                                <dxo-grouping #expand [autoExpandAll]="true"></dxo-grouping>

                                <dxi-column caption="Diet Comments" dataField="comments" [width]="550"></dxi-column>
                                <dxi-column caption="from" dataField="from"></dxi-column>
                                <dxi-column caption="to" dataField="to" [groupIndex]="0"></dxi-column>
                                <dxi-column caption="doctor" dataField="doctor" [groupIndex]="0"></dxi-column>
                                <dxi-column dataField="sessionStatus" caption="Session Status" [width]="125">
                                    <dxo-lookup [dataSource]="sessionStatuses" displayExpr="name" valueExpr="id">
                                    </dxo-lookup>
                                    <dxi-validation-rule type="required"></dxi-validation-rule>
                                </dxi-column>

                                <dxo-scrolling rowRenderingMode="virtual"> </dxo-scrolling>
                                <dxo-paging [pageSize]="5"> </dxo-paging>
                                <dxo-pager [visible]="true" [allowedPageSizes]="allowedPageSizes"
                                    [displayMode]="displayMode" [showPageSizeSelector]="showPageSizeSelector"
                                    [showInfo]="showInfo" [showNavigationButtons]="showNavButtons">
                                </dxo-pager>
                                <dxo-summary>
                                    <dxi-group-item column="date" summaryType="count" displayFormat="{0} visits">
                                    </dxi-group-item>
                                </dxo-summary>

                                <dxi-sort-by-group-summary-info summaryItem="count"></dxi-sort-by-group-summary-info>
                            </dx-data-grid>
                        </div>
                    </nb-card-body>
                </nb-tab>
                <nb-tab tabTitle="Body Line Chart" badgeText="" tabIcon="activity-outline" badgeStatus="danger">
                    <nb-card-header class="row">

                    </nb-card-header>
                    <nb-card-body>
                        <div class="row">
                            <dx-chart [dataSource]="sessions" #dxChart>
                                <dxo-size [height]="450" [width]="1270">
                                </dxo-size>
                                <dxi-series *ngFor="let line of lineChartModel" [valueField]="line.value"
                                    [name]="line.name">
                                </dxi-series>
                                <dxo-common-series-settings argumentField="date" type="spline">
                                    <dxo-point hoverMode="allArgumentPoints"></dxo-point>
                                </dxo-common-series-settings>

                                <dxo-crosshair [enabled]="true" color="#949494" [width]="3" dashStyle="dot">
                                    <dxo-label [visible]="true" backgroundColor="#949494">
                                        <dxo-font color="#fff" [size]="12"> </dxo-font>
                                    </dxo-label>
                                </dxo-crosshair>

                                <dxo-title text="Body Weight Line Chart">
                                    <dxo-subtitle text="(Weight progression over time)"></dxo-subtitle>
                                </dxo-title>
                                <dxo-tooltip [enabled]="true"></dxo-tooltip>
                                <dxo-export [enabled]="true"></dxo-export>
                            </dx-chart>
                        </div>
                    </nb-card-body>
                </nb-tab>
            </nb-tabset>
        </nb-card-body>
    </nb-card>
</div>
<dx-load-panel shadingColor="rgba(0,0,0,0.6)" [(visible)]="loadingVisible" [showIndicator]="true" [showPane]="true"
    [shading]="true" [height]="300" [width]="800"
    message='&#10102; Getting Client Visits ...  &#10103; Calculating Summaries ... &#10104; Drawing Body Charts ...'
    #loadPanel>
</dx-load-panel>