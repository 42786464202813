import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { DiseaseService } from "../../../../@core/app-services/disease.service";
import { MedicationService } from "../../../../@core/app-services/medication.service";
import { ToasterHelper } from "../../../../@core/app-services/toasterHelper";
import { Disease } from "../../../../@core/models/disease";
import { Medication } from "../../../../@core/models/medication";

@Component({
  selector: "ngx-medication",
  templateUrl: "./medication.component.html",
  styleUrls: ["./medication.component.scss"],
})
export class MedicationComponent implements OnInit {
  diseases: Disease[] = [];
  disease: Disease;
  medications: Medication[] = [];
  medication: Medication;
  refreshMode: string;
  taskSubject: string;
  taskDetailsHtml: SafeHtml;
  taskStatus: string;
  taskProgress: string;
  currentFilter: any;
  loadingDiseases: boolean = false;
  showFilterRow: boolean = true;
  loadingMedications: boolean = false;
  showHeaderFilter: boolean = true;
  autoNavigateToFocusedRow = true;
  constructor(
    private diseaseService: DiseaseService,
    private medicationService: MedicationService,
    private toasterHelper: ToasterHelper,
    private sanitizer: DomSanitizer,
    private router: Router
  ) {}

  // exercise type functions
  getDiseases() {
    this.loadingDiseases = true;
    this.diseaseService.getDiseases().subscribe(
      (data) => {
        if (data.status) {
          this.diseases = data.responseObject;
        } else {
          this.diseases = [];
        }
        this.loadingDiseases = false;
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
        this.loadingDiseases = false;
      }
    );
  }

  updateDisease(e) {
    this.loadingDiseases = true;
    let updatedDisease = new Disease();
    this.disease = e.data;
    updatedDisease.nameAr = this.disease.nameAr;
    updatedDisease.nameEn = this.disease.nameEn;
    updatedDisease.descriptionAr = this.disease.descriptionAr;
    updatedDisease.descriptionEn = this.disease.descriptionEn;

    this.diseaseService
      .updateDisease(this.disease.id, updatedDisease)
      .subscribe(
        (data) => {
          if (data.status) {
            this.toasterHelper.showToast(
              "Updated successfully",
              data.message,
              "success"
            );
          } else {
            this.toasterHelper.showError(data.message);
          }
          this.loadingDiseases = false;
          this.getDiseases();
        },
        (error) => {
          this.toasterHelper.showError("Something went wrong");
          this.loadingDiseases = false;
        }
      );
  }

  addDisease(e) {
    this.loadingDiseases = true;
    let addDisease = new Disease();
    this.disease = e.data;
    addDisease.nameAr = this.disease.nameAr;
    addDisease.nameEn = this.disease.nameEn;
    addDisease.descriptionAr = this.disease.descriptionAr;
    addDisease.descriptionEn = this.disease.descriptionEn;
    this.diseaseService.addDisease(addDisease).subscribe(
      (data) => {
        if (data.status) {
          this.toasterHelper.showToast(
            "Added successfully",
            data.message,
            "success"
          );
        } else {
          this.toasterHelper.showError(data.message);
        }
        this.loadingDiseases = false;
        this.getDiseases();
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
        this.loadingDiseases = false;
      }
    );
  }
  deleteDisease(e) {
    this.disease = e.data;
    this.diseaseService.deleteDisease(this.disease.id).subscribe(
      (data) => {
        if (data.status) {
          this.toasterHelper.showToast(
            "Deleted successfully",
            data.message,
            "success"
          );
        } else {
          this.toasterHelper.showError(data.message);
        }
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
        this.loadingDiseases = false;
      }
    );
  }
  // Medications functions
  getMedications() {
    this.loadingMedications = true;
    this.medicationService.getMedications().subscribe(
      (data) => {
        if (data.status) {
          this.medications = data.responseObject;
        } else {
          this.medications = [];
        }
        this.loadingMedications = false;
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
        this.loadingMedications = false;
      }
    );
  }

  updateMedication(e) {
    this.loadingMedications = true;
    let updatedMedication = new Medication();
    this.medication = e.data;
    updatedMedication.nameAr = this.medication.nameAr;
    updatedMedication.nameEn = this.medication.nameEn;
    updatedMedication.diseaseId = this.medication.diseaseId;
    updatedMedication.precautionsAr = this.medication.precautionsAr;
    updatedMedication.precautionsEn = this.medication.precautionsEn;
    updatedMedication.useIntructionAr = this.medication.useIntructionAr;
    updatedMedication.useIntructionEn = this.medication.useIntructionEn;
    this.medicationService
      .updateMedication(this.medication.id, updatedMedication)
      .subscribe(
        (data) => {
          if (data.status) {
            this.toasterHelper.showToast(
              "Updated successfully",
              data.message,
              "success"
            );
          } else {
            this.toasterHelper.showError(data.message);
          }
          this.loadingMedications = true;
          this.getMedications();
        },
        (error) => {
          this.toasterHelper.showError("Something went wrong");
          this.loadingMedications = false;
        }
      );
  }

  addMedication(e) {
    this.loadingMedications = true;
    let addMedication = new Medication();
    this.medication = e.data;
    addMedication.nameAr = this.medication.nameAr;
    addMedication.nameEn = this.medication.nameEn;
    addMedication.diseaseId = this.medication.diseaseId;
    addMedication.precautionsAr = this.medication.precautionsAr;
    addMedication.precautionsEn = this.medication.precautionsEn;
    addMedication.useIntructionAr = this.medication.useIntructionAr;
    addMedication.useIntructionEn = this.medication.useIntructionAr;
    this.medicationService.addMedication(addMedication).subscribe(
      (data) => {
        if (data.status) {
          this.toasterHelper.showToast(
            "Added successfully",
            data.message,
            "success"
          );
        } else {
          this.toasterHelper.showError(data.message);
        }
        this.loadingMedications = false;
        this.getMedications();
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
        this.loadingMedications = false;
      }
    );
  }
  deleteMedication(e) {
    this.loadingMedications = true;
    this.medication = e.data;

    this.medicationService.deleteMedication(this.medication.id).subscribe(
      (data) => {
        if (data.status) {
          this.toasterHelper.showToast(
            "Deleted successfully",
            data.message,
            "success"
          );
        } else {
          this.toasterHelper.showError(data.message);
        }
        this.loadingMedications = false;
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
        this.loadingMedications = false;
      }
    );
  }
  onFocusedRowChanging(e) {
    const rowsCount = e.component.getVisibleRows().length;
    const pageCount = e.component.pageCount();
    const pageIndex = e.component.pageIndex();
    const key = e.event && e.event.key;
    const rowData = e.row && e.row.data;
    if (key && e.prevRowIndex === e.newRowIndex) {
      if (e.newRowIndex === rowsCount - 1 && pageIndex < pageCount - 1) {
        e.component.pageIndex(pageIndex + 1).done(() => {
          e.component.option("focusedRowIndex", 0);
        });
      } else if (e.newRowIndex === 0 && pageIndex > 0) {
        e.component.pageIndex(pageIndex - 1).done(() => {
          e.component.option("focusedRowIndex", rowsCount - 1);
        });
      }
    }
  }
  onFocusedRowChanged(e) {
    const rowData = e.row && e.row.data;
    // this.rowPackage= rowData;
    if (rowData) {
      this.taskSubject = rowData.Task_Subject;
      this.taskDetailsHtml = this.sanitizer.bypassSecurityTrustHtml(
        rowData.Task_Description
      );
      this.taskStatus = rowData.Task_Status;
      this.taskProgress = rowData.Task_Completion
        ? `${rowData.Task_Completion}` + "%"
        : "";
    }
  }

  ngOnInit(): void {
    this.getMedications();
    this.getDiseases();
  }
}
