<div class="row">
    <div class="col-md-12">
        <nb-card class="own-scroll">
            <nb-card-header>
                <div class="row">
                    <div class="col-md-10">
                        Set Branches
                    </div>
                </div>
            </nb-card-header>
        </nb-card>
        <nb-card class="own-scroll">

            <nb-card-body [nbSpinner]="loadingBranches" nbSpinnerStatus="primary" nbSpinnerSize="large">
                <div id="data-grid-demo">
                    <dx-data-grid #grid id="gridContainer" [dataSource]="branches" keyExpr="id"
                        [focusedRowEnabled]="true" [showBorders]="true"
                        (onFocusedRowChanging)="onFocusedRowChanging($event)"
                        (onFocusedRowChanged)="onFocusedRowChanged($event)" (onRowUpdated)="updateBranch($event)"
                        (onRowInserting)="addBranch($event)" (onRowRemoving)="deleteBranch($event)"
                        (onEditingStart)="onEditingStart($event)" (onSaving)="onSaving($event)"
                        (onEditCanceled)="onEditCanceled($event)" (onSaved)="onSaved($event)">

                        <dxo-paging [enabled]="false"></dxo-paging>
                        <dxo-editing mode="form" [refreshMode]="refreshMode" [allowUpdating]="true"
                            [allowDeleting]="true" [allowAdding]="true">
                            <dxi-item itemType="group" caption="Photo" [colCount]="2" [colSpan]="2">
                                <dxi-item dataField="imageUrl" [colSpan]="2"></dxi-item>
                            </dxi-item>
                        </dxo-editing>
                        <dxo-filter-row [visible]="showFilterRow" [applyFilter]="currentFilter"></dxo-filter-row>
                        <dxo-header-filter [visible]="showHeaderFilter"></dxo-header-filter>
                        <dxo-search-panel [visible]="true" [width]="300" placeholder="Search...">

                        </dxo-search-panel>
                        <dxi-column dataField="imageUrl" [allowFiltering]="false" [allowSorting]="false"
                            cellTemplate="cellTemplate" editCellTemplate="editCellTemplate">
                        </dxi-column>
                        <dxi-column dataField="nameAr">
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dxi-column>
                        <dxi-column dataField="nameEn">
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dxi-column>
                        <dxi-column dataField="addressAr">
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dxi-column>
                        <dxi-column dataField="addressEn">
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dxi-column>
                        <dxi-column dataField="phone">
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dxi-column>

                        <div *dxTemplate="let data of 'cellTemplate'">
                            <img [src]="data.value" width="100" height="100" />
                        </div>
                        <div *dxTemplate="let data of 'editCellTemplate'">
                            <img #uploadedImage class="uploadedImage" width="100" height="100" [src]="data.value" />
                            <dx-file-uploader #fileUploader [multiple]="false" uploadMode="instantly"
                                (onValueChanged)="onValueChanged($event)" (onUploaded)="onUploaded($event, data)"
                                (onUploadError)="onUploadError($event)"></dx-file-uploader>
                        </div>
                    </dx-data-grid>
                </div>
            </nb-card-body>
        </nb-card>



    </div>
</div>


<div class="row">
    <div class="col-md-12">
        <nb-card class="own-scroll">
            <nb-card-header>
                <div class="row">
                    <div class="col-md-10">
                        control Branch Packages
                    </div>
                </div>
            </nb-card-header>
        </nb-card>
        <nb-card class="own-scroll">

            <nb-card-body [nbSpinner]="loadingBranchPackages" nbSpinnerStatus="primary" nbSpinnerSize="large">
                <div id="data-grid-demo">
                    <div style="width: 40%;">
                        <label>Choose an Branch</label><span style="color: red;">(required)</span>
                        <ngx-search-dropdown [items]="branches" [size]="'small'" [label]="'nameEn'"
                            (afterChange)="selectBranch()" [uid]="'id'" [(ngModel)]="branchName">
                        </ngx-search-dropdown>
                        <hr>
                    </div>
                    <dx-data-grid #grid id="gridContainer" [dataSource]="branchPackages" keyExpr="id"
                        [focusedRowEnabled]="true" [showBorders]="true"
                        (onFocusedRowChanging)="onFocusedRowChanging($event)"
                        (onFocusedRowChanged)="onFocusedRowChanged($event)" (onRowInserting)="addBranchPackage($event)"
                        (onRowRemoving)="deleteBranchPackage($event)" [disabled]="isDisabled">

                        <dxo-paging [enabled]="false"></dxo-paging>
                        <dxo-editing mode="form" [refreshMode]="refreshMode" [allowAdding]="true"
                            [allowDeleting]="true">
                        </dxo-editing>
                        <dxo-filter-row [visible]="showFilterRow" [applyFilter]="currentFilter"></dxo-filter-row>
                        <dxo-header-filter [visible]="showHeaderFilter"></dxo-header-filter>
                        <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>
                        <dxi-column dataField="packageId" caption="Package">
                            <dxo-lookup [dataSource]="packages" displayExpr="titleEn" valueExpr="id">
                            </dxo-lookup>
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dxi-column>



                    </dx-data-grid>
                </div>
            </nb-card-body>
        </nb-card>



    </div>
</div>