import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ToasterHelper } from "../../../@core/app-services/toasterHelper";
import { ApplicationUser } from "../../../@core/models/applicationUser";
import { Patient } from "../../../@core/models/patient";
import { User } from "../../../@core/models/user";
import { ClientSharedService } from "../../../@core/shared-services/client-shared.service";
import { MenuSharedService } from "../../../@core/shared-services/menu-shared.service";

@Component({
  selector: "ngx-patient-list",
  templateUrl: "./patient-list.component.html",
  styleUrls: ["./patient-list.component.scss"],
})
export class PatientListComponent implements OnInit {
  functionShowAll: boolean;
  constructor(
    public clientShared: ClientSharedService,
    private menuSharedService: MenuSharedService,
    private router: Router
  ) {}

  ngOnInit(): void {}
  redirect(client: ApplicationUser) {
    this.menuSharedService.setClientMenu(client.id, client.fullName);
    this.router.navigate(["/pages/dashboard/patient/" + client.id + "/index"]);
  }
  deleteClient(client: ApplicationUser) {
    this.clientShared.deleteClient(client);
    setTimeout(() => {
      this.router.navigate([this.router.url]);
    }, 2000);
  }
}
