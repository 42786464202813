import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "ngx-empty-state",
  templateUrl: "./empty-state.component.html",
  styleUrls: ["./empty-state.component.scss"],
})
export class EmptyStateComponent implements OnInit {
  src: string = "../../assets/images/";
  @Input() text: string = "";
  @Input() set image(value: string) {
    this.src += value;
  }
  constructor() {}

  ngOnInit(): void {}
}
