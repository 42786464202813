export class Payment {
  id: number;
  patientId: number;
  activityId: number | null;
  paymentDate: Date;
  price: number;
  activityTitle: string;
  agentId: string;
  comments: string;
}
