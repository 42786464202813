export class Package {
  id: number;
  titleAr: string;
  titleEn: string;
  descriptionAr: string;
  descriptionEn: string;
  isActive: boolean;
  priceBefore: number;
  discount: number;
  priceAfter: number;
  sessionsNumber: number;
  type: string;
  duration: number;
  priceInUSD: number;
}
