<div class="row">
    <div class="col-md-12">
        <nb-card class="own-scroll">
            <nb-card-header>
                <div class="row">
                    <div class="col-md-10">
                        control Plan meals
                    </div>
                </div>
            </nb-card-header>
        </nb-card>
        <nb-card class="own-scroll">

            <nb-card-body [nbSpinner]="loadingPackageMeals" nbSpinnerStatus="primary" nbSpinnerSize="large">
                <div id="data-grid-demo">
                    <div>
                        <label>Choose a plan</label>
                        <select #selectElem class="custom-select" (change)="selectPlan(selectElem.value)">
                            <option disabled selected value>select a plan</option>
                            <option *ngFor="let plan of plans" ([ngValue])="package">
                                {{plan.nameEn}}</option>
                        </select>
                        <hr>

                    </div>
                    <dx-data-grid #grid id="gridContainer" [dataSource]="packageMeals" keyExpr="id"
                        [focusedRowEnabled]="true" [showBorders]="true"
                        (onFocusedRowChanging)="onFocusedRowChanging($event)" (onRowUpdated)="updatePackageMeal($event)"
                        (onFocusedRowChanged)="onFocusedRowChanged($event)" (onRowInserting)="addPackageMeal($event)"
                        (onRowRemoving)="deletePackageMeal($event)" [disabled]="isDisabled">
                        <dxo-paging [enabled]="false"></dxo-paging>
                        <dxo-editing mode="form" [refreshMode]="refreshMode" [allowAdding]="true" [allowUpdating]="true"
                            [allowDeleting]="true">
                        </dxo-editing>
                        <dxo-filter-row [visible]="showFilterRow" [applyFilter]="currentFilter"></dxo-filter-row>
                        <dxo-header-filter [visible]="showHeaderFilter"></dxo-header-filter>
                        <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>
                        <dxi-column dataField="dateNumber" dataType="string">
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dxi-column>
                        <dxi-column dataField="sequance" dataType="number">
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dxi-column>
                        <dxi-column dataField="nameAr">
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dxi-column>
                        <dxi-column dataField="nameEn">
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dxi-column>
                        <dxi-column dataField="meal.id" caption="meal" [width]="125">
                            <dxo-lookup [dataSource]="meals" displayExpr="nameEn" valueExpr="id">
                            </dxo-lookup>
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dxi-column>


                    </dx-data-grid>
                </div>
            </nb-card-body>
        </nb-card>



    </div>
</div>