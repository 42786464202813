import { Component, OnInit, TemplateRef } from "@angular/core";
import { NbDialogService } from "@nebular/theme";
import { DataService } from "../../../../@core/app-services/data.service";
import { ToasterHelper } from "../../../../@core/app-services/toasterHelper";
import { ActivityType } from "../../../../@core/models/activityType";
import { ActivityTypeCategory } from "../../../../@core/models/activityTypeCategory";
import { UserType } from "../../../../@core/models/userType";

@Component({
  selector: "ngx-activity-manager",
  templateUrl: "./activity-manager.component.html",
  styleUrls: ["./activity-manager.component.scss"],
})
export class ActivityManagerComponent implements OnInit {
  categories: ActivityTypeCategory[] = new Array();
  activityTypes: ActivityType[] = new Array();
  userTypes: UserType[] = new Array();
  selectedCategory: ActivityTypeCategory = new ActivityTypeCategory();
  selectedActivityType: ActivityType = new ActivityType();
  gettingCategories: boolean = true;
  gettingActivityTypes: boolean = true;
  savingCategory: boolean = false;
  savingActivityType: boolean = false;
  constructor(
    private dataService: DataService,
    private dialogService: NbDialogService,
    private toasterHelper: ToasterHelper
  ) {
    dataService.getActivityTypeCategories().subscribe((data) => {
      this.categories = data;
      this.gettingCategories = false;
    });
    dataService.getUserTypes().subscribe((data) => {
      this.userTypes = data;
    });
    this.dataService.getActivityTypes().subscribe((data) => {
      this.activityTypes = data;
      this.gettingActivityTypes = false;
    });
  }
  openCategoryDialog(category: ActivityTypeCategory, dialog: TemplateRef<any>) {
    if (category == null) {
      this.selectedCategory = new ActivityTypeCategory();
    } else {
      this.selectedCategory.id = category.id;
      this.selectedCategory.title = category.title;
      this.selectedCategory.hold = category.hold;
    }
    this.dialogService.open(dialog);
  }
  openActivityTypeDialog(activityType: ActivityType, dialog: TemplateRef<any>) {
    if (activityType == null) {
      this.selectedActivityType = new ActivityType();
    } else {
      this.selectedActivityType.id = activityType.id;
      this.selectedActivityType.categoryId = activityType.categoryId;
      this.selectedActivityType.hold = activityType.hold;
      this.selectedActivityType.price = activityType.price;
      this.selectedActivityType.title = activityType.title;
      this.selectedActivityType.userTypeId = activityType.userTypeId;
      this.selectedActivityType.categoryTitle = activityType.categoryTitle;
      this.selectedActivityType.userTypeTitle = activityType.userTypeTitle;
    }
    this.dialogService.open(dialog);
  }
  saveActivity(ref) {
    this.savingActivityType = true;
    this.dataService
      .addOrUpdateActivityType(this.selectedActivityType)
      .subscribe((data) => {
        if (data.status) {
          this.toasterHelper.showSaved(data.message);
          var type = this.activityTypes.find(
            (c) => c.id == data.responseObject.id
          );
          const index = this.activityTypes.indexOf(type, 0);
          if (index > -1) {
            this.activityTypes.splice(index, 1);
          }
          data.responseObject.userTypeTitle = this.userTypes.find(
            (u) => u.id == data.responseObject.userTypeId
          ).title;
          data.responseObject.categoryTitle = this.categories.find(
            (u) => u.id == data.responseObject.categoryId
          ).title;
          this.activityTypes.push(data.responseObject);
          this.activityTypes.sort((n1, n2) => {
            if (n1.id > n2.id) {
              return 1;
            }
            if (n1.id < n2.id) {
              return -1;
            }
            return 0;
          });
          ref.close();
        } else {
          this.toasterHelper.showError(data.message);
        }
        this.savingActivityType = false;
      });
  }
  saveCategory(ref) {
    this.savingCategory = true;
    this.dataService
      .addOrUpdateActivityTypeCategory(this.selectedCategory)
      .subscribe((data) => {
        if (data.status) {
          this.toasterHelper.showSaved(data.message);
          var cat = this.categories.find((c) => c.id == data.responseObject.id);
          const index = this.categories.indexOf(cat, 0);
          if (index > -1) {
            this.categories.splice(index, 1);
          }
          this.categories.push(data.responseObject);
          this.categories.sort((n1, n2) => {
            if (n1.id > n2.id) {
              return 1;
            }
            if (n1.id < n2.id) {
              return -1;
            }
            return 0;
          });
          ref.close();
        } else {
          this.toasterHelper.showError(data.message);
        }
        this.savingCategory = false;
      });
  }
  deleteCategory(category: ActivityTypeCategory) {
    this.dataService
      .deleteActivityTypeCategory(category.id)
      .subscribe((data) => {
        if (data.status) {
          this.toasterHelper.showSaved(data.message);
          var cat = this.categories.find((c) => c.id == category.id);
          cat.hold = true;
        } else {
          this.toasterHelper.showError(data.message);
        }
      });
  }
  deleteActivity(activity: ActivityType) {
    this.dataService.deleteActivityType(activity.id).subscribe((data) => {
      if (data.status) {
        this.toasterHelper.showSaved(data.message);
        var type = this.activityTypes.find((c) => c.id == activity.id);
        type.hold = true;
      } else {
        this.toasterHelper.showError(data.message);
      }
    });
  }
  ngOnInit(): void {}
}
