<div class="row">
    <div class="col-md-5">
        <nb-card [size]="'large'">
            <nb-card-header>
                Journal Entries by dates
            </nb-card-header>
            <nb-card-body [nbSpinner]="gettingJournalEntries" nbSpinnerStatus="primary" nbSpinnerSize="large"
                nbSpinnerMessage="Getting Journal Entries">
                <ngx-empty-state *ngIf="journalEntriesDates.length==0 && !gettingJournalEntries" image="EmptyState.png"
                    text="There is no entries">
                </ngx-empty-state>
                <table class="table" *ngIf="journalEntriesDates.length>0">
                    <thead>
                        <tr>
                            <th>Date</th>
                            <th>Debit</th>
                            <th>Payment Credit</th>
                            <th>Transaction Credit</th>
                            <th>Balance</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr (click)="selectTreasuryDay(treasuryDay)" *ngFor="let treasuryDay of journalEntriesDates"
                            style="cursor: pointer;">
                            <td>
                                {{treasuryDay.date | date: "dd-MM-yyyy"}}
                            </td>
                            <td [style.color]="treasuryDay.transactionDebit < 0 ? 'red' : null">
                                {{ treasuryDay.debit < 0 ? '(' +(treasuryDay.transactionDebit * -1)+')' :
                                    treasuryDay.transactionDebit}} </td>
                            <td>
                                {{treasuryDay.paymentCredit}} </td>
                            <td>
                                {{treasuryDay.transactionCredit}} </td>
                            <td [style.color]="treasuryDay.balance < 0 ? 'red' : null">
                                {{ treasuryDay.balance < 0 ? '(' +(treasuryDay.balance * -1)+')' : treasuryDay.balance}}
                                    </td>
                        </tr>
                    </tbody>
                </table>
            </nb-card-body>
            <nb-card-footer>
                Treasury's Balance : <span [style.color]="balance < 0 ? 'red' : null"> {{ balance < 0 ? '(' +(balance *
                        -1)+')' : balance}} </span>
            </nb-card-footer>
        </nb-card>
    </div>
    <div class="col-md-7">
        <nb-card [size]="'large'">
            <nb-card-header class="row">
                <div class="col-md-10">
                    Selected Date: {{ selectedTreasuryDay.date | date: "dd-MM-yyyy"}}
                </div>
                <div class="col-md-2">
                    <nb-action title="Add Payment" icon="plus-outline" (click)="openDialog(dialogPayment)">
                    </nb-action>
                </div>
            </nb-card-header>
            <nb-card-body>
                <div *ngIf="selectedTreasuryDay.journalEntries.length == 0">
                    <ngx-empty-state image="EmptyState.png" text="This patient has no payment">
                    </ngx-empty-state>
                    <div style="text-align: center;margin-top: 10px;">
                        <button (click)="openDialog(dialogPayment)" nbButton status="success">Add Payment</button>
                    </div>
                </div>
                <table class="table" *ngIf="selectedTreasuryDay.journalEntries.length>0">
                    <thead>
                        <tr>
                            <th>Description</th>
                            <th>Price</th>
                            <th>Created by</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let transaction of selectedTreasuryDay.journalEntries">
                            <td>
                                <span style="color: red;">{{ transaction.price < 0 ? 'Payment for- ' : 'Payment from- '
                                        }}</span>
                                        <!-- <span *ngIf="transaction.price > 0" style="color: #3366ff; cursor: pointer;" [routerLink]="['../patient/1/payments']">{{ transaction.from}}</span> -->
                                        <span>{{ transaction.from}}</span>
                            </td>
                            <td [style.color]="transaction.price < 0 ? 'red' : null">
                                {{ transaction.price < 0 ? '(' +(transaction.price * -1)+')' : transaction.price}} </td>
                            <td>
                                {{transaction.createdBy}}
                            </td>

                        </tr>
                    </tbody>
                </table>
            </nb-card-body>
        </nb-card>
    </div>
</div>

<ng-template #dialogPayment let-data let-ref="dialogRef">
    <nb-card size="medium">
        <nb-card-header>
            Add Transaction
        </nb-card-header>
        <nb-card-body>
            <div class="form-group">
                <label class="label">Transaction Type</label>
                <nb-select [(ngModel)]="this.newTransaction.transactionTypeId" fullWidth>
                    <nb-option *ngFor="let type of transactionTypes" [value]="type.id">{{type.title}}</nb-option>
                </nb-select>
            </div>
            <div class="form-group">
                <label class="label">payment type</label>
                <nb-select [(ngModel)]="this.newTransaction.isDebit" fullWidth>
                    <nb-option *ngFor="let paymentType of paymentTypes" [value]="paymentType.id">{{paymentType.name}}
                    </nb-option>
                </nb-select>
            </div>
            <div class="form-group">
                <input fullWidth nbInput placeholder="Select Date" [(ngModel)]="this.newTransaction.spendingDate"
                    [nbDatepicker]="datePicker">
                <nb-datepicker #datePicker></nb-datepicker>
            </div>
            <div class="form-group">
                <label class="label">Amount</label>
                <input fullWidth [(ngModel)]="this.newTransaction.amount" placeholder="Amount" type="text" nbInput>
            </div>
        </nb-card-body>
        <nb-card-footer style="text-align: right;">
            <button [nbSpinner]="addingPayment" nbSpinnerStatus="success" status='primary' nbButton
                (click)="addPayment(ref)">Pay</button>
        </nb-card-footer>
    </nb-card>
</ng-template>