import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-log-new-call',
  templateUrl: './log-new-call.component.html',
  styleUrls: ['./log-new-call.component.scss']
})
export class LogNewCallComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
