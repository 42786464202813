import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { AddPackageMeal } from "../models/addPackageMeal";
import { ApiResponse } from "../models/ApiResponse";

@Injectable({
  providedIn: "root",
})
export class PackageMealService {
  private getPackageMealsByPlanIdPath =
    environment.apiUrl + "PackageMeal/GetPackageMealsByPlanId?id=";
  private addPackageMealPath =
    environment.apiUrl + "PackageMeal/AddPackageMeal";
  private deletePackageMealPath =
    environment.apiUrl + "PackageMeal/DeletePackageMealById?id=";
  private updatePackageMealPath =
    environment.apiUrl + "PackageMeal/UpdatePackageMeal?id=";

  constructor(private http: HttpClient) {}
  // package meals apis

  getPackageMealsByPackageId(planId: number): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(
      this.getPackageMealsByPlanIdPath + planId
    );
  }
  addPackageMeal(model: AddPackageMeal): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.addPackageMealPath, model);
  }
  updatePackageMeal(
    packageMealId: number,
    model: AddPackageMeal
  ): Observable<ApiResponse> {
    return this.http.put<ApiResponse>(
      this.updatePackageMealPath + packageMealId,
      model
    );
  }
  deletePackageMeal(packageMealId: number): Observable<ApiResponse> {
    return this.http.delete<ApiResponse>(
      this.deletePackageMealPath + packageMealId
    );
  }
}
