import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from "../../../environments/environment";
import { ApiResponse } from '../models/ApiResponse';
import { SessionType } from '../models/sessionType';

@Injectable({
  providedIn: 'root'
})
export class SessionTypeService {
  private getSessionTypesPath = environment.apiUrl + "SessionType/GetSessionTypes";
  private deleteSessionTypesPath = environment.apiUrl + "SessionType/DeleteSessionTypeById?id=";
  private addSessionTypesPath = environment.apiUrl + "SessionType/AddSessionType";
  private updateSessionTypesPath = environment.apiUrl + "SessionType/UpdateSessionType?id=";

  
  constructor(private http: HttpClient) {}

  getSessionTypes(): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.getSessionTypesPath);
  }
  addSessionType(model: SessionType): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.addSessionTypesPath, model);
  }
  updateSessionType(sessionTypeId: number,model: SessionType): Observable<ApiResponse> {
    return this.http.put<ApiResponse>(this.updateSessionTypesPath + sessionTypeId, model);
  }
  deleteSessionType(sessionTypeId: number): Observable<ApiResponse> {
    return this.http.delete<ApiResponse>(this.deleteSessionTypesPath +sessionTypeId);
  }
}
