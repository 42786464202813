<div class="row">
    <nb-card>
        <nb-card-header>
            Pick a date to show your activities and visits
        </nb-card-header>
        <nb-card-body [nbSpinner]="loadingSessions" nbSpinnerStatus="primary" nbSpinnerSize="large">
            <nb-calendar [(date)]="selectedDate" [boundingMonth]="true" showWeekNumber size="large"
                (dateChange)="this.reloadSchedule();"></nb-calendar>
        </nb-card-body>
    </nb-card>
    <div class="col-md-8">

        <nb-card size='giant' style="height: auto;">
            <nb-tabset fullWidth>
                <nb-tab tabTitle="All Sessions" badgeText={{this.AllSessionsCount}} tabIcon="calendar-outline"
                    [nbSpinner]="loadingSessions" nbSpinnerStatus="primary" nbSpinnerSize="large" badgeStatus="danger">
                    <nb-card-header class="row">
                        <div style="text-align: center;" class="col-md-3">Sessions on: {{ selectedDate | date }}</div>
                        <div style="text-align: center;" class="col-md-3">NA: {{ NACount}}</div>
                        <div style="text-align: center;" class="col-md-3">Confirmed: {{ ConfirmedCount}}</div>
                        <div style="text-align: center;" class="col-md-3">Missed: {{ MissedCount}}</div>
                    </nb-card-header>
                    <nb-card-body>
                        <nb-card *ngFor="let session of sessions" class="session" [status]="session?.sessionStatus == 1 ? 'info' : 
                        session?.sessionStatus == 2 ? 'success' :
                        session?.sessionStatus == 3 ? 'danger' : 'info'">
                            <nb-card-header class="row">
                                <div style="text-align: center;" class="col-md-3">
                                    Patient: {{ session?.applicationUser == "" ? "No patient!" :
                                    session?.applicationUser
                                    }} - {{ session?.appUserPhone == "" ? "No patient!" :
                                    session?.appUserPhone
                                    }}
                                </div>

                                <div style="text-align: center;" class="col-md-3">
                                    time: {{ session?.from | date: "hh:mm a" }} - {{ session?.to | date: "hh:mm a" }}
                                </div>
                                <div style="text-align: center;" class="col-md-2">
                                    {{ session?.sessionStatus == 1? "NA": "" }}
                                    {{ session?.sessionStatus == 2? "Confirmed": "" }}
                                    {{ session?.sessionStatus == 3? "Missed": "" }}


                                </div>
                                <div style="text-align: center;" class="col-md-3">
                                    Doctor: {{ session?.doctor == "" ? "No Doctor": session?.doctor }}
                                </div>
                                <div class="col-md-1" style="text-align: center;">
                                    <nb-action (click)="openZoom(session.id, currentUserSession?.to)" title="video"
                                        icon="video-outline">
                                    </nb-action>
                                </div>

                            </nb-card-header>
                        </nb-card>
                    </nb-card-body>
                </nb-tab>
                <nb-tab tabTitle="My Sessions" tabIcon="book-open-outline" badgeText={{this.mySessionsCount}}
                    badgeStatus="danger">
                    <nb-card-header class="row">
                        <div class="col-md-8">Sessions on: {{ selectedDate | date }}</div>
                        <div class="col-md-4">
                            Sessions count: {{mySessionsCount}}
                        </div>
                    </nb-card-header>
                    <nb-card-body>
                        <nb-card *ngFor="let currentUserSession of currentUserSessions" [status]="currentUserSession?.sessionStatus == 1 ? 'info' : 
                        currentUserSession.sessionStatus == 2 ? 'success' :
                        currentUserSession.sessionStatus == 3 ? 'danger' : 'info'" class="session">
                            <nb-card-header class="row">
                                <div style="text-align: center;" class="col-md-3">
                                    Patient: {{ currentUserSession?.applicationUser == "" ? "No patient!" :
                                    currentUserSession?.applicationUser
                                    }}
                                </div>
                                <div style="text-align: center;" class="col-md-3">
                                    time: {{ currentUserSession?.from | date: "hh:mm a" }} - {{ currentUserSession.to |
                                    date:
                                    "hh:mm a" }}
                                </div>
                                <div style="text-align: center;" class="col-md-2">
                                    status: {{ currentUserSession?.sessionStatus == 1? "NA": "" }}
                                    {{ currentUserSession?.sessionStatus == 2? "Confirmed": "" }}
                                    {{ currentUserSession?.sessionStatus == 3? "Missed": "" }}


                                </div>
                                <div style="text-align: center;" class="col-md-3">
                                    Doctor: {{ currentUserSession?.doctor }}
                                </div>
                                <div class="col-md-1" style="text-align: center;">
                                    <nb-action (click)="openZoom(currentUserSession?.id)" title="video"
                                        icon="video-outline">
                                    </nb-action>
                                </div>

                            </nb-card-header>
                        </nb-card>
                    </nb-card-body>
                </nb-tab>
            </nb-tabset>

        </nb-card>
    </div>

</div>