import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { environment } from "../../../environments/environment";
import { ActivityTypeCategory } from "../models/activityTypeCategory";
import { Activity } from "../models/activity";

@Injectable({
  providedIn: "root",
})
export class ActivityService {
  private getActivityTypesPath = environment.apiUrl + "Activity/GetActivityTypes";
  private getVisitActivitiesPath = environment.apiUrl + "Activity/GetVisitActivities?visitId=";
  private addActivityPath = environment.apiUrl + "Activity/AddActivity";
  private deleteActivityPath = environment.apiUrl + "Activity/DeleteActivity?activityId=";
  private GetActivitiesForCurrentUserPath = environment.apiUrl + "Activity/GetActivitiesForCurrentUser?selectedDate=";
  private updateActivityPath = environment.apiUrl + "Activity/UpdateActivity";

  constructor(private http: HttpClient) {}
  getActivityTypes(): Observable<ActivityTypeCategory[]> {
    return this.http.get<ActivityTypeCategory[]>(this.getActivityTypesPath);
  }
  getVisitActivities(visitId:number): Observable<Activity[]> {
    return this.http.get<Activity[]>(this.getVisitActivitiesPath+visitId);
  }
  addActivity(model:Activity): Observable<any> {
    return this.http.post<any>(this.addActivityPath,model);
  }
  deleteActivity(activityId:number): Observable<any> {
    return this.http.post<any>(this.deleteActivityPath + activityId,'');
  }
  getActivitiesForCurrentUser(selectedDate: Date): Observable<Activity[]>
  {
    return this.http.get<Activity[]>(this.GetActivitiesForCurrentUserPath + selectedDate.toDateString());
  }
  updateActivity(activity:Activity): Observable<any> {
    return this.http.post<any>(this.updateActivityPath,activity);
  }
}
