import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { ApiResponse } from "../models/ApiResponse";
import { Exercise } from "../models/exercise";
import { ExerciseType } from "../models/ExerciseType";

@Injectable({
  providedIn: "root",
})
export class ExerciseService {
  // exercise types paths
  private getExerciseTypesPath =
    environment.apiUrl + "ExerciseTypes/GetExerciseTypes";
  private addExerciseTypePath =
    environment.apiUrl + "ExerciseTypes/AddExerciseType";
  private deleteExerciseTypePath =
    environment.apiUrl + "ExerciseTypes/DeletExerciseTypeById?id=";
  private updateExerciseTypePath =
    environment.apiUrl + "ExerciseTypes/UpdateExercise?id=";

  // exercises paths
  private getExercisesPath = environment.apiUrl + "Exercise/GetExercises";
  private addExercisePath = environment.apiUrl + "Exercise/AddExercise";
  private deleteExercisePath =
    environment.apiUrl + "Exercise/DeleteExerciseById?id=";
  private updateExercisePath =
    environment.apiUrl + "Exercise/UpdateExercise?id=";
  constructor(private http: HttpClient) {}

  // Exercise type apis

  getExerciseTypes(): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.getExerciseTypesPath);
  }

  addExerciseType(model: ExerciseType): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.addExerciseTypePath, model);
  }
  updateExerciseType(
    exerciseTypeId: number,
    model: ExerciseType
  ): Observable<ApiResponse> {
    return this.http.put<ApiResponse>(
      this.updateExerciseTypePath + exerciseTypeId,
      model
    );
  }
  deleteExerciseType(exerciseTypeId: number): Observable<ApiResponse> {
    return this.http.delete<ApiResponse>(
      this.deleteExerciseTypePath + exerciseTypeId
    );
  }

  // Exercise apis
  getExercises(): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.getExercisesPath);
  }

  addExercise(model: Exercise): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.addExercisePath, model);
  }
  updateExercise(exerciseId: number, model: Exercise): Observable<ApiResponse> {
    return this.http.put<ApiResponse>(
      this.updateExercisePath + exerciseId,
      model
    );
  }
  deleteExercise(exerciseId: number): Observable<ApiResponse> {
    return this.http.delete<ApiResponse>(this.deleteExercisePath + exerciseId);
  }
}
