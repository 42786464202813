export class SessionTypeReview {
  id: number;
  description: string;
  titleAr: string;
  titleEn: string;
  videoURL: string;
  imageUrl: string;
  sessionTypeId: number;
  isApproved: true;
}
