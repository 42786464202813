<div class="row">
    <div class="col-md-12">
        <nb-card class="own-scroll">
            <nb-card-header>
                <div class="row">
                    <div class="col-md-10">
                        control alternative meals
                    </div>
                </div>
            </nb-card-header>
        </nb-card>
        <nb-card class="own-scroll">

            <nb-card-body [nbSpinner]="loadingAlternativeMeals" nbSpinnerStatus="primary" nbSpinnerSize="large">
                <div id="data-grid-demo">
                    <div>
                        <label>Choose a meal</label>
                        <select #selectElem class="custom-select" (change)="selectMeal(selectElem.value)">
                            <option disabled selected value>select a meal</option>
                            <option *ngFor="let meal of meals" ([ngValue])="meal">
                                {{meal.nameEn}}</option>
                        </select>
                        <hr>

                    </div>
                    <dx-data-grid #grid id="gridContainer" [dataSource]="alternativeMeals" keyExpr="id"
                        [focusedRowEnabled]="true" [showBorders]="true"
                        (onFocusedRowChanging)="onFocusedRowChanging($event)"
                        (onFocusedRowChanged)="onFocusedRowChanged($event)"
                        (onRowInserting)="addAlternativeMeal($event)" (onRowRemoving)="deleteAlternativeMeal($event)"
                        [disabled]="isDisabled">

                        <dxo-paging [enabled]="false"></dxo-paging>
                        <dxo-editing mode="form" [refreshMode]="refreshMode" [allowAdding]="true"
                            [allowDeleting]="true">
                        </dxo-editing>
                        <dxo-filter-row [visible]="showFilterRow" [applyFilter]="currentFilter"></dxo-filter-row>
                        <dxo-header-filter [visible]="showHeaderFilter"></dxo-header-filter>
                        <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>
                        <dxi-column dataField="alternativeMealId" caption="alternative meal">
                            <dxo-lookup [dataSource]="meals" displayExpr="nameEn" valueExpr="id">
                            </dxo-lookup>
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dxi-column>

                    </dx-data-grid>
                </div>
            </nb-card-body>
        </nb-card>



    </div>
</div>