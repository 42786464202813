import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { ApiResponse } from "../models/ApiResponse";
import { Plan } from "../models/plan";

@Injectable({
  providedIn: "root",
})
export class PlanService {
  private getPlansPath = environment.apiUrl + "Plan/GetPlans";
  private addPlanPath = environment.apiUrl + "Plan/AddPlan";
  private deletePlanPath = environment.apiUrl + "Plan/DeletePlanById?id=";
  private updatePlanPath = environment.apiUrl + "Plan/UpdatePlan?id=";
  constructor(private http: HttpClient) {}

  getPlans(): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.getPlansPath);
  }
  addPlan(model: Plan): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.addPlanPath, model);
  }
  updatePlan(planId: number, model: Plan): Observable<ApiResponse> {
    return this.http.put<ApiResponse>(this.updatePlanPath + planId, model);
  }
  deletePlan(planId: number): Observable<ApiResponse> {
    return this.http.delete<ApiResponse>(this.deletePlanPath + planId);
  }
}
