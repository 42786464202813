import { NgModule } from "@angular/core";
import {
  NbAccordionModule,
  NbActionsModule,
  NbAutocompleteModule,
  NbBadgeModule,
  NbButtonModule,
  NbCalendarModule,
  NbCardModule,
  NbChatModule,
  NbCheckboxModule,
  NbDatepickerModule,
  NbFormFieldModule,
  NbIconModule,
  NbInputModule,
  NbListModule,
  NbMenuModule,
  NbRadioModule,
  NbSelectModule,
  NbSpinnerModule,
  NbStepperModule,
  NbToggleModule,
  NbUserModule,
  NbTabsetModule,
} from "@nebular/theme";
import { ThemeModule } from "../@theme/theme.module";
import { PagesComponent } from "./pages.component";
import { PagesRoutingModule } from "./pages-routing.module";
import { patientComponent } from "./dashboard/patient/patient.component";
import { LoadingComponent } from "./dashboard/loading/loading.component";
import { PatientHomeComponent } from "./dashboard/patient/patient-home/patient-home.component";
import { ConfirmDialogComponent } from "./dashboard/patient/confirm-dialog/confirm-dialog.component";
import { ConfirmationDialogComponent } from "./dashboard/patient/confirmation-dialog/confirmation-dialog.component";
import { BodyProfileComponent } from "./dashboard/patient/body-profile/body-profile.component";
import { CallLogComponent } from "./dashboard/patient/call-log/call-log.component";
import { LogNewCallComponent } from "./dashboard/patient/log-new-call/log-new-call.component";
import { PatientActivityComponent } from "./dashboard/patient/index/patient-activity/patient-activity.component";
import { OperationPlansComponent } from "./dashboard/patient/operation-plans/operation-plans.component";
import { PaymentsComponent } from "./dashboard/patient/payments/payments.component";
import { DashboardIndexComponent } from "./dashboard/index/index.component";
import { IndexComponent } from "./dashboard/patient/index/index.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { PatientListComponent } from "./dashboard/patient-list/patient-list.component";
import { AddPatientComponent } from "./dashboard/add-patient/add-patient.component";
import { ScheduleComponent } from "./dashboard/schedule/schedule.component";
import { TreasuryComponent } from "./dashboard/treasury/treasury.component";
import { ReceptionistComponent } from "./dashboard/receptionist/receptionist.component";
import { InbodyAssistantComponent } from "./dashboard/inbody-assistant/inbody-assistant.component";
import { NutritionistComponent } from "./dashboard/nutritionist/nutritionist.component";
import { EmptyStateComponent } from "./dashboard/empty-state/empty-state.component";
import { VisitComponent } from "./dashboard/visit/visit.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { Ng2SmartTableModule } from "ng2-smart-table";
import { ActivityManagerComponent } from "./dashboard/data-manager/activity-manager/activity-manager.component";
import { OperationPlanTypeManagerComponent } from "./dashboard/data-manager/operation-plan-type-manager/operation-plan-type-manager.component";
import { SpendTypeManagerComponent } from "./dashboard/data-manager/spend-type-manager/spend-type-manager.component";
import { AuditCallsComponent } from "./dashboard/audit-calls/audit-calls.component";
import { FollowupCallsComponent } from "./dashboard/followup-calls/followup-calls.component";
import { BodySimulatorComponent } from "./dashboard/body-simulator/body-simulator.component";
import { ChartComponent } from "./dashboard/chart/chart.component";
import { ChartsModule } from "ng2-charts";
import { CallManagerComponent } from "./dashboard/CallManager/CallManager.component";
import { UserManagerComponent } from "./dashboard/user-manager/user-manager.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { CheckVisitComponent } from "./dashboard/visit/checkvisit/checkvisit.component";
import { ClientProfileComponent } from "./dashboard/patient/client-profile/client-profile.component";
import {
  DxDataGridModule,
  DxChartModule,
  DxLoadPanelModule,
  DxDropDownBoxModule,
  DxTreeViewModule,
  DxHtmlEditorModule,
  DxPieChartModule,
  DxFileUploaderModule,
  DxTextAreaModule,
} from "devextreme-angular";
import { PackageComponent } from "./dashboard/application-admin/package/package.component";
import { ApplicationAdmin } from "./dashboard/application-admin/application-admin.component";
import { UserAvailabilityComponent } from "./dashboard/application-admin/user-availability/user-availability.component";
import { IdentityComponent } from "./dashboard/application-admin/identity/identity.component";
import { SessionTypeComponent } from "./dashboard/application-admin/session-type/session-type.component";
import { PackageStructureComponent } from "./dashboard/application-admin/package-structure/package-structure.component";
import { SessionScheduleComponent } from "./dashboard/application-admin/session-schedule/session-schedule.component";
import { ZoomMeetComponent } from "./dashboard/zoom-meet/zoom-meet.component";
import { MealComponent } from "./dashboard/application-admin/meal/meal.component";
import { ExerciseComponent } from "./dashboard/application-admin/exercise/exercise.component";
import { SubscriptionComponent } from "./dashboard/application-admin/subscription/subscription.component";
import { ApplicationUserComponent } from "./dashboard/application-admin/application-user/application-user.component";
import { PromoCodeComponent } from "./dashboard/application-admin/promo-code/promo-code.component";
import { ClientInfoComponent } from "./dashboard/patient/client-info/client-info.component";
import { SessionListComponent } from "./dashboard/patient/session-list/session-list.component";
import { AlternativeMealComponent } from "./dashboard/application-admin/alternative-meal/alternative-meal.component";
import { InterestComponent } from "./dashboard/application-admin/interest/interest.component";
import { PlanComponent } from "./dashboard/application-admin/plan/plan.component";
import { PackagePlanComponent } from "./dashboard/application-admin/plan/package-plan/package-plan.component";
import { MedicationComponent } from "./dashboard/application-admin/medication/medication.component";
import { PlanMealComponent } from "./dashboard/application-admin/plan-meal/plan-meal.component";
import { PlanExerciseComponent } from "./dashboard/application-admin/plan-exercise/plan-exercise.component";
import { SearchDropdownComponent } from "./search-dropdown/search-dropdown.component";
import { ArticleComponent } from "./dashboard/application-admin/article/article.component";
import { ChallengeComponent } from "./dashboard/application-admin/challenge/challenge.component";
import { BurnoutCalorieComponent } from "./dashboard/application-admin/burnout-calorie/burnout-calorie.component";
import { BranchComponent } from "./dashboard/application-admin/branch/branch.component";
import { SessionTypeReviewComponent } from "./dashboard/application-admin/session-type-review/session-type-review.component";
import { SubscriptionMealComponent } from "./dashboard/patient/subscription-meal/subscription-meal.component";
import { SubscriptionExerciseComponent } from "./dashboard/patient/subscription-exercise/subscription-exercise.component";
import { SessionComponent } from "./dashboard/patient/session/session.component";
import { WalletComponent } from "./dashboard/patient/wallet/wallet.component";
import { CrmReportingComponent } from "./dashboard/crm-reporting/crm-reporting.component";
import { LessThanOneKiloReportComponent } from "./dashboard/crm-reporting/less-than-one-kilo-report/less-than-one-kilo-report.component";
import { TreatmentTypeComponent } from "./dashboard/application-admin/treatment-type/treatment-type.component";
import { ResetPasswordComponent } from "./dashboard/application-admin/reset-password/reset-password.component";
import { MissedSessionsReportComponent } from './dashboard/crm-reporting/missed-sessions-report/missed-sessions-report.component';
import { SubscriptionFollowupReportComponent } from './dashboard/crm-reporting/subscription-followup-report/subscription-followup-report.component';
import { ExaminationFollowupReportComponent } from './dashboard/crm-reporting/examination-followup-report/examination-followup-report.component';
import { RenewalFollowupReportComponent } from './dashboard/crm-reporting/renewal-followup-report/renewal-followup-report.component';
import { IntermittentFastingFollowupReportComponent } from './dashboard/crm-reporting/intermittent-fasting-followup-report/intermittent-fasting-followup-report.component';
@NgModule({
  imports: [
    PagesRoutingModule,
    ThemeModule,
    NbCardModule,
    NbButtonModule,
    NbSpinnerModule,
    NbRadioModule,
    Ng2SmartTableModule,
    FormsModule,
    NbStepperModule,
    ReactiveFormsModule,
    NbIconModule,
    NbInputModule,
    NbFormFieldModule,
    NbListModule,
    NbSelectModule,
    NbActionsModule,
    NbDatepickerModule,
    NbAutocompleteModule,
    NbAccordionModule,
    NbCheckboxModule,
    NbChatModule,
    NbToggleModule,
    NbStepperModule,
    NbBadgeModule,
    NbUserModule,
    NbMenuModule,
    ChartsModule,
    NgbModule,
    NbCalendarModule,
    NbTabsetModule,
    DxDataGridModule,
    DxChartModule,
    DxLoadPanelModule,
    DxDropDownBoxModule,
    DxTreeViewModule,
    DxHtmlEditorModule,
    DxPieChartModule,
    DxFileUploaderModule,
    DxTextAreaModule,
  ],
  declarations: [
    PagesComponent,
    patientComponent,
    LoadingComponent,
    PatientHomeComponent,
    ConfirmDialogComponent,
    ConfirmationDialogComponent,
    IndexComponent,
    DashboardIndexComponent,
    BodyProfileComponent,
    CallLogComponent,
    LogNewCallComponent,
    PatientActivityComponent,
    OperationPlansComponent,
    PaymentsComponent,
    DashboardComponent,
    PatientListComponent,
    AddPatientComponent,
    ScheduleComponent,
    TreasuryComponent,
    ReceptionistComponent,
    InbodyAssistantComponent,
    NutritionistComponent,
    EmptyStateComponent,
    VisitComponent,
    ActivityManagerComponent,
    OperationPlanTypeManagerComponent,
    SpendTypeManagerComponent,
    AuditCallsComponent,
    FollowupCallsComponent,
    BodySimulatorComponent,
    ChartComponent,
    CallManagerComponent,
    UserManagerComponent,
    CheckVisitComponent,
    ClientProfileComponent,
    UserAvailabilityComponent,
    PackageComponent,
    ApplicationAdmin,
    IdentityComponent,
    SessionTypeComponent,
    PackageStructureComponent,
    SessionScheduleComponent,
    MealComponent,
    ExerciseComponent,
    PlanMealComponent,
    PlanExerciseComponent,
    SubscriptionMealComponent,
    SubscriptionExerciseComponent,
    SubscriptionComponent,
    ApplicationUserComponent,
    PromoCodeComponent,
    ClientInfoComponent,
    SessionListComponent,
    SessionComponent,
    AlternativeMealComponent,
    InterestComponent,
    PlanComponent,
    PackagePlanComponent,
    MedicationComponent,
    SearchDropdownComponent,
    ArticleComponent,
    ChallengeComponent,
    BurnoutCalorieComponent,
    BranchComponent,
    SessionTypeReviewComponent,
    WalletComponent,
    CrmReportingComponent,
    LessThanOneKiloReportComponent,
    TreatmentTypeComponent,
    ResetPasswordComponent,
    MissedSessionsReportComponent,
    SubscriptionFollowupReportComponent,
    ExaminationFollowupReportComponent,
    RenewalFollowupReportComponent,
    IntermittentFastingFollowupReportComponent,
  ],
})
export class PagesModule {}
