export class BodyProfileInstance {
  constructor() {
    this.weight = 0;
    this.height = 0;
  }
  id: number;
  visitId: number;
  applicationUserId: number;
  weight: number;
  height: number;
  totalBodyWater: number;
  protein: number;
  minerals: number;
  bodyFatMass: number;
  smm: number;
  bmi: number;
  pbf: number;
  bmr: number;
  whr: number;
  vfl: number;
  comments: string;
  profileDate: Date;
  treatmentTypeId: number;
}
