import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { environment } from "../../../environments/environment";
import { Patient } from "../models/patient";
import { catchError } from "rxjs/operators";
import { PatientAction } from "../models/action";

@Injectable({
  providedIn: "root",
})
export class PatientService {
  private addPatientPath = environment.apiUrl + "patients/addPatient";
  private updatePatientPath = environment.apiUrl + "patients/updatePatient";
  private getPatientPath = environment.apiUrl + "patients/getPatient?patientId=";
  private searchPath = environment.apiUrl + "patients/search?keyword=";
  private opentSimulatorPath = environment.apiUrl + "patients/sendHumanData?patientId=";
  private getPatientActionsPath = environment.apiUrl + "patients/GetPatientActions?patientId=";
  private deleteClientPath = environment.apiUrl + "patients/DeleteClient?clientId=";

  constructor(private http: HttpClient) {}
  getPatient(patientId: number): Observable<Patient> {
    return this.http.get<Patient>(this.getPatientPath + patientId);
  }
  getPatientActions(patientId: number): Observable<PatientAction[]> {
    return this.http.get<PatientAction[]>(this.getPatientActionsPath + patientId);
  }
  addPatient(data: Patient): Observable<any> {
    return this.http.post<any>(this.addPatientPath, data);
  }
  updatePatient(data: Patient): Observable<any> {
    return this.http.post<any>(this.updatePatientPath, data);
  }
  search(data): Observable<Patient[]> {
    return this.http.get<Patient[]>(this.searchPath + data);
  }
  openSimulator(data): Observable<any> {
    return this.http.post<any>(this.opentSimulatorPath+data, '');
  }
  deleteClient(clientId :number): Observable<any> {
    return this.http.post<any>(this.deleteClientPath + clientId,'' );
  }
}
