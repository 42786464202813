import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { PatientService } from "../../../../../@core/app-services/patient.service";
import { PatientAction } from "../../../../../@core/models/action";

@Component({
  selector: "ngx-patient-activity",
  templateUrl: "./patient-activity.component.html",
  styleUrls: ["./patient-activity.component.scss"],
})
export class PatientActivityComponent implements OnInit {
  actions: PatientAction[] = new Array();
  patientId: number;
  gettingPatientActions: boolean = true;
  constructor(route: ActivatedRoute, private patientService: PatientService) {
    route.params.subscribe((params) => {
      this.patientId = params["id"];
      patientService.getPatientActions(this.patientId).subscribe((data) => {
        this.actions = data;
        this.gettingPatientActions = false;
      });
    });
  }

  ngOnInit(): void {}
}
