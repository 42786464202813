<div class="row">
    <div class="col-md-6">
        <nb-card size='large'>
            <nb-card-header>
                Today's Visits
            </nb-card-header>
            <nb-card-body>
                <nb-card *ngFor="let visit of visits"
                    [status]="visit.status == 'NA' ? 'info': visit.status == 'Missed' ? 'danger' : 'success'"
                    class="visit" (click)="openVisit(visit, dialogVisit)">
                    <nb-card-header class="row">
                        <div class="col-md-8">
                            {{ visit.startTime | date: "hh:mm a" }} - {{ visit.endTime | date: "hh:mm a" }} | {{
                            visit.patient }}

                        </div>
                        <div class="col-md-4">
                            Dr. {{ visit.doctor }}
                        </div>
                    </nb-card-header>
                </nb-card>
            </nb-card-body>
        </nb-card>
    </div>
    <div class="col-md-6">

    </div>
</div>

<ng-template #dialogVisit let-data let-ref="dialogRef">
    <nb-card style="width: 800px;">
        <nb-card-header>
            Visit at <strong>{{ selectedVisit.date | date: "EEEE, MMM d, yyyy"}} {{ selectedVisit.startTime | date: "hh:mm a"}}</strong>
        </nb-card-header>
        <nb-card-body>
            <ngx-visit [visit]="selectedVisit"></ngx-visit>
        </nb-card-body>
    </nb-card>
</ng-template>