import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { BranchService } from "../../../../@core/app-services/branch.service";
import { PackageService } from "../../../../@core/app-services/package.service";
import { ToasterHelper } from "../../../../@core/app-services/toasterHelper";
import { Branch } from "../../../../@core/models/branch";
import { Package } from "../../../../@core/models/package";

@Component({
  selector: "ngx-package",
  templateUrl: "./package.component.html",
  styleUrls: ["./package.component.scss"],
})
export class PackageComponent implements OnInit {
  packages: Package[] = [];
  branches: Branch[] = [];
  rowPackage: Package;
  refreshMode: string;
  taskSubject: string;
  taskDetailsHtml: SafeHtml;
  taskStatus: string;
  taskProgress: string;
  currentFilter: any;
  loadingPackages: boolean = false;
  showFilterRow: boolean = true;

  showHeaderFilter: boolean = true;
  autoNavigateToFocusedRow = true;
  constructor(
    private packageService: PackageService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private toasterHelper: ToasterHelper
  ) {}

  getPackages() {
    this.loadingPackages = true;
    this.packageService.getPackages().subscribe(
      (data) => {
        if (data.status) {
          this.packages = data.responseObject;
        } else {
          this.packages = [];
        }
        this.loadingPackages = false;
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
        this.loadingPackages = false;
      }
    );
  }
  updatePackage(e) {
    let updatedPackage = new Package();
    this.rowPackage = e.data;
    updatedPackage.isActive = this.rowPackage.isActive;
    updatedPackage.titleAr = this.rowPackage.titleAr;
    updatedPackage.titleEn = this.rowPackage.titleEn;
    updatedPackage.type = this.rowPackage.type;
    updatedPackage.priceInUSD = this.rowPackage.priceInUSD;
    updatedPackage.descriptionAr = this.rowPackage.descriptionAr;
    updatedPackage.descriptionEn = this.rowPackage.descriptionEn;
    updatedPackage.discount = this.rowPackage.discount;
    this.rowPackage.priceAfter =
      this.rowPackage.priceBefore - this.rowPackage.discount;
    updatedPackage.priceAfter = this.rowPackage.priceAfter;
    updatedPackage.priceBefore = this.rowPackage.priceBefore;
    updatedPackage.duration = this.rowPackage.duration;

    this.packageService
      .updatePackage(this.rowPackage.id, updatedPackage)
      .subscribe(
        (data) => {
          if (data.status) {
            this.toasterHelper.showToast(
              "Updated successfully",
              data.message,
              "success"
            );
          } else {
            this.toasterHelper.showError(data.message);
          }
          this.getPackages();
        },
        (error) => {
          this.toasterHelper.showError("Something went wrong");
          this.loadingPackages = false;
        }
      );
  }

  addPackage(e) {
    let newPackage = new Package();
    this.rowPackage = e.data;
    newPackage.isActive = this.rowPackage.isActive;
    if (newPackage.isActive == null || newPackage.isActive == undefined) {
      newPackage.isActive = true;
    }
    newPackage.priceInUSD = this.rowPackage.priceInUSD;
    newPackage.titleAr = this.rowPackage.titleAr;
    newPackage.titleEn = this.rowPackage.titleEn;
    newPackage.type = this.rowPackage.type;
    newPackage.descriptionAr = this.rowPackage.descriptionAr;
    newPackage.descriptionEn = this.rowPackage.descriptionEn;
    newPackage.discount = this.rowPackage.discount;
    this.rowPackage.priceAfter =
      this.rowPackage.priceBefore - this.rowPackage.discount;
    newPackage.priceAfter = this.rowPackage.priceAfter;
    newPackage.priceBefore = this.rowPackage.priceBefore;
    newPackage.duration = this.rowPackage.duration;

    this.packageService.addPackage(newPackage).subscribe(
      (data) => {
        if (data.status) {
          this.toasterHelper.showToast(
            "Added successfully",
            data.message,
            "success"
          );
        } else {
          this.toasterHelper.showError(data.message);
        }
        this.getPackages();
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
        this.loadingPackages = false;
      }
    );
  }
  async deletePackage(e) {
    this.rowPackage = e.data;
    await this.packageService.deletePackage(this.rowPackage.id).subscribe(
      (data) => {
        if (data.status) {
          this.toasterHelper.showToast(
            "Deleted successfully",
            data.message,
            "success"
          );
        } else {
          this.toasterHelper.showSaved(data.message);
        }
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
        this.loadingPackages = false;
      }
    );
  }
  onFocusedRowChanging(e) {
    const rowsCount = e.component.getVisibleRows().length;
    const pageCount = e.component.pageCount();
    const pageIndex = e.component.pageIndex();
    const key = e.event && e.event.key;
    const rowData = e.row && e.row.data;
    this.rowPackage = rowData;
    if (key && e.prevRowIndex === e.newRowIndex) {
      if (e.newRowIndex === rowsCount - 1 && pageIndex < pageCount - 1) {
        e.component.pageIndex(pageIndex + 1).done(() => {
          e.component.option("focusedRowIndex", 0);
        });
      } else if (e.newRowIndex === 0 && pageIndex > 0) {
        e.component.pageIndex(pageIndex - 1).done(() => {
          e.component.option("focusedRowIndex", rowsCount - 1);
        });
      }
    }
  }
  onFocusedRowChanged(e) {
    const rowData = e.row && e.row.data;
    // this.rowPackage= rowData;
    if (rowData) {
      this.taskSubject = rowData.Task_Subject;
      this.taskDetailsHtml = this.sanitizer.bypassSecurityTrustHtml(
        rowData.Task_Description
      );
      this.taskStatus = rowData.Task_Status;
      this.taskProgress = rowData.Task_Completion
        ? `${rowData.Task_Completion}` + "%"
        : "";
    }
  }
  validateDuration(e) {
    if (e.value % 7 == 0) {
      return true;
    } else return false;
  }
  ngOnInit(): void {
    this.getPackages();
  }
}
