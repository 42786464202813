<h4>Patient Actions</h4>
<nb-card size="large">
  <nb-card-body [nbSpinner]="gettingPatientActions" nbSpinnerStatus="primary" nbSpinnerSize="large"
    nbSpinnerMessage="Getting patient actions">
    <div *ngIf="actions.length == 0 && !gettingPatientActions">
      <ngx-empty-state image="EmptyState.png" text="This patient doesn't has any actions yet!">
      </ngx-empty-state>
    </div>
    <nb-list *ngIf="actions.length > 0">
      <nb-list-item class="contact" *ngFor="let action of actions">
        <nb-user [title]="action.description" [name]="action.loggedBy" size="medium"></nb-user>
        <span class="caption">{{ action.createdOn | date: "d-MMM"}}</span>
      </nb-list-item>
    </nb-list>
  </nb-card-body>
</nb-card>