<div class="row">
    <div class="col-md-12">
        <nb-card class="own-scroll">
            <nb-card-header>
                <div class="row">
                    <div class="col-md-11">
                        Call History
                    </div>
                    <div class="col-md-1">
                        <nb-action title="Log a new Call" (click)="openLogNewCall(dialogAddCall)" icon="plus-outline">
                        </nb-action>
                    </div>
                </div>
            </nb-card-header>
            <nb-card-body [nbSpinner]="getClientCalls" nbSpinnerStatus="primary" nbSpinnerSize="large"
                nbSpinnerMessage="Getting Calls">
                <div class="table-container">
                    <table class="table" *ngIf="calls.length>0">
                        <thead>
                            <tr>
                                <th>
                                    Call date
                                </th>
                                <th>
                                    Log
                                </th>
                                <th>
                                    Logged by
                                </th>
                                <th>
                                    Closed
                                </th>
                                <th>
                                    Audited by
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let call of calls">
                                <td>
                                    {{ call.callDate | date: "dd-MM-yyyy h:mm:ss a" }}
                                </td>
                                <td>
                                    <p *ngIf="call.log.length>50">
                                        {{call.log.slice(0,50)}}...
                                    </p>
                                    <p *ngIf="call.log.length<50">
                                        {{call.log}}
                                    </p>
                                </td>
                                <td>
                                    {{call.caller}}
                                </td>
                                <td>
                                    {{call.submitted}}
                                </td>
                                <td>
                                    {{call.auditer}}
                                </td>
                                <td>
                                    <nb-actions fullWidth size="small">
                                        <nb-action *ngIf="call.auditer==''" title="Audit" (click)="openAuditCall(dialogAudit, call.id)"
                                            icon="award-outline"></nb-action>
                                            <nb-action *ngIf="call.auditer!=''" title="Open audit comment" (click)="openAuditComment(dialogAuditComment,call.auditComment)"
                                            icon="award-outline"></nb-action>
                                        <nb-action title="Show description"
                                            (click)="openCallDescription(dialogDescription, call.log)"
                                            icon="message-square-outline"></nb-action>
                                    </nb-actions>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </nb-card-body>
        </nb-card>
    </div>
</div>
<ng-template #dialogDescription let-data let-ref="dialogRef">
    <nb-card [size]="'medium'">
        <nb-card-header>
            Call Description
        </nb-card-header>
        <nb-card-body>
            <p [innerHTML]="description"></p>
        </nb-card-body>
    </nb-card>
</ng-template>
<ng-template #dialogAddCall let-data let-ref="dialogRef">
    <nb-card [size]="'medium'">
        <nb-card-header>
            Log a new call
        </nb-card-header>
        <nb-card-body>
            <div class="form-group">
                <label class="label">Call Description</label>
                <textarea style="height: 150px;" fullWidth placeholder="Description" nbInput
                    [(ngModel)]="logCallModel.log"></textarea>
            </div>

            <div class="form-group">
                <nb-checkbox fullWidth [(ngModel)]="hasFollowup">
                    <strong>Didn't completed?</strong> Add a follow up
                </nb-checkbox>
            </div>
            <div class="form-group">
                <label class="label">Follow up Date</label>
                <input readonly [disabled]="!hasFollowup" fullWidth nbInput placeholder="Follow up Date"
                    [nbDatepicker]="DatePicker" [(ngModel)]="logCallModel.followupDate">
                <nb-datepicker #DatePicker></nb-datepicker>
            </div>
        </nb-card-body>
        <nb-card-footer style="text-align: right;">
            <button [nbSpinner]="addingCall" (click)="logCall(ref)" nbSpinnerStatus="success" nbButton
                status="primary">Add</button>
        </nb-card-footer>
    </nb-card>
</ng-template>
