import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BodyProfileService } from '../../../../@core/app-services/bodyProfile.service';
import { ReportService } from '../../../../@core/app-services/report.service';
import { ToasterHelper } from '../../../../@core/app-services/toasterHelper';
import { ReportModel } from '../../../../@core/models/reportModel';

@Component({
  selector: 'ngx-missed-sessions-report',
  templateUrl: './missed-sessions-report.component.html',
  styleUrls: ['./missed-sessions-report.component.scss']
})

export class MissedSessionsReportComponent implements OnInit {
  readonly displayModes = [
    { text: "Display Mode 'full'", value: "full" },
    { text: "Display Mode 'compact'", value: "compact" },
  ];
  readonly allowedPageSizes = [5, 10, "all"];
  displayMode = "full";
  showPageSizeSelector = true;
  showInfo = true;
  showNavButtons = true;
  //Loader settings
  loadingVisible = true;
  report: ReportModel[] = [];
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private toasterHelper: ToasterHelper,
    private bodyProfileService: BodyProfileService,
    private reportService: ReportService
  ) {
    this.getProfile = this.getProfile.bind(this);
  }

  getReport() {
    this.loadingVisible = true;

    this.reportService
      .getAppUsersMissedSessions()
      .subscribe(
        (data) => {
          if (data.status) {
            this.report = data.responseObject;
          } else {
            this.report = [];
          }
          this.loadingVisible = false;
        },
        (error) => {
          this.toasterHelper.showError("Something went wrong");
          this.loadingVisible = false;
        }
      );
  }
  getProfile(e) {
    window.open(
      `/#/pages/dashboard/patient/${e.row.data.applicationUserId}/client-info`,
      "_blank"
    );
  }
  getCallLog(e){
    window.open(`/#/pages/dashboard/patient/${e.row.data.applicationUserId}/call-log`,
    "_blank")
  };
  ngOnInit(): void {
    this.getReport();
  }
}
