<nb-card>
    <nb-card-header>Basic info
    </nb-card-header>
    <nb-card-body [nbSpinner]="gettingPatientData" nbSpinnerStatus="primary" nbSpinnerSize="large"
        nbSpinnerMessage="Getting patient's data">
        <div class="row profile-pic form-group">
        </div>
        <div class="row" *ngIf="!gettingPatientData">
            <div class="form-group col-sm-3">
                <label class="label">First Name</label>
                <input type="text" fullWidth placeholder="First Name" nbInput [(ngModel)]="appUser.firstName">
            </div>
            <div class="form-group col-sm-3">
                <label class="label">Last Name</label>
                <input type="text" fullWidth placeholder="Last Name" nbInput [(ngModel)]="appUser.lastName">
            </div>
            <div class="form-group col-sm-3">
                <label class="label">Phone Number</label>
                <input type="text" fullWidth placeholder="Phone Number" nbInput [(ngModel)]="appUser.phone" disabled>
            </div>
            <div class="form-group col-sm-3">
                <label class="label">Date of birth</label>
                <input fullWidth type="datetime-local" nbInput [(ngModel)]="appUser.birthdate">
            </div>
            <div class="form-group col-sm-3">
                <label class="label">Gender</label>
                <nb-select [(ngModel)]="gender" [(selected)]="this.gender" fullWidth>
                    <nb-option value="0">Male</nb-option>
                    <nb-option value="1">Female</nb-option>
                </nb-select>
            </div>
            <div class="form-group col-sm-3">
                <label class="label">Language</label>
                <nb-select [(ngModel)]="language" [(selected)]="this.language" fullWidth>
                    <nb-option value="1">Arabic</nb-option>
                    <nb-option value="0">English</nb-option>
                </nb-select>
            </div>
            <div class="form-group col-sm-6">
                <label class="label">email</label>
                <input type="email" fullWidth placeholder="Email..." nbInput [(ngModel)]="appUser.email">
            </div>
            <div class="form-group col-sm-3">
                <label class="label">Weight</label>
                <input type="number" fullWidth placeholder="Weight" nbInput [(ngModel)]="appUser.weight">
            </div>
            <div class="form-group col-sm-3">
                <label class="label">Height</label>
                <input type="number" fullWidth placeholder="Height" nbInput [(ngModel)]="appUser.height">
            </div>
        </div>
        <!-- <div class="row">
            <ngx-session-list></ngx-session-list>
        </div> -->
        <div class="row">
            <div class="form-group col-sm-12" *ngIf="!gettingPatientData">
                <dx-html-editor height="725px" [(value)]="appUser.htmlContent" [height]="300">
                    <dxo-toolbar [multiline]="true">
                        <dxi-item name="undo"></dxi-item>
                        <dxi-item name="redo"></dxi-item>
                        <dxi-item name="separator"></dxi-item>
                        <dxi-item name="size"
                            [acceptedValues]="['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt']"></dxi-item>
                        <dxi-item name="font" [acceptedValues]="[
                        'Arial',
                        'Courier New',
                        'Georgia',
                        'Impact',
                        'Lucida Console',
                        'Tahoma',
                        'Times New Roman',
                        'Verdana'
                    ]"></dxi-item>
                        <dxi-item name="separator"></dxi-item>
                        <dxi-item name="bold"></dxi-item>
                        <dxi-item name="italic"></dxi-item>
                        <dxi-item name="strike"></dxi-item>
                        <dxi-item name="underline"></dxi-item>
                        <dxi-item name="separator"></dxi-item>
                        <dxi-item name="alignLeft"></dxi-item>
                        <dxi-item name="alignCenter"></dxi-item>
                        <dxi-item name="alignRight"></dxi-item>
                        <dxi-item name="alignJustify"></dxi-item>
                        <dxi-item name="separator"></dxi-item>
                        <dxi-item name="orderedList"></dxi-item>
                        <dxi-item name="bulletList"></dxi-item>
                        <dxi-item name="separator"></dxi-item>
                        <dxi-item name="header" [acceptedValues]="[false, 1, 2, 3, 4, 5]"></dxi-item>
                        <dxi-item name="separator"></dxi-item>
                        <dxi-item name="color"></dxi-item>
                        <dxi-item name="background"></dxi-item>
                        <dxi-item name="separator"></dxi-item>
                        <dxi-item name="link"></dxi-item>
                        <dxi-item name="image"></dxi-item>
                        <dxi-item name="separator"></dxi-item>
                        <dxi-item name="clear"></dxi-item>
                        <dxi-item name="codeBlock"></dxi-item>
                        <dxi-item name="blockquote"></dxi-item>
                        <dxi-item name="separator"></dxi-item>
                        <dxi-item name="insertTable"></dxi-item>
                        <dxi-item name="deleteTable"></dxi-item>
                        <dxi-item name="insertRowAbove"></dxi-item>
                        <dxi-item name="insertRowBelow"></dxi-item>
                        <dxi-item name="deleteRow"></dxi-item>
                        <dxi-item name="insertColumnLeft"></dxi-item>
                        <dxi-item name="insertColumnRight"></dxi-item>
                        <dxi-item name="deleteColumn"></dxi-item>
                    </dxo-toolbar>
                    <dxo-media-resizing [enabled]="true"> </dxo-media-resizing>

                </dx-html-editor>
            </div>
        </div>
    </nb-card-body>
    <nb-card-footer style="text-align: right;">
        <button [nbSpinner]="saving" nbSpinnerStatus="success" nbButton (click)="save()" status="primary">Save</button>
    </nb-card-footer>
</nb-card>