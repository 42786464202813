import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BodyProfileService } from "../../../../@core/app-services/bodyProfile.service";
import { ReportService } from "../../../../@core/app-services/report.service";
import { ToasterHelper } from "../../../../@core/app-services/toasterHelper";
import { ReportModel } from "../../../../@core/models/reportModel";
import { TimeRangeModel } from "../../../../@core/models/timeRangeModel";

@Component({
  selector: "ngx-less-than-one-kilo-report",
  templateUrl: "./less-than-one-kilo-report.component.html",
  styleUrls: ["./less-than-one-kilo-report.component.scss"],
})
export class LessThanOneKiloReportComponent implements OnInit {
  readonly displayModes = [
    { text: "Display Mode 'full'", value: "full" },
    { text: "Display Mode 'compact'", value: "compact" },
  ];
  readonly allowedPageSizes = [5, 10, "all"];
  displayMode = "full";
  showPageSizeSelector = true;
  showInfo = true;
  showNavButtons = true;
  //Loader settings
  loadingVisible = true;
  startDate: Date = new Date();
  endDate: Date = new Date();
  report: ReportModel[] = [];
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private toasterHelper: ToasterHelper,
    private bodyProfileService: BodyProfileService,
    private reportService: ReportService
  ) {
    this.getProfile = this.getProfile.bind(this);
  }

  getReport(start: Date, end: Date) {
    this.loadingVisible = true;
    let timeRangeModel = new TimeRangeModel();
    timeRangeModel.start = start;
    timeRangeModel.end = end;

    this.reportService
      .getAppUsersLossLessThanOneKilo(timeRangeModel)
      .subscribe(
        (data) => {
          if (data.status) {
            this.report = data.responseObject;
          } else {
            this.report = [];
          }
          this.loadingVisible = false;
        },
        (error) => {
          this.toasterHelper.showError("Something went wrong");
          this.loadingVisible = false;
        }
      );
  }
  getProfile(e) {
    window.open(
      `/#/pages/dashboard/patient/${e.row.data.applicationUserId}/client-info`,
      "_blank"
    );
  }
  getCallLog(e){
    window.open(`/#/pages/dashboard/patient/${e.row.data.applicationUserId}/call-log`,
    "_blank")
  };
  ngOnInit(): void {
    this.startDate.setHours(2);
    this.startDate.setMinutes(0);
    this.endDate.setHours(2);
    this.endDate.setDate(this.startDate.getDate());
    this.endDate.setMinutes(0);
    this.startDate.setDate(this.startDate.getDate() - 7);
    this.getReport(this.startDate, this.endDate);
  }
}
