import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { AlternativeMealService } from "../../../../@core/app-services/alternativeMeal.service";
import { MealService } from "../../../../@core/app-services/meal.service";
import { ToasterHelper } from "../../../../@core/app-services/toasterHelper";
import { AlternativeMeal } from "../../../../@core/models/alternativeMeal";
import { Meal } from "../../../../@core/models/meal";

@Component({
  selector: "ngx-alternative-meal",
  templateUrl: "./alternative-meal.component.html",
  styleUrls: ["./alternative-meal.component.scss"],
})
export class AlternativeMealComponent implements OnInit {
  alternativeMeals: AlternativeMeal[] = [];
  alternativeMeal: AlternativeMeal = null;
  meals: Meal[] = [];
  meal: Meal = new Meal();

  refreshMode: string;
  taskSubject: string;
  taskDetailsHtml: SafeHtml;
  taskStatus: string;
  taskProgress: string;
  currentFilter: any;
  showFilterRow: boolean = true;
  showHeaderFilter: boolean = true;
  autoNavigateToFocusedRow = true;
  isDisabled: boolean = true;
  loadingAlternativeMeals: boolean = false;
  loadingPackages: boolean = false;

  constructor(
    private alternativeMealService: AlternativeMealService,
    private mealService: MealService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private toasterHelper: ToasterHelper
  ) {}
  getMeals() {
    this.mealService.getMeals().subscribe(
      (data) => {
        if (data.status) {
          this.meals = data.responseObject;
        } else {
          this.meals = [];
        }
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
      }
    );
  }

  addAlternativeMeal(e) {
    this.loadingAlternativeMeals = true;

    let newAlternativeMeal = new AlternativeMeal();
    this.alternativeMeal = e.data;
    newAlternativeMeal.originalMealId = this.meal.id;
    newAlternativeMeal.alternativeMealId =
      this.alternativeMeal.alternativeMealId;
    this.alternativeMealService
      .addALternativesMeal(newAlternativeMeal)
      .subscribe(
        (data) => {
          if (data.status) {
            this.toasterHelper.showToast(
              "Added successfully",
              data.message,
              "success"
            );
          } else {
            this.toasterHelper.showError(data.message);
          }
          this.loadingAlternativeMeals = false;

          this.getAlternativeMeals();
        },
        (error) => {
          this.toasterHelper.showError("Something went wrong");
          this.loadingAlternativeMeals = false;
        }
      );
  }
  selectMeal(nameEn: string) {
    this.meal = this.meals.find((p) => p.nameEn.trim() == nameEn.trim());
    this.getAlternativeMeals();
    this.isDisabled = false;
  }
  getAlternativeMeals() {
    this.loadingAlternativeMeals = true;
    this.alternativeMealService
      .getAlternativesMealByMealId(this.meal.id)
      .subscribe(
        (data) => {
          if (data.status) {
            this.alternativeMeals = data.responseObject;
          } else {
            this.alternativeMeals = [];
          }
          this.loadingAlternativeMeals = false;
        },
        (error) => {
          this.toasterHelper.showError("Something went wrong");
          this.loadingAlternativeMeals = false;
        }
      );
  }

  deleteAlternativeMeal(e) {
    this.loadingAlternativeMeals = true;

    this.alternativeMeal = e.data;
    this.alternativeMealService
      .deleteAlternativesMeal(this.alternativeMeal.id)
      .subscribe(
        (data) => {
          if (data.status) {
            this.toasterHelper.showToast(
              "Deleted successfully",
              data.message,
              "success"
            );
          } else {
            this.toasterHelper.showError(data.message);
          }
          this.loadingAlternativeMeals = false;
        },
        (error) => {
          this.toasterHelper.showError("Something went wrong");
          this.loadingAlternativeMeals = false;
        }
      );
  }

  onFocusedRowChanging(e) {
    const rowsCount = e.component.getVisibleRows().length;
    const pageCount = e.component.pageCount();
    const pageIndex = e.component.pageIndex();
    const key = e.event && e.event.key;
    const rowData = e.row && e.row.data;
    if (key && e.prevRowIndex === e.newRowIndex) {
      if (e.newRowIndex === rowsCount - 1 && pageIndex < pageCount - 1) {
        e.component.pageIndex(pageIndex + 1).done(() => {
          e.component.option("focusedRowIndex", 0);
        });
      } else if (e.newRowIndex === 0 && pageIndex > 0) {
        e.component.pageIndex(pageIndex - 1).done(() => {
          e.component.option("focusedRowIndex", rowsCount - 1);
        });
      }
    }
  }
  onFocusedRowChanged(e) {
    const rowData = e.row && e.row.data;
    // this.rowPackage= rowData;
    if (rowData) {
      this.taskSubject = rowData.Task_Subject;
      this.taskDetailsHtml = this.sanitizer.bypassSecurityTrustHtml(
        rowData.Task_Description
      );
      this.taskStatus = rowData.Task_Status;
      this.taskProgress = rowData.Task_Completion
        ? `${rowData.Task_Completion}` + "%"
        : "";
    }
  }

  ngOnInit(): void {
    this.getMeals();
  }
}
