import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { BranchService } from "../../../../@core/app-services/branch.service";
import { PackageService } from "../../../../@core/app-services/package.service";
import { ToasterHelper } from "../../../../@core/app-services/toasterHelper";
import { AwsRepoConfig, AwsS3Manage } from "../../../../@core/lib/AwsS3Manage";
import { Branch } from "../../../../@core/models/Branch";
import { BranchPackage } from "../../../../@core/models/BranchPackage";
import { Package } from "../../../../@core/models/package";

@Component({
  selector: "ngx-branch",
  templateUrl: "./branch.component.html",
  styleUrls: ["./branch.component.scss"],
})
export class BranchComponent implements OnInit {
  branches: Branch[] = [];
  rowKey;
  branch: Branch;
  selectedFile: File;
  awsS3Manage: AwsS3Manage = new AwsS3Manage();
  refreshMode: string;
  taskSubject: string;
  taskDetailsHtml: SafeHtml;
  taskStatus: string;
  taskProgress: string;
  currentFilter: any;

  showFilterRow: boolean = true;

  showHeaderFilter: boolean = true;
  autoNavigateToFocusedRow = true;
  loadingBranches: boolean = false;
  packages: Package[] = [];
  branchPackages: BranchPackage[] = [];
  loadingBranchPackages: boolean = false;
  branchPackage: BranchPackage;
  branchName: string = "";
  isDisabled: boolean = true;
  constructor(
    private toasterHelper: ToasterHelper,
    private sanitizer: DomSanitizer,
    private BranchService: BranchService,
    private PackageService: PackageService
  ) {}
  // get Branch
  getBranches() {
    this.BranchService.getBranches().subscribe((data) => {
      if (data.status) {
        this.branches = data.responseObject;
      } else {
        this.branches = [];
        this.toasterHelper.showError(data.message);
      }
    });
  }

  selectBranch() {
    if (this.branchName != undefined) {
      this.branch = this.branches.find(
        (p) => p.nameEn.trim() == this.branchName.trim()
      );
      this.getBranchPackages();
    }
    this.isDisabled = false;
  }

  getPackages() {
    this.PackageService.getPackages().subscribe((data) => {
      if (data.status) {
        this.packages = data.responseObject;
      } else {
        this.packages = [];
      }
    });
  }

  updateBranch(e) {
    this.loadingBranches = true;
    let updatedBranch = new Branch();
    this.branch = e.data;
    updatedBranch.nameEn = this.branch.nameEn;
    updatedBranch.nameAr = this.branch.nameAr;
    updatedBranch.addressAr = this.branch.addressAr;
    updatedBranch.addressEn = this.branch.addressEn;
    updatedBranch.phone = this.branch.phone;
    if (this.selectedFile) {
      this.awsS3Manage
        .uploadFile({
          fileStream: this.selectedFile,
          name:
            updatedBranch.nameEn +
            "." +
            this.selectedFile.type.split("/").pop(),
          location: AwsRepoConfig.branchesURL,
        })
        .then((result) => {
          updatedBranch.imageUrl = result.Location;
          this.BranchService.updateBranch(
            this.branch.id,
            updatedBranch
          ).subscribe((data) => {
            if (data.status) {
              this.toasterHelper.showToast(
                "Updated successfully",
                data.message,
                "success"
              );
            } else {
              this.toasterHelper.showError(data.message);
            }
            this.loadingBranches = false;
            this.selectedFile = null;
            this.getBranches();
          });
        })
        .catch((error) => {
          this.toasterHelper.showError(
            "Couldn't set a photo for " + updatedBranch.nameEn
          );
          console.log(error.message);
        });
    } else {
      this.BranchService.updateBranch(this.branch.id, updatedBranch).subscribe(
        (data) => {
          if (data.status) {
            this.toasterHelper.showToast(
              "Updated successfully",
              data.message,
              "success"
            );
          } else {
            this.toasterHelper.showError(data.message);
          }
          this.loadingBranches = false;
          this.selectedFile = null;
          this.getBranches();
        }
      );
    }
    this.BranchService.updateBranch(this.branch.id, updatedBranch).subscribe(
      (data) => {
        if (data.status) {
          this.toasterHelper.showToast(
            "Updated successfully",
            data.message,
            "success"
          );
        } else {
          this.toasterHelper.showError(data.message);
        }
        this.loadingBranches = false;
        this.getBranches();
      }
    );
  }

  addBranch(e) {
    this.loadingBranches = true;
    let newBranch = new Branch();
    this.branch = e.data;
    newBranch.nameEn = this.branch.nameEn;
    newBranch.nameAr = this.branch.nameAr;
    newBranch.addressAr = this.branch.addressAr;
    newBranch.addressEn = this.branch.addressEn;
    newBranch.phone = this.branch.phone;
    if (this.selectedFile) {
      this.awsS3Manage
        .uploadFile({
          fileStream: this.selectedFile,
          name:
            newBranch.nameEn + "." + this.selectedFile.type.split("/").pop(),
          location: AwsRepoConfig.branchesURL,
        })
        .then((result) => {
          newBranch.imageUrl = result.Location;
          this.BranchService.addBranch(newBranch).subscribe((data) => {
            if (data.status) {
              this.toasterHelper.showToast(
                "Added successfully",
                data.message,
                "success"
              );
            } else {
              this.toasterHelper.showError(data.message);
            }
            this.loadingBranches = false;
            this.selectedFile = null;
            this.getBranches();
          });
        })
        .catch((error) => {
          this.toasterHelper.showError(
            "Couldn't set a photo for " + newBranch.nameEn
          );
          console.log(error.message);
        });
    } else {
      newBranch.imageUrl =
        "https://diethub-resources.s3.eu-central-1.amazonaws.com/defaultimage.jpg";
      this.BranchService.addBranch(newBranch).subscribe((data) => {
        if (data.status) {
          this.toasterHelper.showToast(
            "Added successfully",
            data.message,
            "success"
          );
        } else {
          this.toasterHelper.showError(data.message);
        }
        this.loadingBranches = false;
        this.getBranches();
      });
    }
  }
  deleteBranch(e) {
    this.loadingBranches = true;
    this.branch = e.data;
    if (
      this.branch.imageUrl ==
      "https://diethub-resources.s3.eu-central-1.amazonaws.com/defaultimage.jpg"
    ) {
      this.BranchService.deleteBranch(this.branch.id).subscribe((data) => {
        if (data.status) {
          this.toasterHelper.showToast(
            "Deleted successfully",
            data.message,
            "success"
          );
        } else {
          this.toasterHelper.showError(data.message);
        }
        this.loadingBranches = false;
      });
    } else {
      this.awsS3Manage
        .deleteFile({
          fileName:
            this.branch.nameEn + "." + this.branch.imageUrl.split(".").pop(),
          location: AwsRepoConfig.branchesURL,
        })
        .then((result) => {
          this.BranchService.deleteBranch(this.branch.id).subscribe((data) => {
            if (data.status) {
              this.toasterHelper.showToast(
                "Deleted successfully",
                data.message,
                "success"
              );
            } else {
              this.toasterHelper.showError(data.message);
            }
            this.loadingBranches = false;
          });
        })
        .catch((error) => {
          this.toasterHelper.showError("Couldn't set a photo for ");
          console.log(error.message);
        });
    }
  }

  // Article interests crud operations
  getBranchPackages() {
    this.BranchService.getAllBranchPackagesByBranchId(this.branch.id).subscribe(
      (data) => {
        if (data.status) {
          this.branchPackages = data.responseObject;
        } else {
          this.branchPackages = [];
        }
      }
    );
  }

  addBranchPackage(e) {
    this.loadingBranchPackages = true;
    let newBranchPackage = new BranchPackage();
    this.branchPackage = e.data;
    newBranchPackage.branchId = this.branch.id;
    newBranchPackage.packageId = this.branchPackage.packageId;
    this.BranchService.addBranchPackage(newBranchPackage).subscribe((data) => {
      if (data.status) {
        this.toasterHelper.showToast(
          "Added successfully",
          data.message,
          "success"
        );
      } else {
        this.toasterHelper.showError(data.message);
      }
      this.loadingBranchPackages = false;

      this.getBranchPackages();
    });
  }

  deleteBranchPackage(e) {
    this.loadingBranchPackages = true;
    this.branchPackage = e.data;
    this.BranchService.deleteBranchPackage(this.branchPackage.id).subscribe(
      (data) => {
        if (data.status) {
          this.toasterHelper.showToast(
            "Deleted successfully",
            data.message,
            "success"
          );
        } else {
          this.toasterHelper.showError(data.message);
        }
        this.loadingBranchPackages = false;
      }
    );
  }

  onFocusedRowChanging(e) {
    const rowsCount = e.component.getVisibleRows().length;
    const pageCount = e.component.pageCount();
    const pageIndex = e.component.pageIndex();
    const key = e.event && e.event.key;
    const rowData = e.row && e.row.data;
    if (key && e.prevRowIndex === e.newRowIndex) {
      if (e.newRowIndex === rowsCount - 1 && pageIndex < pageCount - 1) {
        e.component.pageIndex(pageIndex + 1).done(() => {
          e.component.option("focusedRowIndex", 0);
        });
      } else if (e.newRowIndex === 0 && pageIndex > 0) {
        e.component.pageIndex(pageIndex - 1).done(() => {
          e.component.option("focusedRowIndex", rowsCount - 1);
        });
      }
    }
  }
  onEditingStart(e) {
    this.rowKey = e.key;
  }

  onSaving(e) {
    if (e.changes.length == 0) {
      e.changes.push({
        type: "update",
        key: this.rowKey,
        data: {},
      });
    }
  }
  onFocusedRowChanged(e) {
    const rowData = e.row && e.row.data;
    // this.rowPackage= rowData;
    if (rowData) {
      this.taskSubject = rowData.Task_Subject;
      this.taskDetailsHtml = this.sanitizer.bypassSecurityTrustHtml(
        rowData.Task_Description
      );
      this.taskStatus = rowData.Task_Status;
      this.taskProgress = rowData.Task_Completion
        ? `${rowData.Task_Completion}` + "%"
        : "";
    }
  }
  onClick(e: any): void {}

  onValueChanged(e: any): void {
    this.selectedFile = e.value[0]; // convert to base64 string
  }

  onUploaded(e: any, cellInfo: any): void {}

  onUploadError(e: any): void {
    const xhttp = e.request;
    if (xhttp.status === 400) {
      e.message = e.error.responseText;
    }
    if (xhttp.readyState === 4 && xhttp.status === 0) {
      e.message = "Connection refused";
    }
  }

  onEditCanceled(e: any): void {}

  onSaved(e: any): void {}
  ngOnInit(): void {
    this.getBranches();
    this.getPackages();
  }
}
