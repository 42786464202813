import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { PackagePlanService } from "../../../../../@core/app-services/package-plan.service";
import { PackageService } from "../../../../../@core/app-services/package.service";
import { PlanService } from "../../../../../@core/app-services/plan.service";
import { ToasterHelper } from "../../../../../@core/app-services/toasterHelper";
import { Package } from "../../../../../@core/models/package";
import { PackagePlan } from "../../../../../@core/models/packageplan";
import { Plan } from "../../../../../@core/models/plan";

@Component({
  selector: "ngx-package-plan",
  templateUrl: "./package-plan.component.html",
  styleUrls: ["./package-plan.component.scss"],
})
export class PackagePlanComponent implements OnInit {
  packagePlans: PackagePlan[] = [];
  packagePlan: PackagePlan;
  packages: Package[] = [];
  package: Package;
  plans: Plan[] = [];
  plan: Plan;
  refreshMode: string;
  taskSubject: string;
  taskDetailsHtml: SafeHtml;
  taskStatus: string;
  taskProgress: string;
  currentFilter: any;
  showFilterRow: boolean = true;
  showHeaderFilter: boolean = true;
  autoNavigateToFocusedRow = true;
  isDisabled: boolean = true;
  loadingPackagePlans: boolean = false;
  constructor(
    private packagePlanService: PackagePlanService,
    private packageService: PackageService,
    private planService: PlanService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private toasterHelper: ToasterHelper
  ) {}
  getPlans() {
    this.loadingPackagePlans = true;
    this.planService.getPlans().subscribe(
      (data) => {
        if (data.status) {
          this.plans = data.responseObject;
        } else {
          this.plans = [];
        }
        this.loadingPackagePlans = false;
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
        this.loadingPackagePlans = false;
      }
    );
  }
  getPackages() {
    this.loadingPackagePlans = true;
    this.packageService.getPackages().subscribe(
      (data) => {
        if (data.status) {
          this.packages = data.responseObject;
        } else {
          this.packages = [];
        }
        this.loadingPackagePlans = false;
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
        this.loadingPackagePlans = false;
      }
    );
  }
  selectPackage(titleEn: string) {
    this.package = this.packages.find(
      (p) => p.titleEn.trim() == titleEn.trim()
    );
    this.getPackagePlans();
    this.isDisabled = false;
  }

  // Package Plans crud operations
  getPackagePlans() {
    this.loadingPackagePlans = true;
    this.packagePlanService
      .getPackagePlansByPackageId(this.package.id)
      .subscribe(
        (data) => {
          if (data.status) {
            this.packagePlans = data.responseObject;
          } else {
            this.packagePlans = [];
          }
          this.loadingPackagePlans = false;
        },
        (error) => {
          this.toasterHelper.showError("Something went wrong");
          this.loadingPackagePlans = false;
        }
      );
  }

  addPackagePlan(e) {
    this.loadingPackagePlans = true;
    let newPackagePlan = new PackagePlan();
    this.packagePlan = e.data;
    newPackagePlan.packageId = this.package.id;
    newPackagePlan.planId = this.packagePlan.planId;
    newPackagePlan.planSequence = this.packagePlan.planSequence;
    this.packagePlanService.addPackagePlan(newPackagePlan).subscribe(
      (data) => {
        if (data.status) {
          this.toasterHelper.showToast(
            "Added successfully",
            data.message,
            "success"
          );
        } else {
          this.toasterHelper.showError(data.message);
        }
        this.loadingPackagePlans = false;

        this.getPackagePlans();
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
        this.loadingPackagePlans = false;
      }
    );
  }

  deletePackagePlan(e) {
    this.loadingPackagePlans = true;
    this.packagePlan = e.data;
    this.packagePlanService.deletePlan(this.packagePlan.id).subscribe(
      (data) => {
        if (data.status) {
          this.toasterHelper.showToast(
            "Deleted successfully",
            data.message,
            "success"
          );
        } else {
          this.toasterHelper.showError(data.message);
        }
        this.loadingPackagePlans = false;
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
        this.loadingPackagePlans = false;
      }
    );
  }

  onFocusedRowChanging(e) {
    const rowsCount = e.component.getVisibleRows().length;
    const pageCount = e.component.pageCount();
    const pageIndex = e.component.pageIndex();
    const key = e.event && e.event.key;
    const rowData = e.row && e.row.data;
    if (key && e.prevRowIndex === e.newRowIndex) {
      if (e.newRowIndex === rowsCount - 1 && pageIndex < pageCount - 1) {
        e.component.pageIndex(pageIndex + 1).done(() => {
          e.component.option("focusedRowIndex", 0);
        });
      } else if (e.newRowIndex === 0 && pageIndex > 0) {
        e.component.pageIndex(pageIndex - 1).done(() => {
          e.component.option("focusedRowIndex", rowsCount - 1);
        });
      }
    }
  }
  onFocusedRowChanged(e) {
    const rowData = e.row && e.row.data;
    // this.rowPackage= rowData;
    if (rowData) {
      this.taskSubject = rowData.Task_Subject;
      this.taskDetailsHtml = this.sanitizer.bypassSecurityTrustHtml(
        rowData.Task_Description
      );
      this.taskStatus = rowData.Task_Status;
      this.taskProgress = rowData.Task_Completion
        ? `${rowData.Task_Completion}` + "%"
        : "";
    }
  }

  ngOnInit(): void {
    this.getPackages();
    this.getPlans();
  }
}
