<div class="row">
    <div class="col-md-6">
        <nb-card class="own-scroll">
            <nb-card-header>
                <div class="row">
                    <div class="col-md-10">
                        control dashboard Users Types
                    </div>
                </div>
            </nb-card-header>
        </nb-card>
        <nb-card class="own-scroll">

            <nb-card-body [nbSpinner]="loadingUserTypes" nbSpinnerStatus="primary" nbSpinnerSize="large">
                <div id="data-grid-demo">
                    <dx-data-grid #grid id="gridContainer" [dataSource]="userTypes" keyExpr="id"
                        [focusedRowEnabled]="true" [showBorders]="true"
                        (onFocusedRowChanging)="onFocusedRowChanging($event)"
                        (onFocusedRowChanged)="onFocusedRowChanged($event)" (onRowInserting)="addUserType($event)"
                        (onRowUpdated)="updateUserType($event)" (onRowRemoving)="deleteUserType($event)">

                        <dxo-paging [enabled]="false"></dxo-paging>
                        <dxo-editing mode="form" [refreshMode]="refreshMode" [allowAdding]="true" [allowUpdating]="true"
                            allowDeleting="true">
                        </dxo-editing>
                        <dxo-filter-row [visible]="showFilterRow" [applyFilter]="currentFilter"></dxo-filter-row>
                        <dxo-header-filter [visible]="showHeaderFilter"></dxo-header-filter>
                        <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>
                        <dxi-column dataField="title">
                            <dxi-validation-rule type="required"></dxi-validation-rule>

                        </dxi-column>
                    </dx-data-grid>
                </div>
            </nb-card-body>
        </nb-card>



    </div>
    <div class="col-md-12">
        <nb-card class="own-scroll">
            <nb-card-header>
                <div class="row">
                    <div class="col-md-10">
                        control dashboard users
                    </div>
                </div>
            </nb-card-header>
        </nb-card>
        <nb-card class="own-scroll">

            <nb-card-body [nbSpinner]="loadingUsers" nbSpinnerStatus="primary" nbSpinnerSize="large">
                <div id="data-grid-demo">
                    <dx-data-grid #grid id="gridContainer" [dataSource]="users" keyExpr="id" [focusedRowEnabled]="true"
                        [showBorders]="true" (onFocusedRowChanging)="onFocusedRowChanging($event)"
                        (onFocusedRowChanged)="onFocusedRowChanged($event)" (onRowInserting)="addUser($event)"
                        (onRowUpdated)="updateUser($event)" (onRowRemoving)="deleteUser($event)"
                        (onInitNewRow)="allowEditing()" (onEditingStart)="disAllowEditing($event)">

                        <dxo-paging [enabled]="false"></dxo-paging>
                        <dxo-editing mode="form" [refreshMode]="refreshMode" [allowAdding]="true" [allowUpdating]="true"
                            allowDeleting="true">
                        </dxo-editing>
                        <dxo-filter-row [visible]="showFilterRow" [applyFilter]="currentFilter"></dxo-filter-row>
                        <dxo-header-filter [visible]="showHeaderFilter"></dxo-header-filter>
                        <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>
                        <dxi-column dataField="firstName">
                            <dxi-validation-rule type="required"></dxi-validation-rule>

                        </dxi-column>
                        <dxi-column dataField="lastName">
                            <dxi-validation-rule type="required"></dxi-validation-rule>

                        </dxi-column>
                        <dxi-column dataField="email">
                            <dxi-validation-rule type="required" message="required"></dxi-validation-rule>
                            <dxi-validation-rule type="email" message="invalid email"></dxi-validation-rule>
                        </dxi-column>
                        <dxi-column dataField="password" [allowEditing]="isAllowed">
                            <dxi-validation-rule type="custom" [validationCallback]="validatePassword"
                                message="should have capital and small latter">
                            </dxi-validation-rule>
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                            <dxi-validation-rule type="stringLength" [min]="8"
                                message="password must have at least 9 digits with at least one capital letters and small letter">
                            </dxi-validation-rule>
                        </dxi-column>
                        <dxi-column dataField="userTypeId" caption="User type" [width]="125">
                            <dxo-lookup [dataSource]="userTypes" displayExpr="title" valueExpr="id">
                            </dxo-lookup>
                            <dxi-validation-rule type="required"></dxi-validation-rule>

                        </dxi-column>
                    </dx-data-grid>
                </div>
            </nb-card-body>
        </nb-card>



    </div>
</div>