import { Injectable } from "@angular/core";
import { CookieService } from "ngx-cookie";
import { Router } from "@angular/router";


@Injectable({
  providedIn: "root",
})
export class UserSharedService {
    constructor() {}
    public UserTypeId: number = 0;  
}
