import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-nutritionist',
  templateUrl: './nutritionist.component.html',
  styleUrls: ['./nutritionist.component.scss']
})
export class NutritionistComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
