<nb-card size='gaint' style="background-color: #eaeef4;" class="container">
    <nb-card-header>Adding new patient</nb-card-header>
    <nb-card-body>
        <nb-card accent="info" class="col-md-8">
            <nb-card-body>
                <div class="row" *ngIf="!isAbleToRegister">

                    <div class="form-group col-sm-12">
                        <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta3/dist/css/bootstrap.min.css"
                            rel="stylesheet" />
                        <div class="col-md-12">
                            <label class="label">Phone Number</label>
                            <div class="row" style="width: 100%;">
                                <div class="col-md-4">
                                    <select #selectElem class="custom-select" *ngIf="!isPhoneDisabled"
                                        (change)="selectCountryCode(selectElem.value)">
                                        <option *ngFor="let countryCode of countryCodes" ([ngValue])="CountryCode">
                                            {{countryCode.code + " " + countryCode.dial_code}}</option>
                                    </select>
                                </div>
                                <div class="col-md-8">
                                    <input nbInput fullWidth [(ngModel)]="userData.phone" #phone="ngModel" name="phone"
                                        id="phone" pattern="[- +()0-9]+" placeholder="Phone Number" fieldSize="large"
                                        autofocus
                                        [status]="phone.dirty ? (phone.invalid  ? 'danger' : 'success') : 'basic'"
                                        [attr.aria-invalid]="phone.invalid && phone.touched ? true : null"
                                        maxlength="15" minlength="11" autocomplete="off" [disabled]="isPhoneDisabled">
                                </div>

                                <ng-container *ngIf="phone.invalid && phone.touched">
                                    <p class="caption status-danger" *ngIf="phone.errors?.required">
                                        phone is required!
                                    </p>
                                    <p class="caption status-danger" *ngIf="phone.errors?.pattern">
                                        phone should numbers!
                                    </p>

                                </ng-container>
                            </div>

                        </div>


                    </div>
                    <div class="form-group col-sm-12" *ngIf="isOTPSent">
                        <label class="label">OTP number</label>
                        <input nbInput fullWidth [(ngModel)]="userData.OtpNumber" #OtpNumber="ngModel" name="OtpNumber"
                            id="OtpNumber" pattern='^\d+$' placeholder="Otp Number" fieldSize="large" autofocus
                            [status]="OtpNumber.dirty ? (OtpNumber.invalid  ? 'danger' : 'success') : 'basic'"
                            [attr.aria-invalid]="OtpNumber.invalid && OtpNumber.touched ? true : null" maxlength="6"
                            minlength="6" autocomplete="off">
                        <ng-container *ngIf="OtpNumber.invalid && OtpNumber.touched">
                            <p class="caption status-danger" *ngIf="OtpNumber.errors?.required">
                                Otp Number is required!
                            </p>
                            <p class="caption status-danger" *ngIf="OtpNumber.errors?.pattern">
                                OtpNumber should numbers!
                            </p>

                        </ng-container>

                    </div>
                    <button class="col-sm-12" nbSpinnerStatus="success" (click)="checkOTP()" *ngIf="isOTPSent" nbButton
                        status="primary">

                        Verify </button>
                    <button class="col-sm-12" nbSpinnerStatus="success" (click)="checkIsUserRegistered()"
                        *ngIf="isUserInTheSystem && !phone.invalid && !phone.error?.minlength && !phone.error?.maxlength"
                        nbButton status="primary">

                        Check User in the system </button>
                    <button class="col-sm-12" nbSpinnerStatus="success" (click)="sendOTP()"
                        *ngIf="!isOTPSent && !isUserInTheSystem" nbButton status="primary">

                        Send OTP </button>
                    <button class="col-sm-12" nbSpinnerStatus="success" (click)="sendOTP()" [disabled]="isAbleToResend"
                        nbButton status="primary" *ngIf="isOTPSent">
                        Resend OTP </button>
                </div>

            </nb-card-body>
        </nb-card>

        <nb-card accent="info" class="col-md-12">
            <nb-card-header>Basic information</nb-card-header>

            <nb-card-body>
                <div class="row" *ngIf="isAbleToRegister">
                    <div class="form-group col-sm-3">
                        <label class="label">First Name</label>
                        <input type="text" fullWidth placeholder="First Name" nbInput [(ngModel)]="userData.firstName">
                    </div>
                    <div class="form-group col-sm-3">
                        <label class="label">Last Name</label>
                        <input type="text" fullWidth placeholder="Last Name" nbInput [(ngModel)]="userData.lastName">
                    </div>
                    <div class="form-group col-sm-3">
                        <label class="label">Phone Number</label>
                        <input nbInput fullWidth [(ngModel)]="userData.phone" #phone="ngModel" name="phone" id="phone"
                            pattern="[- +()0-9]+" placeholder="Phone Number" fieldSize="large" autofocus
                            [status]="phone.dirty ? (phone.invalid  ? 'danger' : 'success') : 'basic'"
                            [attr.aria-invalid]="phone.invalid && phone.touched ? true : null" maxlength="13"
                            minlength="11" autocomplete="off" disabled="isPhoneDisabled">
                        <ng-container *ngIf="phone.invalid && phone.touched">
                            <p class="caption status-danger" *ngIf="phone.errors?.required">
                                phone is required!
                            </p>
                            <p class="caption status-danger" *ngIf="phone.errors?.pattern">
                                phone should be numbers!
                            </p>

                        </ng-container>

                    </div>
                    <div class="form-group col-sm-3">
                        <label class="label">Date of birth</label>
                        <input fullWidth type="datetime-local" nbInput [(ngModel)]="userData.birthdate">
                    </div>

                    <div class="form-group col-sm-3">
                        <label class="label">Gender</label>
                        <nb-select [(ngModel)]="gender" [(selected)]="this.gender" fullWidth>
                            <nb-option value="0">Male</nb-option>
                            <nb-option value="1">Female</nb-option>
                        </nb-select>
                    </div>
                    <div class="form-group col-sm-3">
                        <label class="label">Language</label>
                        <nb-select [(ngModel)]="language" [(selected)]="this.language" fullWidth>
                            <nb-option value="1">Arabic</nb-option>
                            <nb-option value="0">English</nb-option>
                        </nb-select>
                    </div>

                    <div class="form-group col-sm-6">
                        <label class="label">Password</label>
                        <input type="password" fullWidth placeholder="Password..." nbInput
                            [(ngModel)]="userData.password">
                    </div>

                    <div class="form-group col-sm-12">
                        <label class="label" for="input-email">Email address:</label>
                        <input nbInput fullWidth [(ngModel)]="userData.email" #email="ngModel" name="email"
                            id="input-email" pattern=".+@.+\..+" placeholder="Email address" fieldSize="large" autofocus
                            [status]="email.dirty ? (email.invalid  ? 'danger' : 'success') : 'basic'"
                            [attr.aria-invalid]="email.invalid && email.touched ? true : null">
                        <ng-container *ngIf="email.invalid && email.touched">
                            <p class="caption status-danger" *ngIf="email.errors?.required">
                                Email is required!
                            </p>
                            <p class="caption status-danger" *ngIf="email.errors?.pattern">
                                Email should be the real one!
                            </p>
                        </ng-container>
                    </div>
                    <div style="text-align: center;" class="form-group col-sm-12">
                        <button style="width: 400px;" [nbSpinner]="saving" nbSpinnerStatus="success" nbButton
                            (click)="save()" status="primary" *ngIf="isAbleToRegister">Add</button>
                    </div>

                </div>
            </nb-card-body>
        </nb-card>

    </nb-card-body>
    <nb-card-footer style="text-align: right;">

    </nb-card-footer>
</nb-card>