import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { CookieService } from "ngx-cookie";
import { PatientService } from "../../../../@core/app-services/patient.service";
import { MenuSharedService } from "../../../../@core/shared-services/menu-shared.service";
import { UserSharedService } from "../../../../@core/shared-services/user-shared.service";

@Component({
  selector: "ngx-index",
  templateUrl: "./index.component.html",
  styleUrls: ["./index.component.scss"],
})
export class IndexComponent implements OnInit {
  patientId: string;
  displayBasicInfo: boolean;
  displayAddressAndBox: boolean;
  displayMembership: boolean;
  displayProfiles: boolean;
  displayCreateOrder: boolean;
  displayCheckOrders: boolean;
  displayWallet: boolean;
  displayLogANewCall: boolean;
  displayCallLogs: boolean;

  constructor(
    route: ActivatedRoute,
    private menuShared: MenuSharedService,
    private cookieService: CookieService,
    private patientService:PatientService,
    public userSharedService: UserSharedService
  ) {
    route.params.subscribe((params) => {
      this.patientId = params["id"];
    });
  }
  openSimulator(){
    this.patientService.openSimulator(this.patientId).subscribe(data=>{
      alert('Done');
    });
  }
  ngOnInit(): void {}
}
