import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { ExerciseService } from "../../../../@core/app-services/exercise.service";
import { ToasterHelper } from "../../../../@core/app-services/toasterHelper";
import { Exercise } from "../../../../@core/models/exercise";
import { ExerciseType } from "../../../../@core/models/ExerciseType";
// upload image definitions
import { AwsRepoConfig, AwsS3Manage } from "../../../../@core/lib/AwsS3Manage";
@Component({
  selector: "ngx-exercise",
  templateUrl: "./exercise.component.html",
  styleUrls: ["./exercise.component.scss"],
})
export class ExerciseComponent implements OnInit {
  animationSelectedFile: File;
  videoSelectedFile: File;
  awsS3Manage: AwsS3Manage = new AwsS3Manage();
  exerciseTypes: ExerciseType[] = [];
  exerciseType: ExerciseType;
  exercises: Exercise[] = [];
  exercise: Exercise;
  refreshMode: string;
  taskSubject: string;
  taskDetailsHtml: SafeHtml;
  taskStatus: string;
  taskProgress: string;
  currentFilter: any;
  loadingExerciseTypes: boolean = false;
  showFilterRow: boolean = true;
  loadingExercises: boolean = false;
  rowKey;
  showHeaderFilter: boolean = true;
  autoNavigateToFocusedRow = true;
  constructor(
    private exerciseService: ExerciseService,
    private toasterHelper: ToasterHelper,
    private sanitizer: DomSanitizer,
    private router: Router
  ) {}

  // exercise type functions
  getExerciseTypes() {
    this.loadingExerciseTypes = true;
    this.exerciseService.getExerciseTypes().subscribe(
      (data) => {
        if (data.status) {
          this.exerciseTypes = data.responseObject;
        } else {
          this.exerciseTypes = [];
        }
        this.loadingExerciseTypes = false;
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
        this.loadingExerciseTypes = false;
      }
    );
  }

  updateExerciseType(e) {
    this.loadingExerciseTypes = true;
    let updatedExerciseType = new ExerciseType();
    this.exerciseType = e.data;
    updatedExerciseType.nameAr = this.exerciseType.nameAr;
    updatedExerciseType.nameEn = this.exerciseType.nameEn;

    this.exerciseService
      .updateExerciseType(this.exerciseType.id, updatedExerciseType)
      .subscribe(
        (data) => {
          if (data.status) {
            this.toasterHelper.showToast(
              "Updated successfully",
              data.message,
              "success"
            );
          } else {
            this.toasterHelper.showError(data.message);
          }
          this.loadingExerciseTypes = false;
          this.getExerciseTypes();
        },
        (error) => {
          this.toasterHelper.showError("Something went wrong");
          this.loadingExerciseTypes = false;
        }
      );
  }

  addExerciseType(e) {
    this.loadingExerciseTypes = true;
    let newExerciseType = new ExerciseType();
    this.exerciseType = e.data;
    newExerciseType.nameAr = this.exerciseType.nameAr;
    newExerciseType.nameEn = this.exerciseType.nameEn;
    this.exerciseService.addExerciseType(newExerciseType).subscribe(
      (data) => {
        if (data.status) {
          this.toasterHelper.showToast(
            "Added successfully",
            data.message,
            "success"
          );
        } else {
          this.toasterHelper.showError(data.message);
        }
        this.loadingExerciseTypes = false;
        this.getExerciseTypes();
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
        this.loadingExerciseTypes = false;
      }
    );
  }
  deleteExerciseType(e) {
    this.exerciseType = e.data;
    this.exerciseService
      .deleteExerciseType(this.exerciseType.id)
      .subscribe((data) => {
        if (data.status) {
          this.toasterHelper.showToast(
            "Deleted successfully",
            data.message,
            "success"
          );
        } else {
          this.toasterHelper.showError(data.message);
        }
      });
  }
  // exercise functions
  getExercises() {
    this.loadingExercises = true;
    this.exerciseService.getExercises().subscribe(
      (data) => {
        if (data.status) {
          this.exercises = data.responseObject;
        } else {
          this.exercises = [];
        }
        this.loadingExercises = false;
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
        this.loadingExercises = false;
      }
    );
  }

  onEditingStart(e) {
    this.rowKey = e.key;
  }

  onSaving(e) {
    if (e.changes.length == 0) {
      e.changes.push({
        type: "update",
        key: this.rowKey,
        data: {},
      });
    }
  }

  async updateExercise(e) {
    this.loadingExercises = true;
    let updatedExercise = new Exercise();
    this.exercise = e.data;
    updatedExercise.nameAr = this.exercise.nameAr;
    updatedExercise.animationUrl = this.exercise.animationUrl;
    updatedExercise.videoUrl = this.exercise.videoUrl;
    updatedExercise.nameEn = this.exercise.nameEn;
    updatedExercise.descriptionAr = this.exercise.descriptionAr;
    updatedExercise.descriptionEn = this.exercise.descriptionEn;
    updatedExercise.hintAr = this.exercise.hintAr;
    updatedExercise.hintEn = this.exercise.hintEn;
    updatedExercise.repetitions = this.exercise.repetitions;
    updatedExercise.seconds = this.exercise.seconds;
    updatedExercise.exerciseTypeId = this.exercise.exerciseTypeId;
    if (this.animationSelectedFile) {
      await this.awsS3Manage
        .uploadFile({
          fileStream: this.animationSelectedFile,
          name:
            updatedExercise.nameEn +
            "animation" +
            "." +
            this.animationSelectedFile.type.split("/").pop(),
          location: AwsRepoConfig.exercisesURL,
        })
        .then((result) => {
          updatedExercise.animationUrl = result.Location;
          this.animationSelectedFile = null;
        })
        .catch((error) => {
          this.toasterHelper.showError(
            "Couldn't set a animation for " + updatedExercise.nameEn
          );
          this.animationSelectedFile = null;
          console.log(error.message);
        });
    }
    if (this.videoSelectedFile) {
      await this.awsS3Manage
        .uploadFile({
          fileStream: this.videoSelectedFile,
          name:
            updatedExercise.nameEn +
            "video" +
            "." +
            this.videoSelectedFile.type.split("/").pop(),
          location: AwsRepoConfig.exercisesURL,
        })
        .then((result) => {
          updatedExercise.videoUrl = result.Location;
          this.videoSelectedFile = null;
        })
        .catch((error) => {
          this.toasterHelper.showError(
            "Couldn't set a photo for " + updatedExercise.nameEn
          );
          this.loadingExercises = false;
          console.log(error.message);
          this.videoSelectedFile = null;
        });
    }
    await this.exerciseService
      .updateExercise(this.exercise.id, updatedExercise)
      .subscribe(
        async (data) => {
          if (data.status) {
            this.toasterHelper.showToast(
              "Updated successfully",
              data.message,
              "success"
            );
          } else {
            this.toasterHelper.showError(data.message);
          }
          this.loadingExercises = true;
          this.animationSelectedFile = null;
          await this.getExercises();
        },
        (error) => {
          this.toasterHelper.showError("Something went wrong");
          this.loadingExercises = false;
        }
      );
  }

  async addExercise(e) {
    this.loadingExercises = true;
    let newExercise = new Exercise();
    this.exercise = e.data;
    newExercise.nameAr = this.exercise.nameAr;
    newExercise.animationUrl = this.exercise.animationUrl;
    newExercise.videoUrl = this.exercise.videoUrl;
    newExercise.nameEn = this.exercise.nameEn;
    newExercise.descriptionAr = this.exercise.descriptionAr;
    newExercise.descriptionEn = this.exercise.descriptionEn;
    newExercise.hintAr = this.exercise.hintAr;
    newExercise.hintEn = this.exercise.hintEn;
    newExercise.repetitions = this.exercise.repetitions;
    newExercise.seconds = this.exercise.seconds;
    newExercise.exerciseTypeId = this.exercise.exerciseTypeId;
    if (this.animationSelectedFile) {
      await this.awsS3Manage
        .uploadFile({
          fileStream: this.animationSelectedFile,
          name:
            newExercise.nameEn +
            "animation" +
            "." +
            this.animationSelectedFile.type.split("/").pop(),
          location: AwsRepoConfig.exercisesURL,
        })
        .then((result) => {
          newExercise.animationUrl = result.Location;
          this.animationSelectedFile = null;
        })
        .catch((error) => {
          this.toasterHelper.showError(
            "Couldn't set a photo for " + newExercise.nameEn
          );
          this.animationSelectedFile = null;
          this.loadingExercises = false;
          console.log(error.message);
        });
    } else {
      newExercise.animationUrl =
        "https://diethub-resources.s3.eu-central-1.amazonaws.com/defaultimage.jpg";
    }
    if (this.videoSelectedFile) {
      await this.awsS3Manage
        .uploadFile({
          fileStream: this.videoSelectedFile,
          name:
            newExercise.nameEn +
            "video" +
            "." +
            this.videoSelectedFile.type.split("/").pop(),
          location: AwsRepoConfig.exercisesURL,
        })
        .then((result) => {
          newExercise.videoUrl = result.Location;
        })
        .catch((error) => {
          this.toasterHelper.showError(
            "Couldn't set a photo for " + newExercise.nameEn
          );
          this.loadingExercises = false;
          console.log(error.message);
        });
    } else {
      newExercise.videoUrl =
        "https://diethub-resources.s3.eu-central-1.amazonaws.com/defaultimage.jpg";
    }

    await this.exerciseService.addExercise(newExercise).subscribe(
      async (data) => {
        if (data.status) {
          this.toasterHelper.showToast(
            "Added successfully",
            data.message,
            "success"
          );
        } else {
          this.toasterHelper.showError(data.message);
        }
        this.loadingExercises = false;
        this.animationSelectedFile = null;
        this.videoSelectedFile = null;
        await this.getExercises();
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
        this.loadingExercises = false;
      }
    );
  }
  deleteExercise(e) {
    this.loadingExercises = true;
    this.exercise = e.data;
    this.exerciseService.deleteExercise(this.exercise.id).subscribe(
      (data) => {
        if (data.status) {
          this.toasterHelper.showToast(
            "Deleted successfully",
            data.message,
            "success"
          );
        } else {
          this.toasterHelper.showError(data.message);
        }
        this.loadingExercises = false;
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
        this.loadingExercises = false;
      }
    );
    if (
      this.exercise.animationUrl != null ||
      this.exercise.animationUrl.length != 0 ||
      this.exercise.animationUrl !=
        "https://diethub-resources.s3.eu-central-1.amazonaws.com/defaultimage.jpg"
    ) {
      this.awsS3Manage
        .deleteFile({
          fileName:
            this.exercise.nameEn +
            "animation" +
            "." +
            this.exercise.animationUrl.split(".").pop(),
          location: AwsRepoConfig.exercisesURL,
        })
        .then((result) => {})
        .catch((error) => {
          this.toasterHelper.showError("Couldn't set a animation photo for ");
          console.log(error.message);
        });
    }
    if (
      this.exercise.videoUrl != null ||
      this.exercise.videoUrl.length != 0 ||
      this.exercise.videoUrl !=
        "https://diethub-resources.s3.eu-central-1.amazonaws.com/defaultimage.jpg"
    ) {
      this.awsS3Manage
        .deleteFile({
          fileName:
            this.exercise.nameEn +
            "video" +
            "." +
            this.exercise.videoUrl.split(".").pop(),
          location: AwsRepoConfig.exercisesURL,
        })
        .then((result) => {})
        .catch((error) => {
          this.toasterHelper.showError("Couldn't set a video photo for ");
          console.log(error.message);
        });
    }
  }
  onFocusedRowChanging(e) {
    const rowsCount = e.component.getVisibleRows().length;
    const pageCount = e.component.pageCount();
    const pageIndex = e.component.pageIndex();
    const key = e.event && e.event.key;
    const rowData = e.row && e.row.data;
    if (key && e.prevRowIndex === e.newRowIndex) {
      if (e.newRowIndex === rowsCount - 1 && pageIndex < pageCount - 1) {
        e.component.pageIndex(pageIndex + 1).done(() => {
          e.component.option("focusedRowIndex", 0);
        });
      } else if (e.newRowIndex === 0 && pageIndex > 0) {
        e.component.pageIndex(pageIndex - 1).done(() => {
          e.component.option("focusedRowIndex", rowsCount - 1);
        });
      }
    }
  }
  onFocusedRowChanged(e) {
    const rowData = e.row && e.row.data;
    // this.rowPackage= rowData;
    if (rowData) {
      this.taskSubject = rowData.Task_Subject;
      this.taskDetailsHtml = this.sanitizer.bypassSecurityTrustHtml(
        rowData.Task_Description
      );
      this.taskStatus = rowData.Task_Status;
      this.taskProgress = rowData.Task_Completion
        ? `${rowData.Task_Completion}` + "%"
        : "";
    }
  }

  onClick(e: any): void {}

  onAnimationValueChanged(e: any): void {
    this.animationSelectedFile = e.value[0]; // convert to base64 string
  }
  onVideoValueChanged(e: any): void {
    this.videoSelectedFile = e.value[0]; // convert to base64 string
  }

  onUploaded(e: any, cellInfo: any): void {}

  onUploadError(e: any): void {
    const xhttp = e.request;
    if (xhttp.status === 400) {
      e.message = e.error.responseText;
    }
    if (xhttp.readyState === 4 && xhttp.status === 0) {
      e.message = "Connection refused";
    }
  }

  onEditCanceled(e: any): void {}

  onSaved(e: any): void {}

  ngOnInit(): void {
    this.getExerciseTypes();
    this.getExercises();
  }
}
