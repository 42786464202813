import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { ApiResponse } from "../models/ApiResponse";
import { SessionTypeReview } from "../models/SessionTypeReview";

@Injectable({
  providedIn: "root",
})
export class SessionTypeReviewService {
  private getSessionTypeReviewsPath =
    environment.apiUrl +
    "SessionTypeReview/GetSessionTypeReviewBySessionTypeId?sessionTypeId=";
  private addSessionTypeReviewPath =
    environment.apiUrl + "SessionTypeReview/AddSessionTypeReview";
  private deleteSessionTypeReviewPath =
    environment.apiUrl + "SessionTypeReview/DeleteSessionTypeReviewById?id=";
  private updateSessionTypeReviewPath =
    environment.apiUrl + "SessionTypeReview/UpdateSessionTypeReview?id=";
  constructor(private http: HttpClient) {}

  getSessionTypeReviews(sessionTypeId: number): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(
      this.getSessionTypeReviewsPath + sessionTypeId
    );
  }
  addSessionTypeReview(model: SessionTypeReview): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.addSessionTypeReviewPath, model);
  }
  updateSessionTypeReview(
    SessionTypeReviewId: number,
    model: SessionTypeReview
  ): Observable<ApiResponse> {
    return this.http.put<ApiResponse>(
      this.updateSessionTypeReviewPath + SessionTypeReviewId,
      model
    );
  }
  deleteSessionTypeReview(
    SessionTypeReviewId: number
  ): Observable<ApiResponse> {
    return this.http.delete<ApiResponse>(
      this.deleteSessionTypeReviewPath + SessionTypeReviewId
    );
  }
}
