import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { SessionService } from "../../../../@core/app-services/session.service";
import { Session } from "../../../../@core/models/session";
import { SessionStatus } from "../../../../@core/models/sessionStatus";
import { LineChartModel } from "../../../../@core/models/visit";

@Component({
  selector: "ngx-session-list",
  templateUrl: "./session-list.component.html",
  styleUrls: ["./session-list.component.scss"],
})
export class SessionListComponent implements OnInit {
  //Pager settings
  readonly allowedPageSizes = [5, 10, "all"];
  readonly displayModes = [
    { text: "Display Mode 'full'", value: "full" },
    { text: "Display Mode 'compact'", value: "compact" },
  ];
  displayMode = "full";
  showPageSizeSelector = true;
  showInfo = true;
  showNavButtons = true;
  //Loader settings
  loadingVisible = true;
  // Data containers
  sessions: Session[];
  allSessions: Session[];
  upcomingCount: number;
  confirmedCount: number;
  missedCount: number;
  sessionStatuses: SessionStatus[] = [
    {
      id: 1,
      name: "UpComing",
    },
    {
      id: 2,
      name: "Confirmed",
    },
    {
      id: 3,
      name: "Missed",
    },
  ];
  lineChartModel: LineChartModel[] = [
    { value: "weight", name: "Weight" },
    { value: "totalBodyWater", name: "Total Body Water" },
    { value: "smm", name: "Skeletal Muscle Mass" },
    { value: "bmi", name: "Body Mass Index" },
    { value: "pbf", name: "Percent Body Fat" },
    { value: "bmr", name: "Basal Metabolic Rate" },
    { value: "whr", name: "Waist-Hip Ratio" },
    { value: "vfl", name: "Visceral Fat Level" },
  ];
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private sessionService: SessionService
  ) {}
  getSessionsForUser() {
    this.loadingVisible = true;
    this.route.params.subscribe((params) => {
      this.sessionService
        .getSessionsByApplicationUserId(params["id"])
        .subscribe((data) => {
          this.allSessions = data.responseObject;
          this.sessions = this.allSessions;
          this.upcomingCount = this.allSessions.filter(
            (x) => x.sessionStatus == 1
          ).length;
          this.confirmedCount = this.allSessions.filter(
            (x) => x.sessionStatus == 2
          ).length;
          this.missedCount = this.allSessions.filter(
            (x) => x.sessionStatus == 3
          ).length;
          this.loadingVisible = false;
        });
    });
  }

  ngOnInit(): void {
    this.getSessionsForUser();
  }
}
