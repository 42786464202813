export class AddPromoCode {
  packageId: number;
  isActive: boolean;
  expirationDate: Date;
  useLimit: number;
  code: string;
  discountType: number;
  discountValue: number;
  owner: string;
}
