import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { ApiResponse } from "../models/ApiResponse";
import { PackageStructure } from "../models/PackageStructure";

@Injectable({
  providedIn: "root",
})
export class PackageStructureService {
  private getPackageStructuresPath =
    environment.apiUrl +
    "PackageStructure/GetPackagesStructuresByPackageId?id=";
  private addPackageStructuresPath =
    environment.apiUrl + "PackageStructure/AddPackageStructure";
  private deletePackageStructuresPath =
    environment.apiUrl + "PackageStructure/DeletePackageStructureById?id=";
  private updatePackageStructuresPath =
    environment.apiUrl + "PackageStructure/UpdatePackageStructure?id=";

  constructor(private http: HttpClient) {}

  getPackageStructures(packageId: number): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(
      this.getPackageStructuresPath + packageId
    );
  }
  addPackageStructure(model: PackageStructure): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.addPackageStructuresPath, model);
  }
  updatePackageStructure(
    model: PackageStructure,
    packageStructureId: number
  ): Observable<ApiResponse> {
    return this.http.put<ApiResponse>(
      this.updatePackageStructuresPath + packageStructureId,
      model
    );
  }
  deletePackageStructure(packageStructureId: number): Observable<ApiResponse> {
    return this.http.delete<ApiResponse>(
      this.deletePackageStructuresPath + packageStructureId
    );
  }
}
