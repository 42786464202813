import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { AddUser } from "../models/AddUser";
import { ApiResponse } from "../models/ApiResponse";
import { UpdateUser } from "../models/updateUser";
import { UserType } from "../models/userType";
@Injectable({
  providedIn: "root",
})
export class IdentityService {
  private getUsersPath = environment.commonUrl + "Identity/GetUsers";
  private addUserPath = environment.commonUrl + "Identity/AddUser";
  private updateUserPath = environment.commonUrl + "Identity/UpdateUser?id=";
  private deleteUserPath =
    environment.commonUrl + "Identity/DeleteUserById?id=";
  private getUserByIdPath = environment.commonUrl + "Identity/GetUserById?id=";
  private searchPath = environment.commonUrl + "Identity/Search?keyword=";
  private getUserTypesPath = environment.commonUrl + "UserType/GetUserTypes";
  private addUserTypesPath = environment.commonUrl + "UserType/AddUserType";
  private updateUserTypePath =
    environment.commonUrl + "UserType/UpdateUserType?id=";
  private deleteUserTypePath =
    environment.commonUrl + "UserType/DeleteUserTypeById?id=";
  private resetPasswordPath = environment.commonUrl + "Identity/ResetPassword";
  constructor(private http: HttpClient) {}

  GetUsers(): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.getUsersPath);
  }
  GetUserById(userId: string): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.getUserByIdPath + userId);
  }
  search(keyword: string): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.searchPath + keyword);
  }
  AddUser(model: AddUser): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.addUserPath, model);
  }
  UpdateUser(model: UpdateUser, id: string): Observable<ApiResponse> {
    return this.http.put<ApiResponse>(this.updateUserPath + id, model);
  }
  DeleteUser(id: string): Observable<ApiResponse> {
    return this.http.delete<ApiResponse>(this.deleteUserPath + id);
  }
  resetPassword(model: AddUser): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.resetPasswordPath, model);
  }
  // for user types
  GetUserTypes(): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.getUserTypesPath);
  }
  AddUserType(model: UserType): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.addUserTypesPath, model);
  }

  UpdateUserType(model: UserType, id: number): Observable<ApiResponse> {
    return this.http.put<ApiResponse>(this.updateUserTypePath + id, model);
  }
  DeleteUserType(id: number): Observable<ApiResponse> {
    return this.http.delete<ApiResponse>(this.deleteUserTypePath + id);
  }
}
