import { Component, OnInit, ViewChild } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { DxDataGridComponent } from "devextreme-angular";
import { IdentityService } from "../../../../@core/app-services/identity.service";
import { ToasterHelper } from "../../../../@core/app-services/toasterHelper";
import { UserAvailabilityService } from "../../../../@core/app-services/user-availability.service";
import { AddUser } from "../../../../@core/models/AddUser";
import { UpdateUser } from "../../../../@core/models/updateUser";
import { UserType } from "../../../../@core/models/userType";

@Component({
  selector: "ngx-identity",
  templateUrl: "./identity.component.html",
  styleUrls: ["./identity.component.scss"],
})
export class IdentityComponent implements OnInit {
  @ViewChild(DxDataGridComponent, { static: false })
  dataGrid: DxDataGridComponent;
  user: AddUser;
  users: AddUser[] = [];
  refreshMode: string;
  taskSubject: string;
  taskDetailsHtml: SafeHtml;
  taskStatus: string;
  taskProgress: string;
  currentFilter: any;
  userTypes: UserType[] = [];
  userType: UserType;
  isAllowed: boolean;
  loadingUsers: boolean = false;
  loadingUserTypes: boolean = false;

  showFilterRow: boolean = true;

  showHeaderFilter: boolean = true;
  autoNavigateToFocusedRow = true;
  constructor(
    private identityService: IdentityService,
    private userAvailabilityService: UserAvailabilityService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private toasterHelper: ToasterHelper
  ) {}

  // user functions
  getUsers() {
    this.loadingUsers = true;
    this.identityService.GetUsers().subscribe(
      (data) => {
        if (data.status) {
          this.users = data.responseObject;
        } else {
          this.users = [];
        }
        this.loadingUsers = false;
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
        this.loadingUsers = false;
      }
    );
  }

  addUser(e) {
    this.loadingUsers = true;
    let newUser = new AddUser();
    this.user = e.data;
    newUser.email = this.user.email;
    newUser.firstName = this.user.firstName;
    newUser.lastName = this.user.lastName;
    newUser.userTypeId = this.user.userTypeId;
    newUser.password = this.user.password;
    this.identityService.AddUser(newUser).subscribe(
      (data) => {
        if (data.status) {
          this.toasterHelper.showSaved(data.message);
          this.user = data.responseObject;
        } else {
          this.toasterHelper.showError(data.message);
        }
        this.loadingUsers = false;

        this.getUsers();
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
        this.loadingUsers = false;
      }
    );
  }

  updateUser(e) {
    this.loadingUsers = true;
    let newUser = new AddUser();
    this.user = e.data;
    newUser.email = this.user.email;
    newUser.firstName = this.user.firstName;
    newUser.lastName = this.user.lastName;
    newUser.userTypeId = this.user.userTypeId;
    this.identityService.UpdateUser(newUser, this.user.id).subscribe(
      (data) => {
        if (data.status) {
          this.toasterHelper.showToast(
            "updated successfully",
            data.message,
            "success"
          );
        } else {
          this.toasterHelper.showError(data.message);
        }
        this.loadingUsers = false;

        this.getUsers();
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
        this.loadingUsers = false;
      }
    );
  }

  deleteUser(e) {
    this.loadingUsers = true;
    this.user = e.data;
    this.identityService.DeleteUser(this.user.id).subscribe(
      (data) => {
        if (data.status) {
          this.toasterHelper.showToast(
            "deleted successfully",
            data.message,
            "success"
          );
        } else {
          this.toasterHelper.showError(data.message);
        }
        this.loadingUsers = false;
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
        this.loadingUsers = false;
      }
    );
  }

  // userType functions
  getUserTypes() {
    this.loadingUserTypes = true;
    this.identityService.GetUserTypes().subscribe(
      (data) => {
        if (data.status) {
          this.toasterHelper.showToast(
            "get successfully",
            data.message,
            "success"
          );
          this.userTypes = data.responseObject;
        } else {
          this.userTypes = [];
          this.toasterHelper.showError(data.message);
        }
        this.loadingUserTypes = false;
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
        this.loadingUserTypes = false;
      }
    );
  }
  addUserType(e) {
    this.loadingUserTypes = true;
    let newUserType = new UserType();
    this.userType = e.data;
    newUserType.title = this.userType.title;
    this.identityService.AddUserType(newUserType).subscribe(
      (data) => {
        if (data.status) {
          this.toasterHelper.showToast(
            "updated successfully",
            data.message,
            "success"
          );
        } else {
          this.toasterHelper.showError(data.message);
        }
        this.loadingUserTypes = false;

        this.getUserTypes();
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
        this.loadingUserTypes = false;
      }
    );
  }

  updateUserType(e) {
    this.loadingUserTypes = true;
    let newUserType = new UserType();
    this.userType = e.data;
    newUserType.title = this.userType.title;

    this.identityService
      .UpdateUserType(newUserType, this.userType.id)
      .subscribe(
        (data) => {
          if (data.status) {
            this.toasterHelper.showToast(
              "updated successfully",
              data.message,
              "success"
            );
          } else {
            this.toasterHelper.showError(data.message);
          }
          this.loadingUserTypes = false;

          this.getUserTypes();
        },
        (error) => {
          this.toasterHelper.showError("Something went wrong");
          this.loadingUserTypes = false;
        }
      );
  }

  deleteUserType(e) {
    this.loadingUserTypes = true;
    this.userType = e.data;
    this.identityService.DeleteUserType(this.userType.id).subscribe(
      (data) => {
        if (data.status) {
          this.toasterHelper.showToast(
            "deleted successfully",
            data.message,
            "success"
          );
        } else {
          this.toasterHelper.showError(data.message);
        }
        this.loadingUserTypes = false;
        this.getUserTypes();
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
        this.loadingUserTypes = false;
      }
    );
  }

  onFocusedRowChanging(e) {
    const rowsCount = e.component.getVisibleRows().length;
    const pageCount = e.component.pageCount();
    const pageIndex = e.component.pageIndex();
    const key = e.event && e.event.key;
    const rowData = e.row && e.row.data;
    if (key && e.prevRowIndex === e.newRowIndex) {
      if (e.newRowIndex === rowsCount - 1 && pageIndex < pageCount - 1) {
        e.component.pageIndex(pageIndex + 1).done(() => {
          e.component.option("focusedRowIndex", 0);
        });
      } else if (e.newRowIndex === 0 && pageIndex > 0) {
        e.component.pageIndex(pageIndex - 1).done(() => {
          e.component.option("focusedRowIndex", rowsCount - 1);
        });
      }
    }
  }
  onFocusedRowChanged(e) {
    const rowData = e.row && e.row.data;
    if (rowData) {
      this.taskSubject = rowData.Task_Subject;
      this.taskDetailsHtml = this.sanitizer.bypassSecurityTrustHtml(
        rowData.Task_Description
      );
      this.taskStatus = rowData.Task_Status;
      this.taskProgress = rowData.Task_Completion
        ? `${rowData.Task_Completion}` + "%"
        : "";
    }
  }
  validatePassword(e) {
    var regExpCapitalLetters = /[A-Z]/g;
    var regExpSmallLetters = /[a-z]/g;
    let status1 = regExpCapitalLetters.test(e.value);

    let status2 = regExpSmallLetters.test(e.value);
    if (status1 == true && status2 == true) {
      return true;
    } else return false;
  }
  allowEditing() {
    this.isAllowed = true;
  }
  disAllowEditing(e) {
    this.isAllowed = false;
    e.data.password = "notYourPassword1.aA";
  }
  ngOnInit(): void {
    this.getUsers();
    this.getUserTypes();
  }
}
