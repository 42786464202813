export class AddSubscriptionMeal {
  id: number;
  dateNumber: number;
  Sequance: number;
  nameAr: string;
  nameEn: string;
  mealId: number;
  appUserId: number;
  subscriptionId: number;
}
