import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { environment } from "../../../environments/environment";
import { TreasuryDay } from "../models/treasuryDay";
import { TransactionType } from "../models/TransactionType";
import { Transaction } from "../models/Transaction";

@Injectable({
  providedIn: "root",
})
export class TreasuryService {
  private getJournalEntriesPath =
    environment.apiUrl + "Treasury/GetJournalEntries";
  private getTransactionTypesPath =
    environment.apiUrl + "Treasury/GetTransactionTypes";
  private addTransactionPath = environment.apiUrl + "Treasury/AddTransaction";
  private deleteTransactionPath =
    environment.apiUrl + "Treasury/DeleteTransaction?transactionId=";
  private getSpendingTypeChartByTimePath =
    environment.apiUrl + "Treasury/GetSpendingTypeChartByTime?from=";
  constructor(private http: HttpClient) {}
  getJournalEntries(): Observable<TreasuryDay[]> {
    return this.http.get<TreasuryDay[]>(this.getJournalEntriesPath);
  }
  getTransactionTypes(): Observable<TransactionType[]> {
    return this.http.get<TransactionType[]>(this.getTransactionTypesPath);
  }
  addTransaction(model: Transaction): Observable<any> {
    return this.http.post<any>(this.addTransactionPath, model);
  }
  deleteTransaction(transactionId: number): Observable<any> {
    return this.http.post<any>(this.deleteTransactionPath + transactionId, "");
  }
  getSpendingTypeChartByTime(from, to): Observable<any> {
    return this.http.get<any>(
      this.getSpendingTypeChartByTimePath +
        from.toDateString() +
        "&to=" +
        to.toDateString()
    );
  }
}
