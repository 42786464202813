import { Component, OnInit, TemplateRef } from "@angular/core";
import { NbDialogService } from "@nebular/theme";
import { VisitService } from "../../../@core/app-services/visit.service";
import { ActivityService } from "../../../@core/app-services/activity.service";
import { Visit } from "../../../@core/models/visit";
import { Activity } from "../../../@core/models/activity";
import { DayCellComponent } from './day-cell/day-cell.component';
import { Router } from "@angular/router";
import { ToasterHelper } from "../../../@core/app-services/toasterHelper";
import { UserSharedService } from "../../../@core/shared-services/user-shared.service";

@Component({
  selector: "ngx-schedule",
  templateUrl: "./schedule.component.html",
  styleUrls: ["./schedule.component.scss"],
  entryComponents: [DayCellComponent],
})
export class ScheduleComponent {
  //Loader settings
  loadingVisible = true;

  // Calendar
  disabledDaysFilter = selectedDate => selectedDate.getDay() !== 0 && selectedDate.getDay() !== 6;
  dayCellComponent = DayCellComponent;

  //Data Containers
  relatedVisit: Visit;
  selectedActivity: Activity;
  MyActivitiesCount : number = 0;
  AllVisitCount : number = 0;
  NACount : number = 0;
  ConfirmedCount : number = 0;
  MissedCount : number = 0;
  showVisits: boolean = false;
  activitiesForCurrentUser: Activity[] = new Array();
  Visits: Visit[] = new Array();
  selectedDate = new Date();
  newVisitDate: Date = new Date();
  newVisitStartTime: number = 0;
  
  
  constructor(private toasterHelper: ToasterHelper,
              private visitService: VisitService, 
              private ActivityService: ActivityService,
              private dialogService:NbDialogService,
              private router:Router,
              public userSharedService: UserSharedService) {
                this.reloadSchedule();
  }
  
  reloadSchedule(){
    this.loadingVisible = true;
    //this.getActivitiesForCurrentUser();
    this.getVisitsForSelectedDate(this.selectedDate,this.selectedDate);
  }

  openRelatedVisit(activity, dialog: TemplateRef<any>) {
    this.router.navigate(['/visit'],{queryParams:{visitid:activity.visitId}});
  }

  openVisit(visit, dialog: TemplateRef<any>) {
      this.relatedVisit = visit;
      if(this.userSharedService.UserTypeId == 1 && visit.status == "Checked in")
      {
        this.router.navigate(['/pages/dashboard/visit'],{queryParams:{visitid:this.relatedVisit.id}});
      }
      else
      {
          this.dialogService.open(dialog)
      }
  }
  
  getActivitiesForCurrentUser()
  {
    this.ActivityService.getActivitiesForCurrentUser(this.selectedDate).subscribe(data=>{
      this.activitiesForCurrentUser = data
      this.MyActivitiesCount = this.activitiesForCurrentUser.length;
    });
  }

  getVisitsForSelectedDate(from,to)
  {
    this.visitService.getVisitsByTime(this.selectedDate,this.selectedDate).subscribe(data=>{
      this.Visits = data
      this.AllVisitCount = this.Visits.length;
      this.NACount = this.Visits.filter(v=> v.status === 'NA').length;
      this.ConfirmedCount = this.Visits.filter(v=> v.status === 'Checked in').length;
      this.MissedCount = this.Visits.filter(v=> v.status === 'Missed').length;
      this.loadingVisible = false;
    });
  }
  
  
}
