import { Component, OnInit } from "@angular/core";
import { IdentityService } from "../../../../@core/app-services/identity.service";
import { ToasterHelper } from "../../../../@core/app-services/toasterHelper";
import { AddUser } from "../../../../@core/models/AddUser";

@Component({
  selector: "ngx-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss"],
})
export class ResetPasswordComponent {
  dashboardUser: AddUser = new AddUser();

  constructor(
    private identityService: IdentityService,
    private toasterHelper: ToasterHelper
  ) {}
  resetPassword() {
    this.identityService.resetPassword(this.dashboardUser).subscribe((data) => {
      if (data) {
        this.toasterHelper.showSaved("password rested successfully");
      } else {
        this.toasterHelper.showError("password didn't reset");
      }
    });
  }
}
