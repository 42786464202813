import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { environment } from "../../../environments/environment";
import { BodyProfileInstance } from "../models/bodyProfileInstance";
import { ApiResponse } from "../models/ApiResponse";
import { TimeRangeModel } from "../models/timeRangeModel";

@Injectable({
  providedIn: "root",
})
export class BodyProfileService {
  private addBodyProfileInstancePath =
    environment.apiUrl + "BodyProfile/AddBodyProfileInstance";
  private getBodyProfileInstancesPath =
    environment.apiUrl +
    "BodyProfile/GetBodyProfileInstances?applicationUserId=";
  private updateBodyProfileInstancePath =
    environment.apiUrl + "BodyProfile/UpdateBodyProfileInstance?Id=";
  private deleteBodyProfileInstancePath =
    environment.apiUrl + "BodyProfile/DeleteBodyProfileInstance?Id=";

  private getBodyProfileChartByTimePath =
    environment.apiUrl + "BodyProfile/GetBodyProfileChartByTime?from=";

  constructor(private http: HttpClient) {}
  addBodyProfileInstance(instance: BodyProfileInstance): Observable<any> {
    return this.http.post<any>(this.addBodyProfileInstancePath, instance);
  }
  updateBodyProfileInstance(
    id: number,
    instance: BodyProfileInstance
  ): Observable<ApiResponse> {
    return this.http.put<ApiResponse>(
      this.updateBodyProfileInstancePath + id,
      instance
    );
  }
  deleteBodyProfileInstance(id: number): Observable<ApiResponse> {
    return this.http.delete<ApiResponse>(
      this.deleteBodyProfileInstancePath + id
    );
  }
  getBodyProfileInstances(applicationUserId: number): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(
      this.getBodyProfileInstancesPath + applicationUserId
    );
  }
  getBodyProfileChartByTime(from, to, property): Observable<any> {
    return this.http.get<any>(
      this.getBodyProfileChartByTimePath +
        from.toDateString() +
        "&to=" +
        to.toDateString() +
        "&property=" +
        property
    );
  }
}
