<div class="row">
    <div class="col-md-6">
        <nb-card class="own-scroll">
            <nb-card-header>
                <div class="row">
                    <div class="col-md-10">
                        Set Diseases
                    </div>
                </div>
            </nb-card-header>
        </nb-card>
        <nb-card class="own-scroll">

            <nb-card-body [nbSpinner]="loadingDiseases" nbSpinnerStatus="primary" nbSpinnerSize="large">
                <div id="data-grid-demo">
                    <dx-data-grid #grid id="gridContainer" [dataSource]="diseases" keyExpr="id"
                        [focusedRowEnabled]="true" [showBorders]="true"
                        (onFocusedRowChanging)="onFocusedRowChanging($event)"
                        (onFocusedRowChanged)="onFocusedRowChanged($event)" (onRowUpdated)="updateDisease($event)"
                        (onRowInserting)="addDisease($event)" (onRowRemoving)="deleteDisease($event)">
                        <dxo-paging [enabled]="false"></dxo-paging>
                        <dxo-editing mode="form" [refreshMode]="refreshMode" [allowUpdating]="true"
                            [allowDeleting]="true" [allowAdding]="true">
                        </dxo-editing>
                        <dxo-filter-row [visible]="showFilterRow" [applyFilter]="currentFilter"></dxo-filter-row>
                        <dxo-header-filter [visible]="showHeaderFilter"></dxo-header-filter>
                        <dxo-search-panel [visible]="true" [width]="240" placeholder="Search...">

                        </dxo-search-panel>
                        <dxi-column dataField="nameAr">
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dxi-column>
                        <dxi-column dataField="nameEn">
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dxi-column>
                        <dxi-column dataField="descriptionAr">
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dxi-column>
                        <dxi-column dataField="descriptionEn">
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dxi-column>
                    </dx-data-grid>
                </div>
            </nb-card-body>
        </nb-card>



    </div>
    <div class="col-md-12">
        <nb-card class="own-scroll">
            <nb-card-header>
                <div class="row">
                    <div class="col-md-10">
                        Set Medications
                    </div>
                </div>
            </nb-card-header>
        </nb-card>
        <nb-card class="own-scroll">

            <nb-card-body [nbSpinner]="loadingMedications" nbSpinnerStatus="primary" nbSpinnerSize="large">
                <div id="data-grid-demo">
                    <dx-data-grid #grid id="gridContainer" [dataSource]="medications" keyExpr="id"
                        [focusedRowEnabled]="true" [showBorders]="true"
                        (onFocusedRowChanging)="onFocusedRowChanging($event)"
                        (onFocusedRowChanged)="onFocusedRowChanged($event)" (onRowUpdated)="updateMedication($event)"
                        (onRowInserting)="addMedication($event)" (onRowRemoving)="deleteMedication($event)">

                        <dxo-paging [enabled]="false"></dxo-paging>
                        <dxo-editing mode="form" [refreshMode]="refreshMode" [allowUpdating]="true"
                            [allowDeleting]="true" [allowAdding]="true">
                        </dxo-editing>
                        <dxo-filter-row [visible]="showFilterRow" [applyFilter]="currentFilter"></dxo-filter-row>
                        <dxo-header-filter [visible]="showHeaderFilter"></dxo-header-filter>
                        <dxo-search-panel [visible]="true" [width]="240" placeholder="Search...">
                        </dxo-search-panel>
                        <dxi-column dataField="nameAr">
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dxi-column>
                        <dxi-column dataField="nameEn">
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dxi-column>
                        <dxi-column dataField="precautionsAr">
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dxi-column>
                        <dxi-column dataField="precautionsEn">
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dxi-column>
                        <dxi-column dataField="useIntructionAr">
                        </dxi-column>
                        <dxi-column dataField="useIntructionEn">
                        </dxi-column>
                        <dxi-column dataField="diseaseId" caption="Disease" [width]="125">
                            <dxo-lookup [dataSource]="diseases" displayExpr="nameEn" valueExpr="id">
                            </dxo-lookup>
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dxi-column>
                    </dx-data-grid>
                </div>
            </nb-card-body>
        </nb-card>



    </div>

</div>