<div class="row">
    <div class="col-md-12">
        <nb-card class="own-scroll">
            <nb-card-header>
                <div class="row">
                    <div class="col-md-10">
                        control subscription
                    </div>
                </div>
            </nb-card-header>
        </nb-card>
        <nb-card class="own-scroll">

            <nb-card-body [nbSpinner]="loadingSubscriptions" nbSpinnerStatus="primary" nbSpinnerSize="large">
                <div id="data-grid-demo">
                    <dx-data-grid #grid id="gridContainer" [dataSource]="subscriptions" keyExpr="id"
                        [focusedRowEnabled]="true" [showBorders]="true"
                        (onFocusedRowChanging)="onFocusedRowChanging($event)"
                        (onRowUpdated)="updateSubscription($event)" (onFocusedRowChanged)="onFocusedRowChanged($event)"
                        (onRowInserting)="addSubscription($event)" (onInitNewRow)="allowEditing()"
                        (onEditingStart)="disAllowEditing()">

                        <dxo-paging [enabled]="false"></dxo-paging>
                        <dxo-editing mode="form" [refreshMode]="refreshMode" [allowAdding]="true"
                            [allowUpdating]="true">
                        </dxo-editing>
                        <dxo-filter-row [visible]="showFilterRow" [applyFilter]="currentFilter"></dxo-filter-row>
                        <dxo-header-filter [visible]="showHeaderFilter"></dxo-header-filter>
                        <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>
                        <dxi-column dataField="applicationUserId" caption="application User" [width]="125"
                            [allowEditing]="isAllowed">
                            <dxo-lookup [dataSource]="appUsers" displayExpr="fullName" valueExpr="id">
                            </dxo-lookup>
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dxi-column>
                        <dxi-column dataField="packageId" caption="package" [width]="125" [allowEditing]="isAllowed">
                            <dxo-lookup [dataSource]="packages" displayExpr="titleEn" valueExpr="id">
                            </dxo-lookup>
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dxi-column>
                        <dxi-column dataField="branchId" caption="branch" [width]="125" [allowEditing]="isAllowed">
                            <dxo-lookup [dataSource]="branches" displayExpr="nameEn" valueExpr="id">
                            </dxo-lookup>
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dxi-column>
                        <dxi-column dataField="paymobTransactionId" [allowEditing]="false">
                        </dxi-column>
                        <dxi-column dataField="isActive" dataType="boolean">
                        </dxi-column>
                        <dxi-column dataField="isSingleUser" caption="Is singe Doctor" dataType="boolean">
                        </dxi-column>
                        <dxi-column dataField="FreezedAt" dataType="datetime">
                        </dxi-column>
                        <dxi-column dataField="expirationDate" dataType="datetime">
                        </dxi-column>
                        <dxi-column dataField="ammount" dataType="number">
                        </dxi-column>
                    </dx-data-grid>
                </div>
            </nb-card-body>
        </nb-card>



    </div>
</div>