import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { AddSession } from "../models/addSession";
import { ApiResponse } from "../models/ApiResponse";
import { GetSessionsFilterModel } from "../models/getSessionsFilterModel";
import { Session } from "../models/session";
import { TimeSlot } from "../models/timeSlot";
import { UpdateSession } from "../models/updateSession";

@Injectable({
  providedIn: "root",
})
export class SessionService {
  private getSessionsWithFiltrationPath =
    environment.apiUrl + "Sessions/GetSessionsWithFiltration";
  private getSessionsForCurrentUserPath =
    environment.apiUrl + "Sessions/GetCurrentUserSessions";
  private getSessionsForByApplicationUserIdPath =
    environment.apiUrl + "Sessions/GetSessionsByAppUserId?id=";
  private addSessionPath = environment.apiUrl + "Sessions/AddSession";
  private deleteSessionByIdPath =
    environment.apiUrl + "Sessions/DeleteSessionById?id=";
  private updateSessionByIdPath =
    environment.apiUrl + "Sessions/UpdateSession?id=";
  private GetAvailableTimeSlotsPath =
    environment.apiUrl + "Sessions/GetAvailbleTimeSlotsForDashboard";

  constructor(private http: HttpClient) {}

  getSessionsWithFiltration(
    model: GetSessionsFilterModel
  ): Observable<ApiResponse> {
    return this.http.put<ApiResponse>(
      this.getSessionsWithFiltrationPath,
      model
    );
  }
  getSessionsForCurrentUser(
    model: GetSessionsFilterModel
  ): Observable<ApiResponse> {
    return this.http.put<ApiResponse>(
      this.getSessionsForCurrentUserPath,
      model
    );
  }
  getSessionsByApplicationUserId(appUserId: number): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(
      this.getSessionsForByApplicationUserIdPath + appUserId
    );
  }
  deleteSessionById(sessionId: number): Observable<ApiResponse> {
    return this.http.delete<ApiResponse>(
      this.deleteSessionByIdPath + sessionId
    );
  }
  updateSessionById(
    sessionId: number,
    model: UpdateSession
  ): Observable<ApiResponse> {
    return this.http.put<ApiResponse>(
      this.updateSessionByIdPath + sessionId,
      model
    );
  }
  GetAvailableTimeSlots(model: TimeSlot): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.GetAvailableTimeSlotsPath, model);
  }
  addSession(model: AddSession): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.addSessionPath, model);
  }
}
