export class Medication {
  id: 0;
  nameEn: string;
  nameAr: string;
  precautionsEn: string;
  precautionsAr: string;
  useIntructionEn: string;
  useIntructionAr: string;
  diseaseId: 0;
}
