<div class="row">
    <div class="col-md-12">
        <nb-card class="own-scroll">
            <nb-card-header>
                <div class="row">
                    <div class="col-md-10">
                        Set Packages
                    </div>
                </div>
            </nb-card-header>
        </nb-card>
        <nb-card class="own-scroll">

            <nb-card-body [nbSpinner]="loadingPackages" nbSpinnerMessage="Getting Packages" nbSpinnerStatus="primary"
                nbSpinnerSize="large">
                <div id="data-grid-demo">
                    <dx-data-grid #grid id="gridContainer" [dataSource]="packages" keyExpr="id"
                        [focusedRowEnabled]="true" [showBorders]="true"
                        (onFocusedRowChanging)="onFocusedRowChanging($event)" [columnWidth]="120"
                        (onFocusedRowChanged)="onFocusedRowChanged($event)" (onRowUpdated)="updatePackage($event)"
                        (onRowInserting)="addPackage($event)" (onRowRemoving)="deletePackage($event)">
                        <dxo-paging [enabled]="false"></dxo-paging>
                        <dxo-scrolling columnRenderingMode="virtual"></dxo-scrolling>
                        <dxo-editing mode="form" [refreshMode]="refreshMode" [allowUpdating]="true"
                            [allowDeleting]="true" [allowAdding]="true">
                        </dxo-editing>
                        <dxo-filter-row [visible]="showFilterRow" [applyFilter]="currentFilter"></dxo-filter-row>
                        <dxo-header-filter [visible]="showHeaderFilter"></dxo-header-filter>
                        <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>
                        <dxi-column type="buttons">
                            <dxi-button hint="Edit" name="edit" text="edit">
                            </dxi-button>
                            <dxi-button hint="Delete" name="delete" text="delete">
                            </dxi-button>
                        </dxi-column>
                        <dxi-column dataField="titleAr">
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dxi-column>
                        <dxi-column dataField="titleEn">
                            <dxi-validation-rule type="required"></dxi-validation-rule>

                        </dxi-column>

                        <dxi-column dataField="descriptionAr">
                            <dxi-validation-rule type="required"></dxi-validation-rule>

                        </dxi-column>
                        <dxi-column dataField="descriptionEn">
                            <dxi-validation-rule type="required"></dxi-validation-rule>

                        </dxi-column>
                        <dxi-column dataField="priceBefore" dataType='number'>
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dxi-column>
                        <dxi-column dataField="priceInUSD" dataType='number'>
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dxi-column>
                        <dxi-column dataField="priceAfter" dataType='number' type="required"></dxi-column>
                        <dxi-column dataField="discount" dataType='number'>
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dxi-column>
                        <dxi-column dataField="type">
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dxi-column>
                        <dxi-column dataField="isActive" dataType='boolean'>
                        </dxi-column>
                        <dxi-column dataField="duration" dataType='number'>
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                            <dxi-validation-rule type="custom" [validationCallback]="validateDuration"
                                message="should be multiple by 7 as weeks">
                            </dxi-validation-rule>
                        </dxi-column>

                    </dx-data-grid>
                </div>
            </nb-card-body>
        </nb-card>



    </div>
</div>