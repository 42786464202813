import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { environment } from "../../../environments/environment";
import { ApiResponse } from "../models/ApiResponse";
import { TimeRangeModel } from "../models/timeRangeModel";

@Injectable({
  providedIn: "root",
})
export class ReportService {
  private getAppUsersLossLessThanOneKiloPath =
    environment.apiUrl + "Report/GetAppUsersLossLessThanOneKilo";
  private getAppUsersMissedSessionsPath =
    environment.apiUrl + "Report/GetAppUserMissedSessions";
  private getAppUsersSubscriptionFollowupsPath =
    environment.apiUrl + "Report/GetAppUserSubscriptionFollowups";
  private getAppUsersExaminationFollowupsPath =
    environment.apiUrl + "Report/GetAppUserExaminationFollowups";
  private getAppUsersRenewalFollowupsPath =
    environment.apiUrl + "Report/GetAppUserRenewalFollowups";
  private getAppUsersIntermittentFastingFollowupsPath =
    environment.apiUrl + "Report/GetAppUsersIntermittentFastingFollowup";
  
  constructor(private http: HttpClient) {}
  
  
  getAppUsersLossLessThanOneKilo(
    model: TimeRangeModel
  ): Observable<ApiResponse> {
    return this.http.put<ApiResponse>(
      this.getAppUsersLossLessThanOneKiloPath,
      model
    );
  }
  getAppUsersMissedSessions( 
  ): Observable<ApiResponse> {
    return this.http.put<ApiResponse>(
      this.getAppUsersMissedSessionsPath,
      ""
    );
  }
  getAppUsersSubscriptionFollowups( 
    ): Observable<ApiResponse> {
      return this.http.put<ApiResponse>(
        this.getAppUsersSubscriptionFollowupsPath,
        ""
      );
    }
    getAppUsersExaminationFollowups( 
    ): Observable<ApiResponse> {
      return this.http.put<ApiResponse>(
        this.getAppUsersExaminationFollowupsPath,
        ""
      );
    }
    getAppUsersRenewalFollowups( 
      ): Observable<ApiResponse> {
        return this.http.put<ApiResponse>(
          this.getAppUsersRenewalFollowupsPath,
          ""
        );
      }  
    getAppUsersIntermittentFastingFollowups( 
    ): Observable<ApiResponse> {
      return this.http.put<ApiResponse>(
        this.getAppUsersIntermittentFastingFollowupsPath,
         ""
      );
    }  
}
