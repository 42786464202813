<div class="row" *ngIf="visit">
    <div class="col-md-12" *ngIf="visit.status=='Missed'">
        <ngx-empty-state image="papers.png" text="This visit was marked as missed!">
        </ngx-empty-state>
    </div>
    <div *ngIf="visit.status=='Not Confirmed'" class="col-sm-12">
        <nb-card size="large">
            <nb-card-header class="row">
                Confirm visit
            </nb-card-header>
            <nb-card-body [nbSpinner]="this.gettingAvailableSlots" nbSpinnerStatus="primary" nbSpinnerSize="large"
                nbSpinnerMessage="Getting available slots">
                <div class="row">
                    <div class="form-group col-md-8">
                        <label class="label">Select Date</label>
                        <input readonly fullWidth nbInput placeholder="Visit Date" [nbDatepicker]="visitDatePicker"
                            [(ngModel)]="selectedVisitDate">
                        <nb-datepicker #visitDatePicker></nb-datepicker>
                    </div>
                </div>
                <button (click)="getAvailableSlots()" nbButton status="success">Get Available Slots</button>
                <h6>Available slots</h6>
                <div>
                    <nb-card *ngFor="let slot of slots" [nbSpinner]="slot.bookingSlot" nbSpinnerStatus="success"
                        nbSpinnerSize="small" [status]="slot.isAvailable ? 'info': 'danger'"
                        (click)="this.bookSlot(slot)" class="visit">
                        <nb-card-header class="row">
                            <div class="col-md-8">
                                {{ slot.startFrom | date: "hh:mm a" }} - {{ slot.endAt | date: "hh:mm a" }}
                            </div>
                            <div class="col-md-4">
                                {{slot.isAvailable ? 'Available' : 'Reserved'}}
                            </div>
                        </nb-card-header>
                    </nb-card>
                </div>
            </nb-card-body>
        </nb-card>
    </div>
    <div *ngIf="visit.status=='Checked in'" class="col-sm-7" >
        <ngx-body-profile style="height: auto;">
        </ngx-body-profile>
    </div>
    <div *ngIf="visit.status=='Checked in'" class="col-sm-5">
        <nb-card size="large" accent="success">
            <nb-card-header class="row">
                <div class="col-md-8">
                    Activities
                </div>
                <div class="col-md-2">
                    <nb-action title="Save" icon="save-outline" [nbSpinner]="this.updatingActivity" (click)="saveChangedAcivities()">
                    </nb-action>
                </div>
                <div class="col-md-2">
                    <nb-action title="Add Activity" icon="plus-outline" (click)="openDialog(addActivityDialog)">
                    </nb-action>
                </div>
            </nb-card-header>
            <nb-card-body [nbSpinner]="this.gettingActivities" nbSpinnerStatus="primary" nbSpinnerSize="large"
                nbSpinnerMessage="Getting visit activities">
                <nb-list *ngIf="activities.length > 0">
                    <nb-list-item *ngFor="let activity of activities; let i = index">
                        <span style="width: 30%;"> {{ activity.activityType }}</span>
                        <nb-select style="width: 60%; padding-right: 5px;" fullWidth [ng-attr-tag]="activity.activityType"
                            [(selected)]="activity.activityOwnerId"
                            (selectedChange)="activtiyOwnerChanged($event)" hero>
                                <nb-option *ngFor="let owner of this.allUsers" 
                                            [value]="owner.id" 
                                            (click)="acivityNewOwnerClicked($event, owner, activity.activityType)">
                                       {{ owner.fullName }}</nb-option>
                        </nb-select>
                        <nb-action (click)="deleteActivity(activity.id)" title="Delete Activity" icon="trash-2-outline">
                            
                        </nb-action>
                    </nb-list-item>
                </nb-list>
                <div *ngIf="activities.length==0 && !gettingActivities">
                    <ngx-empty-state image="EmptyState.png" text="No activities!">
                    </ngx-empty-state>
                    <div style="text-align: center;margin-top: 10px;">
                        <button (click)="openDialog(addActivityDialog)" nbButton status="success">Add an
                            activity</button>
                    </div>
                </div>
            </nb-card-body>
        </nb-card>
    </div>

    <div *ngIf="visit.status=='Checked in'" class="col-sm-12">
        <nb-card style="height: 100%; width: 100%;" accent="danger">
            <nb-card-header class="row">
                <div class="col-md-11">
                    Available visit photos: {{visit.photosUrls == null ? 0 : visit.photosUrls.split(",").length - 1}}
                </div>
                <div class="col-md-1" *ngIf="_albums != null && _albums.length > 0">
                    <nb-action title="View" icon="film-outline" (click)="openLightBox()">
                    </nb-action>
                </div>
            </nb-card-header>
            <nb-card-body>
                <div class="form-group col-md-12">
                    <label class="label">Upload more images</label>
                        <div class="file-drop-area" #dropArea>
                            <span class="fake-btn">Choose files</span>
                            <span class="file-msg" #dropMsg>or drag and drop files here</span>
                            <input class="file-input" type="file" (change)="handleDragAreaClick($event)" #dropButton multiple accept="image/*">
                        </div>
                </div>
            </nb-card-body>
        </nb-card>
    </div>

</div>

<ng-template #addActivityDialog let-data let-ref="dialogRef">
    <nb-card style="width: 400px;">
        <nb-card-header>
            Add an activity
        </nb-card-header>
        <nb-card-body>
            <div class="form-group">
                <label class="label">Activity</label>
                <nb-select [(ngModel)]="this.selectedActivityType" fullWidth>
                    <nb-option-group *ngFor="let activityCategory of activityCategories"
                        [title]="activityCategory.title">
                        <nb-option *ngFor="let type of activityCategory.activities" [value]="type"
                            (click)="onActivityTypeSelected()">{{type.title}}</nb-option>
                    </nb-option-group>
                </nb-select>
            </div>
            <div class="form-group">
                <label class="label">Responsible</label>
                <nb-select [(ngModel)]="this.newActivity.activityOwnerId" fullWidth>
                    <nb-option *ngFor="let owner of selectedActivityType.owners" [value]="owner.id">{{owner.fullName}}
                    </nb-option>
                </nb-select>
            </div>
        </nb-card-body>
        <nb-card-footer style="text-align: right;">
            <button [nbSpinner]="savingActivity" nbSpinnerStatus="success" (click)="addActivity(ref)" nbButton
                status="primary">Add activity</button>
        </nb-card-footer>
    </nb-card>
</ng-template>