import { RouterModule, Routes, ExtraOptions } from "@angular/router";
import { NgModule } from "@angular/core";

import { PagesComponent } from "./pages.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { PatientHomeComponent } from "./dashboard/patient/patient-home/patient-home.component";
import { DashboardIndexComponent } from "./dashboard/index/index.component";
import { PatientListComponent } from "./dashboard/patient-list/patient-list.component";
import { AddPatientComponent } from "./dashboard/add-patient/add-patient.component";
import { ScheduleComponent } from "./dashboard/schedule/schedule.component";
import { TreasuryComponent } from "./dashboard/treasury/treasury.component";
import { IndexComponent } from "./dashboard/patient/index/index.component";
import { PaymentsComponent } from "./dashboard/patient/payments/payments.component";
import { OperationPlansComponent } from "./dashboard/patient/operation-plans/operation-plans.component";
import { CallLogComponent } from "./dashboard/patient/call-log/call-log.component";
import { BodyProfileComponent } from "./dashboard/patient/body-profile/body-profile.component";
import { ActivityManagerComponent } from "./dashboard/data-manager/activity-manager/activity-manager.component";
import { OperationPlanTypeManagerComponent } from "./dashboard/data-manager/operation-plan-type-manager/operation-plan-type-manager.component";
import { SpendTypeManagerComponent } from "./dashboard/data-manager/spend-type-manager/spend-type-manager.component";
import { AuditCallsComponent } from "./dashboard/audit-calls/audit-calls.component";
import { FollowupCallsComponent } from "./dashboard/followup-calls/followup-calls.component";
import { CallManagerComponent } from "./dashboard/CallManager/CallManager.component";
import { VisitComponent } from "./dashboard/visit/visit.component";
import { ApplicationAdmin } from "./dashboard/application-admin/application-admin.component";
import { PackageComponent } from "./dashboard/application-admin/package/package.component";
import { UserAvailabilityComponent } from "./dashboard/application-admin/user-availability/user-availability.component";
import { IdentityComponent } from "./dashboard/application-admin/identity/identity.component";
import { SessionTypeComponent } from "./dashboard/application-admin/session-type/session-type.component";
import { PackageStructureComponent } from "./dashboard/application-admin/package-structure/package-structure.component";
import { SessionScheduleComponent } from "./dashboard/application-admin/session-schedule/session-schedule.component";
import { ZoomMeetComponent } from "./dashboard/zoom-meet/zoom-meet.component";
import { MealComponent } from "./dashboard/application-admin/meal/meal.component";
import { ExerciseComponent } from "./dashboard/application-admin/exercise/exercise.component";
import { SubscriptionMealComponent } from "./dashboard/patient/subscription-meal/subscription-meal.component";
import { SubscriptionExerciseComponent } from "./dashboard/patient/subscription-exercise/subscription-exercise.component";
import { SubscriptionComponent } from "./dashboard/application-admin/subscription/subscription.component";
import { ApplicationUserComponent } from "./dashboard/application-admin/application-user/application-user.component";
import { PromoCodeComponent } from "./dashboard/application-admin/promo-code/promo-code.component";
import { ClientInfoComponent } from "./dashboard/patient/client-info/client-info.component";
import { SessionComponent } from "./dashboard/patient/session/session.component";
import { AlternativeMealComponent } from "./dashboard/application-admin/alternative-meal/alternative-meal.component";
import { InterestComponent } from "./dashboard/application-admin/interest/interest.component";
import { PlanComponent } from "./dashboard/application-admin/plan/plan.component";
import { PackagePlanComponent } from "./dashboard/application-admin/plan/package-plan/package-plan.component";
import { MedicationComponent } from "./dashboard/application-admin/medication/medication.component";
import { PlanMealComponent } from "./dashboard/application-admin/plan-meal/plan-meal.component";
import { PlanExerciseComponent } from "./dashboard/application-admin/plan-exercise/plan-exercise.component";
import { ArticleComponent } from "./dashboard/application-admin/article/article.component";
import { ChallengeComponent } from "./dashboard/application-admin/challenge/challenge.component";
import { BurnoutCalorieComponent } from "./dashboard/application-admin/burnout-calorie/burnout-calorie.component";
import { BranchComponent } from "./dashboard/application-admin/branch/branch.component";
import { SessionTypeReviewComponent } from "./dashboard/application-admin/session-type-review/session-type-review.component";
import { WalletComponent } from "./dashboard/patient/wallet/wallet.component";
import { CrmReportingComponent } from "./dashboard/crm-reporting/crm-reporting.component";
import { LessThanOneKiloReportComponent } from "./dashboard/crm-reporting/less-than-one-kilo-report/less-than-one-kilo-report.component";
import { TreatmentTypeComponent } from "./dashboard/application-admin/treatment-type/treatment-type.component";
import { ResetPasswordComponent } from "./dashboard/application-admin/reset-password/reset-password.component";
import { MissedSessionsReportComponent } from "./dashboard/crm-reporting/missed-sessions-report/missed-sessions-report.component";
import { SubscriptionFollowupReportComponent } from "./dashboard/crm-reporting/subscription-followup-report/subscription-followup-report.component";
import { ExaminationFollowupReportComponent } from "./dashboard/crm-reporting/examination-followup-report/examination-followup-report.component";
import { RenewalFollowupReportComponent } from "./dashboard/crm-reporting/renewal-followup-report/renewal-followup-report.component";
import { IntermittentFastingFollowupReportComponent } from "./dashboard/crm-reporting/intermittent-fasting-followup-report/intermittent-fasting-followup-report.component";

const routes: Routes = [
  {
    path: "",
    component: PagesComponent,
    children: [
      {
        path: "dashboard",
        component: DashboardComponent,
        children: [
          {
            path: "patient",
            component: PatientHomeComponent,
            children: [
              {
                path: ":id/subscription-meal",
                component: SubscriptionMealComponent,
              },
              {
                path: ":id/subscription-exercise",
                component: SubscriptionExerciseComponent,
              },
              {
                path: ":id/wallet",
                component: WalletComponent,
              },
              { path: ":id/session", component: SessionComponent },
              { path: ":id/client-info", component: ClientInfoComponent },
              { path: ":id/body-profile", component: BodyProfileComponent },
              { path: ":id/index", component: IndexComponent },
              { path: ":id/call-log", component: CallLogComponent },
              {
                path: ":id/operation-plans",
                component: OperationPlansComponent,
              },
              { path: ":id/payments", component: PaymentsComponent },
              { path: "**", component: IndexComponent },
              { path: "", redirectTo: "index", pathMatch: "full" },
            ],
          },
          { path: "visit", component: VisitComponent },
          { path: "index", component: DashboardIndexComponent },
          { path: "add-patient", component: AddPatientComponent },
          { path: "patient-list", component: PatientListComponent },
          { path: "schedule", component: ScheduleComponent },
          { path: "treasury", component: TreasuryComponent },
          { path: "activity-manager", component: ActivityManagerComponent },
          {
            path: "plan-type-manager",
            component: OperationPlanTypeManagerComponent,
          },
          { path: "spend-type-manager", component: SpendTypeManagerComponent },
          { path: "audit-calls", component: AuditCallsComponent },
          { path: "followup-calls", component: FollowupCallsComponent },
          { path: "CallManager", component: CallManagerComponent },
          { path: "UserAvailability", component: UserAvailabilityComponent },
          { path: "packages", component: PackageComponent },
          { path: "zoom-meet", component: ZoomMeetComponent },

          { path: "CallManager", component: CallManagerComponent },
          {
            path: "crm-reporting",
            component: CrmReportingComponent,
            children: [
              {
                path: "less-than-one-kilo-report",
                component: LessThanOneKiloReportComponent,
              },
              {
                path: "missed-sessions-report",
                component: MissedSessionsReportComponent,
              },
              {
                path: "subscription-followup-report",
                component: SubscriptionFollowupReportComponent,
              },
              {
                path: "examination-followup-report",
                component: ExaminationFollowupReportComponent,
              },
              {
                path: "renewal-followup-report",
                component: RenewalFollowupReportComponent,
              },
              {
                path: "intermittent-fasting-followup-report",
                component: IntermittentFastingFollowupReportComponent,
              },
            ],
          },

          {
            path: "applicationAdmin",
            component: ApplicationAdmin,
            children: [
              { path: "package", component: PackageComponent },
              {
                path: "user-availability",
                component: UserAvailabilityComponent,
              },
              { path: "identity", component: IdentityComponent },
              { path: "reset-password", component: ResetPasswordComponent },

              { path: "session-type", component: SessionTypeComponent },
              {
                path: "package-structure",
                component: PackageStructureComponent,
              },
              { path: "session-schedule", component: SessionScheduleComponent },
              { path: "meal", component: MealComponent },
              { path: "exercise", component: ExerciseComponent },
              { path: "plan-meal", component: PlanMealComponent },
              { path: "plan-exercise", component: PlanExerciseComponent },
              {
                path: "subscription",
                component: SubscriptionComponent,
              },
              {
                path: "application-user",
                component: ApplicationUserComponent,
              },
              {
                path: "promo-code",
                component: PromoCodeComponent,
              },
              {
                path: "alternative-meal",
                component: AlternativeMealComponent,
              },
              {
                path: "interest",
                component: InterestComponent,
              },
              {
                path: "article",
                component: ArticleComponent,
              },
              {
                path: "plan",
                component: PlanComponent,
                children: [
                  {
                    path: "package-plan",
                    component: PackagePlanComponent,
                  },
                ],
              },
              {
                path: "medication",
                component: MedicationComponent,
              },
              {
                path: "treatment-type",
                component: TreatmentTypeComponent,
              },
              {
                path: "challenge",
                component: ChallengeComponent,
              },
              {
                path: "burnout-calorie",
                component: BurnoutCalorieComponent,
              },
              {
                path: "branch",
                component: BranchComponent,
              },
              {
                path: "session-type-review",
                component: SessionTypeReviewComponent,
              },
            ],
          },
        ],
      },

      {
        path: "",
        redirectTo: "dashboard",
        pathMatch: "full",
      },
    ],
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {}
