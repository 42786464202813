export class ImageUtils 
{
    getThumbnail(FileOriginal, scaleRatio) : Promise<File>
    {
        return new Promise( async (resolve,reject) => {
            var dataUrl = await this.fileToDataUrl(FileOriginal);
            var resizedDataUrl = await this.resizeImageDataUrl(dataUrl, 1/3, "image/png");
            const fileResult = this.dataURLtoFile(resizedDataUrl, FileOriginal.name.split(".")[0] + "-thumb.png");
            resolve(fileResult);
         });
        
    }

    resizeImageDataUrl(dataUrl, scaleRatio, format)
    {
        var canvas = document.createElement('canvas');
        if (!dataUrl){
            var ctx = canvas.getContext('2d');
            var img = new Image();
            img.onload = function () {
                canvas.width = img.width * scaleRatio;
                canvas.height = img.height * scaleRatio;
                ctx.drawImage(img, 0, 0, canvas.width, canvas.width);
            };
            img.src = dataUrl;
        }
        
        return canvas.toDataURL(format);
    }

    fileToDataUrl(file)
    {
        const reader = new FileReader();

        reader.onloadend = function() {
            //console.log(reader.result);
            return reader.result;
        };

        reader.readAsDataURL(file);
    }
      
    dataURLtoFile(dataUrl, filename) {
        console.log(dataUrl);
        var arr = dataUrl.split(','), mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, {type:mime});
    }

}