import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";
import { ApplicationUserService } from "../../../../@core/app-services/application-user.service";
import { ToasterHelper } from "../../../../@core/app-services/toasterHelper";
import { WalletService } from "../../../../@core/app-services/wallet.service";
import { ApplicationUser } from "../../../../@core/models/applicationUser";
import { Wallet } from "../../../../@core/models/Wallet";

@Component({
  selector: "ngx-wallet",
  templateUrl: "./wallet.component.html",
  styleUrls: ["./wallet.component.scss"],
})
export class WalletComponent implements OnInit {
  wallets: Wallet[] = [];
  wallet: Wallet = new Wallet();
  applicationUsers: ApplicationUser[] = [];
  applicationUser: ApplicationUser = new ApplicationUser();
  refreshMode: string;
  taskSubject: string;
  taskDetailsHtml: SafeHtml;
  taskStatus: string;
  taskProgress: string;
  currentFilter: any;
  totalMoney: number;
  showFilterRow: boolean = true;
  showHeaderFilter: boolean = true;
  autoNavigateToFocusedRow = true;
  loadingWallets: boolean = false;
  appUserName: string = "";
  isDisabled: boolean = true;
  appUserId: number;
  constructor(
    private sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    private walletService: WalletService,
    private applicationUserService: ApplicationUserService,

    private toasterHelper: ToasterHelper
  ) {
    route.queryParams.subscribe(
      async (params) => {
        this.appUserId = this.route.snapshot.params.id;
        if (this.appUserId != 0) {
          await this.applicationUserService
            .getApplicationUserById(this.appUserId)
            .subscribe(
              async (data) => {
                if (data.status) {
                  this.applicationUser = data.responseObject;
                  await this.getWallets();
                  await this.getTotalMoney();
                } else {
                  this.applicationUser = new ApplicationUser();
                }
              },
              (error) => {
                toasterHelper.showError(error);
              }
            );
        }
      },
      (error) => {
        toasterHelper.showError(error);
      }
    );
  }

  getApplicationUsers() {
    this.applicationUserService.getAllApplicationUsers().subscribe(
      (data) => {
        if (data.status) {
          this.applicationUsers = data.responseObject;
        } else {
          this.applicationUsers = [];
        }
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
      }
    );
  }
  selectAppUser() {
    if (this.appUserName != undefined) {
      this.applicationUser = this.applicationUsers.find(
        (p) => p.fullName.trim() == this.appUserName.trim()
      );
      this.getWallets();
      this.getTotalMoney();
      this.isDisabled = false;
    }
  }

  getWallets() {
    this.walletService.getWallets(this.applicationUser.id).subscribe(
      (data) => {
        if (data.status) {
          this.wallets = data.responseObject;
        } else {
          this.wallets = [];
        }
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
      }
    );
  }
  getTotalMoney() {
    this.walletService.GetTotalMoney(this.applicationUser.id).subscribe(
      (data) => {
        if (data.status) {
          this.totalMoney = data.responseObject;
        } else {
        }
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
      }
    );
  }

  addWallet(e) {
    this.loadingWallets = true;

    let newWallet = new Wallet();
    this.wallet = e.data;
    newWallet.applicationUserId = this.applicationUser.id;
    newWallet.serviceId = this.wallet.serviceId;
    newWallet.price = this.wallet.price;
    this.walletService.addWallet(newWallet).subscribe(
      (data) => {
        if (data.status) {
          this.toasterHelper.showToast(
            "Added successfully",
            data.message,
            "success"
          );
          this.totalMoney += newWallet.price;
        } else {
          this.toasterHelper.showError(data.message);
        }
        this.loadingWallets = false;

        this.getWallets();
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
        this.loadingWallets = false;
      }
    );
  }

  async updateWallet(e) {
    this.loadingWallets = true;
    let updatedWallet = new Wallet();
    this.wallet = e.data;
    updatedWallet.serviceId = this.wallet.serviceId;
    updatedWallet.price = this.wallet.price;
    updatedWallet.applicationUserId = this.applicationUser.id;
    this.walletService.updateWallet(this.wallet.id, updatedWallet).subscribe(
      (data) => {
        if (data.status) {
          this.toasterHelper.showToast(
            "updated successfully",
            data.message,
            "success"
          );
          this.getTotalMoney();
        } else {
          this.toasterHelper.showError(data.message);
        }
        this.loadingWallets = false;

        this.getWallets();
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
        this.loadingWallets = false;
      }
    );
  }

  deleteWallet(e) {
    this.loadingWallets = true;
    this.wallet = e.data;
    this.walletService.deleteWallet(this.wallet.id).subscribe(
      (data) => {
        if (data.status) {
          this.toasterHelper.showToast(
            "Deleted successfully",
            data.message,
            "success"
          );
          this.totalMoney -= this.wallet.price;
        } else {
          this.toasterHelper.showError(data.message);
        }
        this.loadingWallets = false;
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
        this.loadingWallets = false;
      }
    );
  }

  onFocusedRowChanging(e) {
    const rowsCount = e.component.getVisibleRows().length;
    const pageCount = e.component.pageCount();
    const pageIndex = e.component.pageIndex();
    const key = e.event && e.event.key;
    const rowData = e.row && e.row.data;
    if (key && e.prevRowIndex === e.newRowIndex) {
      if (e.newRowIndex === rowsCount - 1 && pageIndex < pageCount - 1) {
        e.component.pageIndex(pageIndex + 1).done(() => {
          e.component.option("focusedRowIndex", 0);
        });
      } else if (e.newRowIndex === 0 && pageIndex > 0) {
        e.component.pageIndex(pageIndex - 1).done(() => {
          e.component.option("focusedRowIndex", rowsCount - 1);
        });
      }
    }
  }
  onFocusedRowChanged(e) {
    const rowData = e.row && e.row.data;
    // this.rowPackage= rowData;
    if (rowData) {
      this.taskSubject = rowData.Task_Subject;
      this.taskDetailsHtml = this.sanitizer.bypassSecurityTrustHtml(
        rowData.Task_Description
      );
      this.taskStatus = rowData.Task_Status;
      this.taskProgress = rowData.Task_Completion
        ? `${rowData.Task_Completion}` + "%"
        : "";
    }
  }
  ngOnInit(): void {
    this.getApplicationUsers();
  }
}
