<nb-card size='medium' class="col-md-6">
    <nb-card-body>
        <div>
            <canvas *ngIf="!this.gettingData" baseChart style="width: 400px; height: 400px;"
              [datasets]="lineChartData"
              [labels]="lineChartLabels"
              [colors]="lineChartColors"
              [legend]="lineChartLegend"
              [chartType]="lineChartType"
              [plugins]="lineChartPlugins">
            </canvas>
          </div>
          
    </nb-card-body>
</nb-card>