<!-- <ngx-body-simulator></ngx-body-simulator> 
<ngx-receptionist></ngx-receptionist>-->
<div class="row" style="width: 800px;margin-left: auto;margin-right:auto;">
    <nb-card accent="success">
        <nb-card-header>
            Pick a time range
        </nb-card-header>
        <nb-card-body>
            <div class="row">
                <div class="form-group col-sm-4">
                    <label class="label">Start Date</label>
                    <input readonly fullWidth nbInput placeholder="From" [(ngModel)]="startDate" [nbDatepicker]="StartDatePicker">
                    <nb-datepicker #StartDatePicker></nb-datepicker>
                </div>
                <div class="form-group col-sm-4">
                    <label class="label">End Date</label>
                    <input readonly fullWidth nbInput placeholder="To" [(ngModel)]="endDate" [nbDatepicker]="EndDatePicker">
                    <nb-datepicker #EndDatePicker></nb-datepicker>
                </div>
                <div class="form-group col-sm-4" style="text-align: center;">
                    <button [nbSpinner]="reloadingDashboard" nbSpinnerStatus="success" (click)="this.reloadDashboard()"
                        nbButton status="primary">Reload Dashboard</button>
                </div>
            </div>
        </nb-card-body>
    </nb-card>
</div>
<div class="row">
    <div class="col-sm-6">
        <nb-card accent="info">
            <nb-card-body>
                <div class="row">
                    <div *ngIf="visitStatusPieData" class="col-sm-3">
                        <dx-pie-chart
                        #pie
                        title="Visit Status"
                        palette="material"
                        [dataSource]="visitStatusPieData"
                        >
                        <dxo-size
                            [height]="300"
                            [width]="300">
                        </dxo-size>
                        <dxo-legend [visible]="false"></dxo-legend>
                        <dxo-export [enabled]="true"></dxo-export>
                        <dxi-series argumentField="name" valueField="value">
                            <dxo-label [visible]="true" [customizeText]="customizeLabel"> </dxo-label>
                        </dxi-series>
                        </dx-pie-chart>
                    </div>
                    <div *ngIf="ActivityTypePieData" class="col-sm-3">
                        <dx-pie-chart
                        #pie
                        title="Activity Type"
                        palette="material"
                        [dataSource]="ActivityTypePieData"
                        >
                        <dxo-size
                        [height]="300"
                        [width]="300">
                        </dxo-size>
                        <dxo-legend [visible]="false"></dxo-legend>
                        <dxo-export [enabled]="true"></dxo-export>
                        <dxi-series argumentField="name" valueField="value">
                            <dxo-label [visible]="true" [customizeText]="customizeLabel"> </dxo-label>
                        </dxi-series>
                        </dx-pie-chart>
                    </div>
                </div>
            </nb-card-body>
        </nb-card>
    </div>
    <div class="col-sm-6">
        <nb-card accent="info">
            <nb-card-body>
                <div class="row">
                    <div *ngIf="callStatusPieData" class="col-sm-3">
                        <dx-pie-chart
                        #pie
                        title="Call Status"
                        palette="bright"
                        [dataSource]="callStatusPieData"
                        [resolveLabelOverlapping]="resolveOverlappingTypes[0]"
                        >
                        <dxo-size
                            [height]="300"
                            [width]="300">
                        </dxo-size>
                        <dxo-margin [bottom]="20" [right]="20" [left]="20" ></dxo-margin>
                        <dxo-legend [visible]="false"></dxo-legend>
                        <dxo-export [enabled]="true"></dxo-export>
                        <dxi-series argumentField="name" valueField="value">
                            <dxo-label [visible]="true" [customizeText]="customizeLabel"> </dxo-label>
                        </dxi-series>
                        </dx-pie-chart>
                    </div>
                    <div *ngIf="callReasonPieData" class="col-sm-3">
                        <dx-pie-chart
                        #pie
                        title="Call Reasons"
                        palette="bright"
                        [dataSource]="callReasonPieData"
                        [resolveLabelOverlapping]="resolveOverlappingTypes[0]"
                        >
                        <dxo-size
                        [height]="300"
                        [width]="300">
                        </dxo-size>
                        <dxo-margin [bottom]="20" [right]="20" [left]="20" ></dxo-margin>
                        <dxo-legend [visible]="false"></dxo-legend>
                        <dxo-export [enabled]="true"></dxo-export>
                        <dxi-series argumentField="name" valueField="value">
                            <dxo-label [visible]="true" [customizeText]="customizeLabel"> </dxo-label>
                        </dxi-series>
                        </dx-pie-chart>
                    </div>
                </div>
            </nb-card-body>
        </nb-card>
    </div>
</div>


<div class="row">
    <nb-card accent="info">
        <nb-card-body>
            <div class="row">
                <div *ngIf="operationPlanPieData" class="col-sm-4">
                    <dx-pie-chart
                    #pie
                    title="Operation Plan"
                    palette="bright"
                    [dataSource]="operationPlanPieData"
                    [resolveLabelOverlapping]="resolveOverlappingTypes[0]"
                    >
                    <dxo-size
                        [height]="300"
                        [width]="300">
                    </dxo-size>
                    <dxo-margin [bottom]="20" [right]="20" [left]="20" ></dxo-margin>
                    <dxo-legend [visible]="false"></dxo-legend>
                    <dxo-export [enabled]="true"></dxo-export>
                    <dxi-series argumentField="name" valueField="value">
                        <dxo-label [visible]="true" [customizeText]="customizeLabel"> </dxo-label>
                    </dxi-series>
                    </dx-pie-chart>
                </div>
                <div *ngIf="spendingTypePieData" class="col-sm-4">
                    <dx-pie-chart
                    #pie
                    title="Spending Type"
                    palette="bright"
                    [dataSource]="spendingTypePieData"
                    [resolveLabelOverlapping]="resolveOverlappingTypes[0]"
                    >
                    <dxo-size
                    [height]="300"
                    [width]="300">
                    </dxo-size>
                    <dxo-margin [bottom]="20" [right]="20" [left]="20" ></dxo-margin>
                    <dxo-legend [visible]="false"></dxo-legend>
                    <dxo-export [enabled]="true"></dxo-export>
                    <dxi-series argumentField="name" valueField="value">
                        <dxo-label [visible]="true" [customizeText]="customizeLabel"> </dxo-label>
                    </dxi-series>
                    </dx-pie-chart>
                </div>
            </div>
        </nb-card-body>
    </nb-card>
</div>
<div class="row">
    <nb-card accent="info">
        <nb-card-body>
            <div class="row">
                <div *ngIf="muscleIndicatorPieData" class="col-sm-4">
                    <dx-pie-chart
                    #pie
                    title="Muscle Mass"
                    palette="bright"
                    [dataSource]="muscleIndicatorPieData"
                    [resolveLabelOverlapping]="resolveOverlappingTypes[0]"
                    >
                    <dxo-size
                        [height]="300"
                        [width]="300">
                    </dxo-size>
                    <dxo-margin [bottom]="20" [right]="20" [left]="20" ></dxo-margin>
                    <dxo-legend [visible]="false"></dxo-legend>
                    <dxo-export [enabled]="true"></dxo-export>
                    <dxi-series argumentField="name" valueField="value">
                        <dxo-label [visible]="true" [customizeText]="customizeLabel"> </dxo-label>
                    </dxi-series>
                    </dx-pie-chart>
                </div>
                <div *ngIf="fatIndicatorPieData" class="col-sm-4">
                    <dx-pie-chart
                    #pie
                    title="Fat Mass"
                    palette="bright"
                    [dataSource]="fatIndicatorPieData"
                    [resolveLabelOverlapping]="resolveOverlappingTypes[0]"
                    >
                    <dxo-size
                    [height]="300"
                    [width]="300">
                    </dxo-size>
                    <dxo-margin [bottom]="20" [right]="20" [left]="20" ></dxo-margin>
                    <dxo-legend [visible]="false"></dxo-legend>
                    <dxo-export [enabled]="true"></dxo-export>
                    <dxi-series argumentField="name" valueField="value">
                        <dxo-label [visible]="true" [customizeText]="customizeLabel"> </dxo-label>
                    </dxi-series>
                    </dx-pie-chart>
                </div>
            </div>
        </nb-card-body>
    </nb-card>
</div>