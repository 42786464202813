import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { ApiResponse } from "../models/ApiResponse";
import { BurnoutCalorie } from "../models/burnoutCalorie";

@Injectable({
  providedIn: "root",
})
export class BurnoutCalorieService {
  private getBurnoutCaloriesPath =
    environment.apiUrl +
    "BurnoutCalorie/GetBurnoutCaloriesByChallengeId?challengeId=";
  private addBurnoutCaloriePath =
    environment.apiUrl + "BurnoutCalorie/AddBurnoutCalorie";
  private updateBurnoutCalorieByIdPath =
    environment.apiUrl +
    "BurnoutCalorie/UpdateBurnoutCalorie?burnoutCalorieId=";
  private deleteBurnoutCalorieByIdPath =
    environment.apiUrl +
    "BurnoutCalorie/DeleteBurnoutCalorie?burnoutCalorieId=";
  constructor(private http: HttpClient) {}

  getBurnoutCalories(challengeId: number): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(
      this.getBurnoutCaloriesPath + challengeId
    );
  }
  addBurnoutCalorie(model: BurnoutCalorie): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.addBurnoutCaloriePath, model);
  }

  deleteBurnoutCalorie(burnoutCalorieId: number): Observable<ApiResponse> {
    return this.http.delete<ApiResponse>(
      this.deleteBurnoutCalorieByIdPath + burnoutCalorieId
    );
  }
  updateBurnoutCalorie(
    burnoutCalorieId: number,
    model: BurnoutCalorie
  ): Observable<ApiResponse> {
    return this.http.put<ApiResponse>(
      this.updateBurnoutCalorieByIdPath + burnoutCalorieId,
      model
    );
  }
}
