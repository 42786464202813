<div class="row">
    <div class="col-md-12">
        <nb-card class="own-scroll">
            <nb-card-header>
                <div class="row">
                    <div class="col-md-10">
                        Set Challenges
                    </div>
                </div>
            </nb-card-header>
        </nb-card>
        <nb-card class="own-scroll">

            <nb-card-body [nbSpinner]="loadingChallenges" nbSpinnerStatus="primary" nbSpinnerSize="large">
                <div id="data-grid-demo">
                    <dx-data-grid #grid id="gridContainer" [dataSource]="challenges" keyExpr="id"
                        [focusedRowEnabled]="true" [showBorders]="true"
                        (onFocusedRowChanging)="onFocusedRowChanging($event)" [columnWidth]="120"
                        (onFocusedRowChanged)="onFocusedRowChanged($event)" (onRowUpdated)="updateChallenge($event)"
                        (onRowInserting)="addChallenge($event)" (onRowRemoving)="deleteChallenge($event)"
                        (onEditingStart)="onEditingStart($event)" (onSaving)="onSaving($event)"
                        (onEditCanceled)="onEditCanceled($event)" (onSaved)="onSaved($event)">

                        <dxo-paging [enabled]="false"></dxo-paging>
                        <dxo-scrolling columnRenderingMode="virtual"></dxo-scrolling>
                        <dxo-editing mode="form" [refreshMode]="refreshMode" [allowUpdating]="true"
                            [allowDeleting]="true" [allowAdding]="true">
                            <dxi-item itemType="group" caption="Photo" [colCount]="2" [colSpan]="2">
                                <dxi-item dataField="backgroundPictureURL" [colSpan]="2"></dxi-item>
                            </dxi-item>
                        </dxo-editing>
                        <dxo-filter-row [visible]="showFilterRow" [applyFilter]="currentFilter"></dxo-filter-row>
                        <dxo-header-filter [visible]="showHeaderFilter"></dxo-header-filter>
                        <dxo-search-panel [visible]="true" [width]="240" placeholder="Search...">

                        </dxo-search-panel>
                        <dxi-column type="buttons">
                            <dxi-button hint="Edit" name="edit" text="edit">
                            </dxi-button>
                            <dxi-button hint="Delete" name="delete" text="delete">
                            </dxi-button>
                        </dxi-column>
                        <dxi-column dataField="backgroundPictureURL" [width]="100" [allowFiltering]="false"
                            [allowSorting]="false" cellTemplate="cellTemplate" editCellTemplate="editCellTemplate">
                        </dxi-column>
                        <dxi-column dataField="nameAr">
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dxi-column>
                        <dxi-column dataField="nameEn">
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dxi-column>
                        <dxi-column dataField="descriptionAr">
                        </dxi-column>
                        <dxi-column dataField="descriptionEn">
                        </dxi-column>
                        <dxi-column dataField="maxWeight" dataType="number">
                        </dxi-column>
                        <dxi-column dataField="minWeight" dataType="number">
                        </dxi-column>
                        <dxi-column dataField="maxHeight" dataType="number">
                        </dxi-column>
                        <dxi-column dataField="minHeight" dataType="number">
                        </dxi-column>
                        <dxi-column dataField="minPartcipants" dataType="number">
                        </dxi-column>
                        <dxi-column dataField="promotionDate" dataType="datetime">
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dxi-column>
                        <dxi-column dataField="startDate" dataType="datetime">
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dxi-column>
                        <dxi-column dataField="endDate" dataType="datetime">
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dxi-column>
                        <div *dxTemplate="let data of 'cellTemplate'">
                            <img [src]="data.value" width="100" height="100" />
                        </div>
                        <div *dxTemplate="let data of 'editCellTemplate'">
                            <img #uploadedImage class="uploadedImage" width="100" height="100" [src]="data.value" />
                            <dx-file-uploader #fileUploader [multiple]="false" uploadMode="instantly"
                                (onValueChanged)="onValueChanged($event)" (onUploaded)="onUploaded($event, data)"
                                (onUploadError)="onUploadError($event)"></dx-file-uploader>
                        </div>
                    </dx-data-grid>
                </div>
            </nb-card-body>
        </nb-card>



    </div>
</div>