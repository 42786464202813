<router-outlet></router-outlet>
<div class="row">
    <div class="col-md-12">
        <nb-card class="own-scroll">
            <nb-card-header>
                <div class="row">
                    <div class="col-md-10">
                        control Plans
                    </div>
                </div>
            </nb-card-header>
        </nb-card>
        <nb-card class="own-scroll">

            <nb-card-body [nbSpinner]="loadingPlans" nbSpinnerStatus="primary" nbSpinnerSize="large">
                <div id="data-grid-demo">
                    <dx-data-grid #grid id="gridContainer" [dataSource]="plans" keyExpr="id" [focusedRowEnabled]="true"
                        [showBorders]="true" (onFocusedRowChanging)="onFocusedRowChanging($event)"
                        (onFocusedRowChanged)="onFocusedRowChanged($event)" (onRowInserting)="addPlan($event)"
                        (onRowRemoving)="deletePlan($event)" (onRowUpdated)="updatePlan($event)">

                        <dxo-paging [enabled]="false"></dxo-paging>
                        <dxo-editing mode="form" [refreshMode]="refreshMode" [allowAdding]="true" [allowUpdating]="true"
                            [allowDeleting]="true">
                        </dxo-editing>
                        <dxo-filter-row [visible]="showFilterRow" [applyFilter]="currentFilter"></dxo-filter-row>
                        <dxo-header-filter [visible]="showHeaderFilter"></dxo-header-filter>
                        <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>
                        <dxi-column dataField="nameAr" caption="Arabic name">
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dxi-column>
                        <dxi-column dataField="nameEn" caption="English name">
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dxi-column>
                        <dxi-column dataField="descriptionAr" caption="Arabic description">
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dxi-column>
                        <dxi-column dataField="descriptionEn" caption="English description">
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dxi-column>
                        <dxi-column dataField="minWeightImprovement" dataType="number"
                            caption="Min Weight Improvement(%)">
                            <dxi-validation-rule type="custom" [validationCallback]="validateMinWeightImprovement"
                                message="Percentage is smaller than 100">
                            </dxi-validation-rule>
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dxi-column>
                    </dx-data-grid>
                </div>
            </nb-card-body>
        </nb-card>



    </div>
</div>