import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { ApiResponse } from "../models/ApiResponse";
import { Interest } from "../models/interest";

@Injectable({
  providedIn: "root",
})
export class InterestService {
  private getInterestsPath = environment.commonUrl + "Interest/GetInterests";
  private addInterestPath = environment.commonUrl + "Interest/AddInterest";
  private updateInterestByIdPath =
    environment.commonUrl + "Interest/UpdateInterest?id=";
  private deleteInterestByIdPath =
    environment.commonUrl + "Interest/DeleteInterestById?id=";
  constructor(private http: HttpClient) {}

  getInterests(): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.getInterestsPath);
  }
  addInterest(model: Interest): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.addInterestPath, model);
  }

  deleteInterest(interestId: number): Observable<ApiResponse> {
    return this.http.delete<ApiResponse>(
      this.deleteInterestByIdPath + interestId
    );
  }
  updateInterest(interestId: number, model: Interest): Observable<ApiResponse> {
    return this.http.put<ApiResponse>(
      this.updateInterestByIdPath + interestId,
      model
    );
  }
}
