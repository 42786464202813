import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { AlternativeMeal } from "../models/alternativeMeal";
import { ApiResponse } from "../models/ApiResponse";

@Injectable({
  providedIn: "root",
})
export class AlternativeMealService {
  private getALternativesMealByMealIdPath =
    environment.apiUrl + "AlternativeMeal/GetAlternativesMealByMealId?id=";
  private addAlternativesMealByMealPath =
    environment.apiUrl + "AlternativeMeal/AddAlternativeMeal";
  private DeleteAlternativeMealById =
    environment.apiUrl + "AlternativeMeal/DeleteAlternativeMealById?id=";
  constructor(private http: HttpClient) {}

  getAlternativesMealByMealId(mealId: number): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(
      this.getALternativesMealByMealIdPath + mealId
    );
  }
  addALternativesMeal(model: AlternativeMeal): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(
      this.addAlternativesMealByMealPath,
      model
    );
  }

  deleteAlternativesMeal(alternativesMealId: number): Observable<ApiResponse> {
    return this.http.delete<ApiResponse>(
      this.DeleteAlternativeMealById + alternativesMealId
    );
  }
}
