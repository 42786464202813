import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { ChallengeService } from "../../../../@core/app-services/challenge.service";
import { ToasterHelper } from "../../../../@core/app-services/toasterHelper";
import { AwsRepoConfig, AwsS3Manage } from "../../../../@core/lib/AwsS3Manage";
import { Challenge } from "../../../../@core/models/challenge";

@Component({
  selector: "ngx-challenge",
  templateUrl: "./challenge.component.html",
  styleUrls: ["./challenge.component.scss"],
})
export class ChallengeComponent implements OnInit {
  challenges: Challenge[] = [];
  rowKey;
  challenge: Challenge;
  selectedFile: File;
  awsS3Manage: AwsS3Manage = new AwsS3Manage();
  refreshMode: string;
  taskSubject: string;
  taskDetailsHtml: SafeHtml;
  taskStatus: string;
  taskProgress: string;
  currentFilter: any;

  showFilterRow: boolean = true;

  showHeaderFilter: boolean = true;
  autoNavigateToFocusedRow = true;
  loadingChallenges: boolean = false;
  constructor(
    private toasterHelper: ToasterHelper,
    private sanitizer: DomSanitizer,
    private challengeService: ChallengeService
  ) {}
  // get Challenge
  getChallenges() {
    this.challengeService.getChallenges().subscribe((data) => {
      if (data.status) {
        this.challenges = data.responseObject;
      } else {
        this.challenges = [];
        this.toasterHelper.showError(data.message);
      }
    });
  }

  updateChallenge(e) {
    this.loadingChallenges = true;
    let updatedChallenge = new Challenge();
    this.challenge = e.data;
    updatedChallenge.nameEn = this.challenge.nameEn;
    updatedChallenge.nameAr = this.challenge.nameAr;
    updatedChallenge.endDate = this.challenge.endDate;
    updatedChallenge.startDate = this.challenge.startDate;
    updatedChallenge.maxHeight = this.challenge.maxHeight;
    updatedChallenge.maxWeight = this.challenge.maxWeight;
    updatedChallenge.minHeight = this.challenge.minHeight;
    updatedChallenge.minWeight = this.challenge.minWeight;
    updatedChallenge.minPartcipants = this.challenge.minPartcipants;
    updatedChallenge.promotionDate = this.challenge.promotionDate;
    if (this.challenge.backgroundPictureURL == null) {
      updatedChallenge.backgroundPictureURL = ".";
    } else {
      updatedChallenge.backgroundPictureURL =
        this.challenge.backgroundPictureURL;
    }
    if (this.challenge.descriptionAr == null) {
      updatedChallenge.descriptionAr = ".";
    } else {
      updatedChallenge.descriptionAr = this.challenge.descriptionAr;
    }
    if (this.challenge.descriptionEn == null) {
      updatedChallenge.descriptionEn = ".";
    } else {
      updatedChallenge.descriptionEn = this.challenge.descriptionEn;
    }
    if (this.selectedFile) {
      this.awsS3Manage
        .uploadFile({
          fileStream: this.selectedFile,
          name:
            updatedChallenge.nameEn +
            "." +
            this.selectedFile.type.split("/").pop(),
          location: AwsRepoConfig.challengesURL,
        })
        .then((result) => {
          updatedChallenge.backgroundPictureURL = result.Location;
          this.selectedFile = null;
          this.challengeService
            .updateChallenge(this.challenge.id, updatedChallenge)
            .subscribe(
              (data) => {
                if (data.status) {
                  this.toasterHelper.showToast(
                    "Updated successfully",
                    data.message,
                    "success"
                  );
                } else {
                  this.toasterHelper.showError(data.message);
                }
                this.loadingChallenges = false;
                this.selectedFile = null;
                this.getChallenges();
              },
              (error) => {
                this.toasterHelper.showError("Something went wrong");
                this.loadingChallenges = false;
              }
            );
        })
        .catch((error) => {
          this.toasterHelper.showError(
            "Couldn't set a photo for " + updatedChallenge.nameEn
          );
          this.selectedFile = null;
          console.log(error.message);
        });
    } else {
      this.challengeService
        .updateChallenge(this.challenge.id, updatedChallenge)
        .subscribe(
          (data) => {
            if (data.status) {
              this.toasterHelper.showToast(
                "Updated successfully",
                data.message,
                "success"
              );
            } else {
              this.toasterHelper.showError(data.message);
            }
            this.loadingChallenges = false;
            this.selectedFile = null;
            this.getChallenges();
          },
          (error) => {
            this.toasterHelper.showError("Something went wrong");
            this.loadingChallenges = false;
          }
        );
    }
  }

  addChallenge(e) {
    this.loadingChallenges = true;
    let newChallenge = new Challenge();
    this.challenge = e.data;
    newChallenge.nameEn = this.challenge.nameEn;
    newChallenge.nameAr = this.challenge.nameAr;
    newChallenge.endDate = this.challenge.endDate;
    newChallenge.startDate = this.challenge.startDate;
    newChallenge.maxHeight = this.challenge.maxHeight;
    newChallenge.maxWeight = this.challenge.maxWeight;
    newChallenge.minHeight = this.challenge.minHeight;
    newChallenge.minWeight = this.challenge.minWeight;
    newChallenge.minPartcipants = this.challenge.minPartcipants;
    newChallenge.promotionDate = this.challenge.promotionDate;
    if (this.challenge.backgroundPictureURL == null) {
      newChallenge.backgroundPictureURL = ".";
    } else {
      newChallenge.backgroundPictureURL = this.challenge.backgroundPictureURL;
    }
    if (this.challenge.descriptionAr == null) {
      newChallenge.descriptionAr = ".";
    } else {
      newChallenge.descriptionAr = this.challenge.descriptionAr;
    }
    if (this.challenge.descriptionEn == null) {
      newChallenge.descriptionEn = ".";
    } else {
      newChallenge.descriptionEn = this.challenge.descriptionEn;
    }
    if (this.selectedFile) {
      this.awsS3Manage
        .uploadFile({
          fileStream: this.selectedFile,
          name:
            newChallenge.nameEn + "." + this.selectedFile.type.split("/").pop(),
          location: AwsRepoConfig.challengesURL,
        })
        .then((result) => {
          newChallenge.backgroundPictureURL = result.Location;
          this.challengeService.addChallenge(newChallenge).subscribe(
            (data) => {
              if (data.status) {
                this.toasterHelper.showToast(
                  "Added successfully",
                  data.message,
                  "success"
                );
              } else {
                this.toasterHelper.showError(data.message);
              }
              this.loadingChallenges = false;
              this.selectedFile = null;
              this.getChallenges();
            },
            (error) => {
              this.toasterHelper.showError("Something went wrong");
              this.loadingChallenges = false;
            }
          );
        })
        .catch((error) => {
          this.toasterHelper.showError(
            "Couldn't set a photo for " + newChallenge.nameEn
          );
          console.log(error.message);
        });
    } else {
      newChallenge.backgroundPictureURL =
        "https://diethub-resources.s3.eu-central-1.amazonaws.com/defaultimage.jpg";
      this.challengeService.addChallenge(newChallenge).subscribe(
        (data) => {
          if (data.status) {
            this.toasterHelper.showToast(
              "Added successfully",
              data.message,
              "success"
            );
          } else {
            this.toasterHelper.showError(data.message);
          }
          this.loadingChallenges = false;
          this.getChallenges();
        },
        (error) => {
          this.toasterHelper.showError("Something went wrong");
          this.loadingChallenges = false;
        }
      );
    }
  }
  deleteChallenge(e) {
    this.loadingChallenges = true;
    this.challenge = e.data;

    if (
      (this.challenge.backgroundPictureURL =
        "https://diethub-resources.s3.eu-central-1.amazonaws.com/defaultimage.jpg")
    ) {
      this.challengeService.deleteChallenge(this.challenge.id).subscribe(
        (data) => {
          if (data.status) {
            this.toasterHelper.showToast(
              "Deleted successfully",
              data.message,
              "success"
            );
          } else {
            this.toasterHelper.showError(data.message);
          }
          this.loadingChallenges = false;
        },
        (error) => {
          this.toasterHelper.showError("Something went wrong");
          this.loadingChallenges = false;
        }
      );
    } else {
      this.awsS3Manage
        .deleteFile({
          fileName:
            this.challenge.nameEn +
            "." +
            this.challenge.backgroundPictureURL.split(".").pop(),
          location: AwsRepoConfig.challengesURL,
        })
        .then((result) => {
          this.challengeService.deleteChallenge(this.challenge.id).subscribe(
            (data) => {
              if (data.status) {
                this.toasterHelper.showToast(
                  "Deleted successfully",
                  data.message,
                  "success"
                );
              } else {
                this.toasterHelper.showError(data.message);
              }
              this.loadingChallenges = false;
            },
            (error) => {
              this.toasterHelper.showError("Something went wrong");
              this.loadingChallenges = false;
            }
          );
        })
        .catch((error) => {
          this.toasterHelper.showError("Couldn't set a photo for ");
          console.log(error.message);
        });
    }
  }
  onFocusedRowChanging(e) {
    const rowsCount = e.component.getVisibleRows().length;
    const pageCount = e.component.pageCount();
    const pageIndex = e.component.pageIndex();
    const key = e.event && e.event.key;
    const rowData = e.row && e.row.data;
    if (key && e.prevRowIndex === e.newRowIndex) {
      if (e.newRowIndex === rowsCount - 1 && pageIndex < pageCount - 1) {
        e.component.pageIndex(pageIndex + 1).done(() => {
          e.component.option("focusedRowIndex", 0);
        });
      } else if (e.newRowIndex === 0 && pageIndex > 0) {
        e.component.pageIndex(pageIndex - 1).done(() => {
          e.component.option("focusedRowIndex", rowsCount - 1);
        });
      }
    }
  }
  onEditingStart(e) {
    this.rowKey = e.key;
  }

  onSaving(e) {
    if (e.changes.length == 0) {
      e.changes.push({
        type: "update",
        key: this.rowKey,
        data: {},
      });
    }
  }
  onFocusedRowChanged(e) {
    const rowData = e.row && e.row.data;
    // this.rowPackage= rowData;
    if (rowData) {
      this.taskSubject = rowData.Task_Subject;
      this.taskDetailsHtml = this.sanitizer.bypassSecurityTrustHtml(
        rowData.Task_Description
      );
      this.taskStatus = rowData.Task_Status;
      this.taskProgress = rowData.Task_Completion
        ? `${rowData.Task_Completion}` + "%"
        : "";
    }
  }
  onClick(e: any): void {}

  onValueChanged(e: any): void {
    this.selectedFile = e.value[0]; // convert to base64 string
  }

  onUploaded(e: any, cellInfo: any): void {}

  onUploadError(e: any): void {
    const xhttp = e.request;
    if (xhttp.status === 400) {
      e.message = e.error.responseText;
    }
    if (xhttp.readyState === 4 && xhttp.status === 0) {
      e.message = "Connection refused";
    }
  }

  onEditCanceled(e: any): void {}

  onSaved(e: any): void {}
  ngOnInit(): void {
    this.getChallenges();
  }
}
