import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { AddSubscriptionExercise } from "../models/addSubscriptionExercise";
import { AddSubscriptionMeal } from "../models/addSubscriptionMeal";
import { ApiResponse } from "../models/ApiResponse";
import { UpdateSubscriptionExercise } from "../models/updateSubsciptionExercise";

@Injectable({
  providedIn: "root",
})
export class SubscriptionExerciseService {
  private getSubscriptionExercisesBySubscriptionIdPath =
    environment.apiUrl +
    "SubscriptionExercises/GetSubscriptionExercisesByAppUserId?id=";
  private addSubscriptionExercisePath =
    environment.apiUrl + "SubscriptionExercises/AddSubscriptionExercise";
  private deleteSubscriptionExercisePath =
    environment.apiUrl +
    "SubscriptionExercises/DeleteSubscriptionExerciseById?id=";
  private updateSubscriptionExercisePath =
    environment.apiUrl + "SubscriptionExercises/UpdateSubscriptionExercise?id=";

  constructor(private http: HttpClient) {}
  // subscription Exercises apis

  getSubscriptionExercisesByApplicationUserId(
    ApplicationUserId: number
  ): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(
      this.getSubscriptionExercisesBySubscriptionIdPath + ApplicationUserId
    );
  }
  addSubscriptionExercise(
    model: AddSubscriptionExercise
  ): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.addSubscriptionExercisePath, model);
  }
  updateSubscriptionExercise(
    ApplicationUserId: number,
    model: UpdateSubscriptionExercise
  ): Observable<ApiResponse> {
    return this.http.put<ApiResponse>(
      this.updateSubscriptionExercisePath + ApplicationUserId,
      model
    );
  }
  deleteSubscriptionExercise(
    SubscriptionMealId: number
  ): Observable<ApiResponse> {
    return this.http.delete<ApiResponse>(
      this.deleteSubscriptionExercisePath + SubscriptionMealId
    );
  }
}
