import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { ApplicationUserService } from "../../../../@core/app-services/application-user.service";
import { ToasterHelper } from "../../../../@core/app-services/toasterHelper";
import { ApplicationUser } from "../../../../@core/models/applicationUser";
import { gender } from "../../../../@core/models/gender";
import { Language } from "../../../../@core/models/language";

@Component({
  selector: "ngx-application-user",
  templateUrl: "./application-user.component.html",
  styleUrls: ["./application-user.component.scss"],
})
export class ApplicationUserComponent implements OnInit {
  applicationUser: ApplicationUser;
  appUsers: ApplicationUser[] = [];
  appUser: ApplicationUser = null;
  refreshMode: string;
  taskSubject: string;
  taskDetailsHtml: SafeHtml;
  taskStatus: string;
  taskProgress: string;
  currentFilter: any;
  showFilterRow: boolean = true;
  showHeaderFilter: boolean = true;
  autoNavigateToFocusedRow = true;
  loadingApplicationUsers: boolean = false;
  isAllowed: boolean = false;
  genders: gender[] = [
    {
      id: 0,
      name: "Male",
    },
    {
      id: 1,
      name: "Female",
    },
  ];
  languageCodes: Language[] = [
    {
      id: 0,
      name: "English",
    },
    {
      id: 1,
      name: "Arabic",
    },
  ];
  constructor(
    private applicationUserService: ApplicationUserService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private toasterHelper: ToasterHelper
  ) {}

  addAppUser(e) {
    this.loadingApplicationUsers = true;
    let newAppUser = new ApplicationUser();
    this.appUser = e.data;
    newAppUser.languageCode = this.appUser.languageCode;
    newAppUser.birthdate = this.appUser.birthdate;
    newAppUser.email = this.appUser.email;
    newAppUser.firstName = this.appUser.firstName;
    newAppUser.lastName = this.appUser.lastName;
    newAppUser.gender = this.appUser.gender;
    newAppUser.password = this.appUser.password;
    newAppUser.phone = this.appUser.phone;

    this.applicationUserService.addApplicationUser(newAppUser).subscribe(
      (data) => {
        if (data.status) {
          this.toasterHelper.showToast(
            "Added successfully",
            data.message,
            "success"
          );
        } else {
          this.toasterHelper.showError(data.message);
        }
        this.loadingApplicationUsers = false;

        this.getAppUsers();
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
        this.loadingApplicationUsers = false;
      }
    );
  }

  updateAppUser(e) {
    this.loadingApplicationUsers = true;
    let updatedAppUser = new ApplicationUser();
    this.appUser = e.data;
    updatedAppUser.languageCode = this.appUser.languageCode;
    updatedAppUser.birthdate = this.appUser.birthdate;
    updatedAppUser.email = this.appUser.email;
    updatedAppUser.firstName = this.appUser.firstName;
    updatedAppUser.lastName = this.appUser.lastName;
    updatedAppUser.gender = this.appUser.gender;
    updatedAppUser.phone = this.appUser.phone;

    this.applicationUserService
      .updateApplicationUser(this.appUser.id, updatedAppUser)
      .subscribe(
        (data) => {
          if (data.status) {
            this.toasterHelper.showToast(
              "Updated successfully",
              data.message,
              "success"
            );
          } else {
            this.toasterHelper.showError(data.message);
          }
          this.loadingApplicationUsers = false;
          this.getAppUsers();
        },
        (error) => {
          this.toasterHelper.showError("Something went wrong");
          this.loadingApplicationUsers = false;
        }
      );
  }

  deleteAppUser(e) {
    this.loadingApplicationUsers = true;
    this.appUser = e.data;
    this.applicationUserService
      .deleteApplicationUser(this.appUser.id)
      .subscribe(
        (data) => {
          if (data.status) {
            this.toasterHelper.showToast(
              "Deleted successfully",
              data.message,
              "success"
            );
          } else {
            this.toasterHelper.showError(data.message);
          }
          this.loadingApplicationUsers = false;
        },
        (error) => {
          this.toasterHelper.showError("Something went wrong");
          this.loadingApplicationUsers = false;
        }
      );
  }

  getAppUsers() {
    this.loadingApplicationUsers = true;
    this.applicationUserService.getAllApplicationUsers().subscribe(
      (data) => {
        if (data.status) {
          this.appUsers = data.responseObject;
        }
        this.loadingApplicationUsers = false;
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
        this.loadingApplicationUsers = false;
      }
    );
  }

  navigateToAppUserProfile() {
    window.open("/#/pages/dashboard/client-info/" + this.appUser.id, "_blank");
  }

  onFocusedRowChanging(e) {
    const rowsCount = e.component.getVisibleRows().length;
    const pageCount = e.component.pageCount();
    const pageIndex = e.component.pageIndex();
    const key = e.event && e.event.key;
    const rowData = e.row && e.row.data;
    if (key && e.prevRowIndex === e.newRowIndex) {
      if (e.newRowIndex === rowsCount - 1 && pageIndex < pageCount - 1) {
        e.component.pageIndex(pageIndex + 1).done(() => {
          e.component.option("focusedRowIndex", 0);
        });
      } else if (e.newRowIndex === 0 && pageIndex > 0) {
        e.component.pageIndex(pageIndex - 1).done(() => {
          e.component.option("focusedRowIndex", rowsCount - 1);
        });
      }
    }
  }
  onFocusedRowChanged(e) {
    const rowData = e.row && e.row.data;
    this.appUser = e.data;
    // this.rowPackage= rowData;
    if (rowData) {
      this.taskSubject = rowData.Task_Subject;
      this.taskDetailsHtml = this.sanitizer.bypassSecurityTrustHtml(
        rowData.Task_Description
      );
      this.taskStatus = rowData.Task_Status;
      this.taskProgress = rowData.Task_Completion
        ? `${rowData.Task_Completion}` + "%"
        : "";
    }
  }
  allowEditing() {
    this.isAllowed = true;
  }
  disAllowEditing() {
    this.isAllowed = false;
  }
  ngOnInit(): void {
    this.getAppUsers();
    this.appUser = this.appUsers[0];
  }
}
