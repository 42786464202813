<div class="row">
    <div class="col-md-12">
        <nb-card class="own-scroll">
            <nb-card-header>
                Audit Calls
            </nb-card-header>
            <nb-card-body [nbSpinner]="gettingCalls" nbSpinnerStatus="primary" nbSpinnerSize="large"
                nbSpinnerMessage="Getting Calls">
                <div class="table-container">
                    <table class="table" *ngIf="calls.length>0">
                        <thead>
                            <tr>
                                <th>
                                    Call date
                                </th>
                                <th>
                                    Patient
                                </th>
                                <th>
                                    Log
                                </th>
                                <th>
                                    Logged by
                                </th>
                                <th>
                                    Closed
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let call of calls">
                                <td>
                                    {{ call.callDate | date: "dd-MM-yyyy h:mm:ss a" }}
                                </td>
                                <td>
                                    {{ call.patientName }}
                                </td>
                                <td>
                                    <p *ngIf="call.log.length>50">
                                        {{call.log.slice(0,50)}}...
                                    </p>
                                    <p *ngIf="call.log.length<50">
                                        {{call.log}}
                                    </p>
                                </td>
                                <td>
                                    {{call.caller}}
                                </td>
                                <td>
                                    {{call.submitted}}
                                </td>
                                <td>
                                    <nb-actions fullWidth size="small">
                                        <nb-action title="Audit" (click)="openAuditCall(dialogAudit, call.id)"
                                            icon="award-outline">
                                        </nb-action>
                                        <nb-action title="Show description"
                                            (click)="openCallDescription(dialogDescription, call.log)"
                                            icon="message-square-outline"></nb-action>
                                    </nb-actions>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </nb-card-body>
        </nb-card>
    </div>
</div>
<ng-template #dialogDescription let-data let-ref="dialogRef">
    <nb-card [size]="'medium'">
        <nb-card-header>
            Call Description
        </nb-card-header>
        <nb-card-body>
            <p [innerHTML]="description"></p>
        </nb-card-body>
    </nb-card>
</ng-template>
<ng-template #dialogAuditComment let-data let-ref="dialogRef">
    <nb-card [size]="'medium'">
        <nb-card-header>
            Audit Comment
        </nb-card-header>
        <nb-card-body>
            <p [innerHTML]="description"></p>
        </nb-card-body>
    </nb-card>
</ng-template>

<ng-template #dialogAudit let-data let-ref="dialogRef">
    <nb-card>
        <nb-card-header>
            Call Audit
        </nb-card-header>
        <nb-card-body>
            <div class="form-group">
                <label class="label">Audit Comment</label>
                <textarea style="height: 200px;" fullWidth placeholder="Comment" nbInput
                    [(ngModel)]="auditCallModel.auditComment"></textarea>
            </div>
        </nb-card-body>
        <nb-card-footer style="text-align: right;">
            <button [nbSpinner]="savingAudit" (click)="auditCall(ref)" nbSpinnerStatus="success" nbButton
                status="primary">Add</button>
        </nb-card-footer>
    </nb-card>
</ng-template>