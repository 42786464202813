import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";

import { SMSBody } from "../models/SMSBody";
import { ApiResponse } from "../models/ApiResponse";

@Injectable({
  providedIn: "root",
})
export class SmsService {
  private sendSMSPath = environment.apiUrl + "SMS/SendSMS";

  constructor(private http: HttpClient) {}
  SendSMS(SMSBody: SMSBody): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.sendSMSPath, SMSBody);
  }
}
