import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { CookieService } from "ngx-cookie";

@Component({
  selector: "ngx-application-admin",
  templateUrl: "./application-admin.component.html",
  styleUrls: ["./application-admin.component.scss"],
})
export class ApplicationAdmin implements OnInit {
  isShowDiv: boolean = false;
  constructor() {}
  toggleDisplayDiv() {
    this.isShowDiv = !this.isShowDiv;
  }

  ngOnInit(): void {}
}
