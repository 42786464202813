export class AddSession {
  from: Date;
  to: Date;
  appUserId: number;
  sessionTypeId: number;
  branchId: number;
  doctorId: string;
  sessionStatus: number;
  description: string;
  comments: string;
}
