import { JournalEntry } from "./journalEntry";

export class TreasuryDay {
  date: Date;
  transactionDebit: number;
  paymentCredit: number;
  transactionCredit: number;
  balance: number;
  journalEntries: JournalEntry[] = new Array();
}
