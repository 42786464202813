export class AddSubscription {
  applicationUserId: number;
  packageId: number;
  expirationDate: Date;
  freezedAt: Date;
  isSingleUser: boolean;
  isActive: boolean;
  userId: string;
  currency: string;
  ammount: number;
  branchId: number;
}
