import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { BurnoutCalorieService } from "../../../../@core/app-services/burnout-calorie.service";
import { ChallengeService } from "../../../../@core/app-services/challenge.service";
import { ToasterHelper } from "../../../../@core/app-services/toasterHelper";
import { BurnoutCalorie } from "../../../../@core/models/burnoutCalorie";
import { Challenge } from "../../../../@core/models/Challenge";

@Component({
  selector: "ngx-burnout-calorie",
  templateUrl: "./burnout-calorie.component.html",
  styleUrls: ["./burnout-calorie.component.scss"],
})
export class BurnoutCalorieComponent implements OnInit {
  burnoutCalories: BurnoutCalorie[] = [];
  burnoutCalorie: BurnoutCalorie;
  challenges: Challenge[] = [];
  challenge: Challenge;
  refreshMode: string;
  taskSubject: string;
  taskDetailsHtml: SafeHtml;
  taskStatus: string;
  taskProgress: string;
  currentFilter: any;
  showFilterRow: boolean = true;
  showHeaderFilter: boolean = true;
  autoNavigateToFocusedRow = true;
  loadingBurnoutCalories: boolean = false;
  isDisabled: boolean = true;
  challengeName: string = "";
  isAllowed: boolean = true;
  constructor(
    private challengeService: ChallengeService,
    private burnoutCalorieService: BurnoutCalorieService,
    private sanitizer: DomSanitizer,
    private toasterHelper: ToasterHelper
  ) {}

  addBurnoutCalorie(e) {
    this.loadingBurnoutCalories = true;
    let newBurnoutCalorie = new BurnoutCalorie();
    this.burnoutCalorie = e.data;
    newBurnoutCalorie.calorie = this.burnoutCalorie.calorie;
    newBurnoutCalorie.ChallengeId = this.challenge.id;
    newBurnoutCalorie.dayNumber = this.burnoutCalorie.dayNumber;
    this.burnoutCalorieService
      .addBurnoutCalorie(newBurnoutCalorie)
      .subscribe((data) => {
        if (data.status) {
          this.toasterHelper.showToast(
            "Added successfully",
            data.message,
            "success"
          );
        } else {
          this.toasterHelper.showError(data.message);
        }
        this.loadingBurnoutCalories = false;
        this.getBurnoutCalories();
      });
  }
  selectChallenge() {
    if (this.challengeName != undefined) {
      this.challenge = this.challenges.find(
        (p) => p.nameEn.trim() == this.challengeName.trim()
      );

      this.getBurnoutCalories();

      this.isDisabled = false;
    }
  }
  updateBurnoutCalorie(e) {
    this.loadingBurnoutCalories = true;
    let updatedBurnoutCalorie = new BurnoutCalorie();
    this.burnoutCalorie = e.data;
    updatedBurnoutCalorie.calorie = this.burnoutCalorie.calorie;
    this.burnoutCalorieService
      .updateBurnoutCalorie(this.burnoutCalorie.id, updatedBurnoutCalorie)
      .subscribe(
        (data) => {
          if (data.status) {
            this.toasterHelper.showToast(
              "Updated successfully",
              data.message,
              "success"
            );
            this.burnoutCalorie = data.responseObject;
          } else {
            this.toasterHelper.showError(data.message);
          }
          this.loadingBurnoutCalories = false;
          this.getBurnoutCalories();
        },
        (error) => {
          this.toasterHelper.showError("Something went wrong");
          this.loadingBurnoutCalories = false;
        }
      );
  }
  deleteBurnoutCalorie(e) {
    this.loadingBurnoutCalories = true;

    this.burnoutCalorie = e.data;
    this.burnoutCalorieService
      .deleteBurnoutCalorie(this.burnoutCalorie.id)
      .subscribe(
        (data) => {
          if (data.status) {
            this.toasterHelper.showToast(
              "Deleted successfully",
              data.message,
              "success"
            );
          } else {
          }
          this.loadingBurnoutCalories = false;
        },
        (error) => {
          this.toasterHelper.showError("Something went wrong");
          this.loadingBurnoutCalories = false;
        }
      );
  }
  getBurnoutCalories() {
    this.loadingBurnoutCalories = true;
    this.burnoutCalorieService.getBurnoutCalories(this.challenge.id).subscribe(
      (data) => {
        if (data.status) {
          this.burnoutCalories = data.responseObject;
        } else {
          this.burnoutCalories = [];
        }
        this.loadingBurnoutCalories = false;
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
        this.loadingBurnoutCalories = false;
      }
    );
  }

  getChallenges() {
    this.loadingBurnoutCalories = true;
    this.challengeService.getChallenges().subscribe(
      (data) => {
        if (data.status) {
          this.challenges = data.responseObject;
        } else {
          this.challenges = [];
        }
        this.loadingBurnoutCalories = false;
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
        this.loadingBurnoutCalories = false;
      }
    );
  }
  onFocusedRowChanging(e) {
    const rowsCount = e.component.getVisibleRows().length;
    const pageCount = e.component.pageCount();
    const pageIndex = e.component.pageIndex();
    const key = e.event && e.event.key;
    const rowData = e.row && e.row.data;
    if (key && e.prevRowIndex === e.newRowIndex) {
      if (e.newRowIndex === rowsCount - 1 && pageIndex < pageCount - 1) {
        e.component.pageIndex(pageIndex + 1).done(() => {
          e.component.option("focusedRowIndex", 0);
        });
      } else if (e.newRowIndex === 0 && pageIndex > 0) {
        e.component.pageIndex(pageIndex - 1).done(() => {
          e.component.option("focusedRowIndex", rowsCount - 1);
        });
      }
    }
  }
  onFocusedRowChanged(e) {
    const rowData = e.row && e.row.data;
    // this.rowPackage= rowData;
    if (rowData) {
      this.taskSubject = rowData.Task_Subject;
      this.taskDetailsHtml = this.sanitizer.bypassSecurityTrustHtml(
        rowData.Task_Description
      );
      this.taskStatus = rowData.Task_Status;
      this.taskProgress = rowData.Task_Completion
        ? `${rowData.Task_Completion}` + "%"
        : "";
    }
  }

  allowEditing() {
    this.isAllowed = true;
  }
  disAllowEditing(e) {
    this.isAllowed = false;
  }
  ngOnInit(): void {
    this.getChallenges();
  }
}
