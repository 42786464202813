import { Component, OnInit, Inject, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DOCUMENT } from '@angular/common';
import { ZoomMtg } from '@zoomus/websdk';
import { ZoomMeetService } from '../../../@core/app-services/zoom-meet.service';
import { ActivatedRoute } from "@angular/router";

ZoomMtg.setZoomJSLib('https://source.zoom.us/2.7.0/lib', '/av');
ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();
// loads language files, also passes any error messages to the ui
ZoomMtg.i18n.load('en-US');
ZoomMtg.i18n.reload('en-US');

@Component({
  selector: 'ngx-zoom-meet',
  templateUrl: './zoom-meet.component.html',
  styleUrls: ['./zoom-meet.component.scss']
})
export class ZoomMeetComponent implements OnInit {

  // setup your signature endpoint here: https://github.com/zoom/meetingsdk-sample-signature-node.js
  // This Sample App has been updated to use SDK App type credentials https://marketplace.zoom.us/docs/guides/build/sdk-app
  @Input() sessionRow: any;
  sdkKey = ''
  sdkSecret = ''
  meetingNumber = ''
  role = 1
  userName = ''
  userEmail = ''
  password = ''
  leaveUrl = 'http://localhost:4200/#/pages/dashboard/index'
  // pass in the registrant's token if your meeting or webinar requires registration. More info here:
  // Meetings: https://marketplace.zoom.us/docs/sdk/native-sdks/web/client-view/meetings#join-registered
  // Webinars: https://marketplace.zoom.us/docs/sdk/native-sdks/web/client-view/webinars#join-registered
  registrantToken = ''
  signatureEndpoint = "";
  sessionID: number = 0;
  meetingTo = '';
  constructor(
    public httpClient: HttpClient,
    private route: ActivatedRoute,
    private zoomMeetService: ZoomMeetService,
    @Inject(DOCUMENT) document,
  ) {
    route.queryParams.subscribe(
      (params) => {
        // console.log(params);
        this.sessionID = params.sessionID;
        if (this.sessionID != 0) {
          // debugger
          this.initMeeting();
        }
      }
    );

  }


  ngOnInit() {

  }

  async initMeeting() {
    // debugger;
    this.zoomMeetService.createMeetingId(this.sessionID).subscribe((data) => {
      this.sdkKey = data.sdkKey;
      this.sdkSecret = data.sdkSecret;
      this.meetingNumber = data.meetingID;
      this.password = data.meetingPassword;
      this.userName = data.userName + " (" + data.userTypeName + ")";
      this.meetingTo = data.meetingTo;
      this.signatureEndpoint = this.generateSignature(this.sdkKey, this.sdkSecret, this.meetingNumber, this.role);

      if (this.signatureEndpoint != '') {
        // @ts-ignore
        const note = document.querySelector('nb-layout-header').style.display = "none"
        this.startMeeting(this.signatureEndpoint);
      }
    });

  }

  // https://www.npmjs.com/package/jsrsasign
  generateSignature(sdkKey, sdkSecret, meetingNumber, role) {
    const KJUR = require('jsrsasign')

    const iat = Math.round((new Date().getTime() - 30000) / 1000)
    const exp = iat + 60 * 60 * 2
    const oHeader = { alg: 'HS256', typ: 'JWT' }

    const oPayload = {
      sdkKey: sdkKey,
      mn: meetingNumber,
      role: role,
      iat: iat,
      exp: exp,
      appKey: sdkKey,
      tokenExp: iat + 60 * 60 * 2
    }

    const sHeader = JSON.stringify(oHeader)
    const sPayload = JSON.stringify(oPayload)
    const sdkJWT = KJUR.jws.JWS.sign('HS256', sHeader, sPayload, sdkSecret)
    // alert(sdkJWT);
    console.log('sdkJWT==' + sdkJWT);
    return sdkJWT
  }


  startMeeting(signature) {

    var zoomElement = document.getElementById('zmmtg-root');
    zoomElement.style.display = "block";



    ZoomMtg.init({
      leaveUrl: this.leaveUrl,
      success: (success) => {
        console.log(success)
        ZoomMtg.join({
          signature: signature,
          meetingNumber: this.meetingNumber,
          userName: this.userName,
          sdkKey: this.sdkKey,
          userEmail: this.userEmail,
          passWord: this.password,
          tk: this.registrantToken,
          success: (success) => {
            console.log(success)
            var zoomInnerElement = document.querySelector('#zmmtg-root .root-inner');
            let div = document.createElement("timerDiv");
            div.style.width = "10pc";
            div.style.left = "40%";
            div.style.backgroundColor = "yellow";
            div.style.display = "block";
            div.style.position = "relative";
            div.style.zIndex = '1000';

            var html = `
                  <div id="countdown">
                  
                  <br/>
                    <ul>      
                      <li><span id="hours"></span> :</li>
                      <li><span id="minutes"></span> :</li>
                      <li><span id="seconds"></span> </li>
                    </ul>
                </div>`;

            div.innerHTML += html;
            zoomInnerElement.appendChild(div)
            zoomInnerElement.insertBefore(div, zoomInnerElement.firstChild);


            const second = 1000,
              minute = second * 60,
              hour = minute * 60,
              day = hour * 24;

            //I'm adding this section so I don't have to keep updating this pen every year :-)
            //remove this if you don't need it
            let today = new Date(),
              dd = String(today.getDate()).padStart(2, "0"),
              mm = String(today.getMonth() + 1).padStart(2, "0"),
              yyyy = today.getFullYear(),
              nextYear = yyyy + 1,
              dayMonth = "09/30/",
              birthday = dayMonth + yyyy;
            //@ts-ignore
            today = mm + "/" + dd + "/" + yyyy;
            //@ts-ignore
            if (today > birthday) {
              birthday = dayMonth + nextYear;
            }
            //end

            const countDown = new Date(this.meetingTo).getTime(),
              x = setInterval(function () {

                const now = new Date().getTime(),
                  distance = countDown - now;


                let hr = document.getElementById("hours");
                hr.parentElement.style.display = "inline-block";
                //@ts-ignore
                hr.innerText = Math.floor((distance % (day)) / (hour));

                let min = document.getElementById("minutes");
                min.parentElement.style.display = "inline-block";
                //@ts-ignore
                min.innerText = Math.floor((distance % (hour)) / (minute));

                let sec = document.getElementById("seconds");
                sec.parentElement.style.display = "inline-block";
                //@ts-ignore
                sec.innerText = Math.floor((distance % (minute)) / second);

                //do something later when date is reached
                if (distance < 0) {
                  alert('End Meeting ....')
                  clearInterval(x);
                  ZoomMtg.endMeeting(
                    {
                      success: (success) => {
                        console.log('Meeeting ENd');
                        window.open('/#/pages/dashboard/index', '_self');
                        location.reload();
                      }
                    }

                  );
                }
                //seconds
              }, 0);




          },
          error: (error) => {
            console.log(error)
          }
        })
      },
      error: (error) => {
        console.log(error)
      }
    })

  }
}
