import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { BodyProfileInstance } from "../../../../@core/models/bodyProfileInstance";
import { ToasterHelper } from "../../../../@core/app-services/toasterHelper";
import { BodyProfileService } from "../../../../@core/app-services/bodyProfile.service";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { ApplicationUserService } from "../../../../@core/app-services/application-user.service";
import { ApplicationUser } from "../../../../@core/models/applicationUser";
import { TreatmentTypeService } from "../../../../@core/app-services/treatment-type.service";
import { TreatmentType } from "../../../../@core/models/TreatmentType";
@Component({
  selector: "ngx-body-profile",
  templateUrl: "./body-profile.component.html",
  styleUrls: ["./body-profile.component.scss"],
})
export class BodyProfileComponent implements OnInit {
  private appUserId: number;
  profile: BodyProfileInstance = new BodyProfileInstance();
  profiles: BodyProfileInstance[] = [];

  refreshMode: string;
  taskSubject: string;
  taskDetailsHtml: SafeHtml;
  taskStatus: string;
  taskProgress: string;
  currentFilter: any;
  showFilterRow: boolean = true;
  showHeaderFilter: boolean = true;
  autoNavigateToFocusedRow = true;
  loadingProfiles: boolean = false;
  appUser: ApplicationUser;
  treatmentTypes: TreatmentType[];
  constructor(
    private route: ActivatedRoute,
    private bodyProfileService: BodyProfileService,
    private treatmentTypeService: TreatmentTypeService,
    private toasterHelper: ToasterHelper,
    private applicationUserService: ApplicationUserService,
    private sanitizer: DomSanitizer
  ) {
    route.queryParams.subscribe(
      async (params) => {
        this.appUserId = this.route.snapshot.params.id;
        if (this.appUserId != 0) {
          await this.applicationUserService
            .getApplicationUserById(this.appUserId)
            .subscribe(
              async (data) => {
                if (data.status) {
                  this.appUser = data.responseObject;
                  await this.getBodyProfiles(this.appUserId);
                } else {
                  this.appUser = new ApplicationUser();
                }
              },
              (error) => {
                toasterHelper.showError(error);
              }
            );
        }
      },
      (error) => {
        toasterHelper.showError(error);
      }
    );
  }

  getBodyProfiles(appUserId: number) {
    this.bodyProfileService.getBodyProfileInstances(this.appUserId).subscribe(
      (data) => {
        if (data.status) this.profiles = data.responseObject;
        else this.profiles = [];
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
      }
    );
  }
  getTreatmentTypes() {
    this.treatmentTypeService.getTreatmentTypes().subscribe(
      (data) => {
        if (data.status) this.treatmentTypes = data.responseObject;
        else this.treatmentTypes = [];
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
      }
    );
  }

  addProfile(e) {
    this.loadingProfiles = true;
    this.profile = e.data;
    this.profile.applicationUserId = this.appUserId;

    this.bodyProfileService.addBodyProfileInstance(this.profile).subscribe(
      (data) => {
        if (data.status) {
          this.toasterHelper.showToast(
            "Added successfully",
            data.message,
            "success"
          );
        } else {
          this.toasterHelper.showError(data.message);
        }
        this.loadingProfiles = false;

        this.getBodyProfiles(this.appUser.id);
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
        this.loadingProfiles = false;
      }
    );
  }

  updateProfile(e) {
    this.loadingProfiles = true;
    this.profile = e.data;
    this.profile.applicationUserId = this.appUserId;
    this.bodyProfileService
      .updateBodyProfileInstance(this.profile.id, this.profile)
      .subscribe(
        (data) => {
          if (data.status) {
            this.toasterHelper.showToast(
              "updated successfully",
              data.message,
              "success"
            );
          } else {
            this.toasterHelper.showError(data.message);
          }
          this.loadingProfiles = false;

          this.getBodyProfiles(this.appUserId);
        },
        (error) => {
          this.toasterHelper.showError("Something went wrong");
          this.loadingProfiles = false;
        }
      );
  }

  deleteProfile(e) {
    this.loadingProfiles = true;
    this.profile = e.data;
    this.bodyProfileService
      .deleteBodyProfileInstance(this.profile.id)
      .subscribe(
        (data) => {
          if (data.status) {
            this.toasterHelper.showToast(
              "Deleted successfully",
              data.message,
              "success"
            );
          } else {
            this.toasterHelper.showError(data.message);
          }
          this.loadingProfiles = false;
        },
        (error) => {
          this.toasterHelper.showError("Something went wrong");
          this.loadingProfiles = false;
        }
      );
  }

  onFocusedRowChanging(e) {
    const rowsCount = e.component.getVisibleRows().length;
    const pageCount = e.component.pageCount();
    const pageIndex = e.component.pageIndex();
    const key = e.event && e.event.key;
    const rowData = e.row && e.row.data;
    if (key && e.prevRowIndex === e.newRowIndex) {
      if (e.newRowIndex === rowsCount - 1 && pageIndex < pageCount - 1) {
        e.component.pageIndex(pageIndex + 1).done(() => {
          e.component.option("focusedRowIndex", 0);
        });
      } else if (e.newRowIndex === 0 && pageIndex > 0) {
        e.component.pageIndex(pageIndex - 1).done(() => {
          e.component.option("focusedRowIndex", rowsCount - 1);
        });
      }
    }
  }
  onFocusedRowChanged(e) {
    const rowData = e.row && e.row.data;
    // this.rowPackage= rowData;
    if (rowData) {
      this.taskSubject = rowData.Task_Subject;
      this.taskDetailsHtml = this.sanitizer.bypassSecurityTrustHtml(
        rowData.Task_Description
      );
      this.taskStatus = rowData.Task_Status;
      this.taskProgress = rowData.Task_Completion
        ? `${rowData.Task_Completion}` + "%"
        : "";
    }
  }

  ngOnInit(): void {
    this.getTreatmentTypes();
  }
}
