import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { ApiResponse } from "../models/ApiResponse";
import { Challenge } from "../models/Challenge";

@Injectable({
  providedIn: "root",
})
export class ChallengeService {
  private getChallengesPath = environment.apiUrl + "Challenge/GetAllChallenges";
  private addChallengePath = environment.apiUrl + "Challenge/AddChallenge";
  private updateChallengeByIdPath =
    environment.apiUrl + "Challenge/UpdateChallenge?id=";
  private deleteChallengeByIdPath =
    environment.apiUrl + "Challenge/DeleteChallenge?id=";
  constructor(private http: HttpClient) {}

  getChallenges(): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.getChallengesPath);
  }
  addChallenge(model: Challenge): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.addChallengePath, model);
  }

  deleteChallenge(ChallengeId: number): Observable<ApiResponse> {
    return this.http.delete<ApiResponse>(
      this.deleteChallengeByIdPath + ChallengeId
    );
  }
  updateChallenge(
    ChallengeId: number,
    model: Challenge
  ): Observable<ApiResponse> {
    return this.http.put<ApiResponse>(
      this.updateChallengeByIdPath + ChallengeId,
      model
    );
  }
}
