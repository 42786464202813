import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { ApiResponse } from "../models/ApiResponse";
import { Disease } from "../models/disease";

@Injectable({
  providedIn: "root",
})
export class DiseaseService {
  private getDiseasesPath = environment.commonUrl + "Disease/GetDiseases";
  private addDiseasePath = environment.commonUrl + "Disease/AddDisease";
  private deleteDiseasePath =
    environment.commonUrl + "Disease/DeleteDiseaseById?id=";
  private updateDiseasePath =
    environment.commonUrl + "Disease/UpdateDisease?id=";

  constructor(private http: HttpClient) {}
  // Disease apis

  getDiseases(): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.getDiseasesPath);
  }
  addDisease(model: Disease): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.addDiseasePath, model);
  }
  updateDisease(diseaseId: number, model: Disease): Observable<ApiResponse> {
    return this.http.put<ApiResponse>(
      this.updateDiseasePath + diseaseId,
      model
    );
  }
  deleteDisease(diseaseId: number): Observable<ApiResponse> {
    return this.http.delete<ApiResponse>(this.deleteDiseasePath + diseaseId);
  }
}
