<div class="row">
    <div class="col-md-4">
        <nb-card size="large">
            <nb-card-header class="row">
                <div class="col-md-10">
                    Operation Plans
                </div>
                <div class="col-md-2">
                    <nb-action title="Add Plan" icon="plus-outline" (click)="openDialog(addOperationPlanDialog)">
                    </nb-action>
                </div>
            </nb-card-header>
            <nb-card-body [nbSpinner]="gettingOperationPlans" nbSpinnerStatus="primary" nbSpinnerSize="large"
                nbSpinnerMessage="Getting patient's operation plans">
                <div *ngIf="operationPlans.length == 0 && !gettingOperationPlans">
                    <ngx-empty-state image="EmptyState.png" text="This patient doesn't has any operation plan"></ngx-empty-state>
                    <div style="text-align: center;margin-top: 10px;">
                        <button (click)="openDialog(addOperationPlanDialog)" nbButton status="success">Add a new
                            plan</button>
                    </div>
                </div>
                <nb-list *ngIf="operationPlans.length > 0 && !gettingOperationPlans">
                    <nb-list-item *ngFor="let plan of operationPlans" (click)="selectPlan(plan)">
                        <span style="width: 90%;"> {{plan.planTitle}}</span>
                        <nb-actions size="small">
                            <nb-action [badgeText]="plan.status"
                                [badgeStatus]="plan.status == 'Completed' ? 'success' : plan.status == 'In progress' ? 'info' : 'warning'">
                            </nb-action>
                            <nb-action [badgeText]="plan.milestones.length" badgeStatus="info">
                            </nb-action>
                            <nb-action icon="arrow-ios-forward-outline">
                            </nb-action>
                        </nb-actions>
                    </nb-list-item>
                </nb-list>
            </nb-card-body>
        </nb-card>
    </div>

    <div class="col-md-8" *ngIf="!selectedOperationPlan">
        <nb-card size="large">
            <ngx-empty-state image="coffee.png" text="Select operation plan to show it's data"></ngx-empty-state>
        </nb-card>
    </div>
    <div class="col-md-8" *ngIf="selectedOperationPlan">
        <nb-card>
            <nb-card-header class="row">
                <div class="col-md-10">
                    {{selectedOperationPlan.planTitle}}
                </div>
                <div class="col-md-2">
                    <nb-actions size="small">
                        <!-- <nb-action (click)="editPlan(); openDialog(addOperationPlanDialog)" title="Edit Plan"
                            icon="settings-2-outline">
                        </nb-action> -->
                        <nb-action title="Delete Plan" (click)="deletePlan(selectedOperationPlan)"
                            icon="trash-2-outline" [nbSpinner]="this.deletingPlan">
                        </nb-action>
                    </nb-actions>
                </div>
            </nb-card-header>
        </nb-card>
        <div class="summary-container" [nbSpinner]="gettingStatistics" nbSpinnerStatus="primary" nbSpinnerSize="large"
        nbSpinnerMessage="Getting opertion plan statistics">
            <div *ngIf="!gettingStatistics">
                <div>Milestones</div>
                <div class="h6">{{operationPlanStatistics.milestones}}</div>
            </div>
            <div *ngIf="!gettingStatistics">
                <div>Visits</div>
                <div class="h6">{{operationPlanStatistics.visits}}</div>
            </div>
            <div *ngIf="!gettingStatistics">
                <div>Activities</div>
                <div class="h6">{{operationPlanStatistics.activities}}</div>
            </div>
            <div *ngIf="!gettingStatistics">
                <div>Total Cost</div>
                <div class="h6">{{(operationPlanStatistics.totalCost / 1000) + 'k'}}</div>
            </div>
        </div>
        <br />
        <div class="row">
            <div class="col-md-4">
                <nb-card size='medium'>
                    <nb-card-header class="row">
                        <div class="col-md-10">
                            Milestones
                        </div>
                        <div class="col-md-2">
                            <nb-action (click)="addMilestone()" title="Add Milestone" icon="plus-outline">
                            </nb-action>
                        </div>
                    </nb-card-header>
                    <nb-card-body [nbSpinner]="addingMilestone" nbSpinnerStatus="primary" nbSpinnerSize="large"
                        nbSpinnerMessage="Adding milestone">
                        <div *ngIf="selectedOperationPlan.milestones.length == 0">
                            <ngx-empty-state image="EmptyState.png" text="This plan doesn't has milestones"></ngx-empty-state>
                            <div style="text-align: center;margin-top: 10px;">
                                <button (click)="addMilestone()" nbButton status="success">Add a milestone</button>
                            </div>
                        </div>
                        <nb-list>
                            <nb-list-item *ngFor="let milestone of selectedOperationPlan.milestones">
                                <span style="width: 90%;"> {{milestone.title}}</span>
                                <nb-actions size="small">
                                    <nb-action [badgeText]="milestone.visits.length" badgeStatus="success">
                                    </nb-action>
                                    <nb-action title="Delete Milestone" (click)="deleteMilestone(milestone)"
                                        icon="trash-2-outline">
                                    </nb-action>
                                    <nb-action icon="arrow-ios-forward-outline" (click)="selectMilestone(milestone)">
                                    </nb-action>
                                </nb-actions>
                            </nb-list-item>
                        </nb-list>
                    </nb-card-body>
                </nb-card>
            </div>
            <div class="col-md-8" *ngIf="!selectedMilestone">
                <nb-card size="medium">
                    <ngx-empty-state image="coffee.png" text="Select Milestone to show it's visits"></ngx-empty-state>
                </nb-card>
            </div>
            <div class="col-md-8" *ngIf="selectedMilestone">
                <nb-card size='medium'>
                    <nb-card-header class="row">
                        <div class="col-md-11">
                            {{selectedMilestone.title}}'s Visits
                        </div>
                        <div class="col-md-1">
                            <nb-action title="Add Visit" icon="plus-outline" (click)="this.openDialog(dialogAddVisit)">
                            </nb-action>
                        </div>
                    </nb-card-header>
                    <nb-card-body [nbSpinner]="gettingVisits" nbSpinnerStatus="primary" nbSpinnerSize="large"
                    nbSpinnerMessage="Getting milestone visits">
                        <div *ngIf="selectedMilestone.visits.length == 0 && !gettingVisits">
                            <ngx-empty-state image="EmptyState.png" text="This milestone doesn't has visits">
                            </ngx-empty-state>
                            <div style="text-align: center;margin-top: 10px;">
                                <button nbButton status="success" (click)="this.openDialog(dialogAddVisit)">Add
                                    visit</button>
                            </div>
                        </div>
                        <div *ngIf="selectedMilestone.visits.length > 0">
                            <nb-card *ngFor="let visit of selectedMilestone.visits"
                                (click)="openVisit(visit, dialogCheckVisit)"
                                [status]="visit.status == 'NA' ? 'info': visit.status == 'Missed' ? 'danger' : visit.status == 'Not Confirmed' ? 'warning' : 'success'" class="visit">
                                <nb-card-header class="row">
                                    <div class="col-md-1">
                                        {{ visit.date | date: "EEE" }}
                                    </div>
                                    <div class="col-md-3">
                                        {{ visit.date | date: "d-MMM" }}
                                    </div>
                                    <div class="col-md-5" *ngIf="visit.status!= 'Not Confirmed'">
                                        {{ visit.startTime | date: "hh:mm a" }} - {{ visit.endTime | date: "hh:mm a" }}
                                    </div>
                                    <div class="col-md-3" *ngIf="visit.status!= 'Not Confirmed'">
                                        {{ visit.status }}
                                    </div>
                                    <div class="col-md-8" *ngIf="visit.status == 'Not Confirmed'">
                                        Need confirmation
                                    </div>
                                </nb-card-header>
                            </nb-card>
                            <!-- <nb-list-item *ngFor="let visit of selectedMilestone.visits"
                                (click)="openVisit(visit, dialogVisit)">
                                <span style="width: 90%;"> {{ visit.date | date: "EEEE, MMM d, yyyy" }} {{
                                    visit.startTime | date: "hh:mm a" }} </span>
                                <nb-actions size="small">
                                    <nb-action [badgeText]="visit.status"
                                        [badgeStatus]="visit.status == 'NA' ? 'info': visit.status == 'Missed' ? 'danger' : 'success'">
                                    </nb-action>
                                    <nb-action icon="arrow-ios-forward-outline">
                                    </nb-action>
                                </nb-actions>
                            </nb-list-item> -->
                        </div>
                    </nb-card-body>
                </nb-card>
            </div>
        </div>
    </div>
</div>


<ng-template #dialogCheckVisit let-data let-ref="dialogRef">
    <ngx-checkvisit [relatedVisit]="selectedVisit" style="height: auto;">
    </ngx-checkvisit>
</ng-template> 

<!--<ng-template #dialogVisit let-data let-ref="dialogRef">
    <nb-card style="width: 800px;">
        <nb-card-header class="row">
            <div class="col-md-10">
                Visit at <strong>{{ selectedVisit.date | date: "EEEE, MMM d, yyyy"}} {{ selectedVisit.startTime | date:
                    "hh:mm a"}}</strong>
            </div>
            <div class="col-md-2">
                <nb-actions size="small" *ngIf="selectedVisit.status=='NA'">
                    <nb-action title="Edit Visit" icon="settings-2-outline">
                    </nb-action>
                    <nb-action title="Delete Visit" icon="trash-2-outline">
                    </nb-action>
                </nb-actions>
            </div>
        </nb-card-header>
        <nb-card-body>
            <ngx-visit [visit]="selectedVisit" (updated)="reloadVisits()"></ngx-visit>
        </nb-card-body>
    </nb-card>
</ng-template>-->

<ng-template #addOperationPlanDialog let-data let-ref="dialogRef">
    <nb-card>
        <nb-card-header>
            Operation plan
        </nb-card-header>
        <nb-card-body>
            <div class="form-group">
                <label class="label">Operation Plan</label>
                <nb-select [(ngModel)]="this.newPlan.planType" fullWidth>
                    <nb-option *ngFor="let plan of operationPlanTypes" [value]="plan.id">{{plan.planTitle}}</nb-option>
                </nb-select>
            </div>
            <div class="form-group">
                <label class="label">Doctor</label>
                <nb-select [(ngModel)]="this.newPlan.doctorId" fullWidth>
                    <nb-option *ngFor="let doctor of doctors" [value]="doctor.id">{{doctor.fullName}}</nb-option>
                </nb-select>
            </div>
            <div class="form-group">
                <!--<label class="label">Start DateTime</label>
                <input nbInput  placeholder="Pick Date" [nbDatepicker]="dateTimePicker">
                <nb-date-timepicker 
                #dateTimePicker
                singleColumn
                [step]="10"></nb-date-timepicker>-->
                <label class="label">Start Date</label>
                <input readonly fullWidth nbInput placeholder="Pick date" [(ngModel)]="this.newPlan.startDate" [nbDatepicker]="DatePicker">
                <nb-datepicker #DatePicker></nb-datepicker>
            </div>
            <div class="form-group">
                <label class="label">Start Time</label>
                <!--<ngb-timepicker [(ngModel)]="time" [seconds]="false"
                    [hourStep]="hourStep" [minuteStep]="minuteStep" [secondStep]="secondStep"></ngb-timepicker>-->
                <input nbInput [(ngModel)]="operationStartTime" type="number" min="0" max="24" step="0.25" 
                                placeholder="Pick time from 0 to 24 (0 == 12AM)"
                                [status]="operationStartTime < 0 || operationStartTime > 24 ? 'danger' : 'basic'" fullWidth/>
            </div>
        </nb-card-body>
        <nb-card-footer>
            <button (click)="addPlan(ref)" nbButton status="success" [nbSpinner]="this.savingOperationPlan">Save plan</button>
        </nb-card-footer>
    </nb-card>
</ng-template>

<ng-template #dialogAddVisit let-data let-ref="dialogRef">
    <nb-card size="large">
        <nb-card-header>
            Add visit
        </nb-card-header>
        <nb-card-body>
            <div class="form-group">
                <label class="label">Date</label>
                <input readonly fullWidth nbInput placeholder="Select Date" [nbDatepicker]="endDatePicker"
                    [(ngModel)]="selectedDay">
                <nb-datepicker #endDatePicker></nb-datepicker>
            </div>
            <div class="form-group" style="text-align: center;">
                <button [nbSpinner]="gettingAvailableSlots" nbSpinnerStatus="success" (click)="this.getAvailableSlots()"
                    nbButton status="primary">Get Available Slots</button>
            </div>
            <h6>Available slots</h6>
            <div>
                <nb-card *ngFor="let slot of slots" [nbSpinner]="slot.bookingSlot" nbSpinnerStatus="success"
                    nbSpinnerSize="small" [status]="slot.isAvailable ? 'info': 'danger'" (click)="this.bookSlot(slot)"
                    class="visit">
                    <nb-card-header class="row">
                        <div class="col-md-8">
                            {{ slot.startFrom | date: "hh:mm a" }} - {{ slot.endAt | date: "hh:mm a" }}
                        </div>
                        <div class="col-md-4">
                            {{slot.isAvailable ? 'Available' : 'Reserved'}}
                        </div>
                    </nb-card-header>
                </nb-card>
            </div>
        </nb-card-body>
    </nb-card>
</ng-template>