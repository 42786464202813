import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { ApiResponse } from "../models/ApiResponse";
import { Medication } from "../models/medication";

@Injectable({
  providedIn: "root",
})
export class MedicationService {
  private getMedicationsPath =
    environment.commonUrl + "Medication/GetMedications";
  private addMedicationPath =
    environment.commonUrl + "Medication/AddMedication";
  private deleteMedicationPath =
    environment.commonUrl + "Medication/DeleteMedicationById?id=";
  private updateMedicationPath =
    environment.commonUrl + "Medication/UpdateMedication?id=";

  constructor(private http: HttpClient) {}
  // Medication apis

  getMedications(): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.getMedicationsPath);
  }
  addMedication(model: Medication): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.addMedicationPath, model);
  }
  updateMedication(
    medicationId: number,
    model: Medication
  ): Observable<ApiResponse> {
    return this.http.put<ApiResponse>(
      this.updateMedicationPath + medicationId,
      model
    );
  }
  deleteMedication(medicationId: number): Observable<ApiResponse> {
    return this.http.delete<ApiResponse>(
      this.deleteMedicationPath + medicationId
    );
  }
}
