import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { ApiResponse } from "../models/ApiResponse";
import { Ingredient } from "../models/ingredient";

@Injectable({
  providedIn: "root",
})
export class IngredientService {
  private getIngredientsPath =
    environment.apiUrl + "Ingredient/GetIngredientsByMealId?id=";
  private addIngredientPath =
    environment.apiUrl + "Ingredient/AddIngredientForMeal";
  private deleteIngredientPath =
    environment.apiUrl + "Ingredient/DeleteIngredientById?id=";
  private updateIngredientPath =
    environment.apiUrl + "Ingredient/UpdateIngredient?id=";

  constructor(private http: HttpClient) {}
  // meal apis

  getIngredientsByMealId(id: number): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.getIngredientsPath + id);
  }
  addIngredient(model: Ingredient): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.addIngredientPath, model);
  }
  updateIngredient(mealId: number, model: Ingredient): Observable<ApiResponse> {
    return this.http.put<ApiResponse>(
      this.updateIngredientPath + mealId,
      model
    );
  }
  deleteIngredient(ingredientId: number): Observable<ApiResponse> {
    return this.http.delete<ApiResponse>(
      this.deleteIngredientPath + ingredientId
    );
  }
}
