import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { PackageService } from "../../../../@core/app-services/package.service";
import { PromoCodeService } from "../../../../@core/app-services/promo-code.service";
import { ToasterHelper } from "../../../../@core/app-services/toasterHelper";
import { AddPromoCode } from "../../../../@core/models/addPromoCode";
import { DiscountType } from "../../../../@core/models/discountType";
import { GetPromoCode } from "../../../../@core/models/getPromoCode";
import { Package } from "../../../../@core/models/package";
import { UpdatePromoCode } from "../../../../@core/models/updatePromoCode";

@Component({
  selector: "ngx-promo-code",
  templateUrl: "./promo-code.component.html",
  styleUrls: ["./promo-code.component.scss"],
})
export class PromoCodeComponent implements OnInit {
  promoCodes: GetPromoCode[] = [];
  promoCode: GetPromoCode;
  packages: Package[];
  refreshMode: string;
  taskSubject: string;
  taskDetailsHtml: SafeHtml;
  taskStatus: string;
  taskProgress: string;
  currentFilter: any;
  showFilterRow: boolean = true;
  showHeaderFilter: boolean = true;
  autoNavigateToFocusedRow = true;
  isAllowed: boolean = false;
  loadingPromoCode: boolean = false;
  discountTypes: DiscountType[] = [
    {
      id: 1,
      name: "fixed amount",
    },
    {
      id: 2,
      name: "percentage",
    },
  ];
  constructor(
    private promoCodeService: PromoCodeService,
    private packageService: PackageService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private toasterHelper: ToasterHelper
  ) {}
  getPromoCodes() {
    this.loadingPromoCode = true;
    this.promoCodeService.getAllPromoCodes().subscribe(
      (data) => {
        if (data.status) {
          this.promoCodes = data.responseObject;
          this.toasterHelper.showSaved("promoCodes is loaded");
        } else {
          this.promoCodes = [];
          this.toasterHelper.showError(data.message);
        }
        this.loadingPromoCode = false;
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
        this.loadingPromoCode = false;
      }
    );
  }

  addPromoCode(e) {
    this.loadingPromoCode = true;
    let newPromoCode = new AddPromoCode();
    this.promoCode = e.data;
    newPromoCode.code = this.promoCode.code;
    newPromoCode.packageId = this.promoCode.packageId;
    newPromoCode.expirationDate = this.promoCode.expirationDate;
    newPromoCode.discountType = this.promoCode.discountType;
    newPromoCode.discountValue = this.promoCode.discountValue;
    newPromoCode.owner = this.promoCode.owner;
    newPromoCode.useLimit = this.promoCode.useLimit;
    newPromoCode.isActive = this.promoCode.isActive;
    if (newPromoCode.isActive == null || newPromoCode.isActive == undefined) {
      newPromoCode.isActive = true;
    }
    this.promoCodeService.addPromoCode(newPromoCode).subscribe(
      (data) => {
        if (data.status) {
          this.toasterHelper.showToast(
            "Added successfully",
            data.message,
            "success"
          );
        } else {
          this.toasterHelper.showError(data.message);
        }
        this.loadingPromoCode = false;
        this.getPromoCodes();
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
        this.loadingPromoCode = false;
      }
    );
  }

  updatePromoCode(e) {
    this.loadingPromoCode = true;
    let updatedPromoCode = new UpdatePromoCode();
    this.promoCode = e.data;
    updatedPromoCode.expirationDate = this.promoCode.expirationDate;
    updatedPromoCode.useLimit = this.promoCode.useLimit;
    updatedPromoCode.isActive = this.promoCode.isActive;
    updatedPromoCode.promoCodeId = this.promoCode.id;

    this.promoCodeService.updatePromoCode(updatedPromoCode).subscribe(
      (data) => {
        if (data.status) {
          this.toasterHelper.showToast(
            "Updated successfully",
            data.message,
            "success"
          );
        } else {
          this.toasterHelper.showError(data.message);
        }
        this.loadingPromoCode = false;
        this.getPromoCodes();
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
        this.loadingPromoCode = false;
      }
    );
  }

  deletePromoCode(e) {
    this.loadingPromoCode = true;
    this.promoCode = e.data;
    this.promoCodeService.deletePromoCode(this.promoCode.id).subscribe(
      (data) => {
        if (data.status) {
          this.toasterHelper.showToast(
            "Deleted successfully",
            data.message,
            "success"
          );
        } else {
          this.toasterHelper.showError(data.message);
        }
        this.loadingPromoCode = false;
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
        this.loadingPromoCode = false;
      }
    );
  }

  getPackages() {
    this.loadingPromoCode = true;

    this.packageService.getPackages().subscribe(
      (data) => {
        if (data.status) {
          this.packages = data.responseObject;
        }
        this.loadingPromoCode = false;
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
        this.loadingPromoCode = false;
      }
    );
  }
  onFocusedRowChanging(e) {
    const rowsCount = e.component.getVisibleRows().length;
    const pageCount = e.component.pageCount();
    const pageIndex = e.component.pageIndex();
    const key = e.event && e.event.key;
    const rowData = e.row && e.row.data;
    if (key && e.prevRowIndex === e.newRowIndex) {
      if (e.newRowIndex === rowsCount - 1 && pageIndex < pageCount - 1) {
        e.component.pageIndex(pageIndex + 1).done(() => {
          e.component.option("focusedRowIndex", 0);
        });
      } else if (e.newRowIndex === 0 && pageIndex > 0) {
        e.component.pageIndex(pageIndex - 1).done(() => {
          e.component.option("focusedRowIndex", rowsCount - 1);
        });
      }
    }
  }
  onFocusedRowChanged(e) {
    const rowData = e.row && e.row.data;
    // this.rowPackage= rowData;
    if (rowData) {
      this.taskSubject = rowData.Task_Subject;
      this.taskDetailsHtml = this.sanitizer.bypassSecurityTrustHtml(
        rowData.Task_Description
      );
      this.taskStatus = rowData.Task_Status;
      this.taskProgress = rowData.Task_Completion
        ? `${rowData.Task_Completion}` + "%"
        : "";
    }
  }
  allowEditing() {
    this.isAllowed = true;
  }
  disAllowEditing() {
    this.isAllowed = false;
  }
  ngOnInit(): void {
    this.getPackages();
    this.getPromoCodes();
  }
}
