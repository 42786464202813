export class TimeSlot {
  id: number;
  sessionTypeId: number;
  sessionTypeName: string;
  branchName: string;
  userId: string;
  userFullName: string;
  from: Date;
  to: Date;
  branchId: number;
}
