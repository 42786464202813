<div class="header-container">
    <div class="logo-container">
        <a href="#" class="sidebar-toggle" matRipple [matRippleUnbounded]="true" [matRippleCentered]="true" (click)="toggleSidebar()">
            <nb-icon [icon]="(materialTheme$ | async) ? 'menu-outline' : 'menu-2-outline'"></nb-icon>
        </a>
        <!-- <a class="logo" href="#" (click)="navigateHome()">
            <img style="width: 130%;" src="../../../../assets/images/dietacademy.png" alt="">
        </a> -->
        <a class="logo" href="#" (click)="navigateHome()"><span><strong>diet</strong> academy</span></a>
    </div>
</div>
<div class="header-container">
    <div style="margin-left: 20px; margin-right: 20px;">
        <span (click)="changeTheme('dark')" style="background-color: #222b45;" class="dot"></span>
        <span (click)="changeTheme('corporate')" style="background-color: white;" class="dot"></span>
        <span (click)="changeTheme('material-dark')" style="background-color: #981340;" class="dot"></span>
    </div>
        <!-- <nb-action (click)="openThemeDialog(dialogTheme)" class="control-item" icon="options-outline" matRipple [matRippleUnbounded]="true" [matRippleCentered]="true"></nb-action> -->

    <nb-form-field>
        <nb-icon nbPrefix icon="search-outline" pack="eva"></nb-icon>
        <input type="text" spellcheck="false" #searchClient (keyup.enter)="searchForClient(searchClient.value)" placeholder="Search for patient" fullWidth nbInput>
    </nb-form-field>
    <nb-actions size="small">
        <nb-action class="user-action" *nbIsGranted="['view', 'user']" matRipple [matRippleUnbounded]="true" [matRippleCentered]="true">
            <nb-user [name]="loggedUserName" [nbContextMenu]="userMenu">
            </nb-user>
        </nb-action>
    </nb-actions>
</div>
<ng-template #dialogTheme let-data let-ref="dialogRef">
    <nb-select matRipple [selected]="currentTheme" (selectedChange)="changeTheme($event)">
        <nb-option *ngFor="let theme of themes" [value]="theme.value" matRipple>{{ theme.name }}</nb-option>
    </nb-select>
</ng-template>