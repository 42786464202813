import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { ApiResponse } from "../models/ApiResponse";
import { ApplicationUser } from "../models/applicationUser";
import { IsRegistered } from "../models/isRegistered";

@Injectable({
  providedIn: "root",
})
export class ApplicationUserService {
  private getApplicationUsersHaveSubscriptionsPath =
    environment.commonUrl +
    "ApplicationUser/GetApplicationUsersHaveSubscription";
  private searchPath =
    environment.commonUrl + "ApplicationUser/Search?keyword=";
  private getApplicationUsersPath =
    environment.commonUrl + "ApplicationUser/GetApplicationUsers";
  private updateApplicationUserPath =
    environment.commonUrl +
    "ApplicationUser/UpdateApplicationUserInDashBoard?id=";
  private addApplicationUserPath =
    environment.commonUrl + "ApplicationUser/Register";
  private checkIsRegisteredPath =
    environment.commonUrl + "ApplicationUser/isRegistered";
  private deleteApplicationUserPath =
    environment.commonUrl + "ApplicationUser/DeleteApplicationUserById?id=";
  private getApplicationUserByIdPath =
    environment.commonUrl + "ApplicationUser/GetApplicationUserById?id=";
  constructor(private http: HttpClient) {}

  getApplicationUsersHaveSubscriptions(): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(
      this.getApplicationUsersHaveSubscriptionsPath
    );
  }
  getAllApplicationUsers(): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.getApplicationUsersPath);
  }
  search(keyword: string): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.searchPath + keyword);
  }
  updateApplicationUser(
    id: number,
    model: ApplicationUser
  ): Observable<ApiResponse> {
    return this.http.put<ApiResponse>(
      this.updateApplicationUserPath + id,
      model
    );
  }
  checkIsRegistered(model: IsRegistered): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.checkIsRegisteredPath, model);
  }
  addApplicationUser(model: ApplicationUser): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.addApplicationUserPath, model);
  }
  deleteApplicationUser(id: number): Observable<ApiResponse> {
    return this.http.delete<ApiResponse>(this.deleteApplicationUserPath + id);
  }
  getApplicationUserById(id: number): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.getApplicationUserByIdPath + id);
  }
}
