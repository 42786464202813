<div class="row">
    <div class="col-md-12">
        <nb-card class="own-scroll">
            <nb-card-header>
                <div class="row">
                    <div class="col-md-10">
                        control Package sessions
                    </div>
                </div>
            </nb-card-header>
        </nb-card>
        <nb-card class="own-scroll">

            <nb-card-body [nbSpinner]="loadingPackageStructures" nbSpinnerStatus="primary" nbSpinnerSize="large">
                <div id="data-grid-demo">
                    <div>
                        <label>Choose a package</label>
                        <select #selectElem class="custom-select" (change)="selectPackage(selectElem.value)">
                            <option disabled selected value>select a package</option>
                            <option *ngFor="let package of packages" ([ngValue])="package">
                                {{package?.titleEn}}</option>
                        </select>
                        <hr>

                    </div>
                    <dx-data-grid #grid id="gridContainer" [dataSource]="packageStructures" keyExpr="id"
                        [focusedRowEnabled]="true" [showBorders]="true"
                        (onFocusedRowChanging)="onFocusedRowChanging($event)"
                        (onRowUpdated)="updatePackageStructure($event)"
                        (onFocusedRowChanged)="onFocusedRowChanged($event)"
                        (onRowInserting)="addPackageStructure($event)" (onRowRemoving)="deletePackageStructure($event)"
                        [disabled]="isDisabled">

                        <dxo-paging [enabled]="false"></dxo-paging>
                        <dxo-editing mode="form" [refreshMode]="refreshMode" [allowAdding]="true" [allowUpdating]="true"
                            [allowDeleting]="true">
                        </dxo-editing>
                        <dxo-filter-row [visible]="showFilterRow" [applyFilter]="currentFilter"></dxo-filter-row>
                        <dxo-header-filter [visible]="showHeaderFilter"></dxo-header-filter>
                        <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>
                        <dxi-column dataField="sessionSequence" caption="Day number" dataType="number">
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dxi-column>
                        <dxi-column dataField="sessionTypeId" caption="session type">
                            <dxo-lookup [dataSource]="sessionTypes" displayExpr="name" valueExpr="id">
                            </dxo-lookup>
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dxi-column>
                        <dxi-column dataField="description">
                        </dxi-column>
                    </dx-data-grid>
                </div>
            </nb-card-body>
        </nb-card>



    </div>
</div>