import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { ApiResponse } from "../models/ApiResponse";
import { PackageExercise } from "../models/packageExercise";

@Injectable({
  providedIn: "root",
})
export class PackageExerciseService {
  private getPackageExercisesByPlanIdPath =
    environment.apiUrl + "PackageExercise/GetPackageExercisesByPlanId?id=";
  private addPackageExercisePath =
    environment.apiUrl + "PackageExercise/AddPackageExercise";
  private deletePackageExercisePath =
    environment.apiUrl + "PackageExercise/DeletePackageExerciseById?id=";
  private updatePackageExercisePath =
    environment.apiUrl + "PackageExercise/UpdatePackageExercise?id=";

  constructor(private http: HttpClient) {}
  // package Exercise apis

  getPackageExercisesByPlanId(planId: number): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(
      this.getPackageExercisesByPlanIdPath + planId
    );
  }
  addPackageExercise(model: PackageExercise): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.addPackageExercisePath, model);
  }
  updatePackageExercise(
    packageExerciseId: number,
    model: PackageExercise
  ): Observable<ApiResponse> {
    return this.http.put<ApiResponse>(
      this.updatePackageExercisePath + packageExerciseId,
      model
    );
  }
  deletePackageExercise(packageExerciseId: number): Observable<ApiResponse> {
    return this.http.delete<ApiResponse>(
      this.deletePackageExercisePath + packageExerciseId
    );
  }
}
