import { Component, OnInit } from '@angular/core';
declare const init: any;

@Component({
  selector: 'ngx-body-simulator',
  templateUrl: './body-simulator.component.html',
  styleUrls: ['./body-simulator.component.scss']
})
export class BodySimulatorComponent implements OnInit {

  constructor() { 
    
  }

  ngOnInit(): void {
    
  }
  call(){
    
  }

}
