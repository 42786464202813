import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ElementRef,
  ViewChild,
  OnDestroy
} from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NbDateService, NbDialogService } from "@nebular/theme";
import { VisitService } from "../../../@core/app-services/visit.service";
import { ActivityService } from "../../../@core/app-services/activity.service";
import { ToasterHelper } from "../../../@core/app-services/toasterHelper";
import { Activity } from "../../../@core/models/activity";
import { ActivityType } from "../../../@core/models/activityType";
import { ActivityTypeCategory } from "../../../@core/models/activityTypeCategory";
import { Visit } from "../../../@core/models/visit";
import { Slot } from "../../../@core/models/slot";
import { /*AwsPatientRepoConfig,*/ AwsS3Manage } from "../../../@core/lib/AwsS3Manage"
import { ImageUtils } from "../../../@core/lib/ImageUtils"
import { fileURLToPath } from "url";
import { Lightbox } from "ngx-lightbox";
import { url } from "inspector";
import { UserModel } from "../../../@core/models/UserModel";
import { ThrowStmt } from "@angular/compiler";

@Component({
  selector: "ngx-visit",
  templateUrl: "./visit.component.html",
  styleUrls: ["./visit.component.scss"],
})
export class VisitComponent implements OnInit, OnDestroy {
  private _visit: Visit;
  /*@Input() set visit(value: Visit) {
    this._visit = value;
    if (value != null) {
      this.newActivity.visitId = this.visit.id;
      this.getActivities();
      if (this.visit.status == "Not Confirmed") {
        // this.getAvailableSlots();
        this.selectedVisitDate = this.visit.date;
      }
    }
  }*/
  get visit(): Visit {
    return this._visit;
  }
  @Output() updated = new EventEmitter();
  newActivity: Activity = new Activity();
  activities: Activity[] = new Array();
  activityTypes: ActivityType[] = new Array();
  selectedActivityType: ActivityType = new ActivityType();
  activityCategories: ActivityTypeCategory[] = new Array();
  patientId: number;
  gettingActivities: boolean = true;
  gettingAvalableSlots: boolean = true;
  savingActivity: boolean = false;
  slots: Slot[] = new Array();
  toUpdateVisit: Visit = new Visit();
  selectedVisitDate: Date = new Date();
  // temporary solution: Holds all users despite of the activity type
  allUsers: UserModel[] = new Array();
  isChangedActivities: boolean[] = 
          new Array(this.activities.length).fill(false);
  updatingActivity = false;

  //Drag and drop
  @ViewChild('dropArea', { static: false }) dropArea: ElementRef;
  @ViewChild('dropButton', { static: false }) dropButton: ElementRef;
  @ViewChild('dropMsg', { static: false }) dropMessage: ElementRef;
  dropFiles: FileList;
  awsS3Manage: AwsS3Manage = new AwsS3Manage();
  _imageUtils: ImageUtils = new ImageUtils();

  //lightbox
  private _albums = [];

  constructor(
    private toasterHelper: ToasterHelper,
    private dialogService: NbDialogService,
    private route: ActivatedRoute,
    private activityService: ActivityService,
    private visitService: VisitService,
    private dateService: NbDateService<Date>,
    private _lightbox: Lightbox
  ) {
    route.queryParams.subscribe(
      (params) => {
        this.patientId = this.route.snapshot.params.id;
        var visitId = params['visitid'];
        this.visitService.getVisitById(visitId).subscribe(data=>{
          this._visit = data;
          this.getActivities();
          /*this.initAlbum();
          this.bindHiddenDragElements();*/
        });
      },
      (error) => {
        toasterHelper.showError(error);
      }
    );
    activityService.getActivityTypes().subscribe((data) => {
      this.activityCategories = data;
      this.selectedActivityType = this.activityCategories[0].activities[0];
      this.newActivity.activityOwnerId = this.selectedActivityType.owners[0].id;
      
      // List all users
      this.activityCategories.forEach(category => {
        category.activities.forEach(activityType => {
          activityType.owners.forEach(owner => {
              // Push only if unique
              for (var i = 0; i < this.allUsers.length; i++) {
                if (this.allUsers[i].id === owner.id) {
                  return;
                }
              }
              this.allUsers.push(owner);
            })
          });
        });
      });
  }
  visitUpdated() {
    this.updated.emit(true);
  }

  getAvailableSlots() {
    console.log(this.visit);
    this.visitService
      .getDaySlotsByDoctor(new Date(this.selectedVisitDate), "")
      .subscribe((data) => {
        this.slots = data;
        this.gettingAvalableSlots = false;
      });
  }
  bookSlot(slot: Slot) {
    slot.bookingSlot = true;
    this.toUpdateVisit.date = this.selectedVisitDate;
    this.toUpdateVisit.id = this.visit.id;
    this.toUpdateVisit.startTime = slot.startFrom;
    this.toUpdateVisit.endTime = slot.endAt;
    this.visitService.updateVisit(this.toUpdateVisit).subscribe((data) => {
      if (data.status) {
        this.visitUpdated();
        this.toasterHelper.showSaved(data.message);
        this.visit.date = this.selectedVisitDate;
        this.visit.startTime = this.toUpdateVisit.startTime;
        this.visit.endTime = this.toUpdateVisit.endTime;
        this.visit.status = "NA";
      } else {
        this.toasterHelper.showError(data.message);
      }
    });
  }
  getActivities() {
    if (this.visit) {
      this.gettingActivities = true;
      this.activityService
        .getVisitActivities(this.visit.id)
        .subscribe((data) => {
          this.activities = data;
          this.gettingActivities = false;
        });
    }
  }
  
  
  updateVisitPhotos() 
  {
    /*var isUploaded = false;
    var promises = [];
    if (!this.visit.photosUrls)
      this.visit.photosUrls = "";

    if (this.dropFiles && this.dropFiles.length > 0)
    {
      Array.from(this.dropFiles).forEach(file => {
        file.name.replace(',','');
     
        promises.push(this.awsS3Manage.uploadFile({fileStream: file, location: AwsPatientRepoConfig.patientVisitsUrl}));

        this._imageUtils.getThumbnail(file, 1/5).then(resizedFile => {
          this.awsS3Manage.uploadFile({fileStream: resizedFile, location: AwsPatientRepoConfig.patientVisitsUrl}); 
        })
      });

      Promise.all(promises).then(results => {                                
                              this.toasterHelper.showSaved("Photos uploaded sucessfully");
                              console.log(this.visit.photosUrls);
                              
                              Array.from(results).forEach( promiseResult => {
                                  var filename = promiseResult.Key.split('\\').pop().split('/').pop();
                                  if (!this.visit.photosUrls.includes(filename))
                                  {
                                    this.visit.photosUrls += filename + ",";
                                  }
                              });

                              // save to db
                              this.visitService
                                .updateVisitPhotos(this.visit.id, this.visit.photosUrls)
                                .subscribe((data) => {
                                  this.toasterHelper.showSaved(data.message);
                                });
                            }).catch(err => {
                              this.toasterHelper.showError(err.message);
                              console.log(err.message);
                            });
      }
*/

}
  addActivity(ref) {
    this.savingActivity = true;
    this.newActivity.visitId = this._visit.id;
    this.newActivity.activityTypeId = this.selectedActivityType.id;
    this.activityService.addActivity(this.newActivity).subscribe((data) => {
      if (data.status) {
        this.toasterHelper.showAdded(data.message);
        this.getActivities();
        ref.close();
      } else {
        this.toasterHelper.showError(data.message);
      }
      this.savingActivity = false;
    });
  }
  openDialog(dialog: TemplateRef<any>) {
    this.dialogService.open(dialog);
  }
  deleteActivity(activityId: number) {
    this.activityService.deleteActivity(activityId).subscribe((data) => {
      if (data.status) {
        this.toasterHelper.showSaved(data.message);
        this.getActivities();
      } else {
        this.toasterHelper.showError(data.message);
      }
    });
  }
  onActivityTypeSelected() {
    this.newActivity.activityOwnerId = this.selectedActivityType.owners[0].id;
  }
  ngOnInit(): void {}

  ngAfterViewInit() 
  {
    
  }

  bindHiddenDragElements(){
    //Add listeners
    this.addListenerMulti(this.dropArea.nativeElement,'dragenter focus click',() => { this.dropArea.nativeElement.classList.add('is-active'); });
    this.addListenerMulti(this.dropButton.nativeElement,'dragleave blur drop',() => { this.dropArea.nativeElement.classList.remove('is-active'); });
  }

  addListenerMulti(element, eventsNames, handlingFunction) {
    eventsNames.split(' ').forEach(event => element.addEventListener(event, handlingFunction, false));
  }

  handleDragAreaClick(event)
  {
    var filesCount = event.target.files.length;
    
    if (filesCount > 0)
    {
      this.dropFiles = event.target.files;
    }

    if (filesCount === 1) {
      // if single file is selected, show file name
      var fileName = event.target.files[0].name.split('\\').pop();
      this.dropMessage.nativeElement.innerText = fileName;
    } else {
      // otherwise show number of files
      this.dropMessage.nativeElement.innerText = filesCount + ' files selected';
    }
  }

  initAlbum()
  {
    if (this.visit.photosUrls && this.visit.photosUrls != "")
    {
      //var formatedUrl = this.visit.photosUrls.replace("/,\s*$/", "");
      var urls = this.visit.photosUrls.split(",");
      urls.pop();

      this.albumAppend(urls);
    }
  }

  albumAppend(urls: Array<string>)
  {
    if (urls.length > 0)
      {
        /*Array.from(urls).forEach(url => {
          const album = {
            src: AwsPatientRepoConfig.publicUrl + AwsPatientRepoConfig.patientVisitsUrl + url,
            caption: this.visit.date.toString(),
            thumb: ""
          };

          this._albums.push(album);
        })*/
    }
  }

  openLightBox()
  {
    if (this._albums)
    {
      this._lightbox.open(this._albums,0);
    }
  }

  getOwners(activityType) : Array<any>
  {
    console.log(activityType);
    this.activityCategories.forEach(category => {
      category.activities.forEach(activity=>{
        
        if(activity.title == activityType)
        {
          console.log(activity.owners);
          return activity.owners;
        }
      })
    });

    return [];
  }

  acivityNewOwnerClicked(e, newOwner, selectedActivityType)
  {
    this.activities.forEach((activity, index) => {
      if (activity.activityType == selectedActivityType)
      {
        activity.activityOwnerId = newOwner.id;
        this.isChangedActivities[index] = true;
      }
    });
  }

  activtiyOwnerChanged(e)
  {
  }

  saveChangedAcivities()
  {
    this.isChangedActivities.forEach((isChangedActivity, index) => {
      if (isChangedActivity == true)
      {
        var activity = this.activities[index];
        var isRelated = this.checkOwnerRelatedActivity(activity.activityType, activity.activityOwnerId);
        if (isRelated)
        {
          this.updatingActivity = true;
          this.activityService.updateActivity(activity).subscribe(data => {
            this.isChangedActivities[index] = false;
            this.updatingActivity = false;
            this.toasterHelper.showSaved(data.message);
          }, error => {
            this.updatingActivity = false;
            this.toasterHelper.showError(error.message);
          });
        }
        else
        {
          this.isChangedActivities[index] = false;
          this.toasterHelper.showError("the selected user doesn't belong to activity: " + activity.activityType.toString());
        }
      }
    });
  }

  checkOwnerRelatedActivity(selectedActivityType, selectedOwnerId) : boolean
  {
    var result = false;
    this.activityCategories.forEach(category => {
      category.activities.forEach(activityType => {
        if (selectedActivityType == activityType.title)
        {
          activityType.owners.forEach( owner => {
            if (owner.id == selectedOwnerId)
            {
              result = true;
            }
          });
        }
      });
    });

    return result;
  }

  ngOnDestroy()
  {
    //this.updateVisitPhotos();
  }
}
