import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { ToasterHelper } from "../../../../@core/app-services/toasterHelper";
import { TreatmentTypeService } from "../../../../@core/app-services/treatment-type.service";
import { TreatmentType } from "../../../../@core/models/TreatmentType";

@Component({
  selector: "ngx-treatment-type",
  templateUrl: "./treatment-type.component.html",
  styleUrls: ["./treatment-type.component.scss"],
})
export class TreatmentTypeComponent implements OnInit {
  TreatmentTypes: TreatmentType[] = [];
  TreatmentType: TreatmentType = new TreatmentType();

  refreshMode: string;
  taskSubject: string;
  taskDetailsHtml: SafeHtml;
  taskStatus: string;
  taskProgress: string;
  currentFilter: any;
  showFilterRow: boolean = true;
  showHeaderFilter: boolean = true;
  autoNavigateToFocusedRow = true;
  loadingTreatmentTypes: boolean = false;

  constructor(
    private sanitizer: DomSanitizer,
    private TreatmentTypeService: TreatmentTypeService,
    private toasterHelper: ToasterHelper
  ) {}

  getTreatmentTypes() {
    this.TreatmentTypeService.getTreatmentTypes().subscribe(
      (data) => {
        if (data.status) {
          this.TreatmentTypes = data.responseObject;
        } else {
          this.TreatmentTypes = [];
        }
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
      }
    );
  }

  addTreatmentType(e) {
    this.loadingTreatmentTypes = true;

    let newTreatmentType = new TreatmentType();
    this.TreatmentType = e.data;
    newTreatmentType.enName = this.TreatmentType.enName;
    newTreatmentType.arName = this.TreatmentType.arName;
    newTreatmentType.enDescription = this.TreatmentType.enDescription;
    newTreatmentType.arDescription = this.TreatmentType.arDescription;

    this.TreatmentTypeService.addTreatmentType(newTreatmentType).subscribe(
      (data) => {
        if (data.status) {
          this.toasterHelper.showToast(
            "Added successfully",
            data.message,
            "success"
          );
        } else {
          this.toasterHelper.showError(data.message);
        }
        this.loadingTreatmentTypes = false;

        this.getTreatmentTypes();
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
        this.loadingTreatmentTypes = false;
      }
    );
  }

  updateTreatmentType(e) {
    this.loadingTreatmentTypes = true;
    let updatedTreatmentType = new TreatmentType();
    this.TreatmentType = e.data;
    updatedTreatmentType.enName = this.TreatmentType.enName;
    updatedTreatmentType.arName = this.TreatmentType.arName;
    updatedTreatmentType.enDescription = this.TreatmentType.enDescription;
    updatedTreatmentType.arDescription = this.TreatmentType.arDescription;

    this.TreatmentTypeService.updateTreatmentType(
      this.TreatmentType.id,
      updatedTreatmentType
    ).subscribe(
      (data) => {
        if (data.status) {
          this.toasterHelper.showToast(
            "updated successfully",
            data.message,
            "success"
          );
        } else {
          this.toasterHelper.showError(data.message);
        }
        this.loadingTreatmentTypes = false;

        this.getTreatmentTypes();
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
        this.loadingTreatmentTypes = false;
      }
    );
  }

  deleteTreatmentType(e) {
    this.loadingTreatmentTypes = true;
    this.TreatmentType = e.data;
    this.TreatmentTypeService.deleteTreatmentType(
      this.TreatmentType.id
    ).subscribe(
      (data) => {
        if (data.status) {
          this.toasterHelper.showToast(
            "Deleted successfully",
            data.message,
            "success"
          );
        } else {
          this.toasterHelper.showError(data.message);
        }
        this.loadingTreatmentTypes = false;
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
        this.loadingTreatmentTypes = false;
      }
    );
  }

  onFocusedRowChanging(e) {
    const rowsCount = e.component.getVisibleRows().length;
    const pageCount = e.component.pageCount();
    const pageIndex = e.component.pageIndex();
    const key = e.event && e.event.key;
    const rowData = e.row && e.row.data;
    if (key && e.prevRowIndex === e.newRowIndex) {
      if (e.newRowIndex === rowsCount - 1 && pageIndex < pageCount - 1) {
        e.component.pageIndex(pageIndex + 1).done(() => {
          e.component.option("focusedRowIndex", 0);
        });
      } else if (e.newRowIndex === 0 && pageIndex > 0) {
        e.component.pageIndex(pageIndex - 1).done(() => {
          e.component.option("focusedRowIndex", rowsCount - 1);
        });
      }
    }
  }
  onFocusedRowChanged(e) {
    const rowData = e.row && e.row.data;
    // this.rowPackage= rowData;
    if (rowData) {
      this.taskSubject = rowData.Task_Subject;
      this.taskDetailsHtml = this.sanitizer.bypassSecurityTrustHtml(
        rowData.Task_Description
      );
      this.taskStatus = rowData.Task_Status;
      this.taskProgress = rowData.Task_Completion
        ? `${rowData.Task_Completion}` + "%"
        : "";
    }
  }

  ngOnInit(): void {
    this.getTreatmentTypes();
  }
}
