import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { isNumeric } from "rxjs/util/isNumeric";
import { MealService } from "../../../../@core/app-services/meal.service";
import { PackageMealService } from "../../../../@core/app-services/package-meal.service";
import { PlanService } from "../../../../@core/app-services/plan.service";
import { ToasterHelper } from "../../../../@core/app-services/toasterHelper";
import { AddingType } from "../../../../@core/models/addingType";
import { AddPackageMeal } from "../../../../@core/models/addPackageMeal";
import { GetPackageMeal } from "../../../../@core/models/getPackageMeal";
import { Meal } from "../../../../@core/models/meal";
import { MealType } from "../../../../@core/models/mealType";
import { Plan } from "../../../../@core/models/plan";

@Component({
  selector: "ngx-plan-meal",
  templateUrl: "./plan-meal.component.html",
  styleUrls: ["./plan-meal.component.scss"],
})
export class PlanMealComponent implements OnInit {
  packageMeals: GetPackageMeal[] = [];
  packageMeal: GetPackageMeal = null;
  meals: Meal[] = [];
  mealTypes: MealType[] = [];
  plans: Plan[];
  refreshMode: string;
  taskSubject: string;
  taskDetailsHtml: SafeHtml;
  taskStatus: string;
  taskProgress: string;
  currentFilter: any;
  showFilterRow: boolean = true;
  plan: Plan = null;
  showHeaderFilter: boolean = true;
  autoNavigateToFocusedRow = true;
  isDisabled: boolean = true;
  loadingPackageMeals: boolean = false;
  loadingPackages: boolean = false;
  addingTypes: AddingType[] = [
    {
      id: 0,
      type: "weekly",
    },
    {
      id: 1,
      type: "daily",
    },
    {
      id: 2,
      type: "one record",
    },
  ];
  constructor(
    private planService: PlanService,
    private packageMealService: PackageMealService,
    private mealService: MealService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private toasterHelper: ToasterHelper
  ) {}
  getMeals() {
    this.mealService.getMeals().subscribe(
      (data) => {
        if (data.status) {
          this.meals = data.responseObject;
        } else {
          this.meals = [];
        }
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
      }
    );
  }

  addPackageMeal(e) {
    this.loadingPackageMeals = true;

    let newPackageMeal = new AddPackageMeal();
    this.packageMeal = e.data;
    let dateNumbers: string = e.data.dateNumber;
    let dataNumbersList: number[] = [];
    newPackageMeal.mealId = this.packageMeal.meal.id;
    newPackageMeal.nameAr = this.packageMeal.nameAr;
    newPackageMeal.nameEn = this.packageMeal.nameEn;
    newPackageMeal.sequance = this.packageMeal.sequance;
    newPackageMeal.planId = this.plan.id;
    const specialChars = /[`!@#$%^&*()_+\=\[\]{};':"\\|.<>\/?~]/;
    const letters = /[a-zA-Z]/;
    if (specialChars.test(dateNumbers) || letters.test(dateNumbers)) {
      this.toasterHelper.showError(
        "there is wrong special Characters and letters"
      );
      this.getPackageMeals();
    } else if (dateNumbers.includes(",") && dateNumbers.includes("-")) {
      this.toasterHelper.showError("date number has , and - at the same time");
      this.getPackageMeals();
    } else if (dateNumbers.includes(",")) {
      let res = dateNumbers.split(",");
      dataNumbersList = res.map((i) => Number(i));
    } else if (dateNumbers.replace(/[^-]/g, "").length == 1) {
      let res = dateNumbers.split("-");
      let rangeArray: number[] = res.map((i) => Number(i));
      let finalList: number[] = [];
      for (let index = rangeArray[0]; index <= rangeArray[1]; index++) {
        finalList.push(index);
      }
      dataNumbersList = finalList;
    } else if (isNumeric(dateNumbers)) {
      let dateNumber = parseInt(dateNumbers);
      dataNumbersList = [dateNumber];
    }
    if (dataNumbersList.length > 0) {
      dataNumbersList.forEach((element) => {
        if (element <= 7 && element > 0) {
          newPackageMeal.dateNumber = element;
          this.packageMealService.addPackageMeal(newPackageMeal).subscribe(
            (data) => {
              if (data.status) {
                this.toasterHelper.showToast(
                  "Added successfully",
                  data.message,
                  "success"
                );
              } else {
                this.toasterHelper.showError(data.message);
              }
              this.getPackageMeals();
              this.loadingPackageMeals = false;
            },
            (error) => {
              this.toasterHelper.showError("Something went wrong");
              this.loadingPackageMeals = false;
            }
          );
        } else {
          this.toasterHelper.showError(
            "data number should be an integer and <= 7 and > 0"
          );
          this.getPackageMeals();
        }
      });
    } else {
      this.toasterHelper.showError("you enter dateNumber wrong");
      this.getPackageMeals();
      this.loadingPackageMeals = false;
    }
  }
  selectPlan(nameEn: string) {
    this.plan = this.plans.find((p) => p.nameEn.trim() == nameEn.trim());
    this.getPackageMeals();
    this.isDisabled = false;
  }
  getPackageMeals() {
    this.loadingPackageMeals = true;
    this.packageMealService.getPackageMealsByPackageId(this.plan.id).subscribe(
      (data) => {
        if (data.status) {
          this.packageMeals = data.responseObject;
        } else {
          this.packageMeals = [];
        }
        this.loadingPackageMeals = false;
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
        this.loadingPackageMeals = false;
      }
    );
  }

  updatePackageMeal(e) {
    this.loadingPackageMeals = true;
    let updatedPackageMeal = new AddPackageMeal();
    this.packageMeal = e.data;
    updatedPackageMeal.dateNumber = this.packageMeal.dateNumber;
    updatedPackageMeal.mealId = this.packageMeal.meal.id;
    updatedPackageMeal.nameAr = this.packageMeal.nameAr;
    updatedPackageMeal.nameEn = this.packageMeal.nameEn;
    updatedPackageMeal.sequance = this.packageMeal.sequance;
    updatedPackageMeal.planId = this.plan.id;
    if (
      isNumeric(e.data.dateNumber) &&
      e.data.dateNumber <= 7 &&
      e.data.dateNumber > 0
    ) {
      this.packageMealService
        .updatePackageMeal(this.packageMeal.id, updatedPackageMeal)
        .subscribe(
          (data) => {
            if (data.status) {
              this.toasterHelper.showToast(
                "Updated successfully",
                data.message,
                "success"
              );
            } else {
              this.toasterHelper.showError(data.message);
            }
            this.loadingPackageMeals = false;
            this.getPackageMeals();
          },
          (error) => {
            this.toasterHelper.showError("Something went wrong");
            this.loadingPackageMeals = false;
          }
        );
    } else {
      this.toasterHelper.showError(
        "data number should be an integer and <= 7 and > 0"
      );
      this.getPackageMeals();
      this.loadingPackageMeals = false;
    }
  }

  deletePackageMeal(e) {
    this.loadingPackageMeals = true;

    this.packageMeal = e.data;
    this.packageMealService.deletePackageMeal(this.packageMeal.id).subscribe(
      (data) => {
        if (data.status) {
          this.toasterHelper.showToast(
            "Deleted successfully",
            data.message,
            "success"
          );
        } else {
          this.toasterHelper.showError(data.message);
        }
        this.loadingPackageMeals = false;
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
        this.loadingPackageMeals = false;
      }
    );
  }

  getMealTypes() {
    this.mealService.getMealTypes().subscribe(
      (data) => {
        this.mealTypes = data.responseObject;
        console.log(this.mealTypes);
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
      }
    );
  }
  getPlans() {
    this.loadingPackageMeals = true;
    this.planService.getPlans().subscribe(
      (data) => {
        if (data.status) {
          this.plans = data.responseObject;
        } else {
          this.plans = [];
        }
        this.loadingPackageMeals = false;
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
        this.loadingPackageMeals = false;
      }
    );
  }
  onFocusedRowChanging(e) {
    const rowsCount = e.component.getVisibleRows().length;
    const pageCount = e.component.pageCount();
    const pageIndex = e.component.pageIndex();
    const key = e.event && e.event.key;
    const rowData = e.row && e.row.data;
    if (key && e.prevRowIndex === e.newRowIndex) {
      if (e.newRowIndex === rowsCount - 1 && pageIndex < pageCount - 1) {
        e.component.pageIndex(pageIndex + 1).done(() => {
          e.component.option("focusedRowIndex", 0);
        });
      } else if (e.newRowIndex === 0 && pageIndex > 0) {
        e.component.pageIndex(pageIndex - 1).done(() => {
          e.component.option("focusedRowIndex", rowsCount - 1);
        });
      }
    }
  }
  onFocusedRowChanged(e) {
    const rowData = e.row && e.row.data;
    // this.rowPackage= rowData;
    if (rowData) {
      this.taskSubject = rowData.Task_Subject;
      this.taskDetailsHtml = this.sanitizer.bypassSecurityTrustHtml(
        rowData.Task_Description
      );
      this.taskStatus = rowData.Task_Status;
      this.taskProgress = rowData.Task_Completion
        ? `${rowData.Task_Completion}` + "%"
        : "";
    }
  }

  ngOnInit(): void {
    this.getPlans();
    this.getMealTypes();
    this.getMeals();
  }
}
