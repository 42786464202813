<div id="visitscard">
    <nb-card>
        <nb-card-header>
            <div class="row">
                <h2 style="margin-left:auto;margin-right:auto;">Yesterday's missed sessions report</h2>
            </div>
        </nb-card-header>
        <nb-card-body>
            <nb-card-body>
                <div class="row">
                    <dx-data-grid id="visitgrid" [dataSource]="report" keyExpr="applicationUserId" [showBorders]="true"
                        [rowAlternationEnabled]="true" [allowColumnResizing]="true" [allowColumnReordering]="true"
                        #dataGrid>

                        <dxo-search-panel [visible]="true"></dxo-search-panel>
                        <dxo-group-panel [visible]="true"></dxo-group-panel>
                        <dxo-grouping #expand [autoExpandAll]="true"></dxo-grouping>
                        applicationUserId: number;
                        fullName: string;
                        loseWeight: 0;
                        branchName: string;
                        packageName: string;
                        <dxi-column caption="ClientId" dataField="applicationUserId"></dxi-column>
                        <dxi-column caption="ClientName" dataField="fullName"></dxi-column>
                        <dxi-column caption="Branch Name" dataField="branchName"></dxi-column>
                        <dxi-column caption="Package Name" dataField="packageName"></dxi-column>
                        <dxi-column type="buttons">
                            <dxi-button hint="Profile" text="Profile" [onClick]="getProfile">
                            </dxi-button>
                            <dxi-button hint="Call Log" text="Call Log" [onClick]="getCallLog">
                            </dxi-button>
                        </dxi-column>


                        <dxo-scrolling rowRenderingMode="virtual"> </dxo-scrolling>
                        <dxo-paging [pageSize]="5"> </dxo-paging>
                        <dxo-pager [visible]="true" [allowedPageSizes]="allowedPageSizes" [displayMode]="displayMode"
                            [showPageSizeSelector]="showPageSizeSelector" [showInfo]="showInfo"
                            [showNavigationButtons]="showNavButtons">
                        </dxo-pager>
                        <dxo-summary>
                            <dxi-group-item column="date" summaryType="count" displayFormat="{0} visits">
                            </dxi-group-item>
                        </dxo-summary>

                        <dxi-sort-by-group-summary-info summaryItem="count"></dxi-sort-by-group-summary-info>
                    </dx-data-grid>
                </div>
            </nb-card-body>

        </nb-card-body>
    </nb-card>
</div>
<dx-load-panel shadingColor="rgba(0,0,0,0.6)" [(visible)]="loadingVisible" [showIndicator]="true" [showPane]="true"
    [shading]="true" [height]="300" [width]="800" message="&#10102; Generating Yesterday's Missed Sessions Report" #loadPanel>
</dx-load-panel>
