import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { AddSubscriptionMeal } from "../models/addSubscriptionMeal";
import { ApiResponse } from "../models/ApiResponse";
import { UpdateSubscriptionMeal } from "../models/updateSubscriptionMeal";

@Injectable({
  providedIn: "root",
})
export class SubscriptionMealService {
  private getSubscriptionMealsByApplicationUserIdPath =
    environment.apiUrl +
    "SubscriptionMeals/GetSubscriptionMealsByAppUserId?id=";
  private addSubscriptionMealPath =
    environment.apiUrl + "SubscriptionMeals/AddSubscriptionMeal";
  private deleteSubscriptionMealPath =
    environment.apiUrl + "SubscriptionMeals/DeleteSubscriptionMealById?id=";
  private updateSubscriptionMealPath =
    environment.apiUrl + "SubscriptionMeals/UpdateSubscriptionMeal?id=";

  constructor(private http: HttpClient) {}
  // package meals apis

  getSubscriptionMealsByApplicationUserId(
    applicationUserId: number
  ): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(
      this.getSubscriptionMealsByApplicationUserIdPath + applicationUserId
    );
  }
  addSubscriptionMeal(model: AddSubscriptionMeal): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.addSubscriptionMealPath, model);
  }
  updateSubscriptionMeal(
    SubscriptionMealId: number,
    model: UpdateSubscriptionMeal
  ): Observable<ApiResponse> {
    return this.http.put<ApiResponse>(
      this.updateSubscriptionMealPath + SubscriptionMealId,
      model
    );
  }
  deleteSubscriptionMeal(SubscriptionMealId: number): Observable<ApiResponse> {
    return this.http.delete<ApiResponse>(
      this.deleteSubscriptionMealPath + SubscriptionMealId
    );
  }
}
