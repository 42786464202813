import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { environment } from "../../../environments/environment";
import { Call } from "../models/call";
import { LogCallModel } from "../models/logCallModel";
import { LogFollowupCallModel } from "../models/logFollowup";
import { AuditCallModel } from "../models/auditCallModel";

@Injectable({
  providedIn: "root",
})
export class CallService {
  private getPatientCallsPath = environment.apiUrl + "call/GetPatientCalls?patientId=";
  private filterPatientCallsPath = environment.apiUrl + "call/FilterPatientCalls?patientId=";
  private logCallPath = environment.apiUrl + "call/LogCall";
  private editCallPath = environment.apiUrl + "call/EditCall";
  private logFollowupCallPath = environment.apiUrl + "call/LogFollowup";
  private auditCallPath = environment.apiUrl + "call/AuditCall";
  private getFollowupCallsPath = environment.apiUrl + "call/GetFollowupCalls";
  private getSubmittedCallsPath = environment.apiUrl + "call/GetSubmittedCalls";
  private getCallReasonsPath = environment.apiUrl + "call/GetCallReasons";
  private getCallStatusChartByTimePath = environment.apiUrl + "call/GetCallStatusChartByTime?from=";
  private getCallReasonChartByTimePath = environment.apiUrl + "call/GetCallReasonChartByTime?from=";
  
  constructor(private http: HttpClient) {}
  getPatientCalls(patientId: number): Observable<Call[]> {
    return this.http.get<Call[]>(this.getPatientCallsPath + patientId);
  }
  filterCalls(patientId: number, from :Date, to:Date, status:number, reason:number): Observable<Call[]> {
    return this.http.get<Call[]>(this.filterPatientCallsPath + patientId + "&from="+from.toLocaleDateString()+"&to="+to.toLocaleDateString()+"&status="+status+"&reason="+reason);
  }
  getFollowupCalls(): Observable<Call[]> {
    return this.http.get<Call[]>(this.getFollowupCallsPath);
  }
  getSubmittedCalls(): Observable<Call[]> {
    return this.http.get<Call[]>(this.getSubmittedCallsPath);
  }
  logCall(model:LogCallModel): Observable<any> {
    return this.http.post<any>(this.logCallPath, model);
  }
  editCall(model:LogCallModel): Observable<any> {
    return this.http.post<any>(this.editCallPath ,model);
  }
  logFollowup(model:LogFollowupCallModel): Observable<any> {
    return this.http.post<any>(this.logFollowupCallPath, model);
  }
  auditCall(model:AuditCallModel): Observable<any> {
    return this.http.post<any>(this.auditCallPath, model);
  }
  getCallReasons(): Observable<any> {
    return this.http.get<any>(this.getCallReasonsPath);
  }
  getCallStatusChartByTime(from,to): Observable<any> {
    return this.http.get<any>(this.getCallStatusChartByTimePath + from.toDateString() + '&to=' + to.toDateString());
  }
  getCallReasonChartByTime(from,to): Observable<any> {
    return this.http.get<any>(this.getCallReasonChartByTimePath + from.toDateString() + '&to=' + to.toDateString());
  }
}
