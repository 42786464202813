import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ApplicationUserService } from "../../../../@core/app-services/application-user.service";
import { SessionService } from "../../../../@core/app-services/session.service";
import { ToasterHelper } from "../../../../@core/app-services/toasterHelper";
import { ApplicationUser } from "../../../../@core/models/applicationUser";

@Component({
  selector: "ngx-client-info",
  templateUrl: "./client-info.component.html",
  styleUrls: ["./Client-info.component.scss"],
})
export class ClientInfoComponent implements OnInit {
  gettingPatientData: boolean = true;
  saving: boolean = false;
  appUserId: number;
  appUser: ApplicationUser = null;
  gender: string = "1";
  language: string = "1";

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private sessionService: SessionService,
    private applicationUserService: ApplicationUserService,
    private toasterHelper: ToasterHelper
  ) {
    /*this.treeDataSource = <JSON>this.obj;
    this.treeBoxValue = ['1_1'];*/
    route.queryParams.subscribe(
      (params) => {
        this.appUserId = this.route.snapshot.params.id;
        if (this.appUserId != 0) {
          this.getPatient();
        }
      },
      (error) => {
        toasterHelper.showError(error);
      }
    );
  }

  getPatient() {
    this.gettingPatientData = true;
    this.applicationUserService
      .getApplicationUserById(this.appUserId)
      .subscribe(
        (data) => {
          this.appUser = data.responseObject;
          this.gender = this.appUser.gender.toString();
          this.gettingPatientData = false;
        },
        (error) => {
          this.toasterHelper.showError(
            "Unhandled error was happened in the server!"
          );
          this.gettingPatientData = false;
        }
      );
  }
  ngOnInit(): void {}
  async save() {
    this.saving = true;

    this.appUser.gender = Number.parseInt(this.gender);
    this.appUser.languageCode = Number.parseInt(this.language);

    this.applicationUserService
      .updateApplicationUser(this.appUser.id, this.appUser)
      .subscribe(
        (data) => {
          this.saving = false;
          this.toasterHelper.showSaved("The patient was saved successfully!");
        },
        (error) => {
          this.toasterHelper.showError(
            "Unhandled error was happened in the server!"
          );
          this.saving = false;
          console.log(error.message);
        }
      );
  }
}
