import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { ApiResponse } from "../models/ApiResponse";
import { PackagePlan } from "../models/packageplan";

@Injectable({
  providedIn: "root",
})
export class PackagePlanService {
  private getPackagePlansPath =
    environment.apiUrl + "PackagePlan/GetPackagePlansByPackageId?id=";
  private addPackagePlanPath =
    environment.apiUrl + "PackagePlan/AddPackagePlan";
  private deletePackagePlanPath =
    environment.apiUrl + "PackagePlan/DeletePackagePlanById?id=";
  constructor(private http: HttpClient) {}

  getPackagePlansByPackageId(packageId: number): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.getPackagePlansPath + packageId);
  }
  addPackagePlan(model: PackagePlan): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.addPackagePlanPath, model);
  }
  deletePlan(packagePlanId: number): Observable<ApiResponse> {
    return this.http.delete<ApiResponse>(
      this.deletePackagePlanPath + packagePlanId
    );
  }
}
