import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { AddUserAvailability } from "../models/addUserAvailability";
import { ApiResponse } from "../models/ApiResponse";
import { IncreaseAvailability } from "../models/increaseAvailability";
import { MoveAvailability } from "../models/moveAvailability";
import { UserAvailability } from "../models/UserAvailability";
@Injectable({
  providedIn: "root",
})
export class UserAvailabilityService {
  private getUserAvailabilitiesPath =
    environment.apiUrl + "Availability/GetDoctorAvailabilty?doctorId=";
  private moveDoctorAvailabilityPath =
    environment.apiUrl + "Availability/MoveDoctorAvailability";
  private increaseDoctorAvailabilityPath =
    environment.apiUrl + "Availability/IncreaseDoctorAvailability";
  private getAvailabilityUpcomingSessionsPath =
    environment.apiUrl +
    "Availability/GetAvailabiltyUpcommingSession?availabilityId=";

  private AddUserAvailabilitiesPath =
    environment.apiUrl + "Availability/AddDoctorAvailability";
  private DeleteUserAvailabilitiesPath =
    environment.apiUrl +
    "Availability/DeleteAvailabilityAndUpcommingSessions?availabilityId=";
  private createAllAvailabilitiesPath =
    environment.apiUrl + "Availability/CreateAllAvailabilityBerUser?id=";

  private getUsersPath = environment.commonUrl + "Identity/GetUsers";

  constructor(private http: HttpClient) {}
  GetUserAvailability(UserId: string): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.getUserAvailabilitiesPath + UserId);
  }
  IncreaseAvailability(
    UserAvailability: IncreaseAvailability
  ): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(
      this.increaseDoctorAvailabilityPath,
      UserAvailability
    );
  }
  moveDoctorAvailability(
    UserAvailability: MoveAvailability
  ): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(
      this.moveDoctorAvailabilityPath,
      UserAvailability
    );
  }
  AddUserAvailability(
    UserAvailability: AddUserAvailability
  ): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(
      this.AddUserAvailabilitiesPath,
      UserAvailability
    );
  }
  DeleteAvailability(AvailabilityId: number): Observable<ApiResponse> {
    return this.http.delete<ApiResponse>(
      this.DeleteUserAvailabilitiesPath + AvailabilityId
    );
  }
  getAvailabilityUpcomingSessions(
    AvailabilityId: number
  ): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(
      this.getAvailabilityUpcomingSessionsPath + AvailabilityId
    );
  }
  CreateAllAvailabilities(UserId: string): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(
      this.createAllAvailabilitiesPath + UserId,
      ""
    );
  }
}
