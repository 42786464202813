export class SessionType {
  id: number;
  duration: number;
  name: string;
  description: string;
  numberOfUsers: number;
  userTypeId: number;
  descriptionEn: string;
  DescriptionAr: string;
  nameAr: string;
  nameEn: string;
  imageUrl: string;
  videoURL: string;
  mediaURL: string;
  price: number;
  isDevice: boolean;
}
