<div class="row">
    <nb-card>
        <nb-card-header>
            Pick a date to show your activities and visits
        </nb-card-header>
        <nb-card-body>
            <nb-calendar [(date)]="selectedDate" 
                [boundingMonth]="true" 
                showWeekNumber
                size="large"
                (dateChange)="this.reloadSchedule();"></nb-calendar>
        </nb-card-body>
    </nb-card>

        
  
      <div class="col-md-8">

        <nb-card size='giant' style="height: auto;">
            <nb-tabset fullWidth>
                <nb-tab tabTitle="All Visits"  badgeText="{{this.AllVisitCount}}" tabIcon="calendar-outline"
                badgeStatus="danger">
                    <nb-card-header class="row">
                        <div style="text-align: center;" class="col-md-3">Visits on: {{ selectedDate | date }}</div>
                        <div style="text-align: center;" class="col-md-3">NA: {{ NACount}}</div>
                        <div style="text-align: center;" class="col-md-3">Confirmed: {{ ConfirmedCount}}</div>
                        <div style="text-align: center;" class="col-md-3">Missed: {{ MissedCount}}</div>
                    </nb-card-header>
                    <nb-card-body>
                        <nb-card *ngFor="let visit of Visits" 
                                 [status]= "visit.status == 'NA' ? 'info' : 
                                            visit.status == 'Checked in' ? 'success' :
                                            visit.status == 'Missed' ? 'danger' : 'info'"
                                 class="visit"
                                 (click)="openVisit(visit,dialogCheckVisit)">
                            <nb-card-header class="row">
                                <div style="text-align: center;" class="col-md-3">
                                    Patient: {{ visit.patient == "" ? "No patient!" : visit.patient }}
                                </div>
                                <div style="text-align: center;" class="col-md-3" *ngIf="visit.status!= 'Not Confirmed'">
                                    {{ visit.startTime | date: "hh:mm a" }} - {{ visit.endTime | date: "hh:mm a" }}
                                </div>
                                <div style="text-align: center;" class="col-md-3" *ngIf="visit.status!= 'Not Confirmed'">
                                    {{ visit.status }}
                                </div>
                                <div style="text-align: center;" class="col-md-3">
                                    Doctor: {{ visit.doctor }}
                                </div>
                            </nb-card-header>
                        </nb-card>
                    </nb-card-body>
                </nb-tab>
                <nb-tab tabTitle="My Activities" tabIcon="book-open-outline" badgeText="{{this.MyActivitiesCount}}"
                badgeStatus="danger" >
                    <nb-card-header class="row">
                        <div class="col-md-8">Activites on: {{ selectedDate | date }}</div>
                        <div class="col-md-4">
                            Activities count: {{ activitiesForCurrentUser.length}}
                        </div>
                    </nb-card-header>
                    <nb-card-body>
                        <nb-card *ngFor="let activity of activitiesForCurrentUser" 
                                 [status]= "activity.isCompleted ? 'danger' : 'info'" 
                                 class="activity"
                                 (click)="openRelatedVisit(activity,dialogCheckVisit)">
                            <nb-card-header class="row">
                                <div style="text-align: center;" class="col-md-4">
                                    {{ activity.activityType }}
                                </div>
                                <div style="text-align: center;" class="col-md-4">
                                    Patient: {{ activity.patientName == "" ? "No patient!" : activity.patientName }}
                                </div>
                                <div style="text-align: center;" class="col-md-4">
                                    Completed: {{ activity.isCompleted ? "Yes" : "No" }}
                                </div>
                            </nb-card-header>
                        </nb-card>
                    </nb-card-body>
                </nb-tab>
            </nb-tabset>
            
        </nb-card>
    </div>
</div>
<dx-load-panel
shadingColor="rgba(0,0,0,0.6)"
[(visible)]="loadingVisible"
[showIndicator]="true"
[showPane]="true"
[shading]="true"
[height]="300"
[width]="800"
message='&#10102; Getting Client Visits ...  &#10103; Calculating User Activities ...'
#loadPanel>
</dx-load-panel>
<ng-template #dialogCheckVisit let-data let-ref="dialogRef">
    <ngx-checkvisit [relatedVisit]="relatedVisit" style="height: auto;">
    </ngx-checkvisit>
</ng-template> 
