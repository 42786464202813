import { Component, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import { CallService } from '../../../@core/app-services/call.service';
import { ToasterHelper } from '../../../@core/app-services/toasterHelper';
import { AuditCallModel } from '../../../@core/models/auditCallModel';
import { Call } from '../../../@core/models/call';
import { callReason } from '../../../@core/models/callReason';
import { LogCallModel } from '../../../@core/models/logCallModel';


@Component({
  selector: 'ngx-CallManager',
  templateUrl: './CallManager.component.html',
  styleUrls: ['./CallManager.component.scss']
})

export class CallManagerComponent implements OnInit {
  page: number = 1;
  pageSize: number = 8;
  getClientCalls: boolean = true;
  callReasons: callReason[] = new Array();
  calls: Call[] = new Array();
  patientId: number;
  description: string;
  comment: string;
  auditer: string;
  reason: string;
  followupDate:Date = new Date();
  logCallModel: LogCallModel = new LogCallModel();
  hasFollowup: boolean = false;
  savingAudi: boolean = false;
  addingCall: boolean = false;
  auditCallModel: AuditCallModel = new AuditCallModel();
  filterStatus: number = 0;
  filterReason: number = 0;
  dateFrom:Date = new Date();
  dateTo:Date = new Date();
  constructor(
    private toasterHelper: ToasterHelper,
    private callService: CallService,
    private dialogService: NbDialogService
  ) {
    callService.getCallReasons().subscribe((data) => {
      this.callReasons = data.callReasons;
    });
    this.getCalls();        
  }
  getCalls() {
    this.getClientCalls = true;
    this.callService.getPatientCalls(0).subscribe((data) => {
      this.calls = data;
      this.getClientCalls = false;
    });
  }
  openLogNewCall(dialog: TemplateRef<any>) {
    this.dialogService.open(dialog);
  }
  openCallDescription(dialog: TemplateRef<any>, call: Call) {
    this.description = call.log;
    this.comment = call.auditComment;
    this.auditer = call.auditer;
    this.followupDate = call.followupDate;
    this.reason = call.reasonName;
    this.dialogService.open(dialog);
  }
  editCallDialog(dialog: TemplateRef<any>, call: Call) {
    this.logCallModel.callId = call.id;
    this.logCallModel.log = call.log;
    this.logCallModel.followupDate = call.followupDate;
    this.logCallModel.reasonId = call.reasonId;
    this.dialogService.open(dialog);
  }
  openAuditCall(dialog: TemplateRef<any>, call: Call) {
    this.auditCallModel.callId = call.id;
    if(call.auditer == '')
      this.dialogService.open(dialog);
    else
      this.toasterHelper.showToast("Audited already","This call is Audited!","primary");
  }
  auditCall(ref) {
    this.savingAudi = true;
    this.callService.auditCall(this.auditCallModel).subscribe((data) => {
      if (data.status) {
        this.toasterHelper.showSaved(data.message);
        this.getCalls();
      } else {
        this.toasterHelper.showError(data.message);
      }
      this.savingAudi = false;
      ref.close();
    });
  }
  logCall(ref) {
    this.addingCall = true;
    if (!this.hasFollowup) {
      this.logCallModel.followupDate = null;
    }
    this.callService.logCall(this.logCallModel).subscribe((data) => {
      if (data.status) {
        this.toasterHelper.showSaved(data.message);
        this.getCalls();
      } else {
        this.toasterHelper.showError(data.message);
      }
      this.addingCall = false;
      ref.close();
    });
  }

  editCall(ref) {
    this.addingCall = true;
    if (!this.hasFollowup) {
      this.logCallModel.followupDate = null;
    }
    this.callService.editCall(this.logCallModel).subscribe((data) => {
      if (data.status) {
        this.toasterHelper.showSaved(data.message);
        this.getCalls();
      } else {
        this.toasterHelper.showError(data.message);
      }
      this.addingCall = false;
      ref.close();
    });
  }
  
  openFilterDialog(dialog: TemplateRef<any>) {
    this.dialogService.open(dialog);
  }
  filter(ref){
    this.getClientCalls = true;
    this.callService.filterCalls(0, this.dateFrom, this.dateTo, this.filterStatus,this.filterReason).subscribe(data=>{
      this.calls = data;
      this.getClientCalls = false;
      ref.close();
    })
  }
  ngOnInit(): void {}
}
