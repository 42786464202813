import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { PackageStructureService } from "../../../../@core/app-services/Package-structure.service";
import { PackageService } from "../../../../@core/app-services/package.service";
import { SessionTypeService } from "../../../../@core/app-services/session-type.service";
import { ToasterHelper } from "../../../../@core/app-services/toasterHelper";
import { Package } from "../../../../@core/models/package";
import { PackageStructure } from "../../../../@core/models/PackageStructure";
import { SessionType } from "../../../../@core/models/sessionType";

@Component({
  selector: "ngx-package-structure",
  templateUrl: "./package-structure.component.html",
  styleUrls: ["./package-structure.component.scss"],
})
export class PackageStructureComponent implements OnInit {
  packageStructures: PackageStructure[] = [];
  currentPackageStructures: PackageStructure[] = [];
  packageStructure: PackageStructure;
  sessionTypes: SessionType[];
  packages: Package[] = [];
  refreshMode: string;
  taskSubject: string;
  taskDetailsHtml: SafeHtml;
  taskStatus: string;
  taskProgress: string;
  currentFilter: any;
  showFilterRow: boolean = true;
  package: Package;
  showHeaderFilter: boolean = true;
  autoNavigateToFocusedRow = true;
  loadingPackageStructures: boolean = false;
  isDisabled: boolean = true;
  constructor(
    private packageService: PackageService,
    private packageStructureService: PackageStructureService,
    private sessionTypeService: SessionTypeService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private toasterHelper: ToasterHelper
  ) {}

  addPackageStructure(e) {
    this.loadingPackageStructures = true;
    let newPackageStructure = new PackageStructure();
    this.packageStructure = e.data;
    newPackageStructure.description = this.packageStructure.description;
    newPackageStructure.packageId = this.package.id;
    newPackageStructure.sessionSequence = this.packageStructure.sessionSequence;
    newPackageStructure.sessionTypeId = this.packageStructure.sessionTypeId;
    this.packageStructureService
      .addPackageStructure(newPackageStructure)
      .subscribe(
        (data) => {
          if (data.status) {
            this.toasterHelper.showToast(
              "Added successfully",
              data.message,
              "success"
            );
            this.packageStructure = data.responseObject;
          } else {
            this.toasterHelper.showError(data.message);
          }
          this.loadingPackageStructures = false;
          this.getPackageStructures();
        },
        (error) => {
          this.toasterHelper.showError("Something went wrong");
          this.loadingPackageStructures = false;
        }
      );
  }
  selectPackage(titleEn: string) {
    this.package = this.packages.find(
      (p) => p.titleEn.trim() == titleEn.trim()
    );
    this.getPackageStructures();
    this.isDisabled = false;
  }
  updatePackageStructure(e) {
    this.loadingPackageStructures = true;
    let updatedPackageStructure = new PackageStructure();
    this.packageStructure = e.data;
    updatedPackageStructure.description = this.packageStructure.description;
    updatedPackageStructure.packageId = this.package.id;
    updatedPackageStructure.sessionSequence =
      this.packageStructure.sessionSequence;
    updatedPackageStructure.sessionTypeId = this.packageStructure.sessionTypeId;
    this.packageStructureService
      .updatePackageStructure(updatedPackageStructure, this.packageStructure.id)
      .subscribe(
        (data) => {
          if (data.status) {
            this.toasterHelper.showToast(
              "Updated successfully",
              data.message,
              "success"
            );
            this.packageStructure = data.responseObject;
          } else {
            this.toasterHelper.showError(data.message);
          }
          this.loadingPackageStructures = false;
          this.getPackageStructures();
        },
        (error) => {
          this.toasterHelper.showError("Something went wrong");
          this.loadingPackageStructures = false;
        }
      );
  }
  deletePackageStructure(e) {
    this.loadingPackageStructures = true;

    this.packageStructure = e.data;
    this.packageStructureService
      .deletePackageStructure(this.packageStructure.id)
      .subscribe(
        (data) => {
          if (data.status) {
            this.toasterHelper.showToast(
              "Deleted successfully",
              data.message,
              "success"
            );
          } else {
            this.toasterHelper.showError(data.message);
          }
          this.loadingPackageStructures = false;
        },
        (error) => {
          this.toasterHelper.showError("Something went wrong");
          this.loadingPackageStructures = false;
        }
      );
  }
  getPackageStructures() {
    this.loadingPackageStructures = true;
    this.packageStructureService
      .getPackageStructures(this.package.id)
      .subscribe(
        (data) => {
          if (data.status) {
            this.packageStructures = data.responseObject;
          } else {
            this.packageStructures = [];
          }
          this.loadingPackageStructures = false;
        },
        (error) => {
          this.toasterHelper.showError("Something went wrong");
          this.loadingPackageStructures = false;
        }
      );
  }
  getSessionTypes() {
    this.sessionTypeService.getSessionTypes().subscribe(
      (data) => {
        if (data.status) {
          this.sessionTypes = data.responseObject;
        } else {
          this.sessionTypes = [];
        }
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
      }
    );
  }
  getPackages() {
    this.loadingPackageStructures = true;
    this.packageService.getPackages().subscribe(
      (data) => {
        if (data.status) {
          this.packages = data.responseObject;
        } else {
          this.packages = [];
        }
        this.loadingPackageStructures = false;
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
        this.loadingPackageStructures = false;
      }
    );
  }
  onFocusedRowChanging(e) {
    const rowsCount = e.component.getVisibleRows().length;
    const pageCount = e.component.pageCount();
    const pageIndex = e.component.pageIndex();
    const key = e.event && e.event.key;
    const rowData = e.row && e.row.data;
    if (key && e.prevRowIndex === e.newRowIndex) {
      if (e.newRowIndex === rowsCount - 1 && pageIndex < pageCount - 1) {
        e.component.pageIndex(pageIndex + 1).done(() => {
          e.component.option("focusedRowIndex", 0);
        });
      } else if (e.newRowIndex === 0 && pageIndex > 0) {
        e.component.pageIndex(pageIndex - 1).done(() => {
          e.component.option("focusedRowIndex", rowsCount - 1);
        });
      }
    }
  }
  onFocusedRowChanged(e) {
    const rowData = e.row && e.row.data;
    // this.rowPackage= rowData;
    if (rowData) {
      this.taskSubject = rowData.Task_Subject;
      this.taskDetailsHtml = this.sanitizer.bypassSecurityTrustHtml(
        rowData.Task_Description
      );
      this.taskStatus = rowData.Task_Status;
      this.taskProgress = rowData.Task_Completion
        ? `${rowData.Task_Completion}` + "%"
        : "";
    }
  }

  ngOnInit(): void {
    this.getPackages();
    this.getSessionTypes();
  }
}
