export class Exercise {
  id: number;
  animationUrl: string;
  videoUrl: string;
  nameEn: string;
  nameAr: string;
  descriptionEn: string;
  descriptionAr: string;
  hintEn: string;
  hintAr: string;
  repetitions: number;
  seconds: number;
  exerciseTypeId: number;
}
