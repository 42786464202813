<div class="row">
    <div class="col-md-12">
        <nb-card class="own-scroll">
            <nb-card-header>
                <div class="row">
                    <div class="col-md-10">
                        Set User availability
                    </div>
                </div>
            </nb-card-header>
        </nb-card>
        <nb-card class="own-scroll" [nbSpinner]="loadingUsers" nbSpinnerStatus="primary" nbSpinnerSize="large">

            <nb-card-body [nbSpinner]="loadingUsersAvailability" nbSpinnerStatus="primary" nbSpinnerSize="large">
                <div id="data-grid-demo">
                    <div>
                        <label>Choose a User</label>
                        <select #selectElem class="custom-select" (load)="selectUser(selectElem.value)"
                            (change)="selectUser(selectElem.value)">
                            <option disabled selected value>select a user</option>
                            <option *ngFor="let user of Users" ([ngValue])="user">
                                {{user?.fullName}}</option>
                        </select>
                        <hr>

                    </div>
                    <dx-data-grid #grid id="gridContainer" [dataSource]="UserAvailability" keyExpr="id"
                        [disabled]="isDisabled" [focusedRowEnabled]="true"
                        (onFocusedRowChanging)="onFocusedRowChanging($event)"
                        (onRowInserted)="AddAvailabilityRow($event)" (onFocusedRowChanged)="onFocusedRowChanged($event)"
                        [showBorders]="true">
                        <dxo-paging [enabled]="false"></dxo-paging>
                        <dxo-editing mode="cell" [refreshMode]="refreshMode" [allowAdding]="true" [allowUpdating]="true"
                            [allowDeleting]="true">
                        </dxo-editing>
                        <dxo-filter-row [visible]="showFilterRow" [applyFilter]="currentFilter"></dxo-filter-row>
                        <dxo-header-filter [visible]="showHeaderFilter"></dxo-header-filter>
                        <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>
                        <dxi-column dataField="branchId" caption="Branch" [width]="125">
                            <dxo-lookup [dataSource]="Branches" displayExpr="nameEn" valueExpr="id">
                            </dxo-lookup>
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dxi-column>
                        <dxi-column dataField="day" caption="Day" [width]="125">
                            <dxo-lookup [dataSource]="days" displayExpr="name" valueExpr="id">
                            </dxo-lookup>
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dxi-column>
                        <dxi-column dataField="from" dataType='datetime' [editorOptions]="{ type: 'time' }"
                            format='shortTime'>
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dxi-column>
                        <dxi-column dataField="to" dataType='datetime' [editorOptions]="{ type: 'time' }"
                            format='shortTime'>
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dxi-column>
                        <dxi-column caption="Move availability" type="buttons">
                            <dxi-button hint="Move" text="Move" [onClick]="moveAvailability">
                            </dxi-button>
                            <dxi-button hint="Increase" text="increase" [onClick]="increaseAvailability">
                            </dxi-button>
                            <dxi-button name="delete" [onClick]="WarningDeleteAvailabilityRow">
                            </dxi-button>
                        </dxi-column>


                    </dx-data-grid>
                </div>
            </nb-card-body>
        </nb-card>



    </div>
</div>



<div class="dialog-box" *ngIf="isDeleteDialogShowed">

    <div class="modal-content">
        <div>
            <button (click)="closeDialog()" class="close">&times;</button>
        </div>
        <div>
            <div> Are you sure to delete this record<span class="h4" *ngIf="upComingSessions.length> 0"
                    style="color: red;"> Warning!! you will delete these sessions</span></div>

        </div>
        <div class="flex-container">
            <div class="item border border-2 rounded p-3" *ngFor="let upComingSession of upComingSessions">
                <p><strong>Id: </strong>{{upComingSession?.id}}</p>
                <p><strong>from: </strong>{{upComingSession?.from}}</p>
                <p><strong>to:</strong> {{upComingSession?.to}}</p>
                <p><strong>ApplicationUser: </strong>{{upComingSession?.applicationUser}}</p>
            </div>
            <div *ngIf="upComingSessions.length==0">there is no Sessions to delete</div>
        </div>
        <div>
            <button (click)="DeleteAvailabilityRow()" class="btn btn-danger">Delete</button>
            <button (click)="closeDialog()" class="btn btn-outline-primary">Cancel</button>

        </div>
    </div>
</div>