export class Meal {
  id: number;
  nameAr: string;
  nameEn: string;
  imageUrl: string;
  descriptionAr: string;
  descriptionEn: string;
  recipeAr: string;
  recipeEn: string;
  calories: number;
  proteins: number;
  weight: number;
  fats: number;
  carb: number;
  cookingMinutes: number;
  mealTypeId: number;
}
