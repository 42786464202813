import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { InterestService } from "../../../../@core/app-services/interest.service";
import { ToasterHelper } from "../../../../@core/app-services/toasterHelper";
import { Interest } from "../../../../@core/models/interest";

@Component({
  selector: "ngx-interest",
  templateUrl: "./interest.component.html",
  styleUrls: ["./interest.component.scss"],
})
export class InterestComponent implements OnInit {
  interests: Interest[] = [];
  interest: Interest = new Interest();

  refreshMode: string;
  taskSubject: string;
  taskDetailsHtml: SafeHtml;
  taskStatus: string;
  taskProgress: string;
  currentFilter: any;
  showFilterRow: boolean = true;
  showHeaderFilter: boolean = true;
  autoNavigateToFocusedRow = true;
  loadingInterests: boolean = false;

  constructor(
    private sanitizer: DomSanitizer,
    private interestService: InterestService,
    private toasterHelper: ToasterHelper
  ) {}

  getInterests() {
    this.interestService.getInterests().subscribe(
      (data) => {
        if (data.status) {
          this.interests = data.responseObject;
        } else {
          this.interests = [];
        }
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
      }
    );
  }

  addInterest(e) {
    this.loadingInterests = true;

    let newInterest = new Interest();
    this.interest = e.data;
    newInterest.nameEn = this.interest.nameEn;
    newInterest.nameAr = this.interest.nameAr;
    newInterest.isActive = this.interest.isActive;

    this.interestService.addInterest(newInterest).subscribe(
      (data) => {
        if (data.status) {
          this.toasterHelper.showToast(
            "Added successfully",
            data.message,
            "success"
          );
        } else {
          this.toasterHelper.showError(data.message);
        }
        this.loadingInterests = false;

        this.getInterests();
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
        this.loadingInterests = false;
      }
    );
  }

  updateInterest(e) {
    this.loadingInterests = true;
    let updatedInterest = new Interest();
    this.interest = e.data;
    updatedInterest.nameEn = this.interest.nameEn;
    updatedInterest.nameAr = this.interest.nameAr;
    updatedInterest.isActive = this.interest.isActive;

    this.interestService
      .updateInterest(this.interest.id, updatedInterest)
      .subscribe(
        (data) => {
          if (data.status) {
            this.toasterHelper.showToast(
              "updated successfully",
              data.message,
              "success"
            );
          } else {
            this.toasterHelper.showError(data.message);
          }
          this.loadingInterests = false;

          this.getInterests();
        },
        (error) => {
          this.toasterHelper.showError("Something went wrong");
          this.loadingInterests = false;
        }
      );
  }

  deleteInterest(e) {
    this.loadingInterests = true;
    this.interest = e.data;
    this.interestService.deleteInterest(this.interest.id).subscribe(
      (data) => {
        if (data.status) {
          this.toasterHelper.showToast(
            "Deleted successfully",
            data.message,
            "success"
          );
        } else {
          this.toasterHelper.showError(data.message);
        }
        this.loadingInterests = false;
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
        this.loadingInterests = false;
      }
    );
  }

  onFocusedRowChanging(e) {
    const rowsCount = e.component.getVisibleRows().length;
    const pageCount = e.component.pageCount();
    const pageIndex = e.component.pageIndex();
    const key = e.event && e.event.key;
    const rowData = e.row && e.row.data;
    if (key && e.prevRowIndex === e.newRowIndex) {
      if (e.newRowIndex === rowsCount - 1 && pageIndex < pageCount - 1) {
        e.component.pageIndex(pageIndex + 1).done(() => {
          e.component.option("focusedRowIndex", 0);
        });
      } else if (e.newRowIndex === 0 && pageIndex > 0) {
        e.component.pageIndex(pageIndex - 1).done(() => {
          e.component.option("focusedRowIndex", rowsCount - 1);
        });
      }
    }
  }
  onFocusedRowChanged(e) {
    const rowData = e.row && e.row.data;
    // this.rowPackage= rowData;
    if (rowData) {
      this.taskSubject = rowData.Task_Subject;
      this.taskDetailsHtml = this.sanitizer.bypassSecurityTrustHtml(
        rowData.Task_Description
      );
      this.taskStatus = rowData.Task_Status;
      this.taskProgress = rowData.Task_Completion
        ? `${rowData.Task_Completion}` + "%"
        : "";
    }
  }

  ngOnInit(): void {
    this.getInterests();
  }
}
