import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { ApplicationUserService } from "../../../../@core/app-services/application-user.service";
import { ExerciseService } from "../../../../@core/app-services/exercise.service";
import { SubscriptionExerciseService } from "../../../../@core/app-services/subscription-exercise.service";
import { SubscriptionService } from "../../../../@core/app-services/subscription.service";
import { ToasterHelper } from "../../../../@core/app-services/toasterHelper";
import { AddSubscriptionExercise } from "../../../../@core/models/addSubscriptionExercise";
import { ApplicationUser } from "../../../../@core/models/applicationUser";
import { Exercise } from "../../../../@core/models/exercise";
import { ExerciseType } from "../../../../@core/models/ExerciseType";
import { GetSubscriptionExercise } from "../../../../@core/models/getSubscriptionExercise";
import { UpdateSubscriptionExercise } from "../../../../@core/models/updateSubsciptionExercise";

@Component({
  selector: "ngx-subscription-exercise",
  templateUrl: "./subscription-exercise.component.html",
  styleUrls: ["./subscription-exercise.component.scss"],
})
export class SubscriptionExerciseComponent implements OnInit {
  subscriptionExercises: GetSubscriptionExercise[] = [];
  subscriptionExercise: GetSubscriptionExercise;
  exercises: Exercise[];
  exerciseTypes: ExerciseType[];
  appUsers: ApplicationUser[];
  appUser: ApplicationUser;
  refreshMode: string;
  taskSubject: string;
  taskDetailsHtml: SafeHtml;
  taskStatus: string;
  taskProgress: string;
  appUserId: number;
  currentFilter: any;
  showFilterRow: boolean = true;
  subscription: Subscription;
  showHeaderFilter: boolean = true;
  autoNavigateToFocusedRow = true;
  isDisabled: boolean = true;
  loadingSubscriptionExercises: boolean = false;
  constructor(
    private applicationUserService: ApplicationUserService,
    private subscriptionExerciseService: SubscriptionExerciseService,
    private ExerciseService: ExerciseService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private route: ActivatedRoute,
    private toasterHelper: ToasterHelper
  ) {
    route.queryParams.subscribe(
      async (params) => {
        this.appUserId = this.route.snapshot.params.id;
        if (this.appUserId != 0) {
          await this.applicationUserService
            .getApplicationUserById(this.appUserId)
            .subscribe(
              async (data) => {
                if (data.status) {
                  this.appUser = data.responseObject;
                  await this.getSubscriptionExercises(this.appUserId);
                } else {
                  this.appUser = new ApplicationUser();
                }
              },
              (error) => {
                toasterHelper.showError(error);
              }
            );
        }
      },
      (error) => {
        toasterHelper.showError(error);
      }
    );
  }

  getExercises() {
    this.ExerciseService.getExercises().subscribe(
      (data) => {
        this.exercises = data.responseObject;
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
      }
    );
  }

  // Subscription Exercises crud operations
  getSubscriptionExercises(appUserId: number) {
    this.loadingSubscriptionExercises = true;
    this.subscriptionExerciseService
      .getSubscriptionExercisesByApplicationUserId(appUserId)
      .subscribe(
        (data) => {
          if (data.status) {
            this.subscriptionExercises = data.responseObject;
          } else {
            this.subscriptionExercises = [];
          }
          this.loadingSubscriptionExercises = false;
        },
        (error) => {
          this.toasterHelper.showError("Something went wrong");
          this.loadingSubscriptionExercises = false;
        }
      );
  }

  addSubscriptionExercise(e) {
    this.loadingSubscriptionExercises = true;
    let newSubscriptionExercise = new AddSubscriptionExercise();
    this.subscriptionExercise = e.data;
    newSubscriptionExercise.dateNumber = this.subscriptionExercise.dateNumber;
    newSubscriptionExercise.exerciseId = this.subscriptionExercise.exercise.id;
    newSubscriptionExercise.nameAr = this.subscriptionExercise.nameAr;
    newSubscriptionExercise.nameEn = this.subscriptionExercise.nameEn;
    newSubscriptionExercise.sequance = this.subscriptionExercise.sequance;
    newSubscriptionExercise.appUserId = this.appUser.id;

    this.subscriptionExerciseService
      .addSubscriptionExercise(newSubscriptionExercise)
      .subscribe(
        (data) => {
          if (data.status) {
            this.toasterHelper.showToast(
              "Added successfully",
              data.message,
              "success"
            );
          } else {
            this.toasterHelper.showError(data.message);
          }
          this.loadingSubscriptionExercises = false;

          this.getSubscriptionExercises(this.appUser.id);
        },
        (error) => {
          this.toasterHelper.showError("Something went wrong");
          this.loadingSubscriptionExercises = false;
        }
      );
  }

  updateSubscriptionExercise(e) {
    this.loadingSubscriptionExercises = true;
    let updatedSubscriptionExercise = new UpdateSubscriptionExercise();
    this.subscriptionExercise = e.data;
    updatedSubscriptionExercise.dateNumber =
      this.subscriptionExercise.dateNumber;
    updatedSubscriptionExercise.exerciseId =
      this.subscriptionExercise.exercise.id;
    updatedSubscriptionExercise.nameAr = this.subscriptionExercise.nameAr;
    updatedSubscriptionExercise.nameEn = this.subscriptionExercise.nameEn;
    updatedSubscriptionExercise.sequance = this.subscriptionExercise.sequance;
    updatedSubscriptionExercise.subscriptionId =
      this.subscriptionExercise.subscriptionId;

    this.subscriptionExerciseService
      .updateSubscriptionExercise(
        this.subscriptionExercise.id,
        updatedSubscriptionExercise
      )
      .subscribe(
        (data) => {
          if (data.status) {
            this.toasterHelper.showToast(
              "Updated successfully",
              data.message,
              "success"
            );
          } else {
            this.toasterHelper.showError(data.message);
          }
          this.loadingSubscriptionExercises = false;

          this.getSubscriptionExercises(this.appUser.id);
        },
        (error) => {
          this.toasterHelper.showError("Something went wrong");
          this.loadingSubscriptionExercises = false;
        }
      );
  }

  deleteSubscriptionExercise(e) {
    this.loadingSubscriptionExercises = true;
    this.subscriptionExercise = e.data;
    this.subscriptionExerciseService
      .deleteSubscriptionExercise(this.subscriptionExercise.id)
      .subscribe(
        (data) => {
          if (data.status) {
            this.toasterHelper.showToast(
              "Deleted successfully",
              data.message,
              "success"
            );
          } else {
            this.toasterHelper.showError(data.message);
          }
          this.loadingSubscriptionExercises = false;
        },
        (error) => {
          this.toasterHelper.showError("Something went wrong");
          this.loadingSubscriptionExercises = false;
        }
      );
  }

  onFocusedRowChanging(e) {
    const rowsCount = e.component.getVisibleRows().length;
    const pageCount = e.component.pageCount();
    const pageIndex = e.component.pageIndex();
    const key = e.event && e.event.key;
    const rowData = e.row && e.row.data;
    if (key && e.prevRowIndex === e.newRowIndex) {
      if (e.newRowIndex === rowsCount - 1 && pageIndex < pageCount - 1) {
        e.component.pageIndex(pageIndex + 1).done(() => {
          e.component.option("focusedRowIndex", 0);
        });
      } else if (e.newRowIndex === 0 && pageIndex > 0) {
        e.component.pageIndex(pageIndex - 1).done(() => {
          e.component.option("focusedRowIndex", rowsCount - 1);
        });
      }
    }
  }
  onFocusedRowChanged(e) {
    const rowData = e.row && e.row.data;
    // this.rowPackage= rowData;
    if (rowData) {
      this.taskSubject = rowData.Task_Subject;
      this.taskDetailsHtml = this.sanitizer.bypassSecurityTrustHtml(
        rowData.Task_Description
      );
      this.taskStatus = rowData.Task_Status;
      this.taskProgress = rowData.Task_Completion
        ? `${rowData.Task_Completion}` + "%"
        : "";
    }
  }

  ngOnInit(): void {
    this.getExercises();
  }
}
