import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { ApplicationUserService } from "../../../../@core/app-services/application-user.service";
import { MealService } from "../../../../@core/app-services/meal.service";
import { SubscriptionMealService } from "../../../../@core/app-services/subscription-meal.service";
import { ToasterHelper } from "../../../../@core/app-services/toasterHelper";
import { AddSubscriptionMeal } from "../../../../@core/models/addSubscriptionMeal";
import { ApplicationUser } from "../../../../@core/models/applicationUser";
import { GetSubscriptionMeal } from "../../../../@core/models/getSubscriptionMeal";
import { Meal } from "../../../../@core/models/meal";
import { MealType } from "../../../../@core/models/mealType";
import { Subscription } from "../../../../@core/models/subscription";
import { UpdateSubscriptionMeal } from "../../../../@core/models/updateSubscriptionMeal";

@Component({
  selector: "ngx-subscription-meal",
  templateUrl: "./subscription-meal.component.html",
  styleUrls: ["./subscription-meal.component.scss"],
})
export class SubscriptionMealComponent implements OnInit {
  subscriptionMeals: GetSubscriptionMeal[] = [];
  subscriptionMeal: GetSubscriptionMeal;
  meals: Meal[] = [];
  mealTypes: MealType[];
  appUsers: ApplicationUser[];
  appUser: ApplicationUser;
  refreshMode: string;
  taskSubject: string;
  taskDetailsHtml: SafeHtml;
  taskStatus: string;
  taskProgress: string;
  currentFilter: any;
  showFilterRow: boolean = true;
  subscription: Subscription;
  showHeaderFilter: boolean = true;
  autoNavigateToFocusedRow = true;
  isDisabled: boolean = true;
  loadingSubscriptionMeals: boolean = false;
  appUserId: number;
  constructor(
    private applicationUserService: ApplicationUserService,
    private subscriptionMealService: SubscriptionMealService,
    private mealService: MealService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private route: ActivatedRoute,
    private toasterHelper: ToasterHelper
  ) {
    route.queryParams.subscribe(
      async (params) => {
        this.appUserId = this.route.snapshot.params.id;
        if (this.appUserId != 0) {
          await this.applicationUserService
            .getApplicationUserById(this.appUserId)
            .subscribe(
              async (data) => {
                if (data.status) {
                  this.appUser = data.responseObject;
                  await this.getSubscriptionMeals();
                } else {
                  this.appUser = new ApplicationUser();
                }
              },
              (error) => {
                toasterHelper.showError(error);
              }
            );
        }
      },
      (error) => {
        toasterHelper.showError(error);
      }
    );
  }
  getAppUsers() {
    this.applicationUserService
      .getApplicationUsersHaveSubscriptions()
      .subscribe((data) => {
        this.appUsers = data.responseObject;
        console.log(this.appUsers);
      });
  }
  getMeals() {
    this.mealService.getMeals().subscribe(
      (data) => {
        if (data.status) {
          this.meals = data.responseObject;
        } else {
          this.meals = [];
        }
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
      }
    );
  }
  selectAppUser(fullName: string) {
    this.appUser = this.appUsers.find(
      (p) => p.fullName.trim() == fullName.trim()
    );
    this.getSubscriptionMeals();
    this.isDisabled = false;
  }

  // subscription meals crud operations
  getSubscriptionMeals() {
    this.loadingSubscriptionMeals = true;
    this.subscriptionMealService
      .getSubscriptionMealsByApplicationUserId(this.appUser.id)
      .subscribe(
        (data) => {
          if (data.status) {
            this.subscriptionMeals = data.responseObject;
          } else {
            this.subscriptionMeals = [];
          }
          this.loadingSubscriptionMeals = false;
        },
        (error) => {
          this.toasterHelper.showError("Something went wrong");
          this.loadingSubscriptionMeals = false;
        }
      );
  }

  addSubscriptionMeal(e) {
    this.loadingSubscriptionMeals = true;
    let newSubscriptionMeal = new AddSubscriptionMeal();
    this.subscriptionMeal = e.data;
    newSubscriptionMeal.dateNumber = this.subscriptionMeal.dateNumber;
    newSubscriptionMeal.mealId = this.subscriptionMeal.meal.id;
    newSubscriptionMeal.nameAr = this.subscriptionMeal.nameAr;
    newSubscriptionMeal.nameEn = this.subscriptionMeal.nameEn;
    newSubscriptionMeal.appUserId = this.appUser.id;
    newSubscriptionMeal.Sequance = this.subscriptionMeal.sequance;
    this.subscriptionMealService
      .addSubscriptionMeal(newSubscriptionMeal)
      .subscribe(
        (data) => {
          if (data.status) {
            this.toasterHelper.showToast(
              "Added successfully",
              data.message,
              "success"
            );
          } else {
            this.toasterHelper.showError(data.message);
          }
          this.loadingSubscriptionMeals = false;

          this.getSubscriptionMeals();
        },
        (error) => {
          this.toasterHelper.showError("Something went wrong");
          this.loadingSubscriptionMeals = false;
        }
      );
  }

  updateSubscriptionMeal(e) {
    this.loadingSubscriptionMeals = true;
    let updatedSubscriptionMeal = new UpdateSubscriptionMeal();
    this.subscriptionMeal = e.data;
    updatedSubscriptionMeal.dateNumber = this.subscriptionMeal.dateNumber;
    updatedSubscriptionMeal.mealId = this.subscriptionMeal.meal.id;
    updatedSubscriptionMeal.nameAr = this.subscriptionMeal.nameAr;
    updatedSubscriptionMeal.nameEn = this.subscriptionMeal.nameEn;
    updatedSubscriptionMeal.subscriptionId =
      this.subscriptionMeal.subscriptionId;
    updatedSubscriptionMeal.sequance = this.subscriptionMeal.sequance;

    this.subscriptionMealService
      .updateSubscriptionMeal(this.subscriptionMeal.id, updatedSubscriptionMeal)
      .subscribe(
        (data) => {
          if (data.status) {
            this.toasterHelper.showToast(
              "Updated successfully",
              data.message,
              "success"
            );
          } else {
            this.toasterHelper.showError(data.message);
          }
          this.loadingSubscriptionMeals = false;

          this.getSubscriptionMeals();
        },
        (error) => {
          this.toasterHelper.showError("Something went wrong");
          this.loadingSubscriptionMeals = false;
        }
      );
  }

  deleteSubscriptionMeal(e) {
    this.loadingSubscriptionMeals = true;
    this.subscriptionMeal = e.data;
    this.subscriptionMealService
      .deleteSubscriptionMeal(this.subscriptionMeal.id)
      .subscribe(
        (data) => {
          if (data.status) {
            this.toasterHelper.showToast(
              "Deleted successfully",
              data.message,
              "success"
            );
          } else {
            this.toasterHelper.showError(data.message);
          }
          this.loadingSubscriptionMeals = false;
        },
        (error) => {
          this.toasterHelper.showError("Something went wrong");
          this.loadingSubscriptionMeals = false;
        }
      );
  }

  onFocusedRowChanging(e) {
    const rowsCount = e.component.getVisibleRows().length;
    const pageCount = e.component.pageCount();
    const pageIndex = e.component.pageIndex();
    const key = e.event && e.event.key;
    const rowData = e.row && e.row.data;
    if (key && e.prevRowIndex === e.newRowIndex) {
      if (e.newRowIndex === rowsCount - 1 && pageIndex < pageCount - 1) {
        e.component.pageIndex(pageIndex + 1).done(() => {
          e.component.option("focusedRowIndex", 0);
        });
      } else if (e.newRowIndex === 0 && pageIndex > 0) {
        e.component.pageIndex(pageIndex - 1).done(() => {
          e.component.option("focusedRowIndex", rowsCount - 1);
        });
      }
    }
  }
  onFocusedRowChanged(e) {
    const rowData = e.row && e.row.data;
    // this.rowPackage= rowData;
    if (rowData) {
      this.taskSubject = rowData.Task_Subject;
      this.taskDetailsHtml = this.sanitizer.bypassSecurityTrustHtml(
        rowData.Task_Description
      );
      this.taskStatus = rowData.Task_Status;
      this.taskProgress = rowData.Task_Completion
        ? `${rowData.Task_Completion}` + "%"
        : "";
    }
  }

  ngOnInit(): void {
    this.getAppUsers();
    this.getMeals();
  }
}
