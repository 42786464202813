export class ApplicationUser {
  id: number;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  phone: string;
  password: string;
  gender: number;
  languageCode: number;
  birthdate: Date;
  htmlContent: string;
  OtpNumber: number;
  height: number;
  weight: number;
}
