import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { environment } from "../../../environments/environment";
import { ActivityTypeCategory } from "../models/activityTypeCategory";
import { ActivityType } from "../models/activityType";
import { OperationPlanType } from "../models/operationPlanType";
import { UserType } from "../models/userType";
import { MilestoneTemplate } from "../models/milestoneTemplate";
import { VisitTemplate } from "../models/visitTemplate";
import { ActivityTemplate } from "../models/activityTemplate";
import { TransactionType } from "../models/TransactionType";

@Injectable({
  providedIn: "root",
})
export class DataService {
  private getUserTypesPath = environment.apiUrl + "DataManager/getUserTypes";

  private getActivityTypesPath =
    environment.apiUrl + "DataManager/GetActivityTypes";
  private addOrUpdateActivityTypePath =
    environment.apiUrl + "DataManager/addOrUpdateActivityType";
  private deleteActivityTypePath =
    environment.apiUrl + "DataManager/DeleteActivityType?activityTypeId=";

  private getSpendTypesPath = environment.apiUrl + "DataManager/getSpendTypes";
  private addOrUpdateSpendTypePath =
    environment.apiUrl + "DataManager/addOrUpdateSpendType";
  private deleteSpendTypePath =
    environment.apiUrl + "DataManager/deleteSpendType?spendTypeId=";

  private getActivityTypeCategoriesPath =
    environment.apiUrl + "DataManager/getActivityTypeCategories";
  private addOrUpdateActivityTypeCategoryPath =
    environment.apiUrl + "DataManager/addOrUpdateActivityTypeCategory";
  private deleteActivityTypeCategoryPath =
    environment.apiUrl + "DataManager/deleteActivityTypeCategory?categoryId=";

  private getOperationPlanTypesPath =
    environment.apiUrl + "DataManager/getOperationPlanTypes";
  private addOrUpdateOperationPlanTypePath =
    environment.apiUrl + "DataManager/addOrUpdateOperationPlanType";
  private deleteOperationPlanTypePath =
    environment.apiUrl + "DataManager/DeleteOperationPlanType?typeId=";

  private getOperationPlanTypeMilestonesPath =
    environment.apiUrl + "DataManager/GetOperationPlanTypeMilestones?planId=";
  private addOrUpdateMilestoneTemplatePath =
    environment.apiUrl + "DataManager/addOrUpdateMilestoneTemplate";
  private deleteMilestoneTemplatePath =
    environment.apiUrl + "DataManager/deleteMilestoneTemplate?milestoneId=";

  private getMilestoneTemplateVisitsPath =
    environment.apiUrl + "DataManager/GetMilestoneTemplateVisits?milestoneId=";
  private addOrUpdateVisitTemplatePath =
    environment.apiUrl + "DataManager/AddOrUpdateVisitTemplate";
  private deleteVisitTemplatePath =
    environment.apiUrl + "DataManager/DeleteVisitTemplate?visitId=";

  private getVisitTemplateActivitiesPath =
    environment.apiUrl + "DataManager/GetVisitTemplateActivities?visitId=";
  private addOrUpdateVisitTemplateActivityPath =
    environment.apiUrl + "DataManager/AddOrUpdateVisitTemplateActivity";
  private deleteVisitTemplateActivityPath =
    environment.apiUrl + "DataManager/DeleteVisitTemplateActivity?activityId=";

  constructor(private http: HttpClient) {}

  getUserTypes(): Observable<UserType[]> {
    return this.http.get<UserType[]>(this.getUserTypesPath);
  }

  getActivityTypes(): Observable<ActivityType[]> {
    return this.http.get<ActivityType[]>(this.getActivityTypesPath);
  }
  addOrUpdateActivityType(model: ActivityType): Observable<any> {
    return this.http.post<any>(this.addOrUpdateActivityTypePath, model);
  }
  deleteActivityType(activityTypeId: number): Observable<any> {
    return this.http.post<any>(
      this.deleteActivityTypePath + activityTypeId,
      ""
    );
  }

  getSpendTypes(): Observable<TransactionType[]> {
    return this.http.get<TransactionType[]>(this.getSpendTypesPath);
  }
  addOrUpdateSpendType(model: TransactionType): Observable<any> {
    return this.http.post<any>(this.addOrUpdateSpendTypePath, model);
  }
  deleteSpendType(spendTypeId: number): Observable<any> {
    return this.http.post<any>(this.deleteSpendTypePath + spendTypeId, "");
  }

  getActivityTypeCategories(): Observable<ActivityTypeCategory[]> {
    return this.http.get<ActivityTypeCategory[]>(
      this.getActivityTypeCategoriesPath
    );
  }
  addOrUpdateActivityTypeCategory(
    model: ActivityTypeCategory
  ): Observable<any> {
    return this.http.post<any>(this.addOrUpdateActivityTypeCategoryPath, model);
  }
  deleteActivityTypeCategory(categoryId: number): Observable<any> {
    return this.http.post<any>(
      this.deleteActivityTypeCategoryPath + categoryId,
      ""
    );
  }

  getOperationPlanTypes(): Observable<OperationPlanType[]> {
    return this.http.get<OperationPlanType[]>(this.getOperationPlanTypesPath);
  }
  addOrUpdateOperationPlanType(model: OperationPlanType): Observable<any> {
    return this.http.post<any>(this.addOrUpdateOperationPlanTypePath, model);
  }
  deleteOperationPlanType(categoryId: number): Observable<any> {
    return this.http.post<any>(
      this.deleteOperationPlanTypePath + categoryId,
      ""
    );
  }

  getOperationPlanTypeMilestones(
    planId: number
  ): Observable<MilestoneTemplate[]> {
    return this.http.get<MilestoneTemplate[]>(
      this.getOperationPlanTypeMilestonesPath + planId
    );
  }
  addOrUpdateMilestoneTemplate(model: MilestoneTemplate): Observable<any> {
    return this.http.post<any>(this.addOrUpdateMilestoneTemplatePath, model);
  }
  deleteMilestoneTemplate(milestoneId: number): Observable<any> {
    return this.http.post<any>(
      this.deleteMilestoneTemplatePath + milestoneId,
      ""
    );
  }

  getMilestoneTemplateVisits(milestoneId: number): Observable<VisitTemplate[]> {
    return this.http.get<VisitTemplate[]>(
      this.getMilestoneTemplateVisitsPath + milestoneId
    );
  }
  addOrUpdateVisitTemplate(model: VisitTemplate): Observable<any> {
    return this.http.post<any>(this.addOrUpdateVisitTemplatePath, model);
  }
  deleteVisitTemplate(visitId: number): Observable<any> {
    return this.http.post<any>(this.deleteVisitTemplatePath + visitId, "");
  }

  getVisitTemplateActivities(visitId: number): Observable<ActivityTemplate[]> {
    return this.http.get<ActivityTemplate[]>(
      this.getVisitTemplateActivitiesPath + visitId
    );
  }
  addOrUpdateVisitTemplateActivity(model: ActivityTemplate): Observable<any> {
    return this.http.post<any>(
      this.addOrUpdateVisitTemplateActivityPath,
      model
    );
  }
  deleteVisitTemplateActivity(activityId: number): Observable<any> {
    return this.http.post<any>(
      this.deleteVisitTemplateActivityPath + activityId,
      ""
    );
  }
}
