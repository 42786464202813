export class LineChartModel{
  name:string;
  value:string;
}
export class Visit {
  id: number;
  doctorId: string;
  doctor: string;
  patient: string;
  patientId: number;
  bodyProfileInstanceId: number | null;
  milestoneId: number;
  date: Date;
  startTime: Date;
  endTime: Date;
  status: string;
  photosUrls: string;
  weight:number;
  totalBodyWater:number;
  bodyFatMass :number;
  smm :number;
  bmi :number;
  pbf :number;
  bmr :number;
  whr :number;
  vfl :number;
}
