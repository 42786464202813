import { Component, OnInit, TemplateRef } from "@angular/core";
import { NbDialogService } from "@nebular/theme";
import { VisitService } from "../../../@core/app-services/visit.service";
import { Visit } from "../../../@core/models/visit";

@Component({
  selector: "ngx-receptionist",
  templateUrl: "./receptionist.component.html",
  styleUrls: ["./receptionist.component.scss"],
})
export class ReceptionistComponent implements OnInit {
  visits: Visit[] = new Array();
  selectedVisit:Visit;
  constructor(private visitService: VisitService, private dialogService:NbDialogService) {
    // visitService.getVisitsToReceptionist().subscribe((data) => {
    //   this.visits = data;
    // });
  }
  openVisit(visit, dialog: TemplateRef<any>) {
    this.selectedVisit = visit;
    this.dialogService.open(dialog);
  }
  ngOnInit(): void {}
}
