import { Component, OnInit, ViewChild } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { DxDataGridComponent } from "devextreme-angular";
import { ApplicationUserService } from "../../../../@core/app-services/application-user.service";
import { BranchService } from "../../../../@core/app-services/branch.service";
import { IdentityService } from "../../../../@core/app-services/identity.service";
import { SessionTypeService } from "../../../../@core/app-services/session-type.service";
import { SessionService } from "../../../../@core/app-services/session.service";
import { ToasterHelper } from "../../../../@core/app-services/toasterHelper";
import { AddSession } from "../../../../@core/models/addSession";
import { ApplicationUser } from "../../../../@core/models/applicationUser";
import { Branch } from "../../../../@core/models/Branch";
import { GetSubscription } from "../../../../@core/models/getSubscription";
import { Session } from "../../../../@core/models/session";
import { SessionStatus } from "../../../../@core/models/sessionStatus";
import { SessionType } from "../../../../@core/models/sessionType";
import { TimeSlot } from "../../../../@core/models/timeSlot";
import { UpdateSession } from "../../../../@core/models/updateSession";
import { UserModel } from "../../../../@core/models/userModel";

@Component({
  selector: "ngx-session",
  templateUrl: "./session.component.html",
  styleUrls: ["./session.component.scss"],
})
export class SessionComponent implements OnInit {
  @ViewChild("dataGridRef", { static: false }) dataGrid: DxDataGridComponent;
  selectedRowsData: TimeSlot[] = [];
  appUsers: ApplicationUser[] = [];
  appUser: ApplicationUser = null;
  subscription: GetSubscription;
  session: Session;
  sessions: Session[] = [];
  timeSlot: TimeSlot = null;
  timeSlots: TimeSlot[] = [];
  doctor: UserModel;
  doctors: UserModel[] = [];
  refreshMode: string;
  taskSubject: string;
  taskDetailsHtml: SafeHtml;
  taskStatus: string;
  taskProgress: string;
  currentFilter: any;
  rowKey;
  showFilterRow: boolean = true;
  showHeaderFilter: boolean = true;
  autoNavigateToFocusedRow = true;
  isDisabled: boolean = true;
  sessionTypes: SessionType[] = [];
  sessionType: SessionType = null;
  isChooseAUser: boolean = false;
  loadingGetSlots: boolean = false;
  loadingAppUsers: boolean = false;
  loadingSessions: boolean = false;
  isChooseASessionType: boolean = false;
  userFullName: string = "";
  doctorFullName: string = "";
  sessionTypeName: string = "";
  branchName: string = "";
  appUserId: number;
  DayOfSlots: Date = new Date();
  minDate: string = "";
  readonly allowedPageSizes = [5, 10, "all"];
  branches: Branch[] = [];
  branch: Branch;

  // readonly displayModes = [{ text: "Display Mode 'full'", value: 'full' }, { text: "Display Mode 'compact'", value: 'compact' }];

  displayMode = "full";

  showPageSizeSelector = true;

  showInfo = true;

  showNavButtons = true;

  sessionStatuses: SessionStatus[] = [
    {
      id: 1,
      name: "UpComing",
    },
    {
      id: 2,
      name: "Confirmed",
    },
    {
      id: 3,
      name: "Missed",
    },
  ];
  constructor(
    private route: ActivatedRoute,
    private sessionService: SessionService,
    private identityService: IdentityService,
    private applicationUserService: ApplicationUserService,
    private toasterHelper: ToasterHelper,
    private branchService: BranchService,
    private router: Router,
    private sanitizer: DomSanitizer,
    private sessionTypeService: SessionTypeService
  ) {
    this.getSelectedData = this.getSelectedData.bind(this);
    route.queryParams.subscribe(
      async (params) => {
        this.appUserId = this.route.snapshot.params.id;
        if (this.appUserId != 0) {
          await this.applicationUserService
            .getApplicationUserById(this.appUserId)
            .subscribe(
              async (data) => {
                if (data.status) {
                  this.appUser = data.responseObject;
                  await this.getSessionsForUser(this.appUserId);
                } else {
                  this.appUser = new ApplicationUser();
                }
              },
              (error) => {
                toasterHelper.showError(error);
              }
            );
        }
      },
      (error) => {
        toasterHelper.showError(error);
      }
    );
  }
  getSelectedData(e) {
    if (this.timeSlot != null && this.timeSlot.id == e.row.data.id) {
      this.timeSlot = null;
    } else {
      this.timeSlot = e.row.data;
      this.timeSlot.branchName = this.branchName;
      this.timeSlot.sessionTypeName = this.sessionTypeName;
    }
  }
  // getting data from apis

  getFreeSlots(branchId: number, sessionTypeId: number) {
    this.loadingGetSlots = true;
    let newTimeSlot = new TimeSlot();
    let date = new Date(this.DayOfSlots);
    newTimeSlot.from = date;
    newTimeSlot.to = new Date(date.getTime() + 1 * 24 * 60 * 60 * 1000);
    newTimeSlot.sessionTypeId = sessionTypeId;
    newTimeSlot.branchId = branchId;
    if (this.doctor != null) {
      newTimeSlot.userId = this.doctor.id;
    }

    this.sessionService.GetAvailableTimeSlots(newTimeSlot).subscribe(
      (data) => {
        if (data.status) {
          this.timeSlots = data.responseObject;
          this.isChooseASessionType = true;
          this.toasterHelper.showToast(
            "get slots successfully",
            data.message,
            "success"
          );
        } else {
          this.timeSlots = [];
          this.toasterHelper.showToast(
            "Can't find slots",
            data.message,
            "warning"
          );
        }
        this.loadingGetSlots = false;
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
        this.loadingGetSlots = false;
      }
    );
  }

  getSessionTypes() {
    this.sessionTypeService.getSessionTypes().subscribe((data) => {
      if (data.status) {
        this.sessionTypes = data.responseObject;
      } else {
        this.sessionTypes = [];
      }
    }),
      (error) => {
        this.toasterHelper.showError("Something went wrong");
      };
  }
  getBranches() {
    this.branchService.getBranches().subscribe((data) => {
      if (data.status) {
        this.branches = data.responseObject;
      } else {
        this.branches = [];
      }
    }),
      (error) => {
        this.toasterHelper.showError("Something went wrong");
      };
  }

  getDoctors() {
    this.identityService.GetUsers().subscribe(
      (data) => {
        let mostAvailable = new UserModel();
        mostAvailable.fullName = "Most Available doctor";
        mostAvailable.id = "0";
        this.doctors = data.responseObject;
        this.doctors.unshift(mostAvailable);
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
      }
    );
  }

  selectDoctor() {
    if (
      this.doctorFullName != undefined &&
      this.doctorFullName != "Most Available doctor"
    ) {
      this.doctor = this.doctors.find(
        (p) => p.fullName.trim() == this.doctorFullName.trim()
      );
    } else {
      this.doctor = null;
    }
  }

  selectSessionType() {
    if (this.sessionTypeName != undefined) {
      this.sessionType = this.sessionTypes.find(
        (p) => p.nameEn.trim() == this.sessionTypeName.trim()
      );
    }
  }

  selectBranch() {
    if (this.branchName != undefined) {
      this.branch = this.branches.find(
        (p) => p.nameEn.trim() == this.branchName.trim()
      );
    }
  }

  getFreeSlotsAction() {
    if (this.sessionType != null && this.branch != null) {
      this.getFreeSlots(this.branch.id, this.sessionType.id);
    } else {
      this.toasterHelper.showError("please fill required fields");
    }
  }

  onSaving(e) {
    if (e.changes.length == 0) {
      e.changes.push({
        type: "update",
        key: this.rowKey,
        data: {},
      });
    }
  }

  selectAppUser() {
    if (this.userFullName != undefined) {
      this.appUser = this.appUsers.find(
        (p) => p.fullName.trim() == this.userFullName.trim()
      );
      this.getSessionsForUser(this.appUser.id);
      this.isDisabled = false;
      this.isChooseAUser = true;
    }
  }

  // Session crud operations
  getSessionsForUser(appUserId: number) {
    this.loadingSessions = true;
    this.sessionService.getSessionsByApplicationUserId(appUserId).subscribe(
      (data) => {
        if (data.status) {
          this.sessions = data.responseObject;
          this.toasterHelper.showToast(
            "get successfully",
            data.message,
            "success"
          );
        } else {
          this.sessions = [];
        }
        this.loadingSessions = false;
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
        this.loadingSessions = false;
      }
    );
  }
  initializeTimeSlot(e) {
    if (this.timeSlot != null) {
      e.data.from = this.timeSlot.from;
      e.data.to = this.timeSlot.to;
      e.data.branchId = this.timeSlot.branchId;
      e.data.sessionTypeId = this.timeSlot.sessionTypeId;
      e.data.doctorId = this.timeSlot.userId;
    }
  }

  updateTimeSlot(e) {
    this.rowKey = e.key;
    if (this.timeSlot != null) {
      e.data.from = this.timeSlot.from;
      e.data.branchId = this.timeSlot.branchId;
      e.data.to = this.timeSlot.to;
      e.data.sessionTypeId = this.timeSlot.sessionTypeId;
      e.data.doctorId = this.timeSlot.userId;
    }
  }
  cancelEditing() {
    this.getSessionsForUser(this.appUser.id);
  }
  addSessionForUser(e) {
    this.loadingSessions = true;
    let newSession = new AddSession();
    this.session = e.data;
    newSession.appUserId = this.appUser.id;
    newSession.comments = this.session.comments;
    newSession.doctorId = this.session.doctorId;
    newSession.from = this.session.from;
    newSession.to = this.session.to;
    newSession.sessionStatus = this.session.sessionStatus;
    newSession.sessionTypeId = this.session.sessionTypeId;
    newSession.branchId = this.session.branchId;
    newSession.description = this.session.description;

    this.sessionService.addSession(newSession).subscribe(
      (data) => {
        if (data.status) {
          this.toasterHelper.showToast(
            "Added successfully",
            data.message,
            "success"
          );
        } else {
          this.toasterHelper.showError(data.message);
        }
        this.loadingSessions = false;
        this.getSessionsForUser(this.appUser.id);
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
      }
    );
  }

  updateSession(e) {
    this.loadingSessions = true;
    let updatedSession = new UpdateSession();
    this.session = e.data;
    updatedSession.comments = this.session.comments;
    updatedSession.doctorId = this.session.doctorId;
    updatedSession.from = this.session.from;
    updatedSession.to = this.session.to;
    updatedSession.sessionStatus = this.session.sessionStatus;
    updatedSession.sessionTypeId = this.session.sessionTypeId;
    updatedSession.description = this.session.description;
    updatedSession.branchId = this.session.branchId;
    this.sessionService
      .updateSessionById(this.session.id, updatedSession)
      .subscribe(
        (data) => {
          if (data.status) {
            this.toasterHelper.showToast(
              "Updated successfully",
              data.message,
              "success"
            );
          } else {
            this.toasterHelper.showError(data.message);
          }
          this.loadingSessions = false;
          this.getSessionsForUser(this.appUser.id);
        },
        (error) => {
          this.toasterHelper.showError("Something went wrong");
        }
      );
  }

  deleteSession(e) {
    this.loadingSessions = true;
    this.session = e.data;
    this.sessionService.deleteSessionById(this.session.id).subscribe(
      (data) => {
        if (data.status) {
          this.toasterHelper.showToast(
            "Deleted successfully",
            data.message,
            "success"
          );
        } else {
          this.toasterHelper.showError(data.message);
        }
        this.loadingSessions = false;
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
        this.loadingSessions = false;
      }
    );
  }

  onFocusedRowChanging(e) {
    const rowsCount = e.component.getVisibleRows().length;
    const pageCount = e.component.pageCount();
    const pageIndex = e.component.pageIndex();
    const key = e.event && e.event.key;
    const rowData = e.row && e.row.data;
    if (key && e.prevRowIndex === e.newRowIndex) {
      if (e.newRowIndex === rowsCount - 1 && pageIndex < pageCount - 1) {
        e.component.pageIndex(pageIndex + 1).done(() => {
          e.component.option("focusedRowIndex", 0);
        });
      } else if (e.newRowIndex === 0 && pageIndex > 0) {
        e.component.pageIndex(pageIndex - 1).done(() => {
          e.component.option("focusedRowIndex", rowsCount - 1);
        });
      }
    }
  }
  onFocusedRowChanged(e) {
    const rowData = e.row && e.row.data;
    // this.rowPackage= rowData;
    if (rowData) {
      this.taskSubject = rowData.Task_Subject;
      this.taskDetailsHtml = this.sanitizer.bypassSecurityTrustHtml(
        rowData.Task_Description
      );
      this.taskStatus = rowData.Task_Status;
      this.taskProgress = rowData.Task_Completion
        ? `${rowData.Task_Completion}` + "%"
        : "";
    }
  }
  getCurrentDate() {
    var currentDate = new Date();
    let months: string = "";
    let days: string = "";
    let years: string = currentDate.getFullYear().toString();
    if (currentDate.getDate() < 10) {
      days = `0${currentDate.getDate()}`;
    } else {
      days = `${currentDate.getDate()}`;
    }
    if (currentDate.getMonth() < 9) {
      months = `0${currentDate.getMonth() + 1}`;
    } else {
      months = `${currentDate.getMonth() + 1}`;
    }
    return `${years}-${months}-${days}`;
  }
  ngOnInit(): void {
    this.getDoctors();
    this.getSessionTypes();
    this.getBranches();
    this.minDate = this.getCurrentDate();
  }
}
