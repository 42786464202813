<div class="row">
    <div class="col-md-12">
        <div class="title h1">Application Dashboard</div>
        <button class="button" (click)=toggleDisplayDiv()>{{ isShowDiv ? 'Show Service' : 'Hide service'}}</button>

        <div class="h4" [hidden]="isShowDiv">What are you looking for? </div>

        <hr>

        <div class="row" [hidden]="isShowDiv">

            <div class="col-sm-4 col-md-4" (click)=toggleDisplayDiv()>
                <nb-card [routerLink]="['./package']">
                    <div class="icon-container">
                        <div class="icon status-success">
                            <i class="fas fa-gift ic-size"></i>
                        </div>
                    </div>
                    <div class="details">
                        <div class="title h6">Packages</div>
                    </div>
                </nb-card>
            </div>
            <div class="col-sm-4 col-md-4" (click)=toggleDisplayDiv()>
                <nb-card [routerLink]="['./meal']">
                    <div class="icon-container">
                        <div class="icon status-success">
                            <i class="fas fa-gift ic-size"></i>
                        </div>
                    </div>
                    <div class="details">
                        <div class="title h6">Meals System</div>
                    </div>
                </nb-card>
            </div>
            <div class="col-sm-4 col-md-4" (click)=toggleDisplayDiv()>
                <nb-card [routerLink]="['./alternative-meal']">
                    <div class="icon-container">
                        <div class="icon status-success">
                            <i class="fas fa-user ic-size"></i>
                        </div>
                    </div>
                    <div class="details">
                        <div class="title h6">Alternative Meals</div>
                    </div>
                </nb-card>
            </div>
            <div class="col-sm-4 col-md-4" (click)=toggleDisplayDiv()>
                <nb-card [routerLink]="['./exercise']">
                    <div class="icon-container">
                        <div class="icon status-success">
                            <i class="fas fa-gift ic-size"></i>
                        </div>
                    </div>
                    <div class="details">
                        <div class="title h6">Exercises System</div>
                    </div>
                </nb-card>
            </div>
            <div class="col-sm-4 col-md-4" (click)=toggleDisplayDiv()>
                <nb-card [routerLink]="['./plan']">
                    <div class="icon-container">
                        <div class="icon status-success">
                            <i class="fas fa-user ic-size"></i>
                        </div>
                    </div>
                    <div class="details">
                        <div class="title h6">Plans</div>
                    </div>
                </nb-card>
            </div>
            <div class="col-sm-4 col-md-4" (click)=toggleDisplayDiv()>
                <nb-card [routerLink]="['./plan/package-plan']">
                    <div class="icon-container">
                        <div class="icon status-success">
                            <i class="fas fa-user ic-size"></i>
                        </div>
                    </div>
                    <div class="details">
                        <div class="title h6">packages plans</div>
                    </div>
                </nb-card>
            </div>

            <div class="col-sm-4 col-md-4" (click)=toggleDisplayDiv()>
                <nb-card [routerLink]="['./plan-meal']">
                    <div class="icon-container">
                        <div class="icon status-success">
                            <i class="fas fa-user ic-size"></i>
                        </div>
                    </div>
                    <div class="details">
                        <div class="title h6">Plans meals</div>
                    </div>
                </nb-card>
            </div>
            <div class="col-sm-4 col-md-4" (click)=toggleDisplayDiv()>
                <nb-card [routerLink]="['./plan-exercise']">
                    <div class="icon-container">
                        <div class="icon status-success">
                            <i class="fas fa-user ic-size"></i>
                        </div>
                    </div>
                    <div class="details">
                        <div class="title h6">Plans exercises</div>
                    </div>
                </nb-card>
            </div>

            <div class="col-sm-4 col-md-4" (click)=toggleDisplayDiv()>
                <nb-card [routerLink]="['./package-structure']">
                    <div class="icon-container">
                        <div class="icon status-success">
                            <i class="fas fa-user ic-size"></i>
                        </div>
                    </div>
                    <div class="details">
                        <div class="title h6">Packages sessions</div>
                    </div>
                </nb-card>
            </div>

            <div class="col-sm-4 col-md-4" (click)=toggleDisplayDiv()>
                <nb-card [routerLink]="['./medication']">
                    <div class="icon-container">
                        <div class="icon status-success">
                            <i class="fas fa-user ic-size"></i>
                        </div>
                    </div>
                    <div class="details">
                        <div class="title h6">Medications and Diseases</div>
                    </div>
                </nb-card>
            </div>

            <div class="col-sm-4 col-md-4" (click)=toggleDisplayDiv()>
                <nb-card [routerLink]="['./treatment-type']">
                    <div class="icon-container">
                        <div class="icon status-success">
                            <i class="fas fa-user ic-size"></i>
                        </div>
                    </div>
                    <div class="details">
                        <div class="title h6">Treatment types</div>
                    </div>
                </nb-card>
            </div>

            <div class="col-sm-4 col-md-4" (click)=toggleDisplayDiv()>
                <nb-card [routerLink]="['./identity']">
                    <div class="icon-container">
                        <div class="icon status-success">
                            <i class="fas fa-user ic-size"></i>
                        </div>
                    </div>
                    <div class="details">
                        <div class="title h6">Dashboard Users</div>
                    </div>
                </nb-card>
            </div>
            <div class="col-sm-4 col-md-4" (click)=toggleDisplayDiv()>
                <nb-card [routerLink]="['./reset-password']">
                    <div class="icon-container">
                        <div class="icon status-success">
                            <i class="fas fa-user ic-size"></i>
                        </div>
                    </div>
                    <div class="details">
                        <div class="title h6">Reset dashboard User password</div>
                    </div>
                </nb-card>
            </div>
            <div class="col-sm-4 col-md-4" (click)=toggleDisplayDiv()>
                <nb-card [routerLink]="['./application-user']">
                    <div class="icon-container">
                        <div class="icon status-success">
                            <i class="fas fa-user ic-size"></i>
                        </div>
                    </div>
                    <div class="details">
                        <div class="title h6">Application Users</div>
                    </div>
                </nb-card>
            </div>
            <div class="col-sm-4 col-md-4" (click)=toggleDisplayDiv()>
                <nb-card [routerLink]="['./subscription']">
                    <div class="icon-container">
                        <div class="icon status-success">
                            <i class="fas fa-user ic-size"></i>
                        </div>
                    </div>
                    <div class="details">
                        <div class="title h6">subscriptions</div>
                    </div>
                </nb-card>
            </div>




            <div class="col-sm-4 col-md-4" (click)=toggleDisplayDiv()>
                <nb-card [routerLink]="['./user-availability']">
                    <div class="icon-container">
                        <div class="icon status-success">
                            <i class="fas fa-clock ic-size"></i>
                        </div>
                    </div>
                    <div class="details">
                        <div class="title h6">Users availabilities</div>
                    </div>
                </nb-card>
            </div>



            <div class="col-sm-4 col-md-4" (click)=toggleDisplayDiv()>
                <nb-card [routerLink]="['./session-type']">
                    <div class="icon-container">
                        <div class="icon status-success">
                            <i class="fas fa-user ic-size"></i>
                        </div>
                    </div>
                    <div class="details">
                        <div class="title h6">Session types</div>
                    </div>
                </nb-card>
            </div>

            <div class="col-sm-4 col-md-4" (click)=toggleDisplayDiv()>
                <nb-card [routerLink]="['./session-schedule']">
                    <div class="icon-container">
                        <div class="icon status-success">
                            <i class="fas fa-user ic-size"></i>
                        </div>
                    </div>
                    <div class="details">
                        <div class="title h6">sessions schedule</div>
                    </div>
                </nb-card>
            </div>

            <div class="col-sm-4 col-md-4" (click)=toggleDisplayDiv()>
                <nb-card [routerLink]="['./session-type-review']">
                    <div class="icon-container">
                        <div class="icon status-success">
                            <i class="fas fa-user ic-size"></i>
                        </div>
                    </div>
                    <div class="details">
                        <div class="title h6">Session Type Reviews</div>
                    </div>
                </nb-card>
            </div>

            <div class="col-sm-4 col-md-4" (click)=toggleDisplayDiv()>
                <nb-card [routerLink]="['./challenge']">
                    <div class="icon-container">
                        <div class="icon status-success">
                            <i class="fas fa-user ic-size"></i>
                        </div>
                    </div>
                    <div class="details">
                        <div class="title h6">Challenges System</div>
                    </div>
                </nb-card>
            </div>
            <div style="height: 100px;" class="col-sm-4 col-md-4" (click)=toggleDisplayDiv()>
                <nb-card [routerLink]="['./burnout-calorie']">
                    <div class="icon-container">
                        <div class="icon status-success">
                            <i class="fas fa-user ic-size"></i>
                        </div>
                    </div>
                    <div class="details">
                        <div class="title h6">Challenges burnout-calories</div>
                    </div>
                </nb-card>
            </div>

            <div class="col-sm-4 col-md-4" (click)=toggleDisplayDiv()>
                <nb-card [routerLink]="['./promo-code']">
                    <div class="icon-container">
                        <div class="icon status-success">
                            <i class="fas fa-user ic-size"></i>
                        </div>
                    </div>
                    <div class="details">
                        <div class="title h6">Promo Codes</div>
                    </div>
                </nb-card>
            </div>

            <div class="col-sm-4 col-md-4" (click)=toggleDisplayDiv()>
                <nb-card [routerLink]="['./interest']">
                    <div class="icon-container">
                        <div class="icon status-success">
                            <i class="fas fa-user ic-size"></i>
                        </div>
                    </div>
                    <div class="details">
                        <div class="title h6">Interests System</div>
                    </div>
                </nb-card>
            </div>
            <div class="col-sm-4 col-md-4" (click)=toggleDisplayDiv()>
                <nb-card [routerLink]="['./article']">
                    <div class="icon-container">
                        <div class="icon status-success">
                            <i class="fas fa-user ic-size"></i>
                        </div>
                    </div>
                    <div class="details">
                        <div class="title h6">Articles System</div>
                    </div>
                </nb-card>
            </div>




            <div class="col-sm-4 col-md-4" (click)=toggleDisplayDiv()>
                <nb-card [routerLink]="['./branch']">
                    <div class="icon-container">
                        <div class="icon status-success">
                            <i class="fas fa-user ic-size"></i>
                        </div>
                    </div>
                    <div class="details">
                        <div class="title h6">Branches System</div>
                    </div>
                </nb-card>
            </div>

        </div>
        <router-outlet></router-outlet>

    </div>