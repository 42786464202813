import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { PatientService } from "../../../@core/app-services/patient.service";
import { Patient } from "../../../@core/models/patient";
import { ToasterHelper } from "../../../@core/app-services/toasterHelper";
import { ErrorStateMatcher } from "@angular/material/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CountryCodes } from "./../../../@core/data/CountryCodes";
import {
  //AwsPatientRepoConfig,
  AwsS3Manage,
} from "../../../@core/lib/AwsS3Manage";
import { ApplicationUser } from "../../../@core/models/applicationUser";
import { ApplicationUserService } from "../../../@core/app-services/application-user.service";
import { IsRegistered } from "../../../@core/models/isRegistered";
import { SmsService } from "../../../@core/app-services/sms.service";
import { SMSBody } from "../../../@core/models/SMSBody";
import { type } from "os";
import { CountryCode } from "../../../@core/models/countryCode";

@Component({
  selector: "ngx-add-patient",
  templateUrl: "./add-patient.component.html",
  styleUrls: ["./add-patient.component.scss"],
})
export class AddPatientComponent implements OnInit {
  selectedFile: File;
  awsS3Manage: AwsS3Manage = new AwsS3Manage();

  constructor(
    private applicationUserService: ApplicationUserService,
    private smsService: SmsService,
    private toasterHelper: ToasterHelper
  ) {}
  loading: boolean = false;
  saving: boolean = false;
  countryCodes: CountryCode[] = CountryCodes;
  gender: string = "1";
  language: string = "1";
  isValidPhone: boolean = false;
  isValidMail: boolean = false;
  isValidForm: boolean = false;
  isAbleToRegister: boolean = false;
  isOTPSent: boolean = false;
  isAbleToResend: boolean = false;
  isUserInTheSystem: boolean = true;
  OTPNumberGenerated: number;
  isPhoneDisabled: boolean = false;
  isButtonHidden: boolean = true;
  countryCode: string;
  SMSBody: SMSBody = new SMSBody();
  userIsRegistered: IsRegistered = new IsRegistered();
  userData: ApplicationUser = new ApplicationUser();
  ngOnInit(): void {
    this.userData.phone = this.countryCodes[0].dial_code;
  }

  checkIsUserRegistered() {
    if (this.userData.phone.length == 0) {
      this.toasterHelper.showError("please enter your phone number");
      return;
    } else {
      this.userIsRegistered.phone = this.userData.phone;
      this.applicationUserService
        .checkIsRegistered(this.userIsRegistered)
        .subscribe((data) => {
          if (!data.responseObject) {
            this.isUserInTheSystem = false;
            this.isPhoneDisabled = true;
            this.toasterHelper.showToast(
              "Can Use this number",
              data.message,
              "success"
            );
          } else {
            this.isPhoneDisabled = false;
            this.toasterHelper.showError(data.message);
          }
        });
    }
  }
  selectCountryCode(countryCode: string) {
    this.countryCode = countryCode.split(" ")[1];
    this.userData.phone = this.countryCode;
  }
  sendOTP() {
    this.isAbleToResend = true;
    this.OTPNumberGenerated = Math.floor(100000 + Math.random() * 900000);
    this.SMSBody.text = `your verification code is: ${this.OTPNumberGenerated}, please give the OTP to our employee to register you`;
    this.SMSBody.phones = [];
    this.SMSBody.phones.push(this.userData.phone);
    this.smsService.SendSMS(this.SMSBody).subscribe((data) => {
      if (data.status) {
        this.toasterHelper.showToast("OTP was sent", data.message, "success");
        this.isOTPSent = true;
        setTimeout(() => {
          this.isAbleToResend = false;
        }, 30000);
      } else {
        this.toasterHelper.showError(data.message);
      }
    });
  }
  checkOTP() {
    if (this.OTPNumberGenerated == this.userData.OtpNumber) {
      this.isAbleToRegister = true;
      this.toasterHelper.showAdded("OTP is OK, you can register him now");
    } else {
      this.toasterHelper.showError("OTP number is wrong");
    }
  }
  async save() {
    this.saving = true;
    this.userData.gender = Number.parseInt(this.gender);
    this.userData.languageCode = Number.parseInt(this.language);
    this.applicationUserService.addApplicationUser(this.userData).subscribe(
      (data) => {
        if (data.status) {
          this.toasterHelper.showAdded("Client added successfully");
          this.saving = false;
        } else {
          this.toasterHelper.showError(data.message);
          this.saving = false;
        }
      },
      (error) => {
        this.toasterHelper.showError("this user can't be add");
        this.saving = false;
        console.log(this.userData);
      }
    );
  }

  selectFile(event) {
    this.selectedFile = event.target.files.item(0);
  }
}
