import { UserModel } from "./userModel";

export class ActivityType {
  id: number;
  title: string;
  userTypeTitle: string;
  userTypeId: number;
  categoryId: number;
  categoryTitle: string;
  price: number;
  owners: UserModel[] = new Array();
  hold: boolean;
}
