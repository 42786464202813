<div class="col-md-12 col-lg-12 col-xxl-12">
    <nb-card class="list-card" class="own-scroll">
        <nb-card-header>Search result</nb-card-header>
        <nb-card-body [nbSpinner]="clientShared.gettingClients" nbSpinnerStatus="primary" nbSpinnerSize="large"
            nbSpinnerMessage="Getting Clients">
            <nb-list>
                <nb-list-item *ngFor="let client of clientShared.filteredUsers">
                    <nb-user (click)="redirect(client)" style="cursor: pointer;" [name]="client.fullName">
                    </nb-user>
                    <nb-action title="Delete Client" style="position: absolute;right:20px"
                        (click)="deleteClient(client)" icon="trash-2-outline">
                    </nb-action>
                </nb-list-item>
            </nb-list>
        </nb-card-body>
    </nb-card>
</div>