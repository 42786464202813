<div class="row">
    <div class="col-md-12">
        <div class="title h1">CRM Reporting</div>
        <button class="button" (click)=toggleDisplayDiv()>{{ isShowDiv ? 'Show Service' : 'Hide service'}}</button>

        <div class="h4" [hidden]="isShowDiv">What are you looking for? </div>

        <hr>

        <div class="row" [hidden]="isShowDiv">

            <div class="col-sm-4 col-md-4" (click)=toggleDisplayDiv()>
                <nb-card [routerLink]="['./less-than-one-kilo-report']">
                    <div class="icon-container">
                        <div class="icon status-success">
                            <i class="fas fa-user ic-size"></i>
                        </div>
                    </div>
                    <div class="details">
                        <div class="title h6"> &lt; Kilo loss Report</div>
                    </div>
                </nb-card>
            </div>
            <div class="col-sm-4 col-md-4" (click)=toggleDisplayDiv()>
                <nb-card [routerLink]="['./missed-sessions-report']">
                    <div class="icon-container">
                        <div class="icon status-success">
                            <i class="fas fa-user ic-size"></i>
                        </div>
                    </div>
                    <div class="details">
                        <div class="title h6"> Yesterday's Missed Sessions report</div>
                    </div>
                </nb-card>
            </div>
            <div class="col-sm-4 col-md-4" (click)=toggleDisplayDiv()>
                <nb-card [routerLink]="['./subscription-followup-report']">
                    <div class="icon-container">
                        <div class="icon status-success">
                            <i class="fas fa-user ic-size"></i>
                        </div>
                    </div>
                    <div class="details">
                        <div class="title h6"> Subscription follow-ups report</div>
                    </div>
                </nb-card>
            </div>
            <div class="col-sm-4 col-md-4" (click)=toggleDisplayDiv()>
                <nb-card [routerLink]="['./examination-followup-report']">
                    <div class="icon-container">
                        <div class="icon status-success">
                            <i class="fas fa-user ic-size"></i>
                        </div>
                    </div>
                    <div class="details">
                        <div class="title h6"> Examination follow-ups report</div>
                    </div>
                </nb-card>
            </div>
            <div class="col-sm-4 col-md-4" (click)=toggleDisplayDiv()>
                <nb-card [routerLink]="['./renewal-followup-report']">
                    <div class="icon-container">
                        <div class="icon status-success">
                            <i class="fas fa-user ic-size"></i>
                        </div>
                    </div>
                    <div class="details">
                        <div class="title h6"> Renewal follow-ups report</div>
                    </div>
                </nb-card>
            </div>
            <div class="col-sm-4 col-md-4" (click)=toggleDisplayDiv()>
                <nb-card [routerLink]="['./intermittent-fasting-followup-report']">
                    <div class="icon-container">
                        <div class="icon status-success">
                            <i class="fas fa-user ic-size"></i>
                        </div>
                    </div>
                    <div class="details">
                        <div class="title h6"> Intermittent Fasting follow-ups report</div>
                    </div>
                </nb-card>
            </div>

        </div>
        <router-outlet></router-outlet>

    </div>