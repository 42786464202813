import { NbMenuItem } from "@nebular/theme";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { UserSharedService } from "./user-shared.service";
import { ApplicationUserService } from "../app-services/application-user.service";
import { ApplicationUser } from "../models/applicationUser";

@Injectable({ providedIn: "root" })
export class MenuSharedService {
  userSharedServiceInstance: UserSharedService;
  constructor(
    applicationUserService: ApplicationUserService,
    regularRoute: Router,
    userSharedService: UserSharedService
  ) {
    var url = regularRoute.url;
    this.userSharedServiceInstance = userSharedService;

    if (url.length > 24) {
      var from = url.indexOf("/", 24);
      var to = url.indexOf("/", 25);
      var clientId = Number(url.substring(from + 1, to));
      if (clientId != null && clientId != 0) {
        applicationUserService
          .getApplicationUserById(clientId)
          .subscribe((data) => {
            let appUser = new ApplicationUser();
            appUser.firstName = data.responseObject.firstName;
            appUser.lastName = data.responseObject.lastName;
            this.setClientMenu(
              clientId,
              appUser.firstName + " " + appUser.lastName
            );
          });
      } else {
        this.resetMenu();
      }
    } else {
      this.resetMenu();
    }
  }
  public setClientMenu(cId: number, cName: string): void {
    this.DASHBOARD_MENU = [
      {
        title: "Dashboard",
        icon: "home-outline",
        link: "/pages/dashboard/index",
        home: true,
      },
      {
        title: "Schedule",
        icon: "calendar-outline",
        link: "/pages/dashboard/schedule",
      },
      {
        title: "Treasury",
        icon: "lock-outline",
        link: "/pages/dashboard/treasury",
        hidden: !(
          this.userSharedServiceInstance.UserTypeId == 6 ||
          this.userSharedServiceInstance.UserTypeId == 2
        ),
      },
      {
        title: "Call Manager",
        icon: "phone-call-outline",
        link: "/pages/dashboard/CallManager",
      },
      {
        title: "Application Admin",
        icon: "options-2-outline",
        link: "/pages/dashboard/applicationAdmin",
      },
      {
        title: "CRM Reporting",
        icon: "options-2-outline",
        link: "/pages/dashboard/crm-reporting",
      },
      {
        title: "Data Management",
        group: true,
      },
      {
        title: "Activity Manager",
        icon: "activity-outline",
        link: "/pages/dashboard/activity-manager",
      },

      {
        title: "Operation Plans Manager",
        icon: "map-outline",
        link: "/pages/dashboard/plan-type-manager",
      },
      {
        title: "Transaction types Manager",
        icon: "layers-outline",
        link: "/pages/dashboard/spend-type-manager",
      },
      {
        title: "Clients",
        group: true,
      },
      {
        title: "Add",
        icon: "person-add-outline",
        link: "/pages/dashboard/add-patient",
      },
      {
        title: cName + " - " + cId,
        icon: "person-outline",
        link: "/pages/dashboard/patient/" + cId + "/index",
      },
      {
        title: "Basic Info",
        icon: "smiling-face-outline",
        link: "/pages/dashboard/patient/" + cId + "/client-info",
      },
      {
        title: "Call Log",
        icon: "phone-call-outline",
        link: "/pages/dashboard/patient/" + cId + "/call-log",
      },
      // {
      //   title: "Operation Plans",
      //   icon: "book-open-outline",
      //   link: "/pages/dashboard/patient/" + cId + "/operation-plans",
      // },
      // {
      //   title: "Payments",
      //   icon: "shield-outline",
      //   link: "/pages/dashboard/patient/" + cId + "/payments",
      //   hidden: !(
      //     this.userSharedServiceInstance.UserTypeId == 6 ||
      //     this.userSharedServiceInstance.UserTypeId == 2
      //   ),
      // },
    ];
  }
  public resetMenu(): void {
    this.DASHBOARD_MENU = [
      {
        title: "Dashboard",
        icon: "home-outline",
        link: "/pages/dashboard/index",
        home: true,
      },
      {
        title: "Schedule",
        icon: "calendar-outline",
        link: "/pages/dashboard/schedule",
      },
      {
        title: "Treasury",
        icon: "lock-outline",
        link: "/pages/dashboard/treasury",
        hidden: !(
          this.userSharedServiceInstance.UserTypeId == 6 ||
          this.userSharedServiceInstance.UserTypeId == 2
        ),
      },
      {
        title: "Call Manager",
        icon: "phone-call-outline",
        link: "/pages/dashboard/CallManager",
      },
      {
        title: "Application Admin",
        icon: "options-2-outline",
        link: "/pages/dashboard/applicationAdmin",
      },
      {
        title: "CRM Reporting",
        icon: "options-2-outline",
        link: "/pages/dashboard/crm-reporting",
      },
      {
        title: "Data Management",
        group: true,
      },
      {
        title: "Activity Manager",
        icon: "activity-outline",
        link: "/pages/dashboard/activity-manager",
      },
      {
        title: "Operation Plans Manager",
        icon: "map-outline",
        link: "/pages/dashboard/plan-type-manager",
      },
      {
        title: "Transaction types Manager",
        icon: "layers-outline",
        link: "/pages/dashboard/spend-type-manager",
      },
      {
        title: "Clients",
        group: true,
      },
      {
        title: "Add",
        icon: "person-add-outline",
        link: "/pages/dashboard/add-patient",
      },
    ];
  }
  public DASHBOARD_MENU: NbMenuItem[] = [
    {
      title: "Dashboard",
      icon: "home-outline",
      link: "/pages/dashboard/index",
      home: true,
    },
    {
      title: "Schedule",
      icon: "calendar-outline",
      link: "/pages/dashboard/schedule",
    },
    {
      title: "Treasury",
      icon: "lock-outline",
      link: "/pages/dashboard/treasury",
    },
    {
      title: "Call Manager",
      icon: "phone-call-outline",
      link: "/pages/dashboard/CallManager",
    },
    {
      title: "Application Admin",
      icon: "options-2-outline",
      link: "/pages/dashboard/applicationAdmin",
    },
    {
      title: "CRM Reporting",
      icon: "options-2-outline",
      link: "/pages/dashboard/crm-reporting",
    },
    {
      title: "Data Management",
      group: true,
    },
    {
      title: "Activity Manager",
      icon: "activity-outline",
      link: "/pages/dashboard/activity-manager",
    },
    {
      title: "Operation Plans Manager",
      icon: "map-outline",
      link: "/pages/dashboard/plan-type-manager",
    },
    {
      title: "Transaction types Manager",
      icon: "layers-outline",
      link: "/pages/dashboard/spend-type-manager",
    },
    {
      title: "Clients",
      group: true,
    },
    {
      title: "Add",
      icon: "person-add-outline",
      link: "/pages/dashboard/add-patient",
    },
  ];
}
