import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { AddSubscription } from "../models/addSubscription";
import { ApiResponse } from "../models/ApiResponse";
import { UpdateSubscription } from "../models/updateSubscription";

@Injectable({
  providedIn: "root",
})
export class SubscriptionService {
  private getSubscriptionsByApplicationUserIdPath =
    environment.apiUrl + "Subscription/GetSubscriptionsByAppUserId?id=";
  private getAllSubscriptionsPath =
    environment.apiUrl + "Subscription/GetAllSubscriptions";
  private getApplicationUserActiveSubscriptionPath =
    environment.apiUrl + "Subscription/GetApplicationUserActiveSubscription";
  private addSubscriptionPath =
    environment.apiUrl + "Subscription/AddSubscription";
  private updateSubscriptionPath =
    environment.apiUrl + "Subscription/UpdateSubscription?id=";
  private deleteSubscriptionPath =
    environment.apiUrl + "Subscription/DeleteSubscriptionById?id=";

  constructor(private http: HttpClient) {}

  getSubscriptionsByApplicationUserId(
    appUserId: number
  ): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(
      this.getSubscriptionsByApplicationUserIdPath + appUserId
    );
  }
  getAllSubscriptions(): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.getAllSubscriptionsPath);
  }
  getApplicationUserActiveSubscription(): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(
      this.getApplicationUserActiveSubscriptionPath
    );
  }
  addSubscription(model: AddSubscription): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.addSubscriptionPath, model);
  }
  updateSubscription(
    subscriptionId: number,
    model: UpdateSubscription
  ): Observable<ApiResponse> {
    return this.http.put<ApiResponse>(
      this.updateSubscriptionPath + subscriptionId,
      model
    );
  }
  deleteSubscription(subscriptionId: number): Observable<ApiResponse> {
    return this.http.delete<ApiResponse>(
      this.deleteSubscriptionPath + subscriptionId
    );
  }
}
