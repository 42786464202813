import { Payment } from "./payment";

export class PaymentDay {
  constructor(){
    this.payments = new Array();
  }
  date: Date;
  debit: number;
  credit: number;
  balance: number;
  payments: Payment[];
}