import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { AddPromoCode } from "../models/addPromoCode";
import { ApiResponse } from "../models/ApiResponse";
import { UpdatePromoCode } from "../models/updatePromoCode";

@Injectable({
  providedIn: "root",
})
export class PromoCodeService {
  private getAllPromoCodesPath =
    environment.commonUrl + "PromoCode/GetAllPromoCodes";
  private addPromoCodePath = environment.commonUrl + "PromoCode/AddPromoCode";
  private updatePromoCodePath =
    environment.commonUrl + "PromoCode/EditPromoCode";
  private deletePromoCodePath =
    environment.commonUrl + "PromoCode/DeletePromoCodeById?id=";

  constructor(private http: HttpClient) {}

  getAllPromoCodes(): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.getAllPromoCodesPath);
  }

  addPromoCode(model: AddPromoCode): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.addPromoCodePath, model);
  }
  updatePromoCode(model: UpdatePromoCode): Observable<ApiResponse> {
    return this.http.put<ApiResponse>(this.updatePromoCodePath, model);
  }
  deletePromoCode(promoCodeId: number): Observable<ApiResponse> {
    return this.http.delete<ApiResponse>(
      this.deletePromoCodePath + promoCodeId
    );
  }
}
