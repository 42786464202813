import { Component } from "@angular/core";
import { NbSearchService } from "@nebular/theme";
import { Router, ActivatedRoute } from "@angular/router";
import { ClientSharedService } from "../../@core/shared-services/client-shared.service";
import { MenuSharedService } from "../../@core/shared-services/menu-shared.service";
import { CookieService } from "ngx-cookie";
import { PatientService } from "../../@core/app-services/patient.service";
import { VisitService } from "../../@core/app-services/visit.service";
import { Visit } from "../../@core/models/visit";
import { type } from "os";
import { ApplicationUserService } from "../../@core/app-services/application-user.service";

@Component({
  selector: "ngx-dashboard",
  templateUrl: "dashboard.component.html",
})
export class DashboardComponent {
  constructor(
    private searchService: NbSearchService,
    private router: Router,
    private clientShared: ClientSharedService,
    private activatedRoute: ActivatedRoute,
    private applicationUserService: ApplicationUserService
  ) {}
  // fireSearch(tag: string, term: string): void {
  //   this.router.navigate(["patient-list"], { relativeTo: this.activatedRoute });

  //   this.applicationUserService.search(term).subscribe((data) => {
  //     this.clientShared.filteredApplicationUsers = data.responseObject;
  //   });
  // }
}
