import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { ApiResponse } from "../models/ApiResponse";
import { Wallet } from "../models/Wallet";

@Injectable({
  providedIn: "root",
})
export class WalletService {
  private getWalletsPath =
    environment.apiUrl + "Wallet/GetWalletsForAppUser?appUserId=";
  private GetTotalMoneyPath =
    environment.apiUrl + "Wallet/GetTotalMoney?appUserId=";
  private addWalletPath = environment.apiUrl + "Wallet/AddWallet";
  private deleteWalletPath = environment.apiUrl + "Wallet/DeleteWallet?id=";
  private updateWalletPath = environment.apiUrl + "Wallet/UpdateWallet?id=";

  constructor(private http: HttpClient) {}
  // Wallet apis

  GetTotalMoney(appUserId): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.GetTotalMoneyPath + appUserId);
  }
  getWallets(appUserId): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.getWalletsPath + appUserId);
  }
  addWallet(model: Wallet): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.addWalletPath, model);
  }
  updateWallet(WalletId: number, model: Wallet): Observable<ApiResponse> {
    return this.http.put<ApiResponse>(this.updateWalletPath + WalletId, model);
  }
  deleteWallet(WalletId: number): Observable<ApiResponse> {
    return this.http.delete<ApiResponse>(this.deleteWalletPath + WalletId);
  }
}
