import { Injectable } from "@angular/core";
import { CookieService } from "ngx-cookie";
import { Router } from "@angular/router";
import { Patient } from "../models/patient";
import { PatientService } from "../app-services/patient.service";
import { ToasterHelper } from "../app-services/toasterHelper";
import { ApplicationUserService } from "../app-services/application-user.service";
import { ApplicationUser } from "../models/applicationUser";
import { IdentityService } from "../app-services/identity.service";
import { User } from "../models/user";

@Injectable({
  providedIn: "root",
})
export class ClientSharedService {
  public gettingClients: boolean = false;
  public filteredUsers: User[];
  constructor(
    private identityService: IdentityService,
    private applicationUserService: ApplicationUserService,
    private router: Router,
    private toasterHelper: ToasterHelper
  ) {}
  // setClient(selected: Client) {
  //   var expireDate = new Date();
  //   expireDate.setMinutes(expireDate.getMinutes() + 15);
  //   this.cookieService.put(selected.id.toString(), JSON.stringify(selected), {
  //     expires: expireDate
  //   });
  // }
  // getClient(clientId: string): Client {
  //   var cookieObj = this.cookieService.get(clientId);
  //   if (cookieObj == null) {
  //     return null;
  //   }
  //   return JSON.parse(cookieObj);
  // }
  deleteClient(client: ApplicationUser) {
    this.applicationUserService
      .deleteApplicationUser(client.id)
      .subscribe((data) => {
        if (data.status) {
          this.toasterHelper.showSaved(data.message);
        } else {
          this.toasterHelper.showError(data.message);
        }
      });
  }
  search(keyword: string) {
    this.gettingClients = true;
    this.applicationUserService.search(keyword).subscribe((data) => {
      if (data.status) {
        this.filteredUsers = data.responseObject;
      }
      this.gettingClients = false;
    });
  }
  gotoMainPage(clientId: number) {
    this.router.navigate(["/pages/dashboard/patient/" + clientId + "/index"]);
  }
}
