import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { environment } from "../../../environments/environment";
import { PaymentDay } from "../models/paymentDay";
import { Payment } from "../models/payment";

@Injectable({
  providedIn: "root",
})
export class PaymentService {
  private getPatientPaymentsPath =
    environment.apiUrl + "Payment/GetPatientPayments?patientId=";
  private addPaymentPath = environment.apiUrl + "Payment/AddPayment";
  private deletePaymentPath = environment.apiUrl + "Payment/deletePayment?paymentId=";

  constructor(private http: HttpClient) {}
  getPatientPayments(patientId: number): Observable<PaymentDay[]> {
    return this.http.get<PaymentDay[]>(this.getPatientPaymentsPath + patientId);
  }
  addPayment(payment: Payment): Observable<any> {
    return this.http.post<any>(this.addPaymentPath, payment);
  }
  deletePayment(paymentId:number): Observable<any> {
    return this.http.post<any>(this.deletePaymentPath+paymentId, '');
  }
}
