<div class="row">
    <div class="col-md-5">
        <nb-card [size]="'large'">
            <nb-card-header class="row">
                <div class="col-md-10">
                    Operation plan types
                </div>
                <div class="col-md-2">
                    <nb-action title="Add Type" icon="plus-outline" (click)="openPlanTypeDialog(null,dialogPlanType)">
                    </nb-action>
                </div>
            </nb-card-header>
            <nb-card-body [nbSpinner]="gettingOperationPlans" nbSpinnerStatus="primary" nbSpinnerSize="large"
                nbSpinnerMessage="Getting Operation plan types">
                <div *ngIf="planTypes.length == 0 && !gettingOperationPlans">
                    <ngx-empty-state image="EmptyState.png" text="No Operation plan types found!">
                    </ngx-empty-state>
                    <div style="text-align: center;margin-top: 10px;">
                        <button (click)="openPlanTypeDialog(null,dialogPlanType)" nbButton status="success">Add
                            Plan Type</button>
                    </div>
                </div>
                <nb-list *ngIf="planTypes.length>0">
                    <nb-list-item *ngFor="let planType of planTypes">
                        <span style="width: 90%;"> {{planType.planTitle}}
                        </span>
                        <nb-actions size="small">
                            <nb-action *ngIf="planType.hold" icon="trash-2-outline" badgeText="Deleted"
                                badgeStatus="danger">
                            </nb-action>
                            <nb-action *ngIf="!planType.hold" title="Delete" icon="trash-2-outline"
                                (click)="deletePlanType(category)">
                            </nb-action>
                            <nb-action (click)="openPlanTypeDialog(planType,dialogPlanType)" title="Edit"
                                icon="edit-outline">
                            </nb-action>
                            <nb-action icon="arrow-ios-forward-outline" (click)="selectPlan(planType)">
                            </nb-action>
                        </nb-actions>
                    </nb-list-item>
                </nb-list>
            </nb-card-body>
        </nb-card>
    </div>
    <div class="col-md-7" *ngIf="!selectedPlanType">
        <nb-card size="large">
            <ngx-empty-state image="coffee.png" text="Select operation plan to show it's data"></ngx-empty-state>
        </nb-card>
    </div>
    <div class="col-md-7" *ngIf="selectedPlanType">
        <nb-card>
            <nb-card-header>
                {{selectedPlanType.planTitle}}
            </nb-card-header>
        </nb-card>
        <div class="row">
            <div class="col-md-6">
                <nb-card size='medium'>
                    <nb-card-header class="row">
                        <div class="col-md-10">
                            Milestones
                        </div>
                        <div class="col-md-2">
                            <nb-action (click)="addMilestone()" title="Add Milestone" icon="plus-outline">
                            </nb-action>
                        </div>
                    </nb-card-header>
                    <nb-card-body [nbSpinner]="gettingMilestones" nbSpinnerStatus="primary" nbSpinnerSize="large"
                        nbSpinnerMessage="Getting milestone">
                        <div *ngIf="milestones.length == 0 && !gettingMilestones">
                            <ngx-empty-state image="EmptyState.png" text="This plan doesn't has milestones"></ngx-empty-state>
                            <div style="text-align: center;margin-top: 10px;">
                                <button (click)="addMilestone()" nbButton status="success">Add a milestone</button>
                            </div>
                        </div>
                        <nb-list *ngIf="milestones.length > 0 && !gettingMilestones">
                            <nb-list-item *ngFor="let milestone of milestones">
                                <span style="width: 90%;"> {{milestone.title}}</span>
                                <nb-actions size="small">
                                    <nb-action title="Delete Milestone" (click)="deleteMilestone(milestone)"
                                        icon="trash-2-outline">
                                    </nb-action>
                                    <nb-action icon="arrow-ios-forward-outline" (click)="selectMilestone(milestone)">
                                    </nb-action>
                                </nb-actions>
                            </nb-list-item>
                        </nb-list>
                    </nb-card-body>
                </nb-card>
            </div>
            <div class="col-md-6" *ngIf="!selectedMilestone">
                <nb-card size="medium">
                    <ngx-empty-state image="coffee.png" text="Select Milestone to show it's visits"></ngx-empty-state>
                </nb-card>
            </div>
            <div class="col-md-6" *ngIf="selectedMilestone">
                <nb-card size='medium'>
                    <nb-card-header class="row">
                        <div class="col-md-10">
                            {{selectedMilestone.title}}'s Visits
                        </div>
                        <div class="col-md-2">
                            <nb-action title="Add Visit" icon="plus-outline" (click)="this.openNewDialog(dialogAddVisit)">
                            </nb-action>
                        </div>
                    </nb-card-header>
                    <nb-card-body [nbSpinner]="gettingVisits" nbSpinnerStatus="primary" nbSpinnerSize="large"
                        nbSpinnerMessage="Getting visits">
                        <div *ngIf="visits.length == 0 && !gettingVisists">
                            <ngx-empty-state image="EmptyState.png" text="This milestone doesn't has visits">
                            </ngx-empty-state>
                            <div style="text-align: center;margin-top: 10px;">
                                <button nbButton status="success" (click)="this.openDialog(dialogAddVisit)">Add
                                    visit</button>
                            </div>
                        </div>
                        <div *ngIf="visits.length > 0 && !gettingVisists">
                            <nb-card status='info' *ngFor="let visit of visits" (click)="openVisit(visit, dialogVisit)"
                                class="visit">
                                <nb-card-header class="row">
                                    <div class="col-md-6">
                                        <!-- {{ visit.date | date: "EEE" }} -->
                                        +{{visit.day}} {{visit.day == 1 ? 'day' : 'days'}}
                                    </div>
                                    <div class="col-md-6">
                                        {{visit.duration < 1 ? visit.duration * 60 : visit.duration}} {{visit.duration <
                                            1 ? 'minutes' : (visit.duration==1 ? 'hour' : 'hours' )}} </div>
                                </nb-card-header>
                            </nb-card>
                        </div>
                    </nb-card-body>
                </nb-card>
            </div>
        </div>
    </div>
</div>
<ng-template #dialogPlanType let-data let-ref="dialogRef">
    <nb-card>
        <nb-card-header>
            Operation Plan type
        </nb-card-header>
        <nb-card-body>
            <div class="form-group">
                <input fullWidth [(ngModel)]="this.selectedPlanType.planTitle" placeholder="Plan title" type="text"
                    nbInput>
            </div>
        </nb-card-body>
        <nb-card-footer style="text-align: right;">
            <button [nbSpinner]="savingOperationPlan" nbSpinnerStatus="success" (click)="savePlanType(ref)" nbButton
                status="primary">Save</button>
        </nb-card-footer>
    </nb-card>
</ng-template>

<ng-template #dialogVisit let-data let-ref="dialogRef">
    <nb-card size="large" style="width: 800px;">
        <nb-card-header class="row">
            <div class="col-md-10">
                Visit after <strong>+{{selectedVisit.day}} {{selectedVisit.day == 1 ? 'day' : 'days'}}</strong>
            </div>
            <div class="col-md-2">
                <nb-actions size="small">
                    <nb-action title="Edit Visit" icon="settings-2-outline" (click)="editVisit(dialogAddVisit)">
                    </nb-action>
                    <nb-action title="Delete Visit" icon="trash-2-outline" (click)="deleteVisit(ref)">
                    </nb-action>
                </nb-actions>
            </div>
        </nb-card-header>
        <nb-card-body>
            <nb-card size="large">
                <nb-card-header class="row">
                    <div class="col-md-10">
                        Activities
                    </div>
                    <div class="col-md-2">
                        <nb-action title="Add Activity" icon="plus-outline" (click)="openDialog(addActivityDialog)">
                        </nb-action>
                    </div>
                </nb-card-header>
                <nb-card-body [nbSpinner]="this.gettingActivities" nbSpinnerStatus="primary" nbSpinnerSize="large"
                    nbSpinnerMessage="Getting visit activities">
                    <nb-list *ngIf="activities.length > 0">
                        <nb-list-item *ngFor="let activity of activities">
                            <span style="width: 45%;"> {{ activity.activityTitle }}</span>
                            <!-- <span style="width: 45%;"> {{ activity.activityOwnerId }}</span> -->
                            <nb-action (click)="deleteActivity(activity)" title="Delete Activity"
                                icon="trash-2-outline">
                            </nb-action>
                        </nb-list-item>
                    </nb-list>
                    <div *ngIf="activities.length==0 && !gettingActivities">
                        <ngx-empty-state image="EmptyState.png" text="No activities!">
                        </ngx-empty-state>
                        <div style="text-align: center;margin-top: 10px;">
                            <button (click)="openDialog(addActivityDialog)" nbButton status="success">Add an
                                activity</button>
                        </div>
                    </div>
                </nb-card-body>
            </nb-card>
        </nb-card-body>
    </nb-card>
</ng-template>

<ng-template #dialogAddVisit let-data let-ref="dialogRef">
    <nb-card>
        <nb-card-header>
            visit
        </nb-card-header>
        <nb-card-body>
            <div class="form-group">
                <label class="label">Day</label>
                <input fullWidth nbInput placeholder="Day" [(ngModel)]="newVisit.day">
            </div>
            <div class="form-group">
                <label class="label">Duration</label>
                <input fullWidth nbInput placeholder="Visit duration" [(ngModel)]="newVisit.duration">
            </div>
        </nb-card-body>
        <nb-card-footer style="text-align: right;">
            <button [nbSpinner]="addingVisit" nbSpinnerStatus="success" (click)="addVisit(ref)" nbButton
                status="primary">Save Visit</button>
        </nb-card-footer>
    </nb-card>
</ng-template>

<ng-template #addActivityDialog let-data let-ref="dialogRef">
    <nb-card style="width: 400px;">
        <nb-card-header>
            Add an activity
        </nb-card-header>
        <nb-card-body>
            <div class="form-group">
                <label class="label">Activity</label>
                <nb-select [(ngModel)]="this.selectedActivityType" fullWidth>
                    <nb-option-group *ngFor="let activityCategory of activityCategories"
                        [title]="activityCategory.title">
                        <nb-option *ngFor="let type of activityCategory.activities" [value]="type">{{type.title}}
                        </nb-option>
                    </nb-option-group>
                </nb-select>
            </div>
        </nb-card-body>
        <nb-card-footer style="text-align: right;">
            <button [nbSpinner]="addingActivity" nbSpinnerStatus="success" (click)="addActivity(ref)" nbButton
                status="primary">Add activity</button>
        </nb-card-footer>
    </nb-card>
</ng-template>