import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { IdentityService } from "../../../../@core/app-services/identity.service";
import { SessionTypeService } from "../../../../@core/app-services/session-type.service";
import { ToasterHelper } from "../../../../@core/app-services/toasterHelper";
import { SessionType } from "../../../../@core/models/sessionType";
import { UserType } from "../../../../@core/models/userType";
import { AwsRepoConfig, AwsS3Manage } from "../../../../@core/lib/AwsS3Manage";

@Component({
  selector: "ngx-session-type",
  templateUrl: "./session-type.component.html",
  styleUrls: ["./session-type.component.scss"],
})
export class SessionTypeComponent implements OnInit {
  sessionTypes: SessionType[] = [];
  userTypes: UserType[] = [];
  rowSessionType: SessionType;
  refreshMode: string;
  taskSubject: string;
  taskDetailsHtml: SafeHtml;
  taskStatus: string;
  taskProgress: string;
  currentFilter: any;
  imageSelectedFile: File;
  videoSelectedFile: File;
  mediaSelectedFile: File;
  showFilterRow: boolean = true;
  awsS3Manage: AwsS3Manage = new AwsS3Manage();
  showHeaderFilter: boolean = true;
  autoNavigateToFocusedRow = true;
  loadingSessionTypes: boolean = false;
  rowKey: any;
  constructor(
    private SessionTypeService: SessionTypeService,
    private toasterHelper: ToasterHelper,
    private sanitizer: DomSanitizer,
    private router: Router,
    private identityService: IdentityService
  ) {}
  // get userType
  getUserTypes() {
    this.identityService.GetUserTypes().subscribe(
      (data) => {
        if (data.status) {
          this.toasterHelper.showToast(
            "get successfully",
            data.message,
            "success"
          );
          this.userTypes = data.responseObject;
        } else {
          this.userTypes = [];
          this.toasterHelper.showError(data.message);
        }
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
      }
    );
  }

  getSessionTypes() {
    this.loadingSessionTypes = true;
    this.SessionTypeService.getSessionTypes().subscribe(
      (data) => {
        if (data.status) {
          this.sessionTypes = data.responseObject;
        } else {
          this.sessionTypes = [];
        }
        this.loadingSessionTypes = false;
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
      }
    );
  }
  async updateSessionType(e) {
    this.loadingSessionTypes = true;
    let updatedSessionType = new SessionType();
    this.rowSessionType = e.data;
    updatedSessionType.description = this.rowSessionType.description;
    updatedSessionType.name = this.rowSessionType.name;
    updatedSessionType.userTypeId = this.rowSessionType.userTypeId;
    updatedSessionType.duration = this.rowSessionType.duration;
    updatedSessionType.numberOfUsers = this.rowSessionType.numberOfUsers;
    updatedSessionType.DescriptionAr = this.rowSessionType.DescriptionAr;
    updatedSessionType.descriptionEn = this.rowSessionType.descriptionEn;
    updatedSessionType.nameEn = this.rowSessionType.nameEn;
    updatedSessionType.nameAr = this.rowSessionType.nameAr;
    updatedSessionType.price = this.rowSessionType.price;
    updatedSessionType.isDevice = this.rowSessionType.isDevice;
    if (this.imageSelectedFile) {
      await this.awsS3Manage
        .uploadFile({
          fileStream: this.imageSelectedFile,
          name:
            updatedSessionType.nameEn +
            "image" +
            "." +
            this.imageSelectedFile.type.split("/").pop(),
          location: AwsRepoConfig.sessionTypeURL,
        })
        .then((result) => {
          updatedSessionType.imageUrl = result.Location;
          this.imageSelectedFile = null;
        })
        .catch((error) => {
          this.toasterHelper.showError(
            "Couldn't set a image for " + updatedSessionType.nameEn
          );
          this.imageSelectedFile = null;
          console.log(error.message);
        });
    }
    if (this.videoSelectedFile) {
      await this.awsS3Manage
        .uploadFile({
          fileStream: this.videoSelectedFile,
          name:
            updatedSessionType.nameEn +
            "video" +
            "." +
            this.videoSelectedFile.type.split("/").pop(),
          location: AwsRepoConfig.sessionTypeURL,
        })
        .then((result) => {
          updatedSessionType.videoURL = result.Location;
          this.videoSelectedFile = null;
        })
        .catch((error) => {
          this.toasterHelper.showError(
            "Couldn't set a video for " + updatedSessionType.nameEn
          );
          this.videoSelectedFile = null;
          console.log(error.message);
        });
    }
    if (this.mediaSelectedFile) {
      await this.awsS3Manage
        .uploadFile({
          fileStream: this.mediaSelectedFile,
          name:
            updatedSessionType.nameEn +
            "media" +
            "." +
            this.mediaSelectedFile.type.split("/").pop(),
          location: AwsRepoConfig.sessionTypeURL,
        })
        .then((result) => {
          updatedSessionType.mediaURL = result.Location;
          this.mediaSelectedFile = null;
        })
        .catch((error) => {
          this.toasterHelper.showError(
            "Couldn't set a media for " + updatedSessionType.nameEn
          );
          this.mediaSelectedFile = null;
          console.log(error.message);
        });
    }

    await this.SessionTypeService.updateSessionType(
      this.rowSessionType.id,
      updatedSessionType
    ).subscribe(
      async (data) => {
        if (data.status) {
          this.toasterHelper.showToast(
            "Updated successfully",
            data.message,
            "success"
          );
        } else {
          this.toasterHelper.showError(data.message);
        }
        this.loadingSessionTypes = true;

        await this.getSessionTypes();
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
        this.loadingSessionTypes = false;
      }
    );
  }

  async addSessionType(e) {
    this.loadingSessionTypes = true;
    let newSessionType = new SessionType();
    this.rowSessionType = e.data;
    newSessionType.description = this.rowSessionType.description;
    newSessionType.name = this.rowSessionType.name;
    newSessionType.DescriptionAr = this.rowSessionType.DescriptionAr;
    newSessionType.descriptionEn = this.rowSessionType.descriptionEn;
    newSessionType.nameEn = this.rowSessionType.nameEn;
    newSessionType.nameAr = this.rowSessionType.nameAr;
    newSessionType.duration = this.rowSessionType.duration;
    newSessionType.userTypeId = this.rowSessionType.userTypeId;
    newSessionType.numberOfUsers = this.rowSessionType.numberOfUsers;
    newSessionType.price = this.rowSessionType.price;
    newSessionType.isDevice = this.rowSessionType.isDevice;

    if (this.imageSelectedFile) {
      await this.awsS3Manage
        .uploadFile({
          fileStream: this.imageSelectedFile,
          name:
            newSessionType.nameEn +
            "image" +
            "." +
            this.imageSelectedFile.type.split("/").pop(),
          location: AwsRepoConfig.sessionTypeURL,
        })
        .then((result) => {
          newSessionType.imageUrl = result.Location;
        })
        .catch((error) => {
          this.toasterHelper.showError(
            "Couldn't set a image for " + newSessionType.nameEn
          );
          newSessionType.imageUrl =
            "https://diethub-resources.s3.eu-central-1.amazonaws.com/defaultimage.jpg";
          console.log(error.message);
        });
    } else {
      newSessionType.imageUrl =
        "https://diethub-resources.s3.eu-central-1.amazonaws.com/defaultimage.jpg";
    }

    if (this.videoSelectedFile) {
      await this.awsS3Manage
        .uploadFile({
          fileStream: this.videoSelectedFile,
          name:
            newSessionType.nameEn +
            "video" +
            "." +
            this.imageSelectedFile.type.split("/").pop(),
          location: AwsRepoConfig.sessionTypeURL,
        })
        .then((result) => {
          newSessionType.videoURL = result.Location;
        })
        .catch((error) => {
          this.toasterHelper.showError(
            "Couldn't set a video for " + newSessionType.nameEn
          );
          newSessionType.videoURL =
            "https://diethub-resources.s3.eu-central-1.amazonaws.com/defaultimage.jpg";
          console.log(error.message);
        });
    } else {
      newSessionType.videoURL =
        "https://diethub-resources.s3.eu-central-1.amazonaws.com/defaultimage.jpg";
    }

    if (this.mediaSelectedFile) {
      await this.awsS3Manage
        .uploadFile({
          fileStream: this.mediaSelectedFile,
          name:
            newSessionType.nameEn +
            "media" +
            "." +
            this.imageSelectedFile.type.split("/").pop(),
          location: AwsRepoConfig.sessionTypeURL,
        })
        .then((result) => {
          newSessionType.mediaURL = result.Location;
        })
        .catch((error) => {
          this.toasterHelper.showError(
            "Couldn't set a media for " + newSessionType.nameEn
          );
          newSessionType.mediaURL =
            "https://diethub-resources.s3.eu-central-1.amazonaws.com/defaultimage.jpg";
          console.log(error.message);
        });
    } else {
      newSessionType.mediaURL =
        "https://diethub-resources.s3.eu-central-1.amazonaws.com/defaultimage.jpg";
    }

    await this.SessionTypeService.addSessionType(newSessionType).subscribe(
      async (data) => {
        if (data.status) {
          this.toasterHelper.showToast(
            "Added successfully",
            data.message,
            "success"
          );
        } else {
          this.toasterHelper.showError(data.message);
        }
        this.loadingSessionTypes = false;
        this.imageSelectedFile = null;
        this.videoSelectedFile = null;
        this.mediaSelectedFile = null;
        await this.getSessionTypes();
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
        this.loadingSessionTypes = false;
      }
    );
  }
  deleteSessionType(e) {
    this.loadingSessionTypes = true;
    this.rowSessionType = e.data;
    this.SessionTypeService.deleteSessionType(this.rowSessionType.id).subscribe(
      (data) => {
        if (data.status) {
          this.toasterHelper.showToast(
            "Deleted successfully",
            data.message,
            "success"
          );
        } else {
          this.toasterHelper.showError(data.message);
        }
        this.loadingSessionTypes = false;
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
        this.loadingSessionTypes = false;
      }
    );
    if (
      this.rowSessionType.imageUrl != null ||
      this.rowSessionType.imageUrl.length != 0 ||
      this.rowSessionType.imageUrl !=
        "https://diethub-resources.s3.eu-central-1.amazonaws.com/defaultimage.jpg"
    ) {
      this.awsS3Manage
        .deleteFile({
          fileName:
            this.rowSessionType.nameEn +
            "image" +
            "." +
            this.rowSessionType.imageUrl.split(".").pop(),
          location: AwsRepoConfig.sessionTypeURL,
        })
        .then((result) => {})
        .catch((error) => {
          this.toasterHelper.showError("Couldn't set a animation photo for ");
          console.log(error.message);
        });
    }

    if (
      this.rowSessionType.videoURL != null ||
      this.rowSessionType.videoURL.length != 0 ||
      this.rowSessionType.videoURL !=
        "https://diethub-resources.s3.eu-central-1.amazonaws.com/defaultimage.jpg"
    ) {
      this.awsS3Manage
        .deleteFile({
          fileName:
            this.rowSessionType.nameEn +
            "video" +
            "." +
            this.rowSessionType.videoURL.split(".").pop(),
          location: AwsRepoConfig.sessionTypeURL,
        })
        .then((result) => {})
        .catch((error) => {
          this.toasterHelper.showError("Couldn't set a video photo for ");
          console.log(error.message);
        });
    }
    if (
      this.rowSessionType.mediaURL != null ||
      this.rowSessionType.mediaURL.length != 0 ||
      this.rowSessionType.mediaURL !=
        "https://diethub-resources.s3.eu-central-1.amazonaws.com/defaultimage.jpg"
    ) {
      this.awsS3Manage
        .deleteFile({
          fileName:
            this.rowSessionType.nameEn +
            "media" +
            "." +
            this.rowSessionType.mediaURL.split(".").pop(),
          location: AwsRepoConfig.sessionTypeURL,
        })
        .then((result) => {})
        .catch((error) => {
          this.toasterHelper.showError("Couldn't set a video photo for ");
          console.log(error.message);
        });
    }
  }
  onFocusedRowChanging(e) {
    const rowsCount = e.component.getVisibleRows().length;
    const pageCount = e.component.pageCount();
    const pageIndex = e.component.pageIndex();
    const key = e.event && e.event.key;
    const rowData = e.row && e.row.data;
    if (key && e.prevRowIndex === e.newRowIndex) {
      if (e.newRowIndex === rowsCount - 1 && pageIndex < pageCount - 1) {
        e.component.pageIndex(pageIndex + 1).done(() => {
          e.component.option("focusedRowIndex", 0);
        });
      } else if (e.newRowIndex === 0 && pageIndex > 0) {
        e.component.pageIndex(pageIndex - 1).done(() => {
          e.component.option("focusedRowIndex", rowsCount - 1);
        });
      }
    }
  }
  onFocusedRowChanged(e) {
    const rowData = e.row && e.row.data;
    // this.rowPackage= rowData;
    if (rowData) {
      this.taskSubject = rowData.Task_Subject;
      this.taskDetailsHtml = this.sanitizer.bypassSecurityTrustHtml(
        rowData.Task_Description
      );
      this.taskStatus = rowData.Task_Status;
      this.taskProgress = rowData.Task_Completion
        ? `${rowData.Task_Completion}` + "%"
        : "";
    }
  }
  onEditingStart(e) {
    this.rowKey = e.key;
  }

  onSaving(e) {
    if (e.changes.length == 0) {
      e.changes.push({
        type: "update",
        key: this.rowKey,
        data: {},
      });
    }
  }
  onClick(e: any): void {}

  onImageValueChanged(e: any): void {
    this.imageSelectedFile = e.value[0]; // convert to base64 string
  }
  onVideoValueChanged(e: any): void {
    this.videoSelectedFile = e.value[0]; // convert to base64 string
  }
  onMediaValueChanged(e: any): void {
    this.mediaSelectedFile = e.value[0]; // convert to base64 string
  }
  onUploaded(e: any, cellInfo: any): void {}

  onUploadError(e: any): void {
    const xhttp = e.request;
    if (xhttp.status === 400) {
      e.message = e.error.responseText;
    }
    if (xhttp.readyState === 4 && xhttp.status === 0) {
      e.message = "Connection refused";
    }
  }

  onEditCanceled(e: any): void {}

  onSaved(e: any): void {}

  ngOnInit(): void {
    this.getSessionTypes();
    this.getUserTypes();
  }
}
