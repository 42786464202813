import { Component, Input, OnInit, TemplateRef } from "@angular/core";
import { NbDialogService } from "@nebular/theme";
import { Router } from "@angular/router";
import { UserSharedService } from "../../../../@core/shared-services/user-shared.service";
import { VisitService } from "../../../../@core/app-services/visit.service";
import { ToasterHelper } from "../../../../@core/app-services/toasterHelper";
import { Visit } from "../../../../@core/models/visit";

@Component({
  selector: "ngx-checkvisit",
  templateUrl: "./checkvisit.component.html",
  styleUrls: ["./checkvisit.component.scss"],
})
export class CheckVisitComponent {
  newVisitDate: Date = new Date();
  newVisitStartTime: number = 0;
  @Input() relatedVisit: Visit;
  constructor(private toasterHelper: ToasterHelper,
              private router:Router,
              private visitService:VisitService,
              public userSharedService: UserSharedService) {

  }
 
  updateVisitStatus(status: number) {
    if(status == 4)
    {
      this.relatedVisit.date = this.relatedVisit.startTime = this.relatedVisit.endTime = this.newVisitDate;
      this.relatedVisit.startTime.setHours(this.newVisitStartTime); 
      this.relatedVisit.endTime.setHours(this.newVisitStartTime + 0.5); 
      this.visitService.updateVisit(this.relatedVisit).subscribe((data) => {
        if (data.status) {
          //this.visitUpdated();
          this.toasterHelper.showSaved(data.message);
          this.relatedVisit.status = "NA";
        } else {
          this.toasterHelper.showError(data.message);
        }
      });
    }
    else
    {
      this.visitService
      .updateVisitStatus(this.relatedVisit.id, status)
      .subscribe((data) => {
        if (data.status) {
          this.toasterHelper.showSaved(data.message);
          this.relatedVisit.status = status == 2 ? "Checked in" :
          this.relatedVisit.status = status == 3 ? "Missed" : "NA";
        } else {
          this.toasterHelper.showError(data.message);
        }
      });
    }
  }
  
}
