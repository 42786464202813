import { Component, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NbDialogService } from '@nebular/theme';
import { CallService } from '../../../@core/app-services/call.service';
import { ToasterHelper } from '../../../@core/app-services/toasterHelper';
import { AuditCallModel } from '../../../@core/models/auditCallModel';
import { Call } from '../../../@core/models/call';
import { LogCallModel } from '../../../@core/models/logCallModel';

@Component({
  selector: 'ngx-followup-calls',
  templateUrl: './followup-calls.component.html',
  styleUrls: ['./followup-calls.component.scss']
})
export class FollowupCallsComponent implements OnInit {
  getClientCalls: boolean = true;
  calls: Call[] = new Array();
  description: string;
  comment: string;
  logCallModel: LogCallModel = new LogCallModel();
  hasFollowup: boolean = false;
  addingCall: boolean = false;
  constructor(
    private toasterHelper: ToasterHelper,
    private callService: CallService,
    private dialogService: NbDialogService
  ) {
    this.getCalls();        
  }
  getCalls(){
    this.getClientCalls = true;
    this.callService.getFollowupCalls().subscribe((data) => {
      this.calls = data;
      this.getClientCalls = false;
    });
  }
  openLogNewCall(dialog: TemplateRef<any>) {
    this.dialogService.open(dialog);
  }
  openCallDescription(dialog: TemplateRef<any>, description: string) {
    this.description = description;
    this.dialogService.open(dialog);
  }
  logCall(ref) {
    this.addingCall = true;
    if(!this.hasFollowup){
      this.logCallModel.followupDate = null;
    }
    this.callService.logCall(this.logCallModel).subscribe((data) => {
      if (data.status) {
        this.toasterHelper.showSaved(data.message);
        this.getCalls();
      } else {
        this.toasterHelper.showError(data.message);
      }
      this.addingCall = false;
      ref.close();
    });
  }
  ngOnInit(): void {}
}
