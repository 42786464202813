import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { ApiResponse } from "../models/ApiResponse";
import { Meal } from "../models/meal";
import { MealType } from "../models/mealType";

@Injectable({
  providedIn: "root",
})
export class MealService {
  private getMealsPath = environment.apiUrl + "Meal/GetMeals";
  private addMealPath = environment.apiUrl + "Meal/AddMeal";
  private deleteMealPath = environment.apiUrl + "Meal/DeleteMealById?id=";
  private updateMealPath = environment.apiUrl + "Meal/UpdateMeal?id=";

  private getMealTypesPath = environment.apiUrl + "MealTypes/GetMealTypes";
  private addMealTypePath = environment.apiUrl + "MealTypes/AddMealType";
  private deleteMealTypePath =
    environment.apiUrl + "MealTypes/DeleteMealTypeById?id=";
  private updateMealTypePath =
    environment.apiUrl + "MealTypes/UpdateMealType?id=";

  constructor(private http: HttpClient) {}
  // meal apis

  getMeals(): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.getMealsPath);
  }
  addMeal(model: Meal): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.addMealPath, model);
  }
  updateMeal(mealId: number, model: Meal): Observable<ApiResponse> {
    return this.http.put<ApiResponse>(this.updateMealPath + mealId, model);
  }
  deleteMeal(mealId: number): Observable<ApiResponse> {
    return this.http.delete<ApiResponse>(this.deleteMealPath + mealId);
  }

  // meal type apis
  getMealTypes(): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.getMealTypesPath);
  }

  addMealType(model: MealType): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.addMealTypePath, model);
  }
  updateMealType(mealTypeId: number, model: MealType): Observable<ApiResponse> {
    return this.http.put<ApiResponse>(
      this.updateMealTypePath + mealTypeId,
      model
    );
  }
  deleteMealType(mealTypeId: number): Observable<ApiResponse> {
    return this.http.delete<ApiResponse>(this.deleteMealTypePath + mealTypeId);
  }
}
