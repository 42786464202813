<div class="row">
    <div class="col-md-4">
        <nb-card class="own-scroll">
            <nb-card-header>
                <div class="row">
                    <div class="col-md-10">
                        Set meal Types
                    </div>
                </div>
            </nb-card-header>
        </nb-card>
        <nb-card class="own-scroll">

            <nb-card-body [nbSpinner]="loadingMealTypes" nbSpinnerStatus="primary" nbSpinnerSize="large">
                <div id="data-grid-demo">
                    <dx-data-grid #grid id="gridContainer" [dataSource]="mealTypes" keyExpr="id"
                        [focusedRowEnabled]="true" [showBorders]="true"
                        (onFocusedRowChanging)="onFocusedRowChanging($event)"
                        (onFocusedRowChanged)="onFocusedRowChanged($event)" (onRowUpdated)="updateMealType($event)"
                        (onRowInserting)="addMealType($event)" (onRowRemoving)="deleteMealType($event)">

                        <dxo-paging [enabled]="false"></dxo-paging>
                        <dxo-editing mode="form" [refreshMode]="refreshMode" [allowUpdating]="true"
                            [allowDeleting]="true" [allowAdding]="true">
                        </dxo-editing>
                        <dxo-filter-row [visible]="showFilterRow" [applyFilter]="currentFilter"></dxo-filter-row>
                        <dxo-header-filter [visible]="showHeaderFilter"></dxo-header-filter>
                        <dxo-search-panel [visible]="true" [width]="240" placeholder="Search...">

                        </dxo-search-panel>
                        <dxi-column dataField="nameAr">
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dxi-column>
                        <dxi-column dataField="nameEn">
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dxi-column>

                    </dx-data-grid>
                </div>
            </nb-card-body>
        </nb-card>



    </div>
    <div class="col-md-8">
        <nb-card class="own-scroll">
            <nb-card-header>
                <div class="row">
                    <div class="col-md-10">
                        Set meal ingredients
                    </div>
                </div>
            </nb-card-header>
        </nb-card>
        <nb-card class="own-scroll">

            <nb-card-body [nbSpinner]="loadingIngredients" nbSpinnerStatus="primary" nbSpinnerSize="large">
                <div id="data-grid-demo">
                    <div>
                        <label>Choose a Meal</label>
                        <select #selectElem class="custom-select" (change)="selectMeal(selectElem.value)">
                            <option disabled selected value>select a meal</option>
                            <option *ngFor="let meal of meals" ([ngValue])="user">
                                {{meal?.nameEn}}</option>
                        </select>
                        <hr>

                    </div>
                    <dx-data-grid #grid id="gridContainer" [dataSource]="ingredients" keyExpr="id"
                        [focusedRowEnabled]="true" [showBorders]="true"
                        (onFocusedRowChanging)="onFocusedRowChanging($event)"
                        (onFocusedRowChanged)="onFocusedRowChanged($event)" (onRowUpdated)="updateIngredient($event)"
                        (onRowInserting)="addIngredient($event)" (onRowRemoving)="deleteIngredient($event)"
                        [disabled]="isDisabled">

                        <dxo-paging [enabled]="false"></dxo-paging>
                        <dxo-editing mode="form" [refreshMode]="refreshMode" [allowUpdating]="true"
                            [allowDeleting]="true" [allowAdding]="true">
                        </dxo-editing>
                        <dxo-filter-row [visible]="showFilterRow" [applyFilter]="currentFilter"></dxo-filter-row>
                        <dxo-header-filter [visible]="showHeaderFilter"></dxo-header-filter>
                        <dxo-search-panel [visible]="true" [width]="240" placeholder="Search...">

                        </dxo-search-panel>
                        <dxi-column dataField="nameAr">
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dxi-column>
                        <dxi-column dataField="nameEn">
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dxi-column>
                        <dxi-column dataField="uniteAr">
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dxi-column>
                        <dxi-column dataField="uniteEn">
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dxi-column>
                        <dxi-column dataField="amount">
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dxi-column>
                    </dx-data-grid>
                </div>
            </nb-card-body>
        </nb-card>



    </div>
    <div class="col-md-12">
        <nb-card class="own-scroll">
            <nb-card-header>
                <div class="row">
                    <div class="col-md-10">
                        Set meal
                    </div>
                </div>
            </nb-card-header>
        </nb-card>
        <nb-card class="own-scroll">

            <nb-card-body [nbSpinner]="loadingMeals" nbSpinnerStatus="primary" nbSpinnerSize="large">
                <div id="data-grid-demo">
                    <dx-data-grid #grid id="gridContainer" [dataSource]="meals" keyExpr="id" [focusedRowEnabled]="true"
                        [showBorders]="true" (onFocusedRowChanging)="onFocusedRowChanging($event)"
                        (onEditingStart)="onEditingStart($event)" (onSaving)="onSaving($event)"
                        (onFocusedRowChanged)="onFocusedRowChanged($event)" (onRowUpdated)="updateMeal($event)"
                        (onRowInserting)="addMeal($event)" (onRowRemoving)="deleteMeal($event)"
                        (onEditCanceled)="onEditCanceled($event)" (onSaved)="onSaved($event)">

                        <dxo-paging [enabled]="false"></dxo-paging>
                        <dxo-editing mode="form" [refreshMode]="refreshMode" [allowUpdating]="true"
                            [allowDeleting]="true" [allowAdding]="true">
                            <!-- control uploading image -->
                            <dxi-item itemType="group" caption="Photo" [colCount]="2" [colSpan]="2">
                                <dxi-item dataField="imageUrl" [colSpan]="2"></dxi-item>
                            </dxi-item>
                        </dxo-editing>

                        <dxo-filter-row [visible]="showFilterRow" [applyFilter]="currentFilter"></dxo-filter-row>
                        <dxo-header-filter [visible]="showHeaderFilter"></dxo-header-filter>
                        <dxo-search-panel [visible]="true" [width]="240" placeholder="Search...">

                        </dxo-search-panel>
                        <dxi-column dataField="nameAr">
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dxi-column>
                        <dxi-column dataField="nameEn">
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dxi-column>
                        <dxi-column dataField="descriptionAr">
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dxi-column>
                        <dxi-column dataField="descriptionEn">
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dxi-column>
                        <dxi-column dataField="recipeAr">
                            <dxo-form-item [colSpan]="2" editorType="dxTextArea" [editorOptions]="{ height: 100 }">
                            </dxo-form-item>
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dxi-column>
                        <dxi-column dataField="recipeEn">
                            <dxo-form-item [colSpan]="2" editorType="dxTextArea" [editorOptions]="{ height: 100 }">
                            </dxo-form-item>
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dxi-column>
                        <dxi-column dataField="mealTypeId" caption="mealType" [width]="125">
                            <dxo-lookup [dataSource]="mealTypes" displayExpr="nameEn" valueExpr="id">
                            </dxo-lookup>
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dxi-column>
                        <dxi-column dataField="carb" dataType="number">
                            <dxi-validation-rule type="required"></dxi-validation-rule>

                        </dxi-column>
                        <dxi-column dataField="fats" dataType="number">
                            <dxi-validation-rule type="required"></dxi-validation-rule>

                        </dxi-column>
                        <dxi-column dataField="cookingMinutes" dataType="number">
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dxi-column>
                        <dxi-column dataField="weight" dataType="number">
                            <dxi-validation-rule type="required"></dxi-validation-rule>

                        </dxi-column>
                        <dxi-column dataField="proteins" dataType="number">
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dxi-column>
                        <dxi-column dataField="calories" dataType="number">
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dxi-column>
                        <!-- image column -->
                        <dxi-column dataField="imageUrl" [width]="70" [allowFiltering]="false" [allowSorting]="false"
                            cellTemplate="cellTemplate" editCellTemplate="editCellTemplate">
                        </dxi-column>
                        <!-- image selection -->
                        <div *dxTemplate="let data of 'cellTemplate'">
                            <img [src]="data.value" width="70" height="70" />
                        </div>
                        <div *dxTemplate="let data of 'editCellTemplate'">
                            <img #uploadedImage class="uploadedImage" width="70" height="70" [src]="data.value" />
                            <dx-file-uploader #fileUploader [multiple]="false" uploadMode="instantly"
                                (onValueChanged)="onValueChanged($event)" (onUploaded)="onUploaded($event, data)"
                                (onUploadError)="onUploadError($event)"></dx-file-uploader>
                        </div>
                    </dx-data-grid>
                </div>
            </nb-card-body>
        </nb-card>



    </div>
</div>