import { Component, OnInit, TemplateRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NbDialogService, NbMenuItem } from "@nebular/theme";
import { OperationPlanService } from "../../../../@core/app-services/operationPlan.service";
import { ToasterHelper } from "../../../../@core/app-services/toasterHelper";
import { Visit } from "../../../../@core/models/visit";
import { Milestone } from "../../../../@core/models/milestone";
import { OperationPlan } from "../../../../@core/models/operationPlan";
import { OperationPlanType } from "../../../../@core/models/operationPlanType";
import { UserModel } from "../../../../@core/models/userModel";
import { VisitService } from "../../../../@core/app-services/visit.service";
import { Slot } from "../../../../@core/models/slot";
import { Statistics } from "../../../../@core/models/statistics";
import {NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';
import { UserSharedService } from "../../../../@core/shared-services/user-shared.service";

@Component({
  selector: "ngx-operation-plans",
  templateUrl: "./operation-plans.component.html",
  styleUrls: ["./operation-plans.component.scss"],
})

export class OperationPlansComponent implements OnInit {
  operationPlans: OperationPlan[] = new Array();
  operationPlanTypes: OperationPlanType[] = new Array();
  selectedOperationPlan: OperationPlan;
  selectedMilestone: Milestone;
  selectedVisit: Visit;
  patientId: number;
  doctors: UserModel[] = new Array();
  newPlan: OperationPlan = new OperationPlan();
  newMilestone: Milestone = new Milestone();
  selectedDay: Date = new Date();
  selectedDoctorId: string;
  slots: Slot[] = new Array();
  newVisit: Visit = new Visit();
  gettingOperationPlans: boolean = true;
  gettingVisits: boolean = false;
  gettingStatistics: boolean = false;
  operationPlanStatistics: Statistics = new Statistics();
  gettingAvailableSlots: boolean = false;
  savingOperationPlan: boolean = false;
  addingMilestone: boolean = false;
  deletingPlan: boolean = false;
  newVisitDate: Date = new Date();
  newVisitStartTime: number = 0;
  // Commented for future upon adding the timepicker
  /*
  time: NgbTimeStruct = {hour: 12, minute: 0, second: 0};
  hourStep = 1;
  minuteStep = 15;
  */
  operationStartTime: number;

  constructor(
    private route: ActivatedRoute,
    private toasterHelper: ToasterHelper,
    private dialogService: NbDialogService,
    private operationService: OperationPlanService,
    private visitService: VisitService,
    private router:Router,
    public userSharedService: UserSharedService
  ) {
    operationService.getAddingOperationPlanDataModel().subscribe((data) => {
      this.operationPlanTypes = data.operationPlanTypes;
      this.doctors = data.doctors;
    });
    route.queryParams.subscribe(
      (params) => {
        this.patientId = this.route.snapshot.params.id;
        if (this.patientId != 0) {
          this.getOperationPlans();
        }
      },
      (error) => {
        toasterHelper.showError(error);
      }
    );
  }
  getOperationPlans() {
    this.gettingOperationPlans = true;
    this.operationService
      .getPatientOperationPlans(this.patientId)
      .subscribe((data) => {
        this.operationPlans = data;
        this.selectPlan(this.operationPlans[this.operationPlans.length-1]);
        this.selectMilestone(this.operationPlans[this.operationPlans.length-1].milestones[0]);
        this.gettingOperationPlans = false;
      });
  }
  selectPlan(plan) {
    this.selectedOperationPlan = plan;
    this.selectedDoctorId = this.selectedOperationPlan.doctorId;
    this.selectedMilestone = null;
    this.gettingStatistics = true;
    this.operationService
      .getOperationPlanStatistics(this.selectedOperationPlan.planId)
      .subscribe((data) => {
        this.operationPlanStatistics = data;
        this.gettingStatistics = false;
      });
  }
  selectMilestone(milestone: Milestone) {
    this.selectedMilestone = milestone;
    this.reloadVisits();
  }
  getVisits(): number {
    let sum: number = 0;
    this.selectedOperationPlan.milestones.forEach(
      (m) => (sum += m.visits.length)
    );
    return sum;
  }
  openVisit(visit, dialog: TemplateRef<any>) {
    this.selectedVisit = visit;
    if(this.userSharedService.UserTypeId == 1 && visit.status == "Checked in")
    {
      this.router.navigate(['/pages/dashboard/visit'],{queryParams:{visitid:this.selectedVisit.id}});
    }
    else
    {
        this.dialogService.open(dialog)
    }
  }
  openDialog(dialog: TemplateRef<any>) {
    this.dialogService.open(dialog);
  }
  addPlan(ref) {
    this.savingOperationPlan = true;
    this.newPlan.patientId = this.patientId;
    /* fixme: this is to fix starting date of assigned operation plan to client */
    this.newPlan.startDate.setDate( this.newPlan.startDate.getDate() + 1 );
    this.newPlan.startDate.setUTCHours(this.operationStartTime);
    this.newPlan.startDate.setMinutes(0);
    this.newPlan.startDate.setSeconds(0);

    this.operationService.addOperationPlan(this.newPlan).subscribe((data) => {
      if (data.status) {
        this.toasterHelper.showAdded(data.message);
        this.newPlan.planId = data.responseObject;
        this.newPlan.planTitle = this.operationPlanTypes.find(
          (o) => o.id == this.newPlan.planType
        ).planTitle;
        this.newPlan.milestones = new Array();
        this.newPlan.status = "Not started";
        this.operationPlans.push(this.newPlan);
        this.getOperationPlans();
        ref.close();
      } else {
        this.toasterHelper.showError(data.message);
      }
      this.savingOperationPlan = false;
    });
  }
  addMilestone() {
    this.addingMilestone = true;
    this.newMilestone.operationPlanId = this.selectedOperationPlan.planId;
    this.newMilestone.title =
      "Milestone-" + (this.selectedOperationPlan.milestones.length + 1);
    this.operationService.addMilestone(this.newMilestone).subscribe((data) => {
      if (data.status) {
        this.toasterHelper.showAdded(data.message);
        this.newMilestone.id = data.responseObject;
        this.selectedOperationPlan.milestones.push(this.newMilestone);
      } else {
        this.toasterHelper.showError(data.message);
      }
      this.addingMilestone = false;
    });
  }
  editPlan() {
    // this.newPlan = this.selectedOperationPlan;
  }
  getAvailableSlots() {
    this.gettingAvailableSlots = true;
    this.visitService
      .getDaySlotsByDoctor(this.selectedDay, "")
      .subscribe((data) => {
        this.slots = data;
        this.gettingAvailableSlots = false;
      });
  }
  bookSlot(slot: Slot) {
    if (slot.isAvailable) {
      slot.bookingSlot = true;
      this.newVisit.startTime = slot.startFrom;
      this.newVisit.endTime = slot.endAt;
      this.newVisit.milestoneId = this.selectedMilestone.id;
      this.newVisit.patientId = this.patientId;
      this.newVisit.doctorId = this.selectedDoctorId;
      this.newVisit.date = new Date();
      this.newVisit.date.setDate(this.selectedDay.getDate());
      this.newVisit.status = "NA";
      this.visitService.addVisit(this.newVisit).subscribe((data) => {
        if (data.status) {
          this.toasterHelper.showAdded(data.message);
          slot.isAvailable = false;
          this.newVisit.id = data.responseObject;
          this.selectedMilestone.visits.push(this.newVisit);
        } else {
          this.toasterHelper.showError(data.message);
        }
        slot.bookingSlot = false;
      });
    } else {
      this.toasterHelper.showError("This slot is not availble!");
      return;
    }
  }
  deletePlan(plan: OperationPlan) {
    this.deletingPlan = true;
    this.operationService.deletePlan(plan.planId).subscribe((data) => {
      if (data.status) {
        this.toasterHelper.showSaved(data.message);
        this.selectedOperationPlan = null;
        const index = this.operationPlans.indexOf(plan, 0);
        if (index > -1) {
          this.operationPlans.splice(index, 1);
          this.deletingPlan = false;
        }
      } else {
        this.toasterHelper.showError(data.message);
        this.deletingPlan = false;
      }
    });
  }
  deleteMilestone(milestone: Milestone) {
    this.operationService.deleteMilestone(milestone.id).subscribe((data) => {
      if (data.status) {
        this.toasterHelper.showSaved(data.message);
        this.selectedMilestone = null;
        const index = this.selectedOperationPlan.milestones.indexOf(
          milestone,
          0
        );
        if (index > -1) {
          this.selectedOperationPlan.milestones.splice(index, 1);
        }
      } else {
        this.toasterHelper.showError(data.message);
      }
    });
  }
  
  reloadVisits() {
    this.gettingVisits = true;
    this.operationService
      .getMilestoneVisits(this.selectedMilestone.id)
      .subscribe((data) => {
        this.selectedMilestone.visits = data;
        this.gettingVisits = false;
      });
  }
  ngOnInit(): void {}
}
