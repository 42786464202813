import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from "../../../environments/environment";
import { ApiResponse } from '../models/ApiResponse';
import { Package } from '../models/package';

@Injectable({
  providedIn: 'root'
})
export class PackageService {
  private getPackagesPath = environment.applicationUrl + "Package/GetPackages";
  private addPackagePath = environment.applicationUrl + "Package/AddPackage";
  private deletePackagePath = environment.applicationUrl + "Package/DeletePackageById?id=";
  private updatePackagePath = environment.applicationUrl + "Package/UpdatePackage?id=";
  constructor(private http: HttpClient) {}

  getPackages(): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.getPackagesPath);
  }
  addPackage(model: Package): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.addPackagePath, model);
  }
  updatePackage(packageId: number,model: Package): Observable<ApiResponse> {
    return this.http.put<ApiResponse>(this.updatePackagePath + packageId, model);
  }
  deletePackage(packageId: number): Observable<ApiResponse> {
    return this.http.delete<ApiResponse>(this.deletePackagePath +packageId);
  }
}
