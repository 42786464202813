import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NbTokenService } from "@nebular/auth";
import { SessionService } from "../../../../@core/app-services/session.service";
import { ToasterHelper } from "../../../../@core/app-services/toasterHelper";
import { GetSessionsFilterModel } from "../../../../@core/models/getSessionsFilterModel";
import { Session } from "../../../../@core/models/session";
import { UserSharedService } from "../../../../@core/shared-services/user-shared.service";

@Component({
  selector: "ngx-session-schedule",
  templateUrl: "./session-schedule.component.html",
  styleUrls: ["./session-schedule.component.scss"],
})
export class SessionScheduleComponent implements OnInit {
  sessions: Session[] = [];
  currentUserSessions: Session[] = [];
  AllSessionsCount: number;
  mySessionsCount: number;
  NACount: number;
  ConfirmedCount: number;
  MissedCount: number;
  loadingSessions: boolean = false;
  loadingCurrentUserSessions: boolean = false;

  selectedDate = new Date();
  constructor(
    private sessionService: SessionService,
    private router: Router,
    private toasterHelper: ToasterHelper,
    private tokenService: NbTokenService,
    private userSharedService: UserSharedService
  ) {}
  getSessions(from: Date, to: Date) {
    this.loadingSessions = true;
    let sessionFilterModel = new GetSessionsFilterModel();
    sessionFilterModel.from = from;
    sessionFilterModel.to = to;
    this.sessionService
      .getSessionsWithFiltration(sessionFilterModel)
      .subscribe((data) => {
        if (data.status) {
          this.sessions = data.responseObject;
        } else {
          this.sessions = [];
        }
        this.loadingSessions = false;
      });
  }
  getSessionsForCurrentUser(from: Date, to: Date) {
    this.loadingCurrentUserSessions = true;
    let sessionFilterModel = new GetSessionsFilterModel();
    sessionFilterModel.from = from;
    sessionFilterModel.to = to;
    this.sessionService.getSessionsForCurrentUser(sessionFilterModel).subscribe(
      (data) => {
        if (data.status) {
          this.currentUserSessions = data.responseObject;
          console.log(this.currentUserSessions);
        } else {
          this.currentUserSessions = [];
        }
        this.loadingCurrentUserSessions = false;
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
        this.loadingCurrentUserSessions = false;
      }
    );
  }
  reloadSchedule() {
    let endDate = new Date();
    let startDate = new Date();
    startDate = this.selectedDate;
    startDate.setHours(this.selectedDate.getHours() + 2);
    endDate.setFullYear(this.selectedDate.getFullYear());
    endDate.setMonth(this.selectedDate.getMonth());
    endDate.setDate(this.selectedDate.getDate() + 1);
    endDate.setHours(this.selectedDate.getHours());
    endDate.setMinutes(this.selectedDate.getMinutes());
    endDate.setSeconds(this.selectedDate.getSeconds());
    this.getSessions(this.selectedDate, endDate);
    this.getSessionsForSelectedDate(this.selectedDate, endDate);
    this.getSessionsForCurrentUser(this.selectedDate, endDate);
    this.getSessionsForSelectedDate(this.selectedDate, endDate);
  }
  getSessionsForSelectedDate(from, to) {
    this.AllSessionsCount = this.sessions.length;
    this.NACount = this.sessions.filter((s) => s.sessionStatus === 1).length;
    this.ConfirmedCount = this.sessions.filter(
      (s) => s.sessionStatus === 2
    ).length;
    this.MissedCount = this.sessions.filter(
      (s) => s.sessionStatus === 3
    ).length;
    this.mySessionsCount = this.currentUserSessions.length;
  }

  openZoom(id: number): void {
    let dateOfThisTime = new Date();
    let session = this.sessions.find((s) => s.id == id);
    let endSession = new Date(session.to);
    let startSession = new Date(session.from);

    if (dateOfThisTime >= endSession && dateOfThisTime <= startSession) {
      this.toasterHelper.showError("session is end");
    } else {
      let token: string = "";
      this.tokenService.get().subscribe((data) => {
        token = data.getValue();
      });
      window.open(
        `https://meeting.diethubapp.net/#/meeting?sessionID=${id}&token=${token}`,
        "_blank"
      );
    }
  }
  ngOnInit(): void {
    let today = new Date();
    today.setHours(0);
    let endDate = new Date();
    endDate.setFullYear(today.getFullYear());
    endDate.setMonth(today.getMonth());
    endDate.setDate(today.getDate() + 1);
    endDate.setHours(today.getHours());
    endDate.setMinutes(today.getMinutes());
    endDate.setSeconds(today.getSeconds());
    this.getSessions(today, endDate);
    this.getSessionsForCurrentUser(today, endDate);
  }
}
