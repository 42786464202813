<nb-card>
    <nb-card-header>Article System
    </nb-card-header>

    <nb-card-body nbSpinnerStatus="primary" nbSpinnerSize="large" nbSpinnerMessage="Getting patient's data">
        <div class="row profile-pic form-group">
            <button class="button" (click)="ShowNewAddArticle()" *ngIf="!isNewArticle">Add new Article</button>
            <button class="button" (click)="ShowUpdateArticle()" *ngIf="!isUpdatedArticle">update Article</button>
        </div>
        <div class="h3" *ngIf="isNewArticle"> Adding new Article</div>
        <div class="h3" *ngIf="isUpdatedArticle"> Updating Articles</div>

        <div *ngIf="isUpdatedArticle" style="width: 40%;">
            <label>Choose an Application User</label><span style="color: red;">(required)</span>
            <ngx-search-dropdown [items]="articles" [size]="'small'" [label]="'name'" (afterChange)="selectArticle()"
                [uid]="'id'" [(ngModel)]="articleName">
            </ngx-search-dropdown>
            <hr>
        </div>
        <div class="row" *ngIf="isUpdatedArticle || isNewArticle">
            <div class="form-group col-sm-3" *ngIf="isUpdatedArticle">
                <label class="label">Id</label>
                <input type="text" fullWidth placeholder="Id" nbInput [(ngModel)]="article.id" disabled="true">
            </div>
            <div class="form-group col-sm-3">
                <label class="label">Article Name <span style="color: red;">(required)</span></label>
                <input type="text" fullWidth placeholder="Article Name" nbInput [(ngModel)]="article.name">
            </div>
            <div class="form-group col-sm-3">
                <label class="label">description <span style="color: green;">(optional)</span></label>
                <input type="text" fullWidth placeholder="description" nbInput [(ngModel)]="article.description">
            </div>
            <div class="form-group col-sm-3">
                <label class="label">keywords <span style="color: green;">(optional)</span></label>
                <input type="text" fullWidth placeholder="keywords" nbInput [(ngModel)]="article.keywords">
            </div>
            <div class="form-group col-sm-3">
                <label class="label">Image</label>
                <input (change)="onFileSelected($event)" #fileInput type="file" accept="image/*" fullWidth
                    placeholder="Image" nbInput>
            </div>
            <div *ngIf="this.article.imageUrl != null" class="form-group col-sm-3">
                <img class="photo-div" [src]="this.article.imageUrl" alt="article">
            </div>

        </div>

        <div class="row" *ngIf="isUpdatedArticle || isNewArticle">
            <label class="label">Article content<span style="color: red;">(required)</span></label>
            <div class="form-group col-sm-12">
                <dx-html-editor [width]="editorWidth" height="725px" [(value)]="article.contentHtml" [height]="800">
                    <dxo-toolbar [multiline]="true">
                        <dxi-item name="undo"></dxi-item>
                        <dxi-item name="redo"></dxi-item>
                        <dxi-item name="separator"></dxi-item>
                        <dxi-item name="size"
                            [acceptedValues]="['8pt', '10pt', '12pt', '14pt', '18pt', '24pt', '36pt']"></dxi-item>
                        <dxi-item name="font" [acceptedValues]="[
                        'Arial',
                        'Courier New',
                        'Georgia',
                        'Impact',
                        'Lucida Console',
                        'Tahoma',
                        'Times New Roman',
                        'Verdana'
                    ]"></dxi-item>
                        <dxi-item name="separator"></dxi-item>
                        <dxi-item name="bold"></dxi-item>
                        <dxi-item name="italic"></dxi-item>
                        <dxi-item name="strike"></dxi-item>
                        <dxi-item name="underline"></dxi-item>
                        <dxi-item name="separator"></dxi-item>
                        <dxi-item name="alignLeft"></dxi-item>
                        <dxi-item name="alignCenter"></dxi-item>
                        <dxi-item name="alignRight"></dxi-item>
                        <dxi-item name="alignJustify"></dxi-item>
                        <dxi-item name="separator"></dxi-item>
                        <dxi-item name="orderedList"></dxi-item>
                        <dxi-item name="bulletList"></dxi-item>
                        <dxi-item name="separator"></dxi-item>
                        <dxi-item name="header" [acceptedValues]="[false, 1, 2, 3, 4, 5]"></dxi-item>
                        <dxi-item name="separator"></dxi-item>
                        <dxi-item name="color"></dxi-item>
                        <dxi-item name="background"></dxi-item>
                        <dxi-item name="separator"></dxi-item>
                        <dxi-item name="link"></dxi-item>
                        <dxi-item name="image"></dxi-item>
                        <dxi-item name="separator"></dxi-item>
                        <dxi-item name="clear"></dxi-item>
                        <dxi-item name="codeBlock"></dxi-item>
                        <dxi-item name="blockquote"></dxi-item>
                        <dxi-item name="separator"></dxi-item>
                        <dxi-item name="insertTable"></dxi-item>
                        <dxi-item name="deleteTable"></dxi-item>
                        <dxi-item name="insertRowAbove"></dxi-item>
                        <dxi-item name="insertRowBelow"></dxi-item>
                        <dxi-item name="deleteRow"></dxi-item>
                        <dxi-item name="insertColumnLeft"></dxi-item>
                        <dxi-item name="insertColumnRight"></dxi-item>
                        <dxi-item name="deleteColumn"></dxi-item>
                    </dxo-toolbar>
                    <dxo-media-resizing [enabled]="true"> </dxo-media-resizing>

                </dx-html-editor>
            </div>
        </div>

    </nb-card-body>
    <nb-card-footer style="text-align: right;">
        <button (click)="openDialog(dialogArticle)" style="margin-left: 10px;" nbButton status="secondary">preview
            Content</button>
    </nb-card-footer>
    <nb-card-footer style="text-align: right;">
        <button [nbSpinner]="saving" nbSpinnerStatus="success" nbButton (click)="saveNewArticle()"
            *ngIf="isNewArticle && article.contentHtml != undefined && article.name != undefined"
            status="primary">Add</button>
    </nb-card-footer>
    <nb-card-footer style="text-align: right;">
        <button [nbSpinner]="saving" nbSpinnerStatus="success" nbButton (click)="saveUpdate()"
            *ngIf="isUpdatedArticle && article.id != undefined" status="primary">Update</button>
        <button [nbSpinner]="deleting" nbSpinnerStatus="danger" nbButton style="margin-left: 10px;"
            (click)="deleteArticle()" *ngIf="isUpdatedArticle && article.id != undefined"
            status="danger">Delete</button>
    </nb-card-footer>
</nb-card>

<div class="row">
    <div class="col-md-12">
        <nb-card class="own-scroll">
            <nb-card-header>
                <div class="row">
                    <div class="col-md-10">
                        control Articles of interests
                    </div>
                </div>
            </nb-card-header>
        </nb-card>
        <nb-card class="own-scroll">

            <nb-card-body [nbSpinner]="loadingArticleInterests" nbSpinnerStatus="primary" nbSpinnerSize="large">
                <div id="data-grid-demo">
                    <div style="width: 40%;">
                        <label>Choose an Interest</label><span style="color: red;">(required)</span>
                        <ngx-search-dropdown [items]="interests" [size]="'small'" [label]="'nameEn'"
                            (afterChange)="selectInterest()" [uid]="'id'" [(ngModel)]="interestName">
                        </ngx-search-dropdown>
                        <hr>
                    </div>
                    <dx-data-grid #grid id="gridContainer" [dataSource]="articleInterests" keyExpr="id"
                        [focusedRowEnabled]="true" [showBorders]="true"
                        (onFocusedRowChanging)="onFocusedRowChanging($event)"
                        (onFocusedRowChanged)="onFocusedRowChanged($event)"
                        (onRowInserting)="addArticleInterest($event)" (onRowRemoving)="deleteArticleInterest($event)"
                        [disabled]="isDisabled">

                        <dxo-paging [enabled]="false"></dxo-paging>
                        <dxo-editing mode="form" [refreshMode]="refreshMode" [allowAdding]="true"
                            [allowDeleting]="true">
                        </dxo-editing>
                        <dxo-filter-row [visible]="showFilterRow" [applyFilter]="currentFilter"></dxo-filter-row>
                        <dxo-header-filter [visible]="showHeaderFilter"></dxo-header-filter>
                        <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>
                        <dxi-column dataField="articleId" caption="Article">
                            <dxo-lookup [dataSource]="articles" displayExpr="name" valueExpr="id">
                            </dxo-lookup>
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dxi-column>



                    </dx-data-grid>
                </div>
            </nb-card-body>
        </nb-card>



    </div>
</div>

<ng-template #dialogArticle let-data let-ref="dialogRef">
    <div [style.width.px]="dialogWidth">
        <nb-card size="large" style="overflow: scroll;">
            <nb-card-header>
                Preview Html
            </nb-card-header>
            <nb-card-body>
                <div style="margin: 20px;">
                    <div [innerHTML]="article.contentHtml" width="480px"></div>
                </div>
            </nb-card-body>
            <nb-card-footer style="text-align: right;">
                <div style="width: 100%;" class="form-group col-sm-12">
                    <label class="label">Content Width</label>
                    <nb-select [(ngModel)]="dialogWidth" [(selected)]="this.dialogWidth" fullWidth>
                        <nb-option value="370">370px</nb-option>
                        <nb-option value="480">480px</nb-option>
                        <nb-option value="640">640px</nb-option>
                    </nb-select>
                </div>
            </nb-card-footer>

        </nb-card>
    </div>
</ng-template>