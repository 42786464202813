import { Component, OnInit } from "@angular/core";

@Component({
  selector: "ngx-crm-reporting",
  templateUrl: "./crm-reporting.component.html",
  styleUrls: ["./crm-reporting.component.scss"],
})
export class CrmReportingComponent implements OnInit {
  isShowDiv: boolean = false;
  constructor() {}
  toggleDisplayDiv() {
    this.isShowDiv = !this.isShowDiv;
  }
  ngOnInit(): void {}
}
