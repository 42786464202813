import { Component, OnInit } from "@angular/core";
import { NbDialogService } from "@nebular/theme";
import { ToasterHelper } from "../../../@core/app-services/toasterHelper";
import { TreasuryService } from "../../../@core/app-services/treasury.service";
import { paymentType } from "../../../@core/models/paymentType";
import { Transaction } from "../../../@core/models/Transaction";
import { TransactionType } from "../../../@core/models/TransactionType";
import { TreasuryDay } from "../../../@core/models/treasuryDay";

@Component({
  selector: "ngx-treasury",
  templateUrl: "./treasury.component.html",
  styleUrls: ["./treasury.component.scss"],
})
export class TreasuryComponent implements OnInit {
  balance: number;
  patientId: number;
  journalEntriesDates: TreasuryDay[] = new Array();
  selectedTreasuryDay: TreasuryDay = new TreasuryDay();
  transactionTypes: TransactionType[] = new Array();
  newTransaction: Transaction = new Transaction();
  gettingJournalEntries: boolean = true;
  addingPayment: boolean = false;
  paymentTypes: paymentType[] = [
    {
      id: 0,
      name: "credit",
    },
    {
      id: 1,
      name: "debit",
    },
  ];
  constructor(
    private toasterHelper: ToasterHelper,
    private dialogService: NbDialogService,
    private treasuryService: TreasuryService
  ) {
    this.getJournalEntries();
    this.newTransaction.spendingDate = new Date();
    treasuryService.getTransactionTypes().subscribe((data) => {
      this.transactionTypes = data;
      this.newTransaction.transactionTypeId = this.transactionTypes[0].id;
    });
  }
  getJournalEntries() {
    this.gettingJournalEntries = true;
    this.treasuryService.getJournalEntries().subscribe((data) => {
      this.journalEntriesDates = data;
      if (this.journalEntriesDates.length != 0) {
        this.balance = this.journalEntriesDates[0].balance;
      }
      this.gettingJournalEntries = false;
    });
  }
  ngOnInit(): void {}
  selectTreasuryDay(treasuryDay) {
    this.selectedTreasuryDay = treasuryDay;
  }
  openDialog(dialogPayment) {
    this.dialogService.open(dialogPayment);
  }
  addPayment(ref) {
    this.addingPayment = true;
    this.treasuryService
      .addTransaction(this.newTransaction)
      .subscribe((data) => {
        if (data.status) {
          this.toasterHelper.showSaved(data.message);
          this.getJournalEntries();
          ref.close();
        } else {
          this.toasterHelper.showError(data.message);
        }
        this.addingPayment = false;
      });
  }
}
