import * as AWS from "aws-sdk/global";
import * as S3 from "aws-sdk/clients/s3";
import { Md5 } from "ts-md5/dist/md5";
import { environment } from "../../../environments/environment";

const AwsS3Config = {
  accessKey: "AKIA3LMFH2KPGUGZEUWZ",
  secretKey: "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",
  region: "eu-central-1",
};

export const AwsRepoConfig = {
  Bucket: "diethub-resources",
  publicUrl: "https://diethub-resources.s3.eu-central-1.amazonaws.com/",
  mealsURL: environment.mealsBucket,
  exercisesURL: environment.exercisesBucket,
  challengesURL: environment.challengesBucket,
  branchesURL: environment.branchesBucket,
  sessionTypeReviewsURL: environment.sessionTypeReviewsBucket,
  sessionTypeURL: environment.sessionTypesBucket,
  articleURL: environment.articleBucket,
};

const getHash = (originalName) => {
  const md5 = new Md5();
  var hash = md5.appendStr(originalName).end();
  return hash;
};

export class AwsS3Manage {
  bucket: any;
  constructor() {
    this.bucket = new S3({
      accessKeyId: AwsS3Config.accessKey,
      secretAccessKey: AwsS3Config.secretKey,
      region: AwsS3Config.region,
    });
  }

  doesFileExist({ fileName, location }) {
    const params = {
      Bucket: AwsRepoConfig.Bucket,
      Key: location + this.appendFileHash(fileName),
    };

    try {
      const resultPromise = this.bucket.headObject(params).promise();
      return resultPromise;
    } catch (err) {
      console.log(err.message);
    }
  }

  deleteFile({ fileName, location }) {
    const params = {
      Bucket: AwsRepoConfig.Bucket,
      Key: location + this.appendFileHash(fileName),
    };

    try {
      const resultPromise = this.bucket.deleteObject(params).promise();
      return resultPromise;
    } catch (err) {
      console.log(err.message);
    }
  }

  uploadFile({ fileStream, location, name }) {
    const params = {
      Bucket: AwsRepoConfig.Bucket,
      Key: location + this.appendFileHash(name),
      Body: fileStream,
    };

    try {
      const resultPromise = this.bucket.upload(params).promise();
      return resultPromise;
    } catch (err) {
      console.log(err.message);
    }
  }

  appendFileHash(fileName: string) {
    return getHash(fileName) + "-" + fileName;
  }
}
