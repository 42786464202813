import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { ApiResponse } from "../models/ApiResponse";
import { Branch } from "../models/Branch";
import { BranchPackage } from "../models/BranchPackage";

@Injectable({
  providedIn: "root",
})
export class BranchService {
  private getBranchesPath = environment.apiUrl + "Branch/GetAllBranches";
  private addBranchPath = environment.apiUrl + "Branch/AddBranch";
  private deleteBranchPath = environment.apiUrl + "Branch/DeleteBranch?id=";
  private updateBranchPath = environment.apiUrl + "Branch/UpdateBranch?id=";

  //  Branch Packages apis
  private getBranchPackagesPath =
    environment.apiUrl +
    "BranchPackage/GetAllBranchPackagesByBranchId?branchId=";
  private addBranchPackagePath =
    environment.apiUrl + "BranchPackage/AddBranchPackage";
  private deleteBranchPackagePath =
    environment.apiUrl + "BranchPackage/DeleteBranchPackage?id=";
  private updateBranchPackagePath =
    environment.apiUrl + "BranchPackage/UpdateBranchPackage?id=";

  constructor(private http: HttpClient) {}
  // Branch apis

  getBranches(): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.getBranchesPath);
  }
  addBranch(model: Branch): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.addBranchPath, model);
  }
  updateBranch(branchId: number, model: Branch): Observable<ApiResponse> {
    return this.http.put<ApiResponse>(this.updateBranchPath + branchId, model);
  }
  deleteBranch(branchId: number): Observable<ApiResponse> {
    return this.http.delete<ApiResponse>(this.deleteBranchPath + branchId);
  }

  getAllBranchPackagesByBranchId(branchId: number): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.getBranchPackagesPath + branchId);
  }

  addBranchPackage(model: BranchPackage): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.addBranchPackagePath, model);
  }
  updateBranchPackage(
    BranchPackageId: number,
    model: BranchPackage
  ): Observable<ApiResponse> {
    return this.http.put<ApiResponse>(
      this.updateBranchPackagePath + BranchPackageId,
      model
    );
  }
  deleteBranchPackage(BranchPackageId: number): Observable<ApiResponse> {
    return this.http.delete<ApiResponse>(
      this.deleteBranchPackagePath + BranchPackageId
    );
  }
}
