export class UpdateSession {
  from: Date;
  to: Date;
  sessionTypeId: number;
  doctorId: string;
  sessionStatus: number;
  description: string;
  comments: string;
  branchId: number;
}
