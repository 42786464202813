import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { PlanService } from "../../../../@core/app-services/plan.service";
import { ToasterHelper } from "../../../../@core/app-services/toasterHelper";
import { Plan } from "../../../../@core/models/plan";

@Component({
  selector: "ngx-plan",
  templateUrl: "./plan.component.html",
  styleUrls: ["./plan.component.scss"],
})
export class PlanComponent implements OnInit {
  plans: Plan[] = [];
  plan: Plan = new Plan();

  refreshMode: string;
  taskSubject: string;
  taskDetailsHtml: SafeHtml;
  taskStatus: string;
  taskProgress: string;
  currentFilter: any;
  showFilterRow: boolean = true;
  showHeaderFilter: boolean = true;
  autoNavigateToFocusedRow = true;
  loadingPlans: boolean = false;
  constructor(
    private sanitizer: DomSanitizer,
    private planService: PlanService,
    private toasterHelper: ToasterHelper
  ) {}

  getPlans() {
    this.planService.getPlans().subscribe(
      (data) => {
        if (data.status) {
          this.plans = data.responseObject;
        } else {
          this.plans = [];
        }
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
        this.loadingPlans = false;
      }
    );
  }

  addPlan(e) {
    this.loadingPlans = true;

    let newPlan = new Plan();
    this.plan = e.data;
    newPlan.nameEn = this.plan.nameEn;
    newPlan.nameAr = this.plan.nameAr;
    newPlan.descriptionAr = this.plan.descriptionAr;
    newPlan.descriptionEn = this.plan.descriptionEn;
    newPlan.numberOfWeeks = 1;
    newPlan.minWeightImprovement = this.plan.minWeightImprovement;

    this.planService.addPlan(newPlan).subscribe(
      (data) => {
        if (data.status) {
          this.toasterHelper.showToast(
            "Added successfully",
            data.message,
            "success"
          );
        } else {
          this.toasterHelper.showError(data.message);
        }
        this.loadingPlans = false;

        this.getPlans();
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
        this.loadingPlans = false;
      }
    );
  }

  updatePlan(e) {
    this.loadingPlans = true;
    let updatedPlan = new Plan();
    this.plan = e.data;
    updatedPlan.nameEn = this.plan.nameEn;
    updatedPlan.nameAr = this.plan.nameAr;
    updatedPlan.descriptionAr = this.plan.descriptionAr;
    updatedPlan.descriptionEn = this.plan.descriptionEn;
    updatedPlan.numberOfWeeks = 1;
    updatedPlan.minWeightImprovement = this.plan.minWeightImprovement;

    this.planService.updatePlan(this.plan.id, updatedPlan).subscribe(
      (data) => {
        if (data.status) {
          this.toasterHelper.showToast(
            "updated successfully",
            data.message,
            "success"
          );
        } else {
          this.toasterHelper.showError(data.message);
        }
        this.loadingPlans = false;

        this.getPlans();
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
        this.loadingPlans = false;
      }
    );
  }

  deletePlan(e) {
    this.loadingPlans = true;
    this.plan = e.data;
    this.planService.deletePlan(this.plan.id).subscribe(
      (data) => {
        if (data.status) {
          this.toasterHelper.showToast(
            "Deleted successfully",
            data.message,
            "success"
          );
        } else {
          this.toasterHelper.showError(data.message);
        }
        this.loadingPlans = false;
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
        this.loadingPlans = false;
      }
    );
  }
  validateMinWeightImprovement(e) {
    if (e.value > 100) {
      return false;
    } else if (e.value <= 0) {
      return false;
    } else return true;
  }

  onFocusedRowChanging(e) {
    const rowsCount = e.component.getVisibleRows().length;
    const pageCount = e.component.pageCount();
    const pageIndex = e.component.pageIndex();
    const key = e.event && e.event.key;
    const rowData = e.row && e.row.data;
    if (key && e.prevRowIndex === e.newRowIndex) {
      if (e.newRowIndex === rowsCount - 1 && pageIndex < pageCount - 1) {
        e.component.pageIndex(pageIndex + 1).done(() => {
          e.component.option("focusedRowIndex", 0);
        });
      } else if (e.newRowIndex === 0 && pageIndex > 0) {
        e.component.pageIndex(pageIndex - 1).done(() => {
          e.component.option("focusedRowIndex", rowsCount - 1);
        });
      }
    }
  }
  onFocusedRowChanged(e) {
    const rowData = e.row && e.row.data;
    // this.rowPackage= rowData;
    if (rowData) {
      this.taskSubject = rowData.Task_Subject;
      this.taskDetailsHtml = this.sanitizer.bypassSecurityTrustHtml(
        rowData.Task_Description
      );
      this.taskStatus = rowData.Task_Status;
      this.taskProgress = rowData.Task_Completion
        ? `${rowData.Task_Completion}` + "%"
        : "";
    }
  }

  ngOnInit(): void {
    this.getPlans();
  }
}
