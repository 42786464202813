import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { ApiResponse } from "../models/ApiResponse";
import { TreatmentType } from "../models/TreatmentType";

@Injectable({
  providedIn: "root",
})
export class TreatmentTypeService {
  private getTreatmentTypesPath =
    environment.apiUrl + "TreatmentType/GetTreatmentTypes";
  private deleteTreatmentTypesPath =
    environment.apiUrl + "TreatmentType/DeleteTreatmentTypeById?id=";
  private addTreatmentTypesPath =
    environment.apiUrl + "TreatmentType/AddTreatmentType";
  private updateTreatmentTypesPath =
    environment.apiUrl + "TreatmentType/UpdateTreatmentType?id=";

  constructor(private http: HttpClient) {}

  getTreatmentTypes(): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.getTreatmentTypesPath);
  }
  addTreatmentType(model: TreatmentType): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.addTreatmentTypesPath, model);
  }
  updateTreatmentType(
    TreatmentTypeId: number,
    model: TreatmentType
  ): Observable<ApiResponse> {
    return this.http.put<ApiResponse>(
      this.updateTreatmentTypesPath + TreatmentTypeId,
      model
    );
  }
  deleteTreatmentType(TreatmentTypeId: number): Observable<ApiResponse> {
    return this.http.delete<ApiResponse>(
      this.deleteTreatmentTypesPath + TreatmentTypeId
    );
  }
}
