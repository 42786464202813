<div class="row">
    <div class="col-md-12">
        <nb-card class="own-scroll">
            <nb-card-header>
                <div class="row">
                    <div class="col-md-10">
                        control subscription meals
                    </div>
                </div>
            </nb-card-header>
        </nb-card>
        <nb-card class="own-scroll">

            <nb-card-body [nbSpinner]="loadingSubscriptionMeals" nbSpinnerStatus="primary" nbSpinnerSize="large">
                <div id="data-grid-demo">
                    <div>
                        <nb-card *ngIf="appUser != null">
                            <nb-card-header>
                                Full Name
                            </nb-card-header>
                            <nb-card-body>
                                <div class="h2" style="text-align: center;">{{appUser?.fullName}} </div>
                            </nb-card-body>
                        </nb-card>
                    </div>
                    <dx-data-grid #grid id="gridContainer" [dataSource]="subscriptionMeals" keyExpr="id"
                        [focusedRowEnabled]="true" [showBorders]="true"
                        (onFocusedRowChanging)="onFocusedRowChanging($event)"
                        (onRowUpdated)="updateSubscriptionMeal($event)"
                        (onFocusedRowChanged)="onFocusedRowChanged($event)"
                        (onRowInserting)="addSubscriptionMeal($event)" (onRowRemoving)="deleteSubscriptionMeal($event)">

                        <dxo-paging [enabled]="false"></dxo-paging>
                        <dxo-editing mode="form" [refreshMode]="refreshMode" [allowAdding]="true" [allowUpdating]="true"
                            [allowDeleting]="true">
                        </dxo-editing>
                        <dxo-filter-row [visible]="showFilterRow" [applyFilter]="currentFilter"></dxo-filter-row>
                        <dxo-header-filter [visible]="showHeaderFilter"></dxo-header-filter>
                        <dxo-search-panel [visible]="true" [width]="240" placeholder="Search..."></dxo-search-panel>
                        <dxi-column dataField="sequance" dataType="number">
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dxi-column>
                        <dxi-column dataField="dateNumber" dataType="number">
                        </dxi-column>
                        <dxi-column dataField="nameAr">
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dxi-column>
                        <dxi-column dataField="nameEn">
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dxi-column>
                        <dxi-column dataField="meal.id" caption="meal" [width]="125">
                            <dxo-lookup [dataSource]="meals" displayExpr="nameEn" valueExpr="id">
                            </dxo-lookup>
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dxi-column>
                        <dxi-column dataField="subscriptionId" caption="subscription" [width]="125" type="required">
                            <dxi-validation-rule type="required"></dxi-validation-rule>
                        </dxi-column>


                    </dx-data-grid>
                </div>
            </nb-card-body>
        </nb-card>



    </div>
</div>