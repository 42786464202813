<div class="row">
    <div class="col-md-4">
        <nb-card [size]="'large'">
            <nb-card-header class="row">
                <div class="col-md-10">
                    Spend types
                </div>
                <div class="col-md-2">
                    <nb-action title="Add Type" icon="plus-outline"
                        (click)="openSpendTypeDialog(null,dialogSpendType)">
                    </nb-action>
                </div>
            </nb-card-header>
            <nb-card-body [nbSpinner]="gettingSpendTypes" nbSpinnerStatus="primary" nbSpinnerSize="large"
            nbSpinnerMessage="Getting Spend types">
                <div *ngIf="spendTypes.length == 0 && !gettingSpendTypes">
                    <ngx-empty-state image="EmptyState.png" text="No Spend types found!">
                    </ngx-empty-state>
                    <div style="text-align: center;margin-top: 10px;">
                        <button (click)="openSpendTypeDialog(null,dialogSpendType)" nbButton status="success">Add
                            Spend Type</button>
                    </div>
                </div>
                <table class="table" *ngIf="spendTypes.length>0">
                    <thead>
                        <tr>
                            <th>Spend Title</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let spendType of spendTypes">
                            <td>
                                {{spendType.title}}
                            </td>
                            <td>
                                <nb-actions size="small">
                                    <nb-action *ngIf="spendType.hold" icon="trash-2-outline" badgeText="Deleted"
                                        badgeStatus="danger">
                                    </nb-action>
                                    <nb-action *ngIf="!spendType.hold" title="Delete" icon="trash-2-outline"
                                        (click)="deleteSpendType(category)">
                                    </nb-action>
                                    <nb-action (click)="openSpendTypeDialog(spendType,dialogSpendType)" title="Edit"
                                        icon="edit-outline">
                                    </nb-action>
                                </nb-actions>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </nb-card-body>
        </nb-card>
    </div>
</div>
<ng-template #dialogSpendType let-data let-ref="dialogRef">
    <nb-card>
        <nb-card-header>
            Spend type
        </nb-card-header>
        <nb-card-body>
            <div class="form-group">
                <input fullWidth [(ngModel)]="this.selectedSpendType.title" placeholder="Spend title" type="text"
                    nbInput>
            </div>
        </nb-card-body>
        <nb-card-footer style="text-align: right;">
            <button [nbSpinner]="savingSpendType" nbSpinnerStatus="success" (click)="saveSpendType(ref)" nbButton status="primary">Save</button>
        </nb-card-footer>
    </nb-card>
</ng-template>