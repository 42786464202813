import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NbDialogService } from "@nebular/theme";
import { PaymentService } from "../../../../@core/app-services/payment.service";
import { ToasterHelper } from "../../../../@core/app-services/toasterHelper";
import { Payment } from "../../../../@core/models/payment";
import { PaymentDay } from "../../../../@core/models/paymentDay";

@Component({
  selector: "ngx-payments",
  templateUrl: "./payments.component.html",
  styleUrls: ["./payments.component.scss"],
})
export class PaymentsComponent implements OnInit {
  balance: number;
  patientId: number;
  paymentDates: PaymentDay[] = new Array();
  selectedPaymentDay: PaymentDay = new PaymentDay();
  newPayment: Payment = new Payment();
  constructor(
    private toasterHelper: ToasterHelper,
    private dialogService: NbDialogService,
    private route: ActivatedRoute,
    private paymentService: PaymentService
  ) {
    this.newPayment.paymentDate = new Date();
    route.queryParams.subscribe(
      (params) => {
        this.patientId = this.route.snapshot.params.id;
        this.newPayment.patientId = this.patientId;
        this.getPatientPayments();
      },
      (error) => {
        toasterHelper.showError(error);
      }
    );
  }
  getPatientPayments() {
    this.paymentService.getPatientPayments(this.patientId).subscribe((data) => {
      this.paymentDates = data;
      if(this.paymentDates.length != 0){
        this.balance = this.paymentDates[0].balance;
        this.selectedPaymentDay = this.paymentDates[0];
      }
    });
  }
  ngOnInit(): void {}
  selectPaymentDay(paymentDay) {
    this.selectedPaymentDay = paymentDay;
  }
  openDialog(dialogPayment) {
    this.dialogService.open(dialogPayment);
  }
  addPayment(ref) {
    this.paymentService.addPayment(this.newPayment).subscribe((data) => {
      if (data.status) {
        this.toasterHelper.showSaved(data.message);
        this.getPatientPayments();
        ref.close();
      } else {
        this.toasterHelper.showError(data.message);
      }
    });
  }
  deletePayment(paymentId:number){
    this.paymentService.deletePayment(paymentId).subscribe(data=>{
      if (data.status) {
        this.toasterHelper.showSaved(data.message);
        this.getPatientPayments();
      } else {
        this.toasterHelper.showError(data.message);
      }
    })
  }
}
