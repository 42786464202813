import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import { ApiResponse } from "../models/ApiResponse";
import { Article } from "../models/article";
import { ArticleInterest } from "../models/articleInterest";

@Injectable({
  providedIn: "root",
})
export class ArticleService {
  // Articles paths
  private getArticlesPath = environment.apiUrl + "Article/GetAllArticles";
  private addArticlePath = environment.apiUrl + "Article/AddArticle";
  private deleteArticlePath = environment.apiUrl + "Article/DeleteArticle?id=";
  private updateArticlePath = environment.apiUrl + "Article/UpdateArticle?id=";

  // Article interests paths
  private getArticleInterestsPath =
    environment.apiUrl +
    "ArticleInterest/GetAllArticleInterestsByInterestId?interestId=";
  private addArticleInterestPath =
    environment.apiUrl + "ArticleInterest/AddArticleInterest";
  private deleteArticleInterestPath =
    environment.apiUrl + "ArticleInterest/DeleteArticleInterest?id=";
  private updateArticleInterestPath =
    environment.apiUrl + "ArticleInterest/UpdateArticleInterest?id=";
  constructor(private http: HttpClient) {}

  // Article apis
  getArticles(): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(this.getArticlesPath);
  }

  addArticle(model: Article): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.addArticlePath, model);
  }
  updateArticle(ArticleId: number, model: Article): Observable<ApiResponse> {
    return this.http.put<ApiResponse>(
      this.updateArticlePath + ArticleId,
      model
    );
  }
  deleteArticle(ArticleId: number): Observable<ApiResponse> {
    return this.http.delete<ApiResponse>(this.deleteArticlePath + ArticleId);
  }

  // ArticleInterest apis
  getArticleInterestByInterestId(interestId: number): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(
      this.getArticleInterestsPath + interestId
    );
  }

  addArticleInterest(model: ArticleInterest): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(this.addArticleInterestPath, model);
  }
  updateArticleInterest(
    ArticleInterestId: number,
    model: ArticleInterest
  ): Observable<ApiResponse> {
    return this.http.put<ApiResponse>(
      this.updateArticleInterestPath + ArticleInterestId,
      model
    );
  }
  deleteArticleInterest(ArticleInterestId: number): Observable<ApiResponse> {
    return this.http.delete<ApiResponse>(
      this.deleteArticleInterestPath + ArticleInterestId
    );
  }
}
