import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { isNumeric } from "rxjs-compat/util/isNumeric";
import { ExerciseService } from "../../../../@core/app-services/exercise.service";
import { PackageExerciseService } from "../../../../@core/app-services/package-exercise.service";
import { PlanService } from "../../../../@core/app-services/plan.service";
import { ToasterHelper } from "../../../../@core/app-services/toasterHelper";
import { AddingType } from "../../../../@core/models/addingType";
import { Exercise } from "../../../../@core/models/exercise";
import { PackageExercise } from "../../../../@core/models/packageExercise";
import { Plan } from "../../../../@core/models/plan";

@Component({
  selector: "ngx-plan-exercise",
  templateUrl: "./plan-exercise.component.html",
  styleUrls: ["./plan-exercise.component.scss"],
})
export class PlanExerciseComponent implements OnInit {
  packageExercises: PackageExercise[] = [];
  packageExercise: PackageExercise = null;
  exercises: Exercise[] = [];
  exercise: Exercise = null;
  plans: Plan[] = [];
  refreshMode: string;
  taskSubject: string;
  taskDetailsHtml: SafeHtml;
  taskStatus: string;
  taskProgress: string;
  currentFilter: any;
  showFilterRow: boolean = true;
  plan: Plan;
  loadingPackageExercises: boolean = false;
  showHeaderFilter: boolean = true;
  autoNavigateToFocusedRow = true;
  isDisabled: boolean = true;
  constructor(
    private planService: PlanService,
    private packageExerciseService: PackageExerciseService,
    private exerciseService: ExerciseService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private toasterHelper: ToasterHelper
  ) {}

  getPackageExercises() {
    this.loadingPackageExercises = true;
    this.packageExerciseService
      .getPackageExercisesByPlanId(this.plan.id)
      .subscribe(
        (data) => {
          if (data.status) {
            this.packageExercises = data.responseObject;
          } else {
            this.packageExercises = [];
          }
          this.loadingPackageExercises = false;
        },
        (error) => {
          this.toasterHelper.showError("Something went wrong");
          this.loadingPackageExercises = false;
        }
      );
  }

  addPackageExercise(e) {
    this.loadingPackageExercises = true;
    let newPackageExercise = new PackageExercise();
    this.packageExercise = e.data;
    let dateNumbers: string = e.data.dateNumber;
    let dataNumbersList: number[] = [];
    newPackageExercise.nameAr = this.packageExercise.nameAr;
    newPackageExercise.nameEn = this.packageExercise.nameEn;
    newPackageExercise.sequance = this.packageExercise.sequance;
    newPackageExercise.exerciseId = this.packageExercise.exerciseId;
    newPackageExercise.planId = this.plan.id;
    const specialChars = /[`!@#$%^&*()_+\=\[\]{};':"\\|.<>\/?~]/;
    const letters = /[a-zA-Z]/;
    if (specialChars.test(dateNumbers) || letters.test(dateNumbers)) {
      this.toasterHelper.showError(
        "there is wrong special Characters and letters"
      );
      this.getPackageExercises();
    } else if (dateNumbers.includes(",") && dateNumbers.includes("-")) {
      this.toasterHelper.showError("date number has , and - at the same time");
      this.getPackageExercises();
    } else if (dateNumbers.includes(",")) {
      let res = dateNumbers.split(",");
      dataNumbersList = res.map((i) => Number(i));
      this.getPackageExercises();
    } else if (dateNumbers.replace(/[^-]/g, "").length == 1) {
      let res = dateNumbers.split("-");
      let rangeArray: number[] = res.map((i) => Number(i));
      let finalList: number[] = [];
      for (let index = rangeArray[0]; index <= rangeArray[1]; index++) {
        finalList.push(index);
      }
      dataNumbersList = finalList;
    } else if (isNumeric(dateNumbers)) {
      let dateNumber = parseInt(dateNumbers);
      dataNumbersList = [dateNumber];
    }
    if (dataNumbersList.length > 0) {
      dataNumbersList.forEach((element) => {
        if (element <= 7 && element > 0) {
          newPackageExercise.dateNumber = element;
          this.packageExerciseService
            .addPackageExercise(newPackageExercise)
            .subscribe(
              (data) => {
                if (data.status) {
                  this.toasterHelper.showToast(
                    "Added successfully",
                    data.message,
                    "success"
                  );
                } else {
                  this.toasterHelper.showError(data.message);
                }
                this.loadingPackageExercises = false;

                this.getPackageExercises();
              },
              (error) => {
                this.toasterHelper.showError("Something went wrong");
                this.loadingPackageExercises = false;
              }
            );
        } else {
          this.toasterHelper.showError(
            "data number should be an integer and <= 7 and > 0"
          );
          this.getPackageExercises();
        }
      });
    } else {
      this.toasterHelper.showError("you enter dateNumber wrong");
      this.getPackageExercises();
      this.loadingPackageExercises = false;
    }
  }
  selectPlan(nameEn: string) {
    this.plan = this.plans.find((p) => p.nameEn.trim() == nameEn.trim());
    this.getPackageExercises();
    this.isDisabled = false;
  }

  updatePackageExercise(e) {
    this.loadingPackageExercises = true;
    let updatedPackageExercise = new PackageExercise();
    this.packageExercise = e.data;
    updatedPackageExercise.dateNumber = this.packageExercise.dateNumber;
    updatedPackageExercise.exerciseId = this.packageExercise.exerciseId;
    updatedPackageExercise.nameAr = this.packageExercise.nameAr;
    updatedPackageExercise.nameEn = this.packageExercise.nameEn;
    updatedPackageExercise.sequance = this.packageExercise.sequance;
    updatedPackageExercise.planId = this.plan.id;
    if (
      isNumeric(e.data.dateNumber) &&
      e.data.dateNumber <= 7 &&
      e.data.dateNumber > 0
    ) {
      this.packageExerciseService
        .updatePackageExercise(this.packageExercise.id, updatedPackageExercise)
        .subscribe(
          (data) => {
            if (data.status) {
              this.toasterHelper.showToast(
                "Updated successfully",
                data.message,
                "success"
              );
            } else {
              this.toasterHelper.showError(data.message);
            }
            this.loadingPackageExercises = false;

            this.getPackageExercises();
          },
          (error) => {
            this.toasterHelper.showError("Something went wrong");
            this.loadingPackageExercises = false;
          }
        );
    } else {
      this.toasterHelper.showError(
        "date number should be an integer and <= 7 and greater than 0"
      );
      this.loadingPackageExercises = false;
      this.getPackageExercises();
    }
  }

  deletePackageExercise(e) {
    this.loadingPackageExercises = true;

    this.packageExercise = e.data;
    this.packageExerciseService
      .deletePackageExercise(this.packageExercise.id)
      .subscribe(
        (data) => {
          if (data.status) {
            this.toasterHelper.showToast(
              "Deleted successfully",
              data.message,
              "success"
            );
          } else {
            this.toasterHelper.showError(data.message);
          }
          this.loadingPackageExercises = false;
        },
        (error) => {
          this.toasterHelper.showError("Something went wrong");
          this.loadingPackageExercises = false;
        }
      );
  }

  getPlans() {
    this.loadingPackageExercises = true;
    this.planService.getPlans().subscribe(
      (data) => {
        if (data.status) {
          this.toasterHelper.showToast(
            "Get successfully",
            data.message,
            "success"
          );
          this.plans = data.responseObject;
        } else {
          this.plans = [];
        }
        this.loadingPackageExercises = false;
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
        this.loadingPackageExercises = false;
      }
    );
  }

  getExercises() {
    this.exerciseService.getExercises().subscribe(
      (data) => {
        if (data.status) {
          this.toasterHelper.showToast(
            "Get successfully",
            data.message,
            "success"
          );
          this.exercises = data.responseObject;
        } else {
          this.exercises = [];
        }
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
      }
    );
  }
  onFocusedRowChanging(e) {
    const rowsCount = e.component.getVisibleRows().length;
    const pageCount = e.component.pageCount();
    const pageIndex = e.component.pageIndex();
    const key = e.event && e.event.key;
    const rowData = e.row && e.row.data;
    if (key && e.prevRowIndex === e.newRowIndex) {
      if (e.newRowIndex === rowsCount - 1 && pageIndex < pageCount - 1) {
        e.component.pageIndex(pageIndex + 1).done(() => {
          e.component.option("focusedRowIndex", 0);
        });
      } else if (e.newRowIndex === 0 && pageIndex > 0) {
        e.component.pageIndex(pageIndex - 1).done(() => {
          e.component.option("focusedRowIndex", rowsCount - 1);
        });
      }
    }
  }
  onFocusedRowChanged(e) {
    const rowData = e.row && e.row.data;
    // this.rowPackage= rowData;
    if (rowData) {
      this.taskSubject = rowData.Task_Subject;
      this.taskDetailsHtml = this.sanitizer.bypassSecurityTrustHtml(
        rowData.Task_Description
      );
      this.taskStatus = rowData.Task_Status;
      this.taskProgress = rowData.Task_Completion
        ? `${rowData.Task_Completion}` + "%"
        : "";
    }
  }

  ngOnInit(): void {
    this.getExercises();
    this.getPlans();
  }
}
