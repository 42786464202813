<div class="row">
    <div class="col-md-8">
        <h4>What are you looking for?</h4>
        <div class="row">
            <div class="col-sm-3 col-md-4">
                <nb-card [routerLink]="['../client-info']">
                    <div class="icon-container">
                        <div class="icon status-success">
                            <i class="fas fa-user ic-size"></i>
                        </div>
                    </div>
                    <div class="details">
                        <div class="title h6">Basic Info</div>
                    </div>
                </nb-card>
            </div>
            <div class="col-sm-3 col-md-4">
                <nb-card [routerLink]="['../body-profile']">
                    <div class="icon-container">
                        <div class="icon status-success">
                            <i class="fas fa-user ic-size"></i>
                        </div>
                    </div>
                    <div class="details">
                        <div class="title h6">Body Profile System</div>
                    </div>
                </nb-card>
            </div>
            <div class="col-sm-3 col-md-4">
                <nb-card [routerLink]="['../session']">
                    <div class="icon-container">
                        <div class="icon status-success">
                            <i class="fas fa-user ic-size"></i>
                        </div>
                    </div>
                    <div class="details">
                        <div class="title h6">Session system</div>
                    </div>
                </nb-card>
            </div>
            <div class="col-sm-3 col-md-4">
                <nb-card [routerLink]="['../subscription-exercise']">
                    <div class="icon-container">
                        <div class="icon status-success">
                            <i class="fas fa-user ic-size"></i>
                        </div>
                    </div>
                    <div class="details">
                        <div class="title h6">subscription exercises</div>
                    </div>
                </nb-card>
            </div>
            <div class="col-sm-3 col-md-4">
                <nb-card [routerLink]="['../subscription-meal']">
                    <div class="icon-container">
                        <div class="icon status-success">
                            <i class="fas fa-user ic-size"></i>
                        </div>
                    </div>
                    <div class="details">
                        <div class="title h6">subscription meals</div>
                    </div>
                </nb-card>
            </div>
            <div class="col-sm-3 col-md-4">
                <nb-card [routerLink]="['../wallet']">
                    <div class="icon-container">
                        <div class="icon status-success">
                            <i class="fas fa-user ic-size"></i>
                        </div>
                    </div>
                    <div class="details">
                        <div class="title h6">Wallet system</div>
                    </div>
                </nb-card>
            </div>
            <div class="col-sm-3 col-md-4">
                <nb-card [routerLink]="['../call-log']">
                    <div class="icon-container">
                        <div class="icon status-success">
                            <i class="fas fa-user ic-size"></i>
                        </div>
                    </div>
                    <div class="details">
                        <div class="title h6">Call logs</div>
                    </div>
                </nb-card>
            </div>
        </div>

    </div>
    <div class="col-md-4">
        <ngx-patient-activity></ngx-patient-activity>
    </div>
</div>