<nb-card style="width: 800px;">
    <nb-card-header>
        Visit at <strong>{{ relatedVisit.date | date: "EEEE, MMM d, yyyy"}} {{ relatedVisit.startTime | date: "hh:mm a"}}</strong>
    </nb-card-header>
    <nb-card-body>
        <div *ngIf="relatedVisit.status=='NA'" class="col-md-12">
            <div class="row" style="text-align: center;margin-top: 20px;">
                <div class="col-md-6" >
                        <label class="label">Select Date</label>
                        <input readonly fullWidth nbInput placeholder="Visit Date" [nbDatepicker]="visitNewDatePicker"
                            [(ngModel)]="newVisitDate">
                        <nb-datepicker #visitNewDatePicker></nb-datepicker>
                </div>
                <div class="col-md-6" >
                        <label class="label">Start Time</label>
                        <!--<ngb-timepicker [(ngModel)]="time" [seconds]="false"
                            [hourStep]="hourStep" [minuteStep]="minuteStep" [secondStep]="secondStep"></ngb-timepicker>-->
                        <input nbInput [(ngModel)]="newVisitStartTime" type="number" min="10" max="24" step="0.25" 
                                        placeholder="Pick time from 0 to 24 (0 == 12AM)"
                                        [status]="newVisitStartTime < 0 || newVisitStartTime > 24 ? 'danger' : 'basic'" fullWidth/>
                </div>
            </div>
            <div style="text-align: center;margin-top: 20px;">
                <button (click)="updateVisitStatus(2)" style="margin-right: 20px;" nbButton status="success">Check-in</button>
                <button (click)="updateVisitStatus(3)" style="margin-right: 20px;" nbButton status="danger">Missed</button>
                <button (click)="updateVisitStatus(4)" style="margin-right: 20px;" nbButton status="primary" [disabled]="newVisitStartTime <= 0" >Reschedule</button>
            </div>
        </div>
        <div *ngIf="relatedVisit.status=='Checked in'" class="col-md-12">
            <ngx-empty-state image="papers.png" text="This visit was Checked-In!">
            </ngx-empty-state>
        </div>
        <div *ngIf="relatedVisit.status=='Missed'" class="col-md-12">
            <ngx-empty-state image="sleep.png" text="This visit was Missed!">
            </ngx-empty-state>
        </div>
    </nb-card-body>
</nb-card>
