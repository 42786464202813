import { Component, OnInit, TemplateRef } from "@angular/core";
import { NbDialogService } from "@nebular/theme";
import { DataService } from "../../../../@core/app-services/data.service";
import { ToasterHelper } from "../../../../@core/app-services/toasterHelper";
import { TransactionType } from "../../../../@core/models/TransactionType";

@Component({
  selector: "ngx-spend-type-manager",
  templateUrl: "./spend-type-manager.component.html",
  styleUrls: ["./spend-type-manager.component.scss"],
})
export class SpendTypeManagerComponent implements OnInit {
  spendTypes: TransactionType[] = new Array();
  selectedSpendType: TransactionType = new TransactionType();
  gettingSpendTypes: boolean = true;
  savingSpendType: boolean = false;
  constructor(
    private dataService: DataService,
    private dialogService: NbDialogService,
    private toasterHelper: ToasterHelper
  ) {
    dataService.getSpendTypes().subscribe((data) => {
      this.spendTypes = data;
      this.gettingSpendTypes = false;
    });
  }
  openSpendTypeDialog(spendType: TransactionType, dialog: TemplateRef<any>) {
    if (spendType == null) {
      this.selectedSpendType = new TransactionType();
    } else {
      this.selectedSpendType.id = spendType.id;
      this.selectedSpendType.title = spendType.title;
      this.selectedSpendType.hold = spendType.hold;
    }
    this.dialogService.open(dialog);
  }
  saveSpendType(ref) {
    this.savingSpendType = true;
    this.dataService
      .addOrUpdateSpendType(this.selectedSpendType)
      .subscribe((data) => {
        if (data.status) {
          this.toasterHelper.showSaved(data.message);
          var cat = this.spendTypes.find((c) => c.id == data.responseObject.id);
          const index = this.spendTypes.indexOf(cat, 0);
          if (index > -1) {
            this.spendTypes.splice(index, 1);
          }
          this.spendTypes.push(data.responseObject);
          this.spendTypes.sort((n1, n2) => {
            if (n1.id > n2.id) {
              return 1;
            }
            if (n1.id < n2.id) {
              return -1;
            }
            return 0;
          });
          ref.close();
        } else {
          this.toasterHelper.showError(data.message);
        }
        this.savingSpendType = false;
      });
  }
  deleteSpendType(spendType: TransactionType) {
    this.dataService.deleteOperationPlanType(spendType.id).subscribe((data) => {
      if (data.status) {
        this.toasterHelper.showSaved(data.message);
        var cat = this.spendTypes.find((c) => c.id == spendType.id);
        cat.hold = true;
      } else {
        this.toasterHelper.showError(data.message);
      }
    });
  }
  ngOnInit(): void {}
}
