<div class="row">
    <div class="col-md-10">

        <h1 id="title" class="title">Reset password</h1>
        <p class="sub-title">reset password with its email.</p>




        <form #form="ngForm" aria-labelledby="title">

            <div class="form-control-group">
                <label class="label" for="input-email">Email address:</label>
                <input nbInput fullWidth [(ngModel)]="dashboardUser.email" #email="ngModel" name="email"
                    id="input-email" pattern=".+@.+\..+" placeholder="Email address" fieldSize="large" autofocus
                    [status]="email.dirty ? (email.invalid  ? 'danger' : 'success') : 'basic'"
                    [attr.aria-invalid]="email.invalid && email.touched ? true : null">
                <ng-container *ngIf="email.invalid && email.touched">
                    <p class="caption status-danger" *ngIf="email.errors?.required">
                        Email is required!
                    </p>
                    <p class="caption status-danger" *ngIf="email.errors?.pattern">
                        Email should be the real one!
                    </p>
                </ng-container>
            </div>

            <div class="form-control-group">
                <span class="label-with-link">
                    <label class="label" for="input-password">Password:</label>
                </span>
                <input nbInput fullWidth [(ngModel)]="dashboardUser.password" #password="ngModel" name="password"
                    type="password" id="input-password" placeholder="Password" fieldSize="large"
                    [status]="password.dirty ? (password.invalid  ? 'danger' : 'success') : 'basic'"
                    [attr.aria-invalid]="password.invalid && password.touched ? true : null">
                <ng-container *ngIf="password.invalid && password.touched ">
                    <p class="caption status-danger" *ngIf="password.errors?.required">
                        Password is required!
                    </p>
                    <p class="caption status-danger" *ngIf="password.errors?.minlength || password.errors?.maxlength">

                    </p>
                </ng-container>
            </div>



            <button nbButton fullWidth (click)="resetPassword()" status="primary" size="large">
                Submit
            </button>
        </form>
    </div>
</div>