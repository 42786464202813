import { Component, OnInit, ViewChild } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { DxFileUploaderComponent } from "devextreme-angular";
import { IngredientService } from "../../../../@core/app-services/ingredient.service";
import { MealService } from "../../../../@core/app-services/meal.service";
import { ToasterHelper } from "../../../../@core/app-services/toasterHelper";
// upload image definitions
import { AwsRepoConfig, AwsS3Manage } from "../../../../@core/lib/AwsS3Manage";

import { Ingredient } from "../../../../@core/models/ingredient";
import { Meal } from "../../../../@core/models/meal";
import { MealType } from "../../../../@core/models/mealType";

@Component({
  selector: "ngx-meal",
  templateUrl: "./meal.component.html",
  styleUrls: ["./meal.component.scss"],
})
export class MealComponent implements OnInit {
  selectedFile: File;
  awsS3Manage: AwsS3Manage = new AwsS3Manage();
  mealTypes: MealType[] = [];
  mealType: MealType = null;
  meals: Meal[] = [];
  meal: Meal = null;
  selectedMeal: Meal;
  ingredients: Ingredient[] = [];
  ingredient: Ingredient;
  refreshMode: string;
  taskSubject: string;
  taskDetailsHtml: SafeHtml;
  taskStatus: string;
  taskProgress: string;
  currentFilter: any;
  rowKey;

  isDisabled: boolean = true;
  showFilterRow: boolean = true;
  loadingMealTypes: boolean = false;
  loadingMeals: boolean = false;
  loadingIngredients: boolean = false;
  showHeaderFilter: boolean = true;
  autoNavigateToFocusedRow = true;
  backendURL = "http://localhost:4200/";
  retryButtonVisible = true;
  @ViewChild("uploadedImage") uploadedImageRef: HTMLImageElement;
  @ViewChild("fileUploader") fileUploaderRef: DxFileUploaderComponent;
  constructor(
    private mealService: MealService,
    private ingredientService: IngredientService,
    private toasterHelper: ToasterHelper,
    private sanitizer: DomSanitizer,
    private router: Router
  ) {}

  selectFile(e: any) {}

  // meal type functions
  getMealTypes() {
    this.loadingMealTypes = true;
    this.mealService.getMealTypes().subscribe(
      (data) => {
        if (data.status) {
          this.toasterHelper.showToast(
            "Get successfully",
            data.message,
            "success"
          );
          this.mealTypes = data.responseObject;
        } else {
          this.mealTypes = [];
          this.toasterHelper.showError(data.message);
        }
        this.loadingMealTypes = false;
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
        this.loadingMealTypes = false;
      }
    );
  }

  updateMealType(e) {
    this.loadingMealTypes = true;
    let updatedMealType = new MealType();
    this.mealType = e.data;
    updatedMealType.nameAr = this.mealType.nameAr;
    updatedMealType.nameEn = this.mealType.nameEn;
    this.mealService
      .updateMealType(this.mealType.id, updatedMealType)
      .subscribe(
        (data) => {
          if (data.status) {
            this.toasterHelper.showToast(
              "Updated successfully",
              data.message,
              "success"
            );
          } else {
            this.toasterHelper.showError(data.message);
          }
          this.loadingMealTypes = false;

          this.getMealTypes();
        },
        (error) => {
          this.toasterHelper.showError("Something went wrong");
          this.loadingMealTypes = false;
        }
      );
  }

  addMealType(e) {
    this.loadingMealTypes = true;
    let newMealType = new MealType();
    this.mealType = e.data;
    newMealType.nameAr = this.mealType.nameAr;
    newMealType.nameEn = this.mealType.nameEn;

    this.mealService.addMealType(newMealType).subscribe(
      (data) => {
        if (data.status) {
          this.toasterHelper.showToast(
            "Added successfully",
            data.message,
            "success"
          );
        } else {
          this.toasterHelper.showError(data.message);
        }
        this.loadingMealTypes = false;

        this.getMealTypes();
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
        this.loadingMealTypes = false;
      }
    );
  }
  deleteMealType(e) {
    this.loadingMealTypes = true;
    this.mealType = e.data;
    this.mealService.deleteMealType(this.mealType.id).subscribe(
      (data) => {
        if (data.status) {
          this.toasterHelper.showToast(
            "Deleted successfully",
            data.message,
            "success"
          );
        } else {
          this.toasterHelper.showError(data.message);
        }
        this.loadingMealTypes = false;
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
        this.loadingMealTypes = false;
      }
    );
  }
  // ingredients functions
  getIngredientsByMeal(mealId: number) {
    this.loadingIngredients = true;
    this.ingredientService.getIngredientsByMealId(mealId).subscribe(
      (data) => {
        if (data.status) {
          this.toasterHelper.showToast(
            "get successfully",
            data.message,
            "success"
          );
          this.ingredients = data.responseObject;
        } else {
          this.ingredients = [];
        }
        this.loadingIngredients = false;
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
        this.loadingIngredients = false;
      }
    );
  }

  addIngredient(e) {
    this.loadingIngredients = true;

    let newIngredient = new Ingredient();
    this.ingredient = e.data;
    newIngredient.nameAr = this.ingredient.nameAr;
    newIngredient.nameEn = this.ingredient.nameEn;
    newIngredient.amount = this.ingredient.amount;
    newIngredient.uniteAr = this.ingredient.uniteAr;
    newIngredient.uniteEn = this.ingredient.uniteEn;
    newIngredient.mealId = this.selectedMeal.id;

    this.ingredientService.addIngredient(newIngredient).subscribe(
      (data) => {
        if (data.status) {
          this.toasterHelper.showToast(
            "Added successfully",
            data.message,
            "success"
          );
        } else {
          this.toasterHelper.showError(data.message);
        }
        this.loadingIngredients = false;
        this.getIngredientsByMeal(this.selectedMeal.id);
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
        this.loadingIngredients = false;
      }
    );
  }

  updateIngredient(e) {
    this.loadingIngredients = true;
    let updatedIngredient = new Ingredient();
    this.ingredient = e.data;
    updatedIngredient.nameAr = this.ingredient.nameAr;
    updatedIngredient.nameEn = this.ingredient.nameEn;

    updatedIngredient.amount = this.ingredient.amount;
    updatedIngredient.uniteAr = this.ingredient.uniteAr;
    updatedIngredient.uniteEn = this.ingredient.uniteEn;

    updatedIngredient.mealId = this.selectedMeal.id;

    this.ingredientService
      .updateIngredient(this.ingredient.id, updatedIngredient)
      .subscribe(
        (data) => {
          if (data.status) {
            this.toasterHelper.showToast(
              "Added successfully",
              data.message,
              "success"
            );
          } else {
            this.toasterHelper.showError(data.message);
          }
          this.loadingIngredients = false;
          this.getIngredientsByMeal(this.selectedMeal.id);
        },
        (error) => {
          this.toasterHelper.showError("Something went wrong");
          this.loadingIngredients = false;
        }
      );
  }
  deleteIngredient(e) {
    this.loadingIngredients = true;
    this.ingredient = e.data;
    this.ingredientService.deleteIngredient(this.ingredient.id).subscribe(
      (data) => {
        if (data.status) {
          this.toasterHelper.showToast(
            "Deleted successfully",
            data.message,
            "success"
          );
        } else {
          this.toasterHelper.showError(data.message);
        }
        this.loadingIngredients = false;
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
        this.loadingIngredients = false;
      }
    );
  }
  // meal functions
  getMeals() {
    this.loadingMeals = true;
    this.mealService.getMeals().subscribe(
      (data) => {
        if (data.status) {
          this.meals = data.responseObject;
        } else {
          this.meals = [];
          this.toasterHelper.showError(data.message);
        }
        this.loadingMeals = false;
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
        this.loadingMeals = false;
      }
    );
  }
  selectMeal(nameEn: string) {
    this.selectedMeal = this.meals.find(
      (p) => p.nameEn.trim() == nameEn.trim()
    );
    this.getIngredientsByMeal(this.selectedMeal.id);
    this.isDisabled = false;
  }
  addMeal(e) {
    this.loadingMeals = true;
    let newMeal = new Meal();
    this.meal = e.data;
    newMeal.nameAr = this.meal.nameAr;
    newMeal.nameEn = this.meal.nameEn;
    newMeal.calories = this.meal.calories;
    newMeal.carb = this.meal.carb;
    newMeal.cookingMinutes = this.meal.cookingMinutes;
    newMeal.descriptionAr = this.meal.descriptionAr;
    newMeal.descriptionEn = this.meal.descriptionEn;

    newMeal.fats = this.meal.fats;
    newMeal.mealTypeId = this.meal.mealTypeId;
    newMeal.proteins = this.meal.proteins;
    newMeal.recipeAr = this.meal.recipeAr;
    newMeal.recipeEn = this.meal.recipeEn;

    newMeal.weight = this.meal.weight;

    //Upload the file first
    if (this.selectedFile) {
      this.awsS3Manage
        .uploadFile({
          fileStream: this.selectedFile,
          name: newMeal.nameEn + "." + this.selectedFile.type.split("/").pop(),
          location: AwsRepoConfig.mealsURL,
        })
        .then((result) => {
          newMeal.imageUrl = result.Location;
          this.mealService.addMeal(newMeal).subscribe(
            (data) => {
              if (data.status) {
                this.toasterHelper.showToast(
                  "Added successfully",
                  data.message,
                  "success"
                );
              } else {
                this.toasterHelper.showError(data.message);
              }
              this.loadingMeals = false;
              this.selectedFile = null;
              this.getMeals();
            },
            (error) => {
              this.toasterHelper.showError("Something went wrong");
              this.loadingMeals = false;
            }
          );
        })
        .catch((error) => {
          this.toasterHelper.showError(
            "Couldn't set a photo for " + newMeal.nameEn
          );
          console.log(error.message);
        });
    } else {
      newMeal.imageUrl =
        "https://diethub-resources.s3.eu-central-1.amazonaws.com/defaultimage.jpg";
      this.mealService.addMeal(newMeal).subscribe(
        (data) => {
          if (data.status) {
            this.toasterHelper.showToast(
              "Added successfully",
              data.message,
              "success"
            );
          } else {
            this.toasterHelper.showError(data.message);
          }
          this.loadingMeals = false;
          this.getMeals();
        },
        (error) => {
          this.toasterHelper.showError("Something went wrong");
          this.loadingMeals = false;
        }
      );
    }
  }

  updateMeal(e) {
    this.loadingMeals = true;
    let updatedMeal = new Meal();
    this.meal = e.data;

    updatedMeal.recipeAr = this.meal.recipeAr;
    updatedMeal.recipeEn = this.meal.recipeEn;
    updatedMeal.nameAr = this.meal.nameAr;
    updatedMeal.nameEn = this.meal.nameEn;
    updatedMeal.calories = this.meal.calories;
    updatedMeal.carb = this.meal.carb;
    updatedMeal.cookingMinutes = this.meal.cookingMinutes;
    updatedMeal.descriptionAr = this.meal.descriptionAr;
    updatedMeal.descriptionEn = this.meal.descriptionEn;
    updatedMeal.fats = this.meal.fats;
    updatedMeal.imageUrl = this.meal.imageUrl;
    updatedMeal.mealTypeId = this.meal.mealTypeId;
    updatedMeal.proteins = this.meal.proteins;

    updatedMeal.weight = this.meal.weight;
    if (this.selectedFile) {
      this.awsS3Manage
        .uploadFile({
          fileStream: this.selectedFile,
          name:
            updatedMeal.nameEn + "." + this.selectedFile.type.split("/").pop(),
          location: AwsRepoConfig.mealsURL,
        })
        .then((result) => {
          updatedMeal.imageUrl = result.Location;
          this.mealService.updateMeal(this.meal.id, updatedMeal).subscribe(
            (data) => {
              if (data.status) {
                this.toasterHelper.showToast(
                  "Updated successfully",
                  data.message,
                  "success"
                );
              } else {
                this.toasterHelper.showError(data.message);
              }
              this.loadingMeals = false;
              this.selectedFile = null;
              this.getMeals();
            },
            (error) => {
              this.toasterHelper.showError("Something went wrong");
              this.loadingMeals = false;
            }
          );
        })
        .catch((error) => {
          this.toasterHelper.showError(
            "Couldn't set a photo for " + updatedMeal.nameEn
          );
          console.log(error.message);
        });
    } else {
      this.mealService.updateMeal(this.meal.id, updatedMeal).subscribe(
        (data) => {
          if (data.status) {
            this.toasterHelper.showToast(
              "Updated successfully",
              data.message,
              "success"
            );
          } else {
            this.toasterHelper.showError(data.message);
          }
          this.loadingMeals = false;
          this.selectedFile = null;
          this.getMeals();
        },
        (error) => {
          this.toasterHelper.showError("Something went wrong");
          this.loadingMeals = false;
        }
      );
    }
  }
  onEditingStart(e) {
    this.rowKey = e.key;
  }

  onSaving(e) {
    if (e.changes.length == 0) {
      e.changes.push({
        type: "update",
        key: this.rowKey,
        data: {},
      });
    }
  }
  deleteMeal(e) {
    this.loadingMeals = true;
    this.meal = e.data;
    if (
      this.meal.imageUrl ==
      "https://diethub-resources.s3.eu-central-1.amazonaws.com/defaultimage.jpg"
    ) {
      this.mealService.deleteMeal(this.meal.id).subscribe((data) => {
        if (data.status) {
          this.toasterHelper.showToast(
            "Deleted successfully",
            data.message,
            "success"
          );
        } else {
          this.toasterHelper.showError(data.message);
        }
        this.loadingMeals = false;
      });
    } else {
      this.awsS3Manage
        .deleteFile({
          fileName:
            this.meal.nameEn + "." + this.meal.imageUrl.split(".").pop(),
          location: AwsRepoConfig.mealsURL,
        })
        .then((result) => {
          this.mealService.deleteMeal(this.meal.id).subscribe(
            (data) => {
              if (data.status) {
                this.toasterHelper.showToast(
                  "Deleted successfully",
                  data.message,
                  "success"
                );
              } else {
                this.toasterHelper.showError(data.message);
              }
              this.loadingMeals = false;
            },
            (error) => {
              this.toasterHelper.showError("Something went wrong");
              this.loadingMeals = false;
            }
          );
        })
        .catch((error) => {
          this.toasterHelper.showError("Couldn't set a photo for ");
          console.log(error.message);
        });
    }
  }
  onFocusedRowChanging(e) {
    const rowsCount = e.component.getVisibleRows().length;
    const pageCount = e.component.pageCount();
    const pageIndex = e.component.pageIndex();
    const key = e.event && e.event.key;
    const rowData = e.row && e.row.data;
    if (key && e.prevRowIndex === e.newRowIndex) {
      if (e.newRowIndex === rowsCount - 1 && pageIndex < pageCount - 1) {
        e.component.pageIndex(pageIndex + 1).done(() => {
          e.component.option("focusedRowIndex", 0);
        });
      } else if (e.newRowIndex === 0 && pageIndex > 0) {
        e.component.pageIndex(pageIndex - 1).done(() => {
          e.component.option("focusedRowIndex", rowsCount - 1);
        });
      }
    }
  }
  onFocusedRowChanged(e) {
    const rowData = e.row && e.row.data;
    // this.rowPackage= rowData;
    if (rowData) {
      this.taskSubject = rowData.Task_Subject;
      this.taskDetailsHtml = this.sanitizer.bypassSecurityTrustHtml(
        rowData.Task_Description
      );
      this.taskStatus = rowData.Task_Status;
      this.taskProgress = rowData.Task_Completion
        ? `${rowData.Task_Completion}` + "%"
        : "";
    }
  }

  onClick(e: any): void {}

  onValueChanged(e: any): void {
    this.selectedFile = e.value[0]; // convert to base64 string
  }

  onUploaded(e: any, cellInfo: any): void {}

  onUploadError(e: any): void {
    const xhttp = e.request;
    if (xhttp.status === 400) {
      e.message = e.error.responseText;
    }
    if (xhttp.readyState === 4 && xhttp.status === 0) {
      e.message = "Connection refused";
    }
  }

  onEditCanceled(e: any): void {}

  onSaved(e: any): void {}

  ngOnInit(): void {
    this.getMealTypes();
    this.getMeals();
  }
}
