import { Component, OnInit } from '@angular/core';
import { ActivityService } from '../../../@core/app-services/activity.service';
import { BodyProfileService } from '../../../@core/app-services/bodyProfile.service';
import { CallService } from '../../../@core/app-services/call.service';
import { OperationPlanService } from '../../../@core/app-services/operationPlan.service';
import { TreasuryService } from '../../../@core/app-services/treasury.service';
import { VisitService } from '../../../@core/app-services/visit.service';
import { UserSharedService } from '../../../@core/shared-services/user-shared.service';

@Component({
  selector: 'ngx-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss']
})
export class DashboardIndexComponent implements OnInit {
  //dashboard inputs
  startDate: Date = new Date();
  endDate: Date = new Date();
  reloadingDashboard: boolean = false;
  
  //chart data
  visitStatusPieData: any[];
  ActivityTypePieData: any[];
  callStatusPieData:any [];
  callReasonPieData:any [];
  operationPlanPieData:any [];
  spendingTypePieData:any [];
  muscleIndicatorPieData:any [];
  fatIndicatorPieData:any [];
  resolveOverlappingTypes = ['shift', 'hide', 'none'];
  PieView: any[] = [700, 220];

  // chart options
  gradient: boolean = true;
  showLegend: boolean = true;
  showLabels: boolean = true;
  isDoughnut: boolean = false;

  colorScheme = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA','#324aa8','#bf198d']
  };

  onSelect(data): void {
  }

  onActivate(data): void {
  }

  onDeactivate(data): void {
  }
  customizeLabel(arg) {
    return `${arg.argumentText} (${arg.percentText})`;
  }
  constructor(private visitService: VisitService,
              private activityService:ActivityService,
              private callService:CallService,
              private operationPlanService:OperationPlanService,
              private treasuryService:TreasuryService,
              private bodyProfileService: BodyProfileService,
              public userSharedService: UserSharedService) {
      this.reloadDashboard();
   }
   reloadDashboard(){
    this.visitService.getVisitStatusChartByTime(this.startDate,this.endDate).subscribe((data) => {
      this.visitStatusPieData = data;
    });
    this.visitService.getActivityTypeChartByTime(this.startDate,this.endDate).subscribe((data) => {
      this.ActivityTypePieData = data;
    });
    this.callService.getCallStatusChartByTime(this.startDate,this.endDate).subscribe((data) => {
      this.callStatusPieData = data;
    });
    this.callService.getCallReasonChartByTime(this.startDate,this.endDate).subscribe((data) => {
      this.callReasonPieData = data;
    });
    this.operationPlanService.getOperationPlanChartByTime(this.startDate,this.endDate).subscribe((data) => {
      this.operationPlanPieData = data;
    });
    this.treasuryService.getSpendingTypeChartByTime(this.startDate,this.endDate).subscribe((data) => {
      this.spendingTypePieData = data;
    });
    this.bodyProfileService.getBodyProfileChartByTime(this.startDate,this.endDate,"BodyFatMass").subscribe((data) => {
      this.fatIndicatorPieData = data;
    });
    this.bodyProfileService.getBodyProfileChartByTime(this.startDate,this.endDate,"SMM").subscribe((data) => {
      this.muscleIndicatorPieData = data;
    });
   }
  flipped = false;
  ngOnInit(): void {
  }
  toggleView() {
    this.flipped = !this.flipped;
  }
}
