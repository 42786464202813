<div class="row">
    <div class="col-md-12">
        <nb-card class="own-scroll">
            <nb-card-header>
                <div class="row">
                    <div class="col-md-10">
                        Call History
                    </div>
                    <div class="col-md-2">
                        <nb-actions>
                            <!--<nb-action title="Log a new Call" (click)="openLogNewCall(dialogAddCall)" icon="plus-outline">
                            </nb-action>!-->
                            <nb-action title="Filter" (click)="openFilterDialog(dialogFilter)" icon="funnel-outline">
                            </nb-action>
                        </nb-actions>
                    </div>
                </div>
            </nb-card-header>
            <nb-card-body [nbSpinner]="getClientCalls" nbSpinnerStatus="primary" nbSpinnerSize="large"
                nbSpinnerMessage="Getting Calls">
                <div class="table-container">
                    <table class="table" >
                        <thead>
                            <tr>
                                <th>
                                    Call date
                                </th>
                                <th>
                                    Client
                                </th>
                                <th>
                                    Log
                                </th>
                                <th>
                                    Logged by
                                </th>
                                <th>
                                    Status
                                </th>
                                <th>
                                    Audited by
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let call of calls | slice: (page-1) * pageSize : page * pageSize">
                                <td>
                                    {{ call.callDate | date: "dd-MM-yyyy h:mm:ss a" }}
                                </td>
                                <td>
                                    {{ call.patientName }}
                                </td>
                                <td>
                                        {{call.log}}
                                </td>
                                <td>
                                    {{call.caller}}
                                </td>
                                <td>
                                    <p *ngIf="call.status==0">Unhandled</p>
                                    <p *ngIf="call.status==1">Follow-up</p>
                                    <p *ngIf="call.status==2">Submitted</p>
                                    <p *ngIf="call.status==3">Audited</p>
                                </td>
                                <td>
                                    {{call.auditer}}
                                </td>
                                <td>
                                    <nb-actions fullWidth size="small">
                                        
                                        <nb-action title="Edit" (click)="editCallDialog(dialogEditCall,call)"
                                            icon="edit-2-outline"></nb-action>
                                        <nb-action title="Details"
                                            (click)="openCallDescription(dialogDescription, call)"
                                            icon="message-square-outline"></nb-action>
                                        <nb-action [disabled]="call.auditer!='' ? true : null" title="Audit" (click)="openAuditCall(dialogAudit, call)"
                                            icon="award-outline"></nb-action>
                                    </nb-actions>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <ngb-pagination [(page)]="page" class="d-flex justify-content-center" [pageSize]="pageSize" [collectionSize]="calls.length"></ngb-pagination>
                </div>
            </nb-card-body>
        </nb-card>
    </div>
</div>
<ng-template #dialogDescription let-data let-ref="dialogRef">
    <nb-card [size]="'medium'">
        <nb-card-header>
            Call Details
        </nb-card-header>
        <nb-card-body>
            <b>Description:</b> <p [innerHTML]="description"></p>
            <b>Reason:</b><p [innerHTML]="reason"></p>
            <b>Auditer:</b><p [innerHTML]="auditer"></p>
            <b>Comment:</b><p [innerHTML]="comment"></p>
        </nb-card-body>
    </nb-card>
</ng-template>
<ng-template #dialogEditCall let-data let-ref="dialogRef">
    <nb-card [size]="'big'">
        <nb-card-header>
            Edit call
        </nb-card-header>
        <nb-card-body>
            <div class="form-group">
                <label class="label">Call Reason</label>
                <nb-select [(ngModel)]="this.logCallModel.reasonId" fullWidth>
                    <nb-option *ngFor="let reason of this.callReasons" [value]="reason.id">{{reason.name}}</nb-option>
                </nb-select>
            </div>
            <div class="form-group">
                <label class="label">Call Description</label>
                <textarea style="height: 150px;" fullWidth placeholder="Description" nbInput
                    [(ngModel)]="logCallModel.log"></textarea>
            </div>
            <div class="form-group">
                <nb-checkbox fullWidth [(ngModel)]="hasFollowup">
                    <strong>Didn't completed?</strong> Add a follow up
                </nb-checkbox>
            </div>
            <div class="form-group">
                <label class="label">Follow up Date</label>
                <input readonly [disabled]="!hasFollowup" fullWidth nbInput placeholder="Follow up Date"
                    [nbDatepicker]="DatePicker" [(ngModel)]="logCallModel.followupDate">
                <nb-datepicker #DatePicker></nb-datepicker>
            </div>
        </nb-card-body>
        <nb-card-footer style="text-align: right;">
            <button [nbSpinner]="edittingCall" (click)="editCall(ref)" nbSpinnerStatus="success" nbButton
                status="primary">Edit</button>
        </nb-card-footer>
    </nb-card>
</ng-template>
<ng-template #dialogAddCall let-data let-ref="dialogRef">
    <nb-card [size]="'medium'">
        <nb-card-header>
            Log a new call
        </nb-card-header>
        <nb-card-body>
            <div class="form-group">
                <label class="label">Call Description</label>
                <textarea style="height: 150px;" fullWidth placeholder="Description" nbInput
                    [(ngModel)]="logCallModel.log"></textarea>
            </div>

            <div class="form-group">
                <nb-checkbox fullWidth [(ngModel)]="hasFollowup">
                    <strong>Didn't completed?</strong> Add a follow up
                </nb-checkbox>
            </div>
            <div class="form-group">
                <label class="label">Follow up Date</label>
                <input readonly [disabled]="!hasFollowup" fullWidth nbInput placeholder="Follow up Date"
                    [nbDatepicker]="DatePicker" [(ngModel)]="logCallModel.followupDate">
                <nb-datepicker #DatePicker></nb-datepicker>
            </div>
        </nb-card-body>
        <nb-card-footer style="text-align: right;">
            <button [nbSpinner]="addingCall" (click)="logCall(ref)" nbSpinnerStatus="success" nbButton
                status="primary">Add</button>
        </nb-card-footer>
    </nb-card>
</ng-template>

<ng-template #dialogAudit let-data let-ref="dialogRef">
    <nb-card>
        <nb-card-header>
            Call Audit
        </nb-card-header>
        <nb-card-body>
            <div class="form-group">
                <label class="label">Audit Comment</label>
                <textarea style="height: 200px;" fullWidth placeholder="Comment" nbInput
                    [(ngModel)]="auditCallModel.auditComment"></textarea>
            </div>
        </nb-card-body>
        <nb-card-footer style="text-align: right;">
            <button [nbSpinner]="savingAudi" (click)="auditCall(ref)" nbSpinnerStatus="success" nbButton
                status="primary">Add</button>
        </nb-card-footer>
    </nb-card>
</ng-template>

<ng-template #dialogFilter let-data let-ref="dialogRef">
    <nb-card>
        <nb-card-header>
            Filteration Dialog
        </nb-card-header>
        <nb-card-body>
            <div class="form-group">
                <label class="label">From</label>
                <input readonly fullWidth nbInput placeholder="From" [nbDatepicker]="startDatePicker"
                            [(ngModel)]="dateFrom">
                <nb-datepicker #startDatePicker></nb-datepicker>
            </div>
            <div class="form-group">
                <label class="label">To</label>
                <input readonly fullWidth nbInput placeholder="To" [nbDatepicker]="endDatePicker"
                            [(ngModel)]="dateTo">
                <nb-datepicker #endDatePicker></nb-datepicker>
            </div>
            <div class="form-group">
                <label class="label">Call Status</label>
                <nb-select [(ngModel)]="filterStatus" fullWidth>
                    <nb-option value="1">Follow-up</nb-option>
                    <nb-option value="2">Submitted</nb-option>
                    <nb-option value="3">Audited</nb-option>
                </nb-select>
            </div>
            <div class="form-group">
                <label class="label">Call Reason</label>
                <nb-select [(ngModel)]="filterReason" fullWidth>
                    <nb-option *ngFor="let reason of this.callReasons" [value]="reason.id">{{reason.name}}</nb-option>
                </nb-select>
            </div>
        </nb-card-body>
        <nb-card-footer style="text-align: right;">
            <button (click)="filter(ref)" nbButton status="primary">Filter</button>
        </nb-card-footer>
    </nb-card>
</ng-template>