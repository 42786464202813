import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ngx-client-profile',
  templateUrl: './client-profile.component.html',
  styleUrls: ['./client-profile.component.scss']
})
export class ClientProfileComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
