import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { SessionTypeService } from "../../../../@core/app-services/session-type.service";
import { SessionTypeReviewService } from "../../../../@core/app-services/sessionTypeReview.service";
import { ToasterHelper } from "../../../../@core/app-services/toasterHelper";
import { AwsRepoConfig, AwsS3Manage } from "../../../../@core/lib/AwsS3Manage";
import { SessionType } from "../../../../@core/models/sessionType";
import { SessionTypeReview } from "../../../../@core/models/SessionTypeReview";

@Component({
  selector: "ngx-session-type-review",
  templateUrl: "./session-type-review.component.html",
  styleUrls: ["./session-type-review.component.scss"],
})
export class SessionTypeReviewComponent implements OnInit {
  SessionTypeReviews: SessionTypeReview[] = [];
  rowKey;
  SessionTypeReview: SessionTypeReview;
  imageSelectedFile: File;
  videoSelectedFile: File;
  awsS3Manage: AwsS3Manage = new AwsS3Manage();
  refreshMode: string;
  taskSubject: string;
  taskDetailsHtml: SafeHtml;
  taskStatus: string;
  taskProgress: string;
  currentFilter: any;
  sessionType: SessionType;
  sessionTypes: SessionType[] = [];
  sessionTypeName: string = "";
  showFilterRow: boolean = true;

  showHeaderFilter: boolean = true;
  autoNavigateToFocusedRow = true;
  loadingSessionTypeReviews: boolean = false;
  loadingSessionTypes: boolean = false;
  isDisabled: boolean = true;
  constructor(
    private toasterHelper: ToasterHelper,
    private sanitizer: DomSanitizer,
    private SessionTypeReviewService: SessionTypeReviewService,
    private SessionTypeService: SessionTypeService
  ) {}
  selectSessionType() {
    this.sessionType = this.sessionTypes.find(
      (p) => p.nameEn.trim() == this.sessionTypeName.trim()
    );
    this.getSessionTypeReviews();
    this.isDisabled = false;
  }
  getSessionTypes() {
    this.loadingSessionTypes = true;
    this.SessionTypeService.getSessionTypes().subscribe(
      (data) => {
        if (data.status) {
          this.sessionTypes = data.responseObject;
        } else {
          this.sessionTypes = [];
        }
        this.loadingSessionTypes = false;
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
        this.loadingSessionTypes = false;
      }
    );
  }
  // get SessionTypeReview
  getSessionTypeReviews() {
    this.SessionTypeReviewService.getSessionTypeReviews(
      this.sessionType.id
    ).subscribe(
      (data) => {
        if (data.status) {
          this.SessionTypeReviews = data.responseObject;
        } else {
          this.SessionTypeReviews = [];
          this.toasterHelper.showError(data.message);
        }
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
      }
    );
  }

  async updateSessionTypeReview(e) {
    this.loadingSessionTypeReviews = true;
    let updatedSessionTypeReview = new SessionTypeReview();
    this.SessionTypeReview = e.data;
    let sessionTypeReview = new SessionTypeReview();
    updatedSessionTypeReview.description = this.SessionTypeReview.description;
    updatedSessionTypeReview.titleEn = this.SessionTypeReview.titleEn;
    updatedSessionTypeReview.titleAr = this.SessionTypeReview.titleAr;
    updatedSessionTypeReview.isApproved = this.SessionTypeReview.isApproved;
    updatedSessionTypeReview.sessionTypeId = this.sessionType.id;
    sessionTypeReview = this.SessionTypeReviews.find(
      (p) =>
        p.titleEn != undefined &&
        p.titleEn.toLowerCase().trim() ==
          this.SessionTypeReview.titleEn.toLowerCase().trim() &&
        p.id != this.SessionTypeReview.id
    );
    if (sessionTypeReview == undefined || sessionTypeReview == null) {
      if (this.imageSelectedFile) {
        await this.awsS3Manage
          .uploadFile({
            fileStream: this.imageSelectedFile,
            name:
              this.sessionType.nameEn +
              updatedSessionTypeReview.titleEn +
              "image" +
              "." +
              this.imageSelectedFile.type.split("/").pop(),
            location: AwsRepoConfig.sessionTypeReviewsURL,
          })
          .then((result) => {
            updatedSessionTypeReview.imageUrl = result.Location;
            this.imageSelectedFile = null;
          })
          .catch((error) => {
            this.toasterHelper.showError(
              "Couldn't set a image for " + updatedSessionTypeReview.titleEn
            );
            this.imageSelectedFile = null;
            console.log(error.message);
          });
      }
      if (this.videoSelectedFile) {
        await this.awsS3Manage
          .uploadFile({
            fileStream: this.videoSelectedFile,
            name:
              this.sessionType.nameEn +
              updatedSessionTypeReview.titleEn +
              "video" +
              "." +
              this.videoSelectedFile.type.split("/").pop(),
            location: AwsRepoConfig.sessionTypeReviewsURL,
          })
          .then((result) => {
            updatedSessionTypeReview.videoURL = result.Location;
            this.videoSelectedFile = null;
          })
          .catch((error) => {
            this.toasterHelper.showError(
              "Couldn't set a video for " + updatedSessionTypeReview.titleEn
            );
            this.videoSelectedFile = null;
            console.log(error.message);
          });
      }
      await this.SessionTypeReviewService.updateSessionTypeReview(
        this.SessionTypeReview.id,
        updatedSessionTypeReview
      ).subscribe(
        async (data) => {
          if (data.status) {
            this.toasterHelper.showToast(
              "Updated successfully",
              data.message,
              "success"
            );
          } else {
            this.toasterHelper.showError(data.message);
          }
          this.loadingSessionTypeReviews = false;

          await this.getSessionTypeReviews();
        },
        (error) => {
          this.toasterHelper.showError("Something went wrong");
          this.loadingSessionTypeReviews = false;
        }
      );
    } else {
      this.toasterHelper.showError("failed, please don't repeat the title");
      this.loadingSessionTypeReviews = false;
    }
  }

  async addSessionTypeReview(e) {
    this.loadingSessionTypeReviews = true;
    let newSessionTypeReview = new SessionTypeReview();
    this.SessionTypeReview = e.data;
    newSessionTypeReview.description = this.SessionTypeReview.description;
    newSessionTypeReview.titleEn = this.SessionTypeReview.titleEn;
    newSessionTypeReview.titleAr = this.SessionTypeReview.titleAr;
    newSessionTypeReview.isApproved = this.SessionTypeReview.isApproved;
    newSessionTypeReview.sessionTypeId = this.sessionType.id;
    let sessionTypeReview = new SessionTypeReview();
    sessionTypeReview = this.SessionTypeReviews.find(
      (p) =>
        p.titleEn != undefined &&
        p.titleEn.toLowerCase().trim() ==
          this.SessionTypeReview.titleEn.toLowerCase().trim() &&
        p.id != this.SessionTypeReview.id
    );
    if (sessionTypeReview == undefined || sessionTypeReview == null) {
      if (this.imageSelectedFile) {
        await this.awsS3Manage
          .uploadFile({
            fileStream: this.imageSelectedFile,
            name:
              this.sessionType.nameEn +
              newSessionTypeReview.titleEn +
              "image" +
              "." +
              this.imageSelectedFile.type.split("/").pop(),
            location: AwsRepoConfig.sessionTypeReviewsURL,
          })
          .then((result) => {
            newSessionTypeReview.imageUrl = result.Location;
          })
          .catch((error) => {
            this.toasterHelper.showError(
              "Couldn't set a image for " + newSessionTypeReview.titleEn
            );
            newSessionTypeReview.imageUrl =
              "https://diethub-resources.s3.eu-central-1.amazonaws.com/defaultimage.jpg";
            console.log(error.message);
          });
      } else {
        newSessionTypeReview.imageUrl =
          "https://diethub-resources.s3.eu-central-1.amazonaws.com/defaultimage.jpg";
      }

      if (this.videoSelectedFile) {
        await this.awsS3Manage
          .uploadFile({
            fileStream: this.videoSelectedFile,
            name:
              this.sessionType.nameEn +
              newSessionTypeReview.titleEn +
              "video" +
              "." +
              this.videoSelectedFile.type.split("/").pop(),
            location: AwsRepoConfig.sessionTypeReviewsURL,
          })
          .then((result) => {
            newSessionTypeReview.videoURL = result.Location;
          })
          .catch((error) => {
            this.toasterHelper.showError(
              "Couldn't set a video for " + newSessionTypeReview.titleEn
            );
            newSessionTypeReview.videoURL =
              "https://diethub-resources.s3.eu-central-1.amazonaws.com/defaultimage.jpg";
            console.log(error.message);
          });
      } else {
        newSessionTypeReview.videoURL =
          "https://diethub-resources.s3.eu-central-1.amazonaws.com/defaultimage.jpg";
      }
      await this.SessionTypeReviewService.addSessionTypeReview(
        newSessionTypeReview
      ).subscribe(
        async (data) => {
          if (data.status) {
            this.toasterHelper.showToast(
              "Added successfully",
              data.message,
              "success"
            );
          } else {
            this.toasterHelper.showError(data.message);
          }
          this.loadingSessionTypeReviews = false;
          this.imageSelectedFile = null;
          this.videoSelectedFile = null;
          await this.getSessionTypeReviews();
        },
        (error) => {
          this.toasterHelper.showError("Something went wrong");
          this.loadingSessionTypeReviews = false;
        }
      );
    } else {
      this.toasterHelper.showError("failed, please don't repeat the title");
      this.loadingSessionTypeReviews = false;
    }
  }
  deleteSessionTypeReview(e) {
    this.loadingSessionTypeReviews = true;
    this.SessionTypeReview = e.data;
    this.SessionTypeReviewService.deleteSessionTypeReview(
      this.SessionTypeReview.id
    ).subscribe(
      (data) => {
        if (data.status) {
          this.toasterHelper.showToast(
            "Deleted successfully",
            data.message,
            "success"
          );
        } else {
          this.toasterHelper.showError(data.message);
        }
        this.loadingSessionTypeReviews = false;
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
        this.loadingSessionTypeReviews = false;
      }
    );
    if (
      this.SessionTypeReview.imageUrl != null ||
      this.SessionTypeReview.imageUrl.length != 0 ||
      this.SessionTypeReview.imageUrl !=
        "https://diethub-resources.s3.eu-central-1.amazonaws.com/defaultimage.jpg"
    ) {
      this.awsS3Manage
        .deleteFile({
          fileName:
            this.sessionType.nameEn +
            this.SessionTypeReview.titleEn +
            "image" +
            "." +
            this.SessionTypeReview.imageUrl.split(".").pop(),
          location: AwsRepoConfig.sessionTypeReviewsURL,
        })
        .then((result) => {})
        .catch((error) => {
          this.toasterHelper.showError("Couldn't set a animation photo for ");
          console.log(error.message);
        });
    }

    if (
      this.SessionTypeReview.videoURL != null ||
      this.SessionTypeReview.videoURL.length != 0 ||
      this.SessionTypeReview.videoURL !=
        "https://diethub-resources.s3.eu-central-1.amazonaws.com/defaultimage.jpg"
    ) {
      this.awsS3Manage
        .deleteFile({
          fileName:
            this.sessionType.nameEn +
            this.SessionTypeReview.titleEn +
            "video" +
            "." +
            this.SessionTypeReview.videoURL.split(".").pop(),
          location: AwsRepoConfig.sessionTypeReviewsURL,
        })
        .then((result) => {})
        .catch((error) => {
          this.toasterHelper.showError("Couldn't set a video photo for ");
          console.log(error.message);
        });
    }
  }
  onFocusedRowChanging(e) {
    const rowsCount = e.component.getVisibleRows().length;
    const pageCount = e.component.pageCount();
    const pageIndex = e.component.pageIndex();
    const key = e.event && e.event.key;
    const rowData = e.row && e.row.data;
    if (key && e.prevRowIndex === e.newRowIndex) {
      if (e.newRowIndex === rowsCount - 1 && pageIndex < pageCount - 1) {
        e.component.pageIndex(pageIndex + 1).done(() => {
          e.component.option("focusedRowIndex", 0);
        });
      } else if (e.newRowIndex === 0 && pageIndex > 0) {
        e.component.pageIndex(pageIndex - 1).done(() => {
          e.component.option("focusedRowIndex", rowsCount - 1);
        });
      }
    }
  }
  onEditingStart(e) {
    this.rowKey = e.key;
  }

  onSaving(e) {
    if (e.changes.length == 0) {
      e.changes.push({
        type: "update",
        key: this.rowKey,
        data: {},
      });
    }
  }
  onFocusedRowChanged(e) {
    const rowData = e.row && e.row.data;
    // this.rowPackage= rowData;
    if (rowData) {
      this.taskSubject = rowData.Task_Subject;
      this.taskDetailsHtml = this.sanitizer.bypassSecurityTrustHtml(
        rowData.Task_Description
      );
      this.taskStatus = rowData.Task_Status;
      this.taskProgress = rowData.Task_Completion
        ? `${rowData.Task_Completion}` + "%"
        : "";
    }
  }
  onClick(e: any): void {}

  onImageValueChanged(e: any): void {
    this.imageSelectedFile = e.value[0]; // convert to base64 string
  }
  onVideoValueChanged(e: any): void {
    this.videoSelectedFile = e.value[0]; // convert to base64 string
  }

  onUploaded(e: any, cellInfo: any): void {}

  onUploadError(e: any): void {
    const xhttp = e.request;
    if (xhttp.status === 400) {
      e.message = e.error.responseText;
    }
    if (xhttp.readyState === 4 && xhttp.status === 0) {
      e.message = "Connection refused";
    }
  }

  onEditCanceled(e: any): void {}

  onSaved(e: any): void {}
  ngOnInit(): void {
    this.getSessionTypes();
  }
}
