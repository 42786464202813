import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { environment } from "../../../environments/environment";
import { Visit } from "../models/visit";

@Injectable({
  providedIn: "root",
})
export class VisitService {
  private updateVisitStatusPath =
    environment.apiUrl + "Visit/UpdateVisitStatus?visitId=";
  private updateVisitPhototsPath =
    environment.apiUrl + "Visit/UpdateVisitPhotos?visitId=";
  private getMyVisitsPath = environment.apiUrl + "Visit/getMyVisits";
  private getDaySlotsByDoctorPath =
    environment.apiUrl + "Visit/GetDaySlotsByDoctor?selectedDay=";
  private addVisitPath = environment.apiUrl + "Visit/AddVisit";
  private getVisitsByTimePath =
    environment.apiUrl + "Visit/GetVisitsByTime?from=";
  private getVisitStatusChartByTimePath =
    environment.apiUrl + "Visit/GetVisitStatusChartByTime?from=";
  private getActivityTypeChartByTimePath =
    environment.apiUrl + "Visit/GetActivityTypeChartByTime?from=";
  private updateVisitPath = environment.apiUrl + "Visit/UpdateVisit";
  private GetVisitByIdPath = environment.apiUrl + "Visit/GetVisitById?visitId=";
  private getClientVisitsPath =
    environment.apiUrl + "Visit/GetClientVisits?clientId=";

  constructor(private http: HttpClient) {}
  updateVisitStatus(visitId: number, status: number): Observable<any> {
    return this.http.post<any>(
      this.updateVisitStatusPath + visitId + "&status=" + status,
      ""
    );
  }
  updateVisitPhotos(visitId: number, photosUrls: string): Observable<any> {
    return this.http.post<any>(
      this.updateVisitPhototsPath + visitId + "&photosUrls=" + photosUrls,
      ""
    );
  }
  addVisit(visit: Visit): Observable<any> {
    return this.http.post<any>(this.addVisitPath, visit);
  }
  updateVisit(visit: Visit): Observable<any> {
    return this.http.post<any>(this.updateVisitPath, visit);
  }
  getMyVisits(): Observable<any> {
    return this.http.get<any>(this.getMyVisitsPath);
  }
  getDaySlotsByDoctor(selectedDay: Date, doctorId: string): Observable<any> {
    return this.http.get<any>(
      this.getDaySlotsByDoctorPath +
        selectedDay.toDateString() +
        "&doctorId=" +
        doctorId
    );
  }
  getVisitsByTime(from, to): Observable<any> {
    return this.http.get<any>(
      this.getVisitsByTimePath +
        from.toDateString() +
        "&to=" +
        to.toDateString()
    );
  }
  getVisitStatusChartByTime(from, to): Observable<any> {
    return this.http.get<any>(
      this.getVisitStatusChartByTimePath +
        from.toDateString() +
        "&to=" +
        to.toDateString()
    );
  }
  getActivityTypeChartByTime(from, to): Observable<any> {
    return this.http.get<any>(
      this.getActivityTypeChartByTimePath +
        from.toDateString() +
        "&to=" +
        to.toDateString()
    );
  }
  getVisitById(visitId: number): Observable<Visit> {
    return this.http.get<Visit>(this.GetVisitByIdPath + visitId);
  }
  getClientVisits(clientId): Observable<any> {
    return this.http.get<any>(this.getClientVisitsPath + clientId);
  }
}
