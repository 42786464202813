import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { environment } from "../../../environments/environment";
import { OperationPlanType } from "../models/operationPlanType";
import { OperationPlan } from "../models/operationPlan";
import { Milestone } from "../models/milestone";
import { Statistics } from "../models/statistics";
import { MilestoneProgress } from "../models/milestoneProgress";
import { Visit } from "../models/visit";

@Injectable({
  providedIn: "root",
})
export class OperationPlanService {
  private getOperationPlansPath = environment.apiUrl + "OperationPlan/GetOperationPlanTypes";
  private getPatientOperationPlansPath = environment.apiUrl + "OperationPlan/GetPatientOperations?patientId=";
  private addOperationPlanPath = environment.apiUrl + "OperationPlan/AddOperationPlan";
  private addMilestonePath = environment.apiUrl + "OperationPlan/AddMilestone";
  private getAddingOperationPlanDataModelPath = environment.apiUrl + "OperationPlan/GetAddingOperationPlanDataModel";
  private deletePlanPath = environment.apiUrl + "OperationPlan/deletePlan?planId=";
  private deleteMilestonePath = environment.apiUrl + "OperationPlan/deleteMilestone?milestoneId=";
  private getOperationPlanStatisticsPath = environment.apiUrl + "OperationPlan/getOperationPlanStatistics?operationPlanId=";
  private getMilestoneProgressPath = environment.apiUrl + "OperationPlan/getMilestoneProgress?milestoneId=";
  private getMilestoneVisitsPath = environment.apiUrl + "Visit/GetMilestoneVisits?milestoneId=";
  private getOperationPlanChartByTimePath = environment.apiUrl + "OperationPlan/GetOperationPlanChartByTime?from=";
  constructor(private http: HttpClient) {}
  getOperationPlanTypes(): Observable<OperationPlanType[]> {
    return this.http.get<OperationPlanType[]>(this.getOperationPlansPath );
  }

  getMilestoneProgress(milestoneId): Observable<MilestoneProgress[]> {
    return this.http.get<MilestoneProgress[]>(this.getMilestoneProgressPath+ milestoneId);
  }
  getMilestoneVisits(milestoneId): Observable<Visit[]> {
    return this.http.get<Visit[]>(this.getMilestoneVisitsPath+ milestoneId);
  }
  getOperationPlanStatistics(operationPlanId:number): Observable<Statistics> {
    return this.http.get<Statistics>(this.getOperationPlanStatisticsPath+operationPlanId );
  }
  getPatientOperationPlans(patientId:number): Observable<OperationPlan[]> {
    return this.http.get<OperationPlan[]>(this.getPatientOperationPlansPath+patientId);
  }
  getAddingOperationPlanDataModel(): Observable<any> {
    return this.http.get<any>(this.getAddingOperationPlanDataModelPath);
  }
  addOperationPlan(model:OperationPlan): Observable<any> {
    return this.http.post<any>(this.addOperationPlanPath,model);
  }
  addMilestone(model:Milestone): Observable<any> {
    return this.http.post<any>(this.addMilestonePath,model);
  }
  deletePlan(planId:number): Observable<any> {
    return this.http.post<any>(this.deletePlanPath + planId,'');
  }
  deleteMilestone(milestoneId:number): Observable<any> {
    return this.http.post<any>(this.deleteMilestonePath + milestoneId,'');
  }
  getOperationPlanChartByTime(from,to): Observable<any> {
    return this.http.get<any>(this.getOperationPlanChartByTimePath + from.toDateString() + '&to=' + to.toDateString());
  }
}
