import { Component, OnInit, TemplateRef } from "@angular/core";
import { NbDialogService } from "@nebular/theme";
import { ActivityService } from "../../../../@core/app-services/activity.service";
import { DataService } from "../../../../@core/app-services/data.service";
import { ToasterHelper } from "../../../../@core/app-services/toasterHelper";
import { ActivityTemplate } from "../../../../@core/models/activityTemplate";
import { ActivityType } from "../../../../@core/models/activityType";
import { ActivityTypeCategory } from "../../../../@core/models/activityTypeCategory";
import { MilestoneTemplate } from "../../../../@core/models/milestoneTemplate";
import { OperationPlanType } from "../../../../@core/models/operationPlanType";
import { VisitTemplate } from "../../../../@core/models/visitTemplate";

@Component({
  selector: "ngx-operation-plan-type-manager",
  templateUrl: "./operation-plan-type-manager.component.html",
  styleUrls: ["./operation-plan-type-manager.component.scss"],
})
export class OperationPlanTypeManagerComponent implements OnInit {
  planTypes: OperationPlanType[] = new Array();
  selectedPlanType: OperationPlanType;
  gettingMilestones: boolean = false;
  addingVisit: boolean = false;
  gettingOperationPlans: boolean = true;
  savingOperationPlan: boolean = false;
  gettingVisits: boolean = false;
  addingMilestone: boolean = false;
  gettingActivities: boolean = false;
  addingActivity: boolean = false;
  newMilestone: MilestoneTemplate = new MilestoneTemplate();
  selectedMilestone: MilestoneTemplate;
  milestones: MilestoneTemplate[] = new Array();
  visits: VisitTemplate[] = new Array();
  selectedVisit: VisitTemplate = new VisitTemplate();
  newVisit: VisitTemplate = new VisitTemplate();
  activities: ActivityTemplate[] = new Array();
  newActivity: ActivityTemplate = new ActivityTemplate();
  selectedActivityType: ActivityType = new ActivityType();
  activityCategories: ActivityTypeCategory[] = new Array();
  constructor(
    private dataService: DataService,
    private dialogService: NbDialogService,
    private toasterHelper: ToasterHelper,
    activityService: ActivityService
  ) {
    dataService.getOperationPlanTypes().subscribe((data) => {
      this.planTypes = data;
      this.gettingOperationPlans = false;
    });
    activityService.getActivityTypes().subscribe((data) => {
      this.activityCategories = data;
      this.selectedActivityType = this.activityCategories[0].activities[0];
    });
  }
  openPlanTypeDialog(planType: OperationPlanType, dialog: TemplateRef<any>) {
    if (planType == null) {
      this.selectedPlanType = new OperationPlanType();
    } else {
      this.selectedPlanType.id = planType.id;
      this.selectedPlanType.planTitle = planType.planTitle;
      this.selectedPlanType.hold = planType.hold;
    }
    this.dialogService.open(dialog);
  }
  savePlanType(ref) {
    this.savingOperationPlan = true;
    this.dataService
      .addOrUpdateOperationPlanType(this.selectedPlanType)
      .subscribe((data) => {
        if (data.status) {
          this.toasterHelper.showSaved(data.message);
          var cat = this.planTypes.find((c) => c.id == data.responseObject.id);
          const index = this.planTypes.indexOf(cat, 0);
          if (index > -1) {
            this.planTypes.splice(index, 1);
          }
          this.planTypes.push(data.responseObject);
          this.planTypes.sort((n1, n2) => {
            if (n1.id > n2.id) {
              return 1;
            }
            if (n1.id < n2.id) {
              return -1;
            }
            return 0;
          });
          ref.close();
        } else {
          this.toasterHelper.showError(data.message);
        }
        this.savingOperationPlan = false;
      });
  }
  selectPlan(plan) {
    this.selectedPlanType = plan;
    this.gettingMilestones = true;
    this.dataService
      .getOperationPlanTypeMilestones(plan.id)
      .subscribe((data) => {
        this.milestones = data;
        this.gettingMilestones = false;
      });
  }
  deletePlanType(planType: OperationPlanType) {
    this.dataService.deleteOperationPlanType(planType.id).subscribe((data) => {
      if (data.status) {
        this.toasterHelper.showSaved(data.message);
        var cat = this.planTypes.find((c) => c.id == planType.id);
        cat.hold = true;
      } else {
        this.toasterHelper.showError(data.message);
      }
    });
  }
  addActivity(ref) {
    this.addingActivity = true;
    this.newActivity.activityTypeId = this.selectedActivityType.id;
    this.newActivity.activityTitle = this.selectedActivityType.title;
    this.dataService
      .addOrUpdateVisitTemplateActivity(this.newActivity)
      .subscribe((data) => {
        if (data.status) {
          this.toasterHelper.showAdded(data.message);
          this.activities.push(data.responseObject);
          ref.close();
        } else {
          this.toasterHelper.showError(data.message);
        }
        this.addingActivity = false;
      });
  }
  addMilestone() {
    this.addingMilestone = true;
    this.newMilestone.planTypeId = this.selectedPlanType.id;
    this.newMilestone.title = "M" + (this.milestones.length + 1);
    this.dataService
      .addOrUpdateMilestoneTemplate(this.newMilestone)
      .subscribe((data) => {
        if (data.status) {
          this.toasterHelper.showAdded(data.message);
          this.newMilestone.id = data.responseObject;
          this.milestones.push(this.newMilestone);
        } else {
          this.toasterHelper.showError(data.message);
        }
        this.addingMilestone = false;
      });
  }
  deleteMilestone(milestone: MilestoneTemplate) {
    this.dataService.deleteMilestoneTemplate(milestone.id).subscribe((data) => {
      if (data.status) {
        this.toasterHelper.showSaved(data.message);
        this.selectedMilestone = null;
        const index = this.milestones.indexOf(milestone, 0);
        if (index > -1) {
          this.milestones.splice(index, 1);
        }
      } else {
        this.toasterHelper.showError(data.message);
      }
    });
  }
  openVisit(visit, dialog: TemplateRef<any>) {
    this.selectedVisit = visit;
    this.newActivity.visitId = this.selectedVisit.id;
    this.gettingActivities = true;
    this.dataService
      .getVisitTemplateActivities(this.selectedVisit.id)
      .subscribe((data) => {
        this.activities = data;
        this.gettingActivities = false;
      });
    this.dialogService.open(dialog);
  }
  openDialog(dialog: TemplateRef<any>) {
    this.dialogService.open(dialog);
  }

  openNewDialog(dialog: TemplateRef<any>)
  {
    this.newVisit = new VisitTemplate();
    this.newVisit.milestoneTemplateId = this.selectedMilestone.id;
    this.dialogService.open(dialog);
  }

  deleteVisit(ref) {
    this.dataService
      .deleteVisitTemplate(this.selectedVisit.id)
      .subscribe((data) => {
        if (data.status) {
          this.toasterHelper.showSaved(data.message);
          const index = this.visits.indexOf(this.selectedVisit, 0);
          if (index > -1) {
            this.visits.splice(index, 1);
          }
          ref.close();
        } else {
          this.toasterHelper.showError(data.message);
        }
      });
  }
  editVisit(dialog) {
    this.newVisit.day = this.selectedVisit.day;
    this.newVisit.duration = this.selectedVisit.duration;
    this.newVisit.id = this.selectedVisit.id;
    this.newVisit.milestoneTemplateId = this.selectedVisit.milestoneTemplateId;

    this.openDialog(dialog);
  }
  selectMilestone(milestone: MilestoneTemplate) {
    this.selectedMilestone = milestone;
    this.newVisit.milestoneTemplateId = this.selectedMilestone.id;
    this.gettingVisits = true;
    this.dataService
      .getMilestoneTemplateVisits(this.selectedMilestone.id)
      .subscribe((data) => {
        this.visits = data;
        this.gettingVisits = false;
      });
  }
  deleteActivity(activity: ActivityTemplate) {
    this.dataService
      .deleteVisitTemplateActivity(activity.id)
      .subscribe((data) => {
        if (data.status) {
          this.toasterHelper.showSaved(data.message);
          const index = this.activities.indexOf(activity, 0);
          if (index > -1) {
            this.activities.splice(index, 1);
          }
        } else {
          this.toasterHelper.showError(data.message);
        }
      });
  }
  addVisit(ref) {
    this.addingVisit = true;
    this.dataService
      .addOrUpdateVisitTemplate(this.newVisit)
      .subscribe((data) => {
        if (data.status) {
          this.toasterHelper.showAdded(data.message);
          this.newVisit = data.responseObject;
          if (this.newVisit.id == this.selectedVisit.id) {
            this.selectedVisit.day = this.newVisit.day;
            this.selectedVisit.duration = this.newVisit.duration;
          } else {
            this.visits.push(this.newVisit);
          }
          ref.close();
        } else {
          this.toasterHelper.showError(data.message);
        }
        this.addingVisit = false;
      });
  }
  ngOnInit(): void {}
}
