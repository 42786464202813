import { Component, OnInit, TemplateRef } from "@angular/core";
import { NbDialogService } from "@nebular/theme";
import { CallService } from "../../../@core/app-services/call.service";
import { ToasterHelper } from "../../../@core/app-services/toasterHelper";
import { AuditCallModel } from "../../../@core/models/auditCallModel";
import { Call } from "../../../@core/models/call";

@Component({
  selector: "ngx-audit-calls",
  templateUrl: "./audit-calls.component.html",
  styleUrls: ["./audit-calls.component.scss"],
})
export class AuditCallsComponent implements OnInit {
  gettingCalls: boolean = true;
  calls: Call[] = new Array();
  description: string;
  comment: string;
  savingAudit: boolean = false;
  auditCallModel: AuditCallModel = new AuditCallModel();
  constructor(
    private toasterHelper: ToasterHelper,
    private callService: CallService,
    private dialogService: NbDialogService
  ) {
    this.getCalls();
  }

  getCalls(){
    this.gettingCalls = true;
    this.callService.getSubmittedCalls().subscribe(data=>{
      this.calls = data;
      this.gettingCalls = false;
    });
  }
  openCallDescription(dialog: TemplateRef<any>, description: string) {
    this.description = description;
    this.dialogService.open(dialog);
  }
  openAuditComment(dialog: TemplateRef<any>, comment: string) {
    this.comment = comment;
    this.dialogService.open(dialog);
  }
  openAuditCall(dialog: TemplateRef<any>, callId: number) {
    this.auditCallModel.callId = callId;
    this.dialogService.open(dialog);
  }
  auditCall(ref) {
    this.savingAudit = true;
    this.callService.auditCall(this.auditCallModel).subscribe((data) => {
      if (data.status) {
        this.toasterHelper.showSaved(data.message);
        this.getCalls();        
      } else {
        this.toasterHelper.showError(data.message);
      }
      this.savingAudit = false;
      ref.close();
    });
  }
  ngOnInit(): void {}
}
