<div class="row">
    <div class="col-md-4">
        <nb-card [size]="'large'">
            <nb-card-header class="row">
                <div class="col-md-10">
                    Activity Categories
                </div>
                <div class="col-md-2">
                    <nb-action title="Add Category" icon="plus-outline"
                        (click)="openCategoryDialog(null,dialogCategory)">
                    </nb-action>
                </div>
            </nb-card-header>
            <nb-card-body [nbSpinner]="gettingCategories" nbSpinnerStatus="primary" nbSpinnerSize="large"
                nbSpinnerMessage="Getting Categories">
                <div *ngIf="categories.length == 0 && !gettingCategories">
                    <ngx-empty-state image="EmptyState.png" text="No categories found!">
                    </ngx-empty-state>
                    <div style="text-align: center;margin-top: 10px;">
                        <button (click)="openCategoryDialog(null,dialogCategory)" nbButton status="success">Add
                            Category</button>
                    </div>
                </div>
                <table class="table" *ngIf="categories.length>0">
                    <thead>
                        <tr>
                            <th>Category Title</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let category of categories">
                            <td>
                                {{category.title}}
                            </td>
                            <td>
                                <nb-actions size="small">
                                    <nb-action *ngIf="category.hold" icon="trash-2-outline" badgeText="Deleted"
                                        badgeStatus="danger">
                                    </nb-action>
                                    <nb-action *ngIf="!category.hold" title="Delete" icon="trash-2-outline"
                                        (click)="deleteCategory(category)">
                                    </nb-action>
                                    <nb-action (click)="openCategoryDialog(category,dialogCategory)" title="Edit"
                                        icon="edit-outline">
                                    </nb-action>
                                </nb-actions>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </nb-card-body>
        </nb-card>
    </div>
    <div class="col-md-8">
        <nb-card [size]="'large'">
            <nb-card-header class="row">
                <div class="col-md-10">
                    Activity Types
                </div>
                <div class="col-md-2">
                    <nb-action title="Add Type" icon="plus-outline"
                        (click)="openActivityTypeDialog(null,dialogActivityType)">
                    </nb-action>
                </div>
            </nb-card-header>
            <nb-card-body [nbSpinner]="gettingActivityTypes" nbSpinnerStatus="primary" nbSpinnerSize="large"
            nbSpinnerMessage="Getting Activity types">
                <div *ngIf="activityTypes.length == 0 && !gettingActivityTypes">
                    <ngx-empty-state image="EmptyState.png" text="No activity types found!">
                    </ngx-empty-state>
                    <div style="text-align: center;margin-top: 10px;">
                        <button (click)="openActivityTypeDialog(null,dialogActivityType)" nbButton status="success">Add
                            Activity Type</button>
                    </div>
                </div>
                <table class="table" *ngIf="activityTypes.length>0">
                    <thead>
                        <tr>
                            <th>Title</th>
                            <th>Category</th>
                            <th>User Group</th>
                            <th>Price</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let type of activityTypes">
                            <td>
                                {{type.title}}
                            </td>
                            <td>
                                {{type.categoryTitle}}
                            </td>
                            <td>
                                {{type.userTypeTitle}}
                            </td>
                            <td>
                                {{type.price}}
                            </td>
                            <td>
                                <nb-actions size="small">
                                    <nb-action *ngIf="type.hold" icon="trash-2-outline" badgeText="Deleted"
                                        badgeStatus="danger">
                                    </nb-action>
                                    <nb-action *ngIf="!type.hold" title="Delete" icon="trash-2-outline"
                                        (click)="deleteActivity(type)">
                                    </nb-action>
                                    <nb-action (click)="openActivityTypeDialog(type,dialogActivityType)" title="Edit"
                                        icon="edit-outline">
                                    </nb-action>
                                </nb-actions>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </nb-card-body>
        </nb-card>
    </div>
</div>

<ng-template #dialogCategory let-data let-ref="dialogRef">
    <nb-card>
        <nb-card-header>
            Category
        </nb-card-header>
        <nb-card-body>
            <div class="form-group">
                <input fullWidth [(ngModel)]="this.selectedCategory.title" placeholder="Category title" type="text"
                    nbInput>
            </div>
        </nb-card-body>
        <nb-card-footer style="text-align: right;">
            <button [nbSpinner]="savingCategory" nbSpinnerStatus="success" (click)="saveCategory(ref)" nbButton status="primary">Save</button>
        </nb-card-footer>
    </nb-card>
</ng-template>

<ng-template #dialogActivityType let-data let-ref="dialogRef">
    <nb-card>
        <nb-card-header>
            Activity
        </nb-card-header>
        <nb-card-body>
            <div class="form-group">
                <label class="label">Title</label>
                <input fullWidth [(ngModel)]="this.selectedActivityType.title" placeholder="Activity title" type="text"
                    nbInput>
            </div>
            <div class="form-group">
                <label class="label">Category</label>
                <nb-select [(ngModel)]="this.selectedActivityType.categoryId" fullWidth>
                    <nb-option *ngFor="let category of categories" [value]="category.id">{{category.title}}</nb-option>
                </nb-select>
            </div>
            <div class="form-group">
                <label class="label">User Group</label>
                <nb-select [(ngModel)]="this.selectedActivityType.userTypeId" fullWidth>
                    <nb-option *ngFor="let userType of userTypes" [value]="userType.id">{{userType.title}}</nb-option>
                </nb-select>
            </div>
            <div class="form-group">
                <label class="label">Price</label>
                <input fullWidth [(ngModel)]="this.selectedActivityType.price" placeholder="Price" type="text" nbInput>
            </div>
        </nb-card-body>
        <nb-card-footer style="text-align: right;">
            <button [nbSpinner]="savingActivityType" nbSpinnerStatus="success" (click)="saveActivity(ref)" nbButton status="primary">Save</button>
        </nb-card-footer>
    </nb-card>
</ng-template>