import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ClientSharedService } from '../../../@core/shared-services/client-shared.service';

@Component({
  selector: 'ngx-client',
  templateUrl: './patient.component.html',
  styleUrls: ['./patient.component.scss']
})
export class patientComponent implements OnInit {
  // constructor(private route: ActivatedRoute, private clientShared: ClientSharedService) { 
  //    this.route.params.subscribe(data =>{
  //     this.clientShared.clientId = data.id;
  //   })
  // }

  ngOnInit(): void {
  }

}
