import { Time } from "@angular/common";
import { DateTime } from "aws-sdk/clients/devicefarm";
import { Milestone } from "./milestone";

export class OperationPlan {
  planId: number;
  planType: number;
  planTitle: string;
  milestones: Milestone[] = new Array();
  patientId: number;
  doctorId: string;
  doctor: string;
  status:string;
  startDate:Date;
  startTime:Time;
}
