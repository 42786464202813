import { Component, OnInit } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { ApplicationUserService } from "../../../../@core/app-services/application-user.service";
import { BranchService } from "../../../../@core/app-services/branch.service";
import { PackageService } from "../../../../@core/app-services/package.service";
import { SubscriptionService } from "../../../../@core/app-services/subscription.service";
import { ToasterHelper } from "../../../../@core/app-services/toasterHelper";
import { AddSubscription } from "../../../../@core/models/addSubscription";
import { ApplicationUser } from "../../../../@core/models/applicationUser";
import { Branch } from "../../../../@core/models/Branch";
import { GetSubscription } from "../../../../@core/models/getSubscription";
import { Package } from "../../../../@core/models/package";
import { UpdateSubscription } from "../../../../@core/models/updateSubscription";

@Component({
  selector: "ngx-subscription",
  templateUrl: "./subscription.component.html",
  styleUrls: ["./subscription.component.scss"],
})
export class SubscriptionComponent implements OnInit {
  subscriptions: GetSubscription[] = [];
  subscription: GetSubscription;
  appUsers: ApplicationUser[];
  packages: Package[];
  branches: Branch[];

  refreshMode: string;
  taskSubject: string;
  taskDetailsHtml: SafeHtml;
  taskStatus: string;
  taskProgress: string;
  currentFilter: any;
  showFilterRow: boolean = true;
  showHeaderFilter: boolean = true;
  autoNavigateToFocusedRow = true;
  isAllowed: boolean = false;
  loadingSubscriptions: boolean = false;
  constructor(
    private subscriptionService: SubscriptionService,
    private branchService: BranchService,
    private applicationUserService: ApplicationUserService,
    private packageService: PackageService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private toasterHelper: ToasterHelper
  ) {}
  getSubscriptions() {
    this.loadingSubscriptions = true;
    this.subscriptionService.getAllSubscriptions().subscribe(
      (data) => {
        if (data.status) {
          this.subscriptions = data.responseObject;
        } else {
          this.subscriptions = [];
        }
        this.loadingSubscriptions = false;
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
        this.loadingSubscriptions = false;
      }
    );
  }

  addSubscription(e) {
    this.loadingSubscriptions = true;
    let newSubscription = new AddSubscription();
    this.subscription = e.data;
    newSubscription.applicationUserId = this.subscription.applicationUserId;
    newSubscription.packageId = this.subscription.packageId;
    newSubscription.expirationDate = this.subscription.expirationDate;
    newSubscription.freezedAt = this.subscription.freezedAt;
    newSubscription.ammount = this.subscription.ammount;
    newSubscription.currency = this.subscription.currency;
    newSubscription.isActive = this.subscription.isActive;
    newSubscription.branchId = this.subscription.branchId;

    if (
      newSubscription.isActive == null ||
      newSubscription.isActive == undefined
    ) {
      newSubscription.isActive = true;
    }
    newSubscription.isSingleUser = this.subscription.isSingleUser;
    newSubscription.userId = "e336ed74-00c6-408d-8928-35411e49c1f2";

    this.subscriptionService.addSubscription(newSubscription).subscribe(
      (data) => {
        if (data.status) {
          this.toasterHelper.showToast(
            "Added successfully",
            data.message,
            "success"
          );
        } else {
          this.toasterHelper.showError(data.message);
        }
        this.loadingSubscriptions = false;

        this.getSubscriptions();
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
        this.loadingSubscriptions = false;
      }
    );
  }

  updateSubscription(e) {
    this.loadingSubscriptions = true;
    let updatedSubscription = new UpdateSubscription();
    this.subscription = e.data;
    updatedSubscription.expirationDate = this.subscription.expirationDate;
    updatedSubscription.freezedAt = this.subscription.freezedAt;
    updatedSubscription.ammount = this.subscription.ammount;
    updatedSubscription.currency = this.subscription.currency;
    updatedSubscription.isActive = this.subscription.isActive;
    updatedSubscription.isSingleUser = this.subscription.isSingleUser;
    updatedSubscription.userId = "e336ed74-00c6-408d-8928-35411e49c1f2";

    this.subscriptionService
      .updateSubscription(this.subscription.id, updatedSubscription)
      .subscribe(
        (data) => {
          if (data.status) {
            this.toasterHelper.showToast(
              "Updated successfully",
              data.message,
              "success"
            );
          } else {
            this.toasterHelper.showError(data.message);
          }
          this.loadingSubscriptions = false;
          this.getSubscriptions();
        },
        (error) => {
          this.toasterHelper.showError("Something went wrong");
          this.loadingSubscriptions = false;
        }
      );
  }

  deleteSubscription(e) {
    this.loadingSubscriptions = true;
    this.subscription = e.data;
    this.subscriptionService.deleteSubscription(this.subscription.id).subscribe(
      (data) => {
        if (data.status) {
          this.toasterHelper.showToast(
            "Deleted successfully",
            data.message,
            "success"
          );
        } else {
          this.toasterHelper.showError(data.message);
        }
        this.loadingSubscriptions = false;
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
        this.loadingSubscriptions = false;
      }
    );
  }

  getAppUsers() {
    this.applicationUserService.getAllApplicationUsers().subscribe(
      (data) => {
        if (data.status) {
          this.appUsers = data.responseObject;
        }
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
      }
    );
  }
  getPackages() {
    this.packageService.getPackages().subscribe(
      (data) => {
        if (data.status) {
          this.packages = data.responseObject;
        }
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
      }
    );
  }
  getBranches() {
    this.branchService.getBranches().subscribe(
      (data) => {
        if (data.status) {
          this.branches = data.responseObject;
        }
      },
      (error) => {
        this.toasterHelper.showError("Something went wrong");
      }
    );
  }
  onFocusedRowChanging(e) {
    const rowsCount = e.component.getVisibleRows().length;
    const pageCount = e.component.pageCount();
    const pageIndex = e.component.pageIndex();
    const key = e.event && e.event.key;
    const rowData = e.row && e.row.data;
    if (key && e.prevRowIndex === e.newRowIndex) {
      if (e.newRowIndex === rowsCount - 1 && pageIndex < pageCount - 1) {
        e.component.pageIndex(pageIndex + 1).done(() => {
          e.component.option("focusedRowIndex", 0);
        });
      } else if (e.newRowIndex === 0 && pageIndex > 0) {
        e.component.pageIndex(pageIndex - 1).done(() => {
          e.component.option("focusedRowIndex", rowsCount - 1);
        });
      }
    }
  }
  onFocusedRowChanged(e) {
    const rowData = e.row && e.row.data;
    // this.rowPackage= rowData;
    if (rowData) {
      this.taskSubject = rowData.Task_Subject;
      this.taskDetailsHtml = this.sanitizer.bypassSecurityTrustHtml(
        rowData.Task_Description
      );
      this.taskStatus = rowData.Task_Status;
      this.taskProgress = rowData.Task_Completion
        ? `${rowData.Task_Completion}` + "%"
        : "";
    }
  }
  allowEditing() {
    this.isAllowed = true;
  }
  disAllowEditing() {
    this.isAllowed = false;
  }
  ngOnInit(): void {
    this.getAppUsers();
    this.getPackages();
    this.getBranches();
    this.getSubscriptions();
  }
}
